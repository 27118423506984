import { DocumentNode } from 'graphql';
import { groupBy } from 'lodash';
import { useMemo } from 'react';
import {
  Action, AggregateAuditLog, AuditLog, SourceType,
} from '../types/audit/AuditLog';
import { useQueryPager } from './useQueryPager/useQueryPager';
import { useSmartPoll } from './useSmartPoll';
import { useVisibilityChangedCallback } from './useVisibilityChangedCallback';

interface AuditLogAggregatorOptions {
  pageQuery: DocumentNode
  variables: any
}
export const useAuditLogAggregator = ({
  pageQuery,
  variables,
}: AuditLogAggregatorOptions) => {
  const {
    results: _results, Pager, refetch,
  } = useQueryPager<AuditLog>(pageQuery, {
    variables,
    limit: 99999,
    pollInterval: useSmartPoll(),
    parameterPrefix: 'log_',
  });
  useVisibilityChangedCallback(refetch, { onlyCallWhenVisible: true });

  /**
   * Group audit log items by transaction_id
   * for a much cleaner looking audit log
   * that is now by save, instead of by
   * join items.
   *
   * There is one special condition:
   *  - The `CREATE` action will single itself out
   *    and hide any other actions that were performed
   *    during the same transaction.
   */
  const results: AggregateAuditLog[] = useMemo(() => {
    const grouped = groupBy(_results, 'transaction_id');
    const result = Object.entries(grouped).map(([transaction_id, rows]) => {
      const user = rows.find(c => c.source_type === SourceType.USER)?.user;
      const item: AggregateAuditLog = {
        logs: [...rows.sort((a, b) => a.timestamp.localeCompare(b.timestamp))],
        source_type: user ? SourceType.USER : SourceType.SYSTEM,
        user,
        timestamp: new Date(Math.min(...rows.map(r => new Date(r.timestamp).getTime()))),
        transaction_id,
      };
      /**
       * Delete any other logs related to the CREATE action
       * for the processing activities and systems tables.
       *
       * This should probably be an option passed in to the
       * aggregator hook :)
       */
      if (item.logs[0].action === Action.CREATE && ['systems', 'processing_activities'].indexOf(item.logs[0].table_name) !== -1) {
        item.logs.length = 1;
      }
      return item;
    });
    return result.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
  }, [_results]);

  return {
    results,
    Pager,
  };
};
