import { RequestAttributes, SystemIntegrationAttributes } from '@privacy-request/value-objects';
import {
  ContextOptions,
  Pipeline,
  PipelineStep,
  PipelineStepResult,
  PipelineLogStatus,
  PipelineStepUpdate,
  PipelineStepUpdateData,
} from '@privacy-request/basic-types';
import {
  createContext, useCallback, useContext, useRef, useState,
} from 'react';
import update, { Spec } from 'immutability-helper';
import { backendUrl } from '../../utils/url';
import { PIPELINE_MESSAGE_SEPARATOR } from '@privacy-request/common/src/constants';
import store from '../../store';
import { Provider } from '../../store/auth/auth.types';
import { getIdToken } from '../../config/msal';

export type PipelineLog = (PipelineStepUpdateData & { appearance: PipelineStepUpdate['appearance'] });
export interface PipelineDebugStatus {
  status: 'waiting' | PipelineLogStatus['status']
  before?: PipelineStepResult
  after?: PipelineStepResult
  logs: PipelineLog[]
}

export interface PipelineDebug {
  steps: Record<string, PipelineDebugStatus>
  status: 'idle' | 'running' | 'finished'
}

const emptyDebug: PipelineDebug = {
  steps: {},
  status: 'idle',
};

interface PipelineDebugCtx {
  debug: PipelineDebug;
  onClearPipelineDebug: () => void;
  onTestPipeline: (set: Partial<SystemIntegrationAttributes>, pipeline: Pipeline, request: Partial<RequestAttributes>, options: ContextOptions) => Promise<void>
}

export const PipelineDebugContext = createContext<PipelineDebugCtx>(undefined!);

const processStepUpdate = (stepUpdate: PipelineStepUpdate) => (debug: PipelineDebug) => {
  if (!debug.steps[stepUpdate.target]) {
    return debug;
  }

  const op: Spec<PipelineDebug, any> = { steps: {} };

  switch (stepUpdate.data.type) {
    case 'status':
      op.steps[stepUpdate.target] = {
        status: { $set: stepUpdate.data.status },
      };
      if (stepUpdate.data.status !== 'running') {
        op.steps[stepUpdate.target].after = { $set: stepUpdate.data.result };
      }
      break;
    case 'file':
    case 'message':
    case 'object':
      op.steps[stepUpdate.target] = {
        logs: {
          $push: [{
            ...stepUpdate.data,
            appearance: stepUpdate.appearance,
          }],
        },
      };
      break;
    default:
      break;
  }

  return update(debug, op);
};

export const usePipelineDebugger = () => {
  const pipelineDebugger = useContext(PipelineDebugContext);
  return pipelineDebugger;
};

export const usePipelineDebuggerProvider = (id: number) => {
  const [debug, setDebug] = useState<PipelineDebug>(emptyDebug);
  const idRef = useRef(id);

  const onClearPipelineDebug = useCallback(() => {
    setDebug(emptyDebug);
  }, []);

  const onTestPipeline = useCallback(async (set: Partial<SystemIntegrationAttributes>, pipeline: Pipeline, request: Partial<RequestAttributes>, options: ContextOptions) => {
    const reducer = (acc: Record<string, PipelineDebugStatus>, curr: PipelineStep) => {
      acc[curr.id] = {
        status: 'waiting',
        logs: [],
      };
      if (curr.handler === 'condition') {
        // recursive reducer? heck ya
        curr.config.trueSteps?.reduce(reducer, acc);
        curr.config.falseSteps?.reduce(reducer, acc);
      }
      return acc;
    };
    const steps = pipeline.steps.reduce<Record<string, PipelineDebugStatus>>(reducer, {});
    setDebug({
      steps,
      status: 'running',
    });
    let lastIndex: number;
    const req = new XMLHttpRequest();
    req.withCredentials = true;
    req.timeout = 0;
    req.addEventListener('progress', function (e) {
      const SEP_LEN = PIPELINE_MESSAGE_SEPARATOR.length;
      let index = this.responseText.indexOf(PIPELINE_MESSAGE_SEPARATOR, lastIndex ? lastIndex + SEP_LEN : undefined);
      while (index !== -1) {
        const result = this.responseText.substring(lastIndex ? lastIndex + SEP_LEN : 0, index);
        lastIndex = index;
        index = this.responseText.indexOf(PIPELINE_MESSAGE_SEPARATOR, lastIndex ? lastIndex + SEP_LEN : undefined);
        setDebug(processStepUpdate(JSON.parse(result)));
      }
    });
    req.addEventListener('load', () => {
      setDebug(d => ({
        ...d,
        status: 'finished',
      }));
    });
    req.open('POST', backendUrl(`/systems/${idRef.current}/test-pipeline`));
    req.setRequestHeader('content-type', 'application/json');
    // Ensure token freshness
    if (store.getState().auth.provider === Provider.AAD) {
      await getIdToken();
    }
    // Get token
    const token = store.getState().auth.idToken;
    if (token) {
      req.setRequestHeader('authorization', `Bearer ${token}`);
    }
    req.send(JSON.stringify({
      request,
      set,
      options,
    }));
  }, []);

  return {
    debug,
    onClearPipelineDebug,
    onTestPipeline,
  };
};
