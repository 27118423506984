import realFetch, { IncomingOptions } from 'use-http';
import { augmentHeaders } from '../utils/http';

export const useFetch = <T = any>(url: string, options: IncomingOptions & { domain?: string | Function } = {}, ...args: any[]) => (
  realFetch<T>(url, {
    credentials: 'include',
    interceptors: {
      request: async ({ options: _options }) => {
        let _domain;
        if (typeof options.domain === 'function') {
          _domain = options.domain();
        }
        if (typeof options.domain === 'string') {
          _domain = options.domain;
        }
        return {
          ..._options,
          headers: await augmentHeaders({
            ...(options.headers || {}),
          }, _domain),
        };
      },
    },
    ...options,
  }, ...args)
);
