import React, {
  useCallback,
  useMemo,
} from 'react';
import {
  ChevRight,
} from '@privacy-request/ui';
import { TableCell, TableRow } from '@privacy-request/ui/src/atoms/SASSTable';
import { useTranslation } from 'react-i18next';
import { useIsAdminRoute } from '../../../../hooks/useIsAdminRoute';
import { Field, fieldBuilders } from './fieldBuilders';
import { Request } from '../../../../types/request/Request';
import { useRequestListItemHelper } from '../../hooks/useRequestListItemHelper';

export type SystemListRowProps = {
  request: Request
  fields: Field[]
  getRowLink: any
};

export const RequestListRow = (props: SystemListRowProps) => {
  const {
    request,
    fields,
    getRowLink,
  } = props;
  const {
    getStatus,
    getAction,
    getDueDateDisplay,
  } = useRequestListItemHelper(request);
  const [t] = useTranslation('requests');
  const isAdminRoute = useIsAdminRoute();
  const stopEvent = useCallback((e: any) => { e.preventDefault(); }, []);

  const fieldProps = useMemo(() => ({
    request,
    getRowLink,
    isAdminRoute,
    t,
    getStatus,
    getAction,
    getDueDateDisplay,
  }), [request, getRowLink, isAdminRoute, t, getStatus, getAction, getDueDateDisplay]);

  return (
    <TableRow>
      {
        fields.map((field) => (fieldBuilders[field])(fieldProps))
      }
      <TableCell
        onClick={stopEvent}
        style={{
          overflow: 'visible',
          flexDirection: 'row',
          display: 'flex',
          paddingRight: '8px',
          alignItems: 'center',
          height: '48px',
        }}
        shrink
      >
        <ChevRight />
      </TableCell>
    </TableRow>
  );
};
