import { Steps } from '@privacy-request/ui';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CachePolicies } from 'use-http';
import { EntityInfoDisplay } from '../../../../components/EntityInfoDisplay/EntityInfoDisplay';
import { useFetch } from '../../../../hooks/useFetch';
import { useMe } from '../../../../hooks/useMe';
import { appendIf } from '../../../../utils/appendIf';
import evt from '../../../../utils/evt';
import { backendUrl } from '../../../../utils/url';
import { useContextualActivity } from '../../hooks/useActivityContext';
import { approvalRequired } from '@privacy-request/common/src/utils/data_map/approvalRequired';
import { reviewRequired } from '@privacy-request/common/src/utils/data_map/reviewRequired';
import { DummyStep } from '../../../../components/DataMapWorkflowComponents/DummyStep/DummyStep';
import { DetailsStep } from '../OnboardingWorkflow/DetailsStep';
import { OwnershipSelectionStep } from '../OnboardingWorkflow/OwnershipSelectionStep';

export const ProcessingActivityEditInfo = () => {
  const {
    current: processingActivity,
    onChange,
    onSave,
    canEditPerm,
  } = useContextualActivity();
  const [t] = useTranslation('systems');
  const { isISO } = useMe();

  const wizardShowing = (approvalRequired(processingActivity) || reviewRequired(processingActivity)) && !processingActivity.published;

  const steps = useMemo(() => [{
    text: t('workflow.details'),
    value: 'details',
    Component: DetailsStep,
  }, {
    text: t('workflow.owners_and_contacts'),
    value: 'contacts',
    Component: OwnershipSelectionStep,
  }, {
    text: t('workflow.approval'),
    value: 'surebud',
    Component: DummyStep,
  }], [t]);

  const onRequestReview = useCallback(() => {
    onSave({ requestReview: true });
  }, [onSave]);

  const onPublish = useCallback(() => {
    onChange(evt('published', !processingActivity.published));
  }, [processingActivity, onChange]);

  const { get: fetch } = useFetch(backendUrl('processing-activities/check-name'), { cachePolicy: CachePolicies.NETWORK_ONLY });

  const validate = useCallback(async (suggestion: string) => {
    const result = await fetch(`?name=${encodeURIComponent(suggestion)}`);
    if (result !== suggestion) {
      return result;
    }
    return true;
  }, [fetch]);

  return (
    <div>
      {wizardShowing && (
        <Steps steps={steps} value="contacts" />
      )}
      <EntityInfoDisplay
        entity={processingActivity}
        onChange={onChange}
        validate={validate}
        onRequestReview={onRequestReview}
        onPublish={onPublish}
        canRequestReview={canEditPerm('processing_activities.actions.request_review')}
        canPublish={canEditPerm('processing_activities.edit.others')}
        errorKey={`systems:errors.processing_activity_taken${appendIf(isISO, '_service')}`}
      />
    </div>
  );
};
