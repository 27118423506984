import React from 'react';
import {
  Field,
  Label,
  SelectionItem,
} from '@privacy-request/ui';
import { DocumentNode } from 'graphql';
import { get } from 'lodash';
import { TagItem } from '../../../types/tagitem/TagItem';
import { GraphQLSearchAndSelect } from '../../GraphQLSearchAndSelect';
import { CustomFieldDefinition } from '../CustomFieldDefinition';
import { CustomFieldOptions } from '../CustomFieldOptions';
import { getRest } from '../utils/getRest';
import evt from '../../../utils/evt';

export interface CustomGraphQLSearchAndSelectField extends CustomFieldOptions {
  type: 'graphql-search-and-select'
  label?: string
  name: string
  query: DocumentNode
  filter?: any
  multi?: boolean
  add?: boolean
  disabled?: boolean
  onCreate?: (name: string) => Promise<any>
  formatItem: (entity: any) => SelectionItem
  variables?: any
}

export const graphqlSearchAndSelect: CustomFieldDefinition<CustomGraphQLSearchAndSelectField> = {
  type: 'graphql-search-and-select',
  component: (config, { onChange, values }) => {
    const onGQLSASChange = (items: TagItem[]) => {
      onChange(evt(config.name, items));
    };
    return (
      <GraphQLSearchAndSelect
        add={config.add}
        multi={config.multi}
        disabled={config.disabled}
        query={config.query}
        filter={config.filter}
        formatItem={config.formatItem}
        value={get(values, config.name) || []}
        name={config.name}
        onChange={onGQLSASChange}
        onCreate={config.onCreate}
        variables={config.variables}
        {...getRest(config)}
      />
    );
  },
  wrapper: (config, {
    first,
    last,
    fluid,
    content,
  }) => (
    <Field fluid={fluid} last={last} first={first} key={config.name}>
      {config.label && (<Label>{config.label}</Label>)}
      {content}
    </Field>
  ),
};
