import React, { useMemo } from 'react';
import { TableCell, ResizableTableHeader } from '@privacy-request/ui/src/atoms/SASSTable';
import {
  FieldBuilderComponent,
  FieldBuilderProps,
  FieldHeaderComponent,
  FieldHeaderProps,
} from './types/ColumnDefinition';
import { formatContact } from '../../../utils/formatContact';

export const Header: FieldHeaderComponent = ({
  t,
}: FieldHeaderProps) => (
  <ResizableTableHeader key="contacts">{t('table.owners')}</ResizableTableHeader>
);

export const Cell: FieldBuilderComponent = ({
  row,
}: FieldBuilderProps) => {
  const sortedContacts = useMemo(() => row.contacts?.map((contact: any) => (
    formatContact(contact)
  )).sort((contact1: any, contact2: any) => contact1.localeCompare(contact2)).join(', '), [row]);
  return (
    <TableCell key="contacts">{sortedContacts}</TableCell>
  );
};

export const relations = ['contacts'];
