import {
  API_LOCATION, BASE_HOST, SUBDOMAIN_URL,
} from '../constants';
import { stripAll, stripEnd } from './strip';

export const backendUrl = (path = '') => {
  if (API_LOCATION) {
    return `${stripEnd(API_LOCATION, '/')}/${stripAll(path, '/')}`;
  }
  // TODO Remove this in favour of always using REACT_APP_API_LOCATION
  return `${window.location.protocol}//localhost:4000/${stripAll(path, '/')}`;
};

export const frontendBaseUrl = (path = '') => (
  `${stripEnd(BASE_HOST, '/')}/${stripAll(path, '/')}`
);

export const frontendSubdomainUrl = (subdomain: string, path = '') => (
  `${stripEnd(SUBDOMAIN_URL, '/').replace(':subdomain:', subdomain)}/${stripAll(path, '/')}`
);
