import { useMutation } from '@apollo/react-hooks';
import {
  Button, Text, TitleBar,
} from '@privacy-request/ui';
import React, {
  useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  DeleteProcessingActivity, UpdateProcessingActivity, UpdateProcessingActivityVariables,
} from '../../apollo/systems/processing_activites/processing_activities.mutations';
import { GetProcessingActivitiesPaged } from '../../apollo/systems/processing_activites/processing_activities.queries';
import { Link } from '../../components/Link';
import { useMe } from '../../hooks/useMe';
import { usePermissions } from '../../hooks/usePermissions';
import { useQueryPager } from '../../hooks/useQueryPager/useQueryPager';
import { ProcessingActivity } from '../../types/system/ProcessingActivity';
import {
  Table,
} from '@privacy-request/ui/src/atoms/SASSTable';
import { useManagedFilters } from '../../components/DataMapEntityListFilterBar/useManagedFilters';
import { ActivityListFilterBar } from '../../components/DataMapEntityListFilterBar/ActivityListFilterBar';
import { columns } from './columns';
import { ListHeader } from '../../components/DataMapEntityList/ListHeader';
import { ProcessingActivityListRow } from './components/ProcessingActivityListRow';
import { SelectionContext, useSelectionContextController } from '../../hooks/useSelectionContext';
import { useResultSelections } from '../../hooks/shared/useResultSelections';
import { useBulkActionController } from '../../components/DataMapEntityList/bulk_actions/useBulkActionController';
import { BulkActionModalProvider } from '../../components/DataMapEntityList/bulk_actions/BulkActionModalProvider';

const pageSizes = [
  25,
  50,
  100,
  250,
];

export const ProcessingActivitiesList = ({ match }: RouteComponentProps) => {
  const [t] = useTranslation('systems');
  const { isISO, me } = useMe();
  const { hasPermission } = usePermissions();
  const canEditOthers = useMemo(() => hasPermission('processing_activities.edit.others'), [hasPermission]);
  const { addToast } = useToasts();
  const multi_org = me?.organization?.features?.multi_org_enabled;

  const selectionContextController = useSelectionContextController();
  const controller = selectionContextController;
  const { selections } = controller;
  const bulkActionsController = useBulkActionController('activities');

  const {
    filters,
    setFilters,
  } = useManagedFilters({
    storageKey: 'pa',
  });

  const onSelectAllFilter = useCallback((activity: ProcessingActivity) => canEditOthers || activity.contacts?.some(c => c.user_id === me?.id), [canEditOthers, me?.id]);

  const fields = useMemo(() => ([
    ...(bulkActionsController.enabled ? [columns.row_selector] : []),
    columns.name,
    columns.contacts,
    ...(multi_org ? [columns.subsidiaries] : []),
    columns.functions,
    columns.updated_at,
    columns.status,
    columns.approved_at,
  ]), [bulkActionsController.enabled, multi_org]);

  const {
    results,
    onSortChange,
    sort,
    count,
    Pager,
    refetch,
  } = useQueryPager<ProcessingActivity>(GetProcessingActivitiesPaged, {
    where: filters,
    defaultSort: [['name', 'ASC']],
    limit: 50,
    // pollInterval: useSmartPoll(), // Disabled, as it really isn't used all that much
    parameterPrefix: 'pa_',
    variables: {
      fields: ['id', ...fields.flatMap((f) => (f.fields || []))],
      relations: fields.flatMap((f) => (f.relations || [])),
    },
    pageSizes,
  });
  const {
    allSelected,
    onSelectAll,
    onSelect,
  } = useResultSelections(results, {
    selectionNamespace: 'activities',
    onSelectAllFilter,
    controller,
  });

  const [deleteProcessingActivity] = useMutation(DeleteProcessingActivity);
  const [saveProcessingActivity] = useMutation<any, UpdateProcessingActivityVariables>(UpdateProcessingActivity);

  // Entity Archiving
  const onDelete = useCallback(async ({ id }) => {
    try {
      await deleteProcessingActivity({ variables: { id } });
      addToast(t('common:success'), {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (err) {
      addToast(t('common:error'), {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    refetch();
  }, [addToast, deleteProcessingActivity, refetch, t]);
  const onUndelete = useCallback(async (id: number) => (
    saveProcessingActivity({ variables: { id, activity: { deleted_at: null } } })
  ), [saveProcessingActivity]);
  // End Entity Archiving

  const selectedLength = useMemo(() => Object.keys(selections.systems ?? {}).length, [selections]);

  return (
    <SelectionContext.Provider value={controller}>
      <TitleBar
        title={t(`title_${isISO ? 'services' : 'processing_activities'}`)}
        right={[hasPermission('processing_activities.actions.create') && <Link key="chicken" to={`${match.url}/new`}><Button key="0" padded>{t(isISO ? 'create_service' : 'create_processing_activity')}</Button></Link>]}
      />
      <ActivityListFilterBar
        filterData={filters}
        setFilterData={setFilters}
        bulkActionsController={bulkActionsController}
      />

      <Table striped className="nowrap">
        <thead>
          <ListHeader
            fields={fields}
            allSelected={allSelected}
            allowRowSelection={bulkActionsController.enabled}
            onSelectAll={onSelectAll}
            onSortChange={onSortChange}
            sort={sort}
          />
        </thead>
        <tbody>
          {
            results.map((row) => (
              <ProcessingActivityListRow
                key={row.id}
                row={row}
                fields={fields}
                disabled={false}
                onSelect={onSelect}
                onDelete={onDelete}
                onUndelete={onUndelete}
                isSelected={!!(selections.activities ?? {})[row.id]}
                getRowLink={() => (`${match.url}/${row.id}`)}
                setEditing={() => {}}
              />
            ))
          }
        </tbody>
      </Table>

      <div>
        <Pager />
        {bulkActionsController.enabled && (
          <Text style={{ padding: '7px 5px', textAlign: 'center' }}>
            {selectedLength}
            {' '}
            of
            {' '}
            {count}
            {' '}
            selected
          </Text>
        )}
      </div>
      <BulkActionModalProvider {...bulkActionsController} />
    </SelectionContext.Provider>
  );
};
