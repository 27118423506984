import gql from 'graphql-tag';
import { AddressFragment } from '../address/address.fragments';
import { PRFileFragment } from '../utils/prfile.fragments';
import { dataMapItemFragment } from './data_map_item';

export const SystemFragment = gql`
  fragment system_page on System {
    ${dataMapItemFragment}
    is_newly_discovered
    onboarded
    image_url
    image_url_location
    source
    discovered_by
    created_at
    updated_at
    detected_at
    likelihood
    system_template_id
    system_template_last_copied
    recommended_template_id
    organization
    recommendation
    business_functions @type(name: "TagItem") {
      id
      name
    }
    sub_organizations @type(name: "SubOrganization") {
      id
      name
    }
    template @type(name: "System") {
      id
      name
      image_url
      image_url_location
      likelihood
      website
      email
      updated_at
    }
    system_template @type(name: "System") {
      id
      name
      image_url
      image_url_location
      likelihood
      website
      email
      updated_at
    }
    system_related_systems @type(name: "System") {
      id
      name
      image_url
      image_url_location
      likelihood
      website
      email
      updated_at
    }
    contacts @type(name: "SystemContact") {
      id
      user_id
      system_id
      type
      first_name
      last_name
      email
      phone
      is_reviewer
      user @type(name: "User") {
        id
        first_name
        last_name
        email
        phone
      }
    }
  }

  fragment system on System {
    ...system_page
    headquarters_address @type(name: "Address") {
      ...address
    }
    ${dataMapItemFragment}
    type
    website
    email
    dpia_completed
    dpia_reason
    dpia_reason_date
    dpia_link
    dpia_completed_at
    data_retention_period
    data_retention_policy_link

    processing_countries
    gdpr_relationship
    ccpa_relationship
    data_subject_consent_documented
    schema
    schema_last_checked
    schema_last_updated
    system_template_id
    system_template_last_copied
    organization
    system_template @type(name: "System") {
      id
      name
      image_url
      image_url_location
      website
      email
      updated_at
    }
    recommended_template_id
    approver @type(name: "User") {
      id
      first_name
      last_name
    }
    tagitems @type(name: "TagItem") {
      id
      name
    }
    storage_formats @type(name: "TagItem") {
      id
      organization_id
      name
    }
    hosting_types @type(name: "TagItem") {
      id
      organization_id
      name
    }
    hosting_locations @type(name: "TagItem") {
      id
      organization_id
      name
    }
    hosting_countries @type(name: "TagItem") {
      id
      organization_id
      name
    }
    hosting_providers @type(name: "TagItem") {
      id
      organization_id
      name
    }
    organizational_security_measures @type(name: "TagItem") {
      id
      organization_id
      name
    }
    technical_security_measures @type(name: "TagItem") {
      id
      organization_id
      name
    }
    personal_data_categories @type(name: "TagItem") {
      id
      organization_id
      name
    }
    processing_activities @type(name: "ProcessingActivity") {
      id
      name
      contacts @type(name: "ProcessingActivityContact") {
        id
        user_id
      }
      personal_data_categories @type(name: "TagItem") {
        id
        organization_id
        name
      }
      purposes_of_processing @type(name: "TagItem") {
        id
        name
      }
    }
    related_systems @type(name: "System") {
      ${dataMapItemFragment}
      image_url
      image_url_location
      system_template @type(name: "System") {
        id
        name
        image_url
        image_url_location
        website
        updated_at
      }
    }
    system_related_systems @type(name: "SystemRelatedSystem") {
      id
      system_id
      related_system_id
      system
      related_system
      related_tagitems
    }
    agreements @type(name: "PRFile") {
      ...prFile
    }
    privacy_notices @type(name: "PRFile") {
      ...prFile
    }
    certificates @type(name: "PRFile") {
      ...prFile
    }
    dpia @type(name: "PRFile") {
      ...prFile
    }
    retention_policy @type(name: "PRFile") {
      ...prFile
    }
    ccpa_dns
    connector_id
    system_processing_activities {
      tagitems @type(name: "TagItem") {
        id
        organization_id
        name
      }
    }
  }

  ${PRFileFragment}
  ${AddressFragment}
`;
