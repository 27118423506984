import React from 'react';
import {
  Field,
  Input,
  Label,
} from '@privacy-request/ui';
import { get } from 'lodash';
import { CustomFieldDefinition } from '../CustomFieldDefinition';
import { CustomFieldOptions } from '../CustomFieldOptions';
import { err } from '../utils/err';
import { getRest } from '../utils/getRest';

export interface CustomTextField extends CustomFieldOptions {
  type: 'text'
  wrapperStyle?: React.CSSProperties;
  onBlur?: any;
  fluid?: boolean;
  inputType?: HTMLInputElement['type']
  secure?: boolean
  readOnly?: boolean
  name: string
  label: string
  disabled?: boolean
  placeholder?: string
  autoFocus?: boolean
  valueOverride?: string
  mask?: string
}

export const text: CustomFieldDefinition<CustomTextField> = {
  type: 'text',
  component: (config, {
    mask,
    onChange,
    values,
  }) => (
    <Input
      type={config.inputType || (config.secure ? 'password' : 'text')}
      autoComplete="new-password"
      placeholder={config.placeholder}
      value={config.valueOverride || get(values, config.name) || ''}
      disabled={config.disabled}
      name={config.name}
      onChange={onChange}
      autoFocus={config.autoFocus}
      mask={config?.mask || mask}
      {...getRest(config)}
    />
  ),
  wrapper: (config, {
    first,
    last,
    fluid,
    content,
    errors,
  }) => (
    <Field last={last} first={first} fluid={fluid} style={last ? { paddingBottom: 0 } : {}} key={config.name || config.label}>
      <Label>{config.label}</Label>
      {err(errors, config.name)}
      {content}
    </Field>
  ),
};
