import { StatusDisplay, Button } from '@privacy-request/ui';
import {
  differenceInCalendarDays, differenceInBusinessDays,
} from 'date-fns';
import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useRouting } from '../../../hooks/useRouting';
import { Request } from '../../../types/request/Request';
import { useEditRequest } from './useEditRequest';
import { usePermissions } from '../../../hooks/usePermissions';
import { useMe } from '../../../hooks/useMe';

export const useRequestListItemHelper = (request: Partial<Request>) => {
  const [t] = useTranslation('requests');
  const { hasPermission } = usePermissions();
  const { onSave, saving } = useEditRequest(request.id);
  const { push } = useRouting();
  const { me } = useMe();
  const dueDateMode = me?.settings?.preferences?.due_date_preference || me?.organization?.settings?.preferences?.due_date_preference || 'calendar';

  const onProgress = useCallback((viewPage) => {
    if (request.rejected_reason && request.status !== 'verified') {
      return push(`/requests/${request.id}/email-confirmation?status=${request.status}&rejected_reason=${request.rejected_reason}`)();
    }
    switch (request.status) {
      case 'new':
        return onSave({ status: 'verified' });
      case 'verified':
        return push(`/requests/workflow/${request.id}/confirmation`)();
      case 'legal-review':
        return viewPage === 'list' ? push(`/requests/${request.id}/tasks`)() : push(`/requests/${request.id}/email-confirmation?status=${request.status}`)();
      case 'data-extract':
        return push(`/requests/${request.id}/tasks`)();
      default:
        return Promise.resolve();
    }
  }, [request, onSave, push]);

  const getStatus = useCallback((request: Partial<Request>) => {
    // Permissions-based logic here.
    const permitted = true;

    let suffix = `_${permitted ? 'permitted' : 'unpermitted'}`;

    if (request.status === 'new' && !hasPermission('requests.actions.approve_or_reject')) {
      suffix = '_unpermitted';
    }

    if (request.rejected_at) {
      return `rejections.${request.rejected_reason}`;
    }

    if (request.rejected_reason) {
      return `rejections.${request.rejected_reason}${suffix}`;
    }

    switch (request.status) {
      case 'new':
      case 'verified':
      case 'data-extract':
      case 'legal-review':
      case 'data-execute':
        return `${request.status}${suffix}`;
      default:
        return request.status;
    }
  }, [hasPermission]);

  const getAction = useCallback((request: Partial<Request>, {
    disableVerify, viewPage, ...props
  }: any = {}, pathname?: string) => {
    // Permissions-based logic here.
    let permitted = true;

    if (request.rejected_reason) {
      if (request.status === 'complete') {
        return '';
      }
      if (request.status === 'legal-review') {
        permitted = hasPermission('requests.actions.review_and_complete');
      } else {
        permitted = hasPermission('requests.actions.approve_or_reject');
      }
      return (
        <Button key="1" short {...props} disabled={!permitted || saving} onClick={onProgress} style={{ width: '164px' }}>
          {t(`status.rejections.${request.rejected_reason}_action`)}
        </Button>
      );
    }

    switch (request.status) {
      case 'new':
        return (
          hasPermission('requests.actions.manual_verification') ? (
            <Button key="1" short {...props} disabled={disableVerify || !permitted || saving} onClick={onProgress} style={{ width: '164px' }}>
              {disableVerify ? t('status.new_action_disabled') : t('status.new_action')}
            </Button>
          ) : '');
      case 'verified':
        permitted = hasPermission('requests.actions.approve_or_reject');
        return (
          <Button key="1" short {...props} disabled={!permitted || saving} onClick={onProgress} style={{ width: '164px' }}>
            {t(`status.${request.status}_action`)}
          </Button>
        );
      case 'data-extract':
        if (pathname && pathname.indexOf('/tasks') !== -1) {
          return '';
        }
        permitted = hasPermission('requests.actions.allow_upload');
        return (
          <Button key="1" short {...props} disabled={!permitted || saving} onClick={onProgress} style={{ width: '164px' }}>
            {t(`status.${request.status}_action`)}
          </Button>
        );
      case 'data-execute':
        return '';
      case 'legal-review':
        permitted = hasPermission('requests.actions.review_and_complete');
        return (
          <Button key="1" short {...props} disabled={!permitted || saving} onClick={() => onProgress(viewPage ? 'list' : '')} style={{ width: '164px' }}>
            {viewPage ? t(`status.${request.status}_action_list`) : t(`status.${request.status}_action`)}
          </Button>
        );
      default:
        return '';
    }
  }, [t, saving, onProgress, hasPermission]);

  const getDaysRemaining = useCallback((request: Partial<Request>) => {
    const handler = dueDateMode === 'calendar' ? differenceInCalendarDays : differenceInBusinessDays;
    return handler(new Date(request.due_date || new Date()), new Date());
  }, [dueDateMode]);

  const getDueDateDisplay = useCallback((request: Request) => {
    const days = getDaysRemaining(request);
    const pastDue = days < 1;

    switch (request.status) {
      case 'new':
        return (
          request.due_date ? (
            <StatusDisplay error={days < 6} warn={days < 11}>
              {pastDue ? t('past_due') : t('due_in', { count: days })}
            </StatusDisplay>
          ) : (
            <StatusDisplay neutral>{t('to_be_determined')}</StatusDisplay>
          )
        );
      case 'verified':
        return (
          <StatusDisplay error={days < 6} warn={days < 11}>
            {pastDue ? t('past_due') : t('due_in', { count: days })}
          </StatusDisplay>
        );
      case 'data-extract':
      case 'legal-review':
      case 'data-execute':
        return (
          <StatusDisplay error={days < 6} warn={days < 20}>
            {pastDue ? t('past_due') : t('due_in', { count: days })}
          </StatusDisplay>
        );
      default:
        return <StatusDisplay>{t('status.complete')}</StatusDisplay>;
    }
  }, [t, getDaysRemaining]);

  return {
    getStatus,
    getAction,
    getDueDateDisplay,
    getDaysRemaining,
  };
};
