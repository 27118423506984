import React from 'react';
import { Field, Label } from '@privacy-request/ui';
import { CustomFieldDefinition } from '../CustomFieldDefinition';
import { CustomFieldOptions } from '../CustomFieldOptions';
import { renderField } from '../utils/renderField';
import { CustomDropdownField } from './dropdown';
import { CustomTextField } from './text';

export interface CustomGroupField extends CustomFieldOptions {
  type: 'group'
  name: string
  fluid?: boolean
  label: string
  fields: (CustomTextField | CustomDropdownField)[]
}

export const group: CustomFieldDefinition<CustomGroupField> = {
  type: 'group',
  wrapper: (config, opts) => (
    <Field fluid key={config.name || config.type}>
      <Label>{config.label}</Label>
      {
        config.fields.map((fieldConfig: any) => (
          renderField(fieldConfig, opts)
        ))
      }
    </Field>
  ),
};
