import React from 'react';
import { CustomFieldOptions } from '../CustomFieldOptions';
import { Field } from '@privacy-request/ui';
import { CustomFieldDefinition } from '../CustomFieldDefinition';

export interface CustomCustomField extends CustomFieldOptions {
  type: 'custom'
  name?: string; // Should be required, for React keying
  render?: any
  component?: any
  fluid?: boolean
}

export const custom: CustomFieldDefinition<CustomCustomField> = {
  type: 'custom',
  component: (config, { errors }) => (
    <>
      {config.render && config.render({ errors })}
      {config.component && React.createElement(config.component)}
    </>
  ),
  wrapper: (config, {
    first,
    last,
    fluid,
    content,
  }) => (
    <Field last={last} first={first} key={config.name || config.type} fluid={fluid || config.fluid}>
      {content}
    </Field>
  ),
};
