import gql from 'graphql-tag';
import { User } from '../../types/user/User';
import { UserFragment } from './users.fragments';

export interface CreateUserResult {
  create_user: User
}

export interface CreateUserVariables {
  user: Partial<User>
  id: number
}

export const CreateUser = gql`
  mutation create_user($user: User!) {
    create_user(input: $user) @rest(
      type: "User" path: "users" method: "POST"
    ) {
      ...user
    }
  }
  ${UserFragment}
`;

export const CreateOrganizationUser = gql`
  mutation create_organization_user($user: User!, $organization_id: Int!) {
    create_user(input: $user, organization_id: $organization_id) @rest(
      type: "User" path: "organizations/{args.organization_id}/users" method: "POST"
    ) {
      ...user
    }
  }
  ${UserFragment}
`;

export interface UpdateUserResult {
  update_user: User
}

export interface UpdateUserVariables {
  user: Partial<User>
  id: number
}

export const UpdateUser = gql`
  mutation update_user($user: User!, $id: Int!) {
    update_user(input: $user, id: $id) @rest(
      type: "User" path: "users/{args.id}" method: "PATCH"
    ) {
      ...user
    }
  }
  ${UserFragment}
`;

export interface UpdateOrganizationUserVariables {
  user: Partial<User>
  id: number
  orgId: number
}

export const UpdateOrganizationUser = gql`
  mutation update_user($user: User!, $id: Int!, $organization_id: Int!) {
    update_user(input: $user, id: $id, organization_id: $organization_id) @rest(
      type: "User" path: "organizations/{args.organization_id}/users/{args.id}" method: "PATCH"
    ) {
      ...user
    }
  }
  ${UserFragment}
`;
