import {
  Title, List, ListHead, ListTitle, ListRow, ListText, Text,
} from '@privacy-request/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from '../../components/DateTime/DateTime';
import { useQueryPager } from '../../hooks/useQueryPager/useQueryPager';
import { useRouting } from '../../hooks/useRouting';
import { System } from '../../types/system/System';
import { GetSystemsPaged, GetSystemsPagedVariables } from '../../apollo/systems/systems.queries';

export const DashboardSystemsDiscovered = () => {
  const { push } = useRouting();
  const [t] = useTranslation('dashboard');

  const {
    results: systems,
    Pager,
  } = useQueryPager<System, GetSystemsPagedVariables>(GetSystemsPaged, {
    limit: 5,
    parameterPrefix: 'discovered_',
    variables: {
      fields: ['id', 'name', 'discovered_by', 'onboarded', 'is_newly_discovered'],
      filter: {
        onboarded: false,
      },
    },
  });

  return (
    <>
      <Title style={{ marginTop: 0 }}>{t('data_sources_discovered')}</Title>
      <List>
        <ListHead>
          <ListTitle>{t('data_sources')}</ListTitle>
          <ListTitle basis="96px" style={{ paddingRight: 0 }}>{t('detected')}</ListTitle>
        </ListHead>
        {systems.length ? (
          systems.map(system => (
            <ListRow onClick={push(`/systems/workflow/${system.id}/contacts`)} key={system.id} secondary>
              <ListText>{system.name}</ListText>
              <ListText basis="96px" style={{ paddingRight: 0, overflow: 'visible' }}>
                <Text>
                  <DateTime date={Object.values(system.discovered_by || {}).map(({ date }) => (date))?.[0]} format="date" />
                </Text>
              </ListText>
            </ListRow>
          ))
        ) : (
          <ListRow secondary>
            {t('data_sources_discovered_none')}
          </ListRow>
        )}
        <Pager />
      </List>
    </>
  );
};
