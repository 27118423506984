import React from 'react';
import { BulkActionModal, BulkActionModalProps } from '../BulkActionModal';
import { ProcessingActivityPurposeProcessingModal } from '../../../../views/ProcessingActivitiesView/components/ProcessingActivityEdit/ProcessingActivityPurposeProcessingModal';
import { useProcessingActivityBulkActionsEdit } from '../../../../views/ProcessingActivitiesView/hooks/useProcessingActivityBulkActionsEdit';

export const AddPurposesModal: BulkActionModal = ({
  onChange = () => {},
  onCancel = () => {},
}: BulkActionModalProps) => {
  const { items } = useProcessingActivityBulkActionsEdit();

  return (
    <ProcessingActivityPurposeProcessingModal
      items={items}
      onCancel={onCancel}
      type="purposes_of_processing"
    />
  );
};
