import update from 'immutability-helper';

//
// Simplify the process of tracking edits to an object
//

export interface SetEntityAction<Payload, Type> {
  type: Type
  payload: Partial<Payload>
}

export interface UpdateEntityAction<Payload, Type> {
  type: Type
  payload: Partial<Payload>
  ignoreDirty: boolean
}

export interface PatchEntityAction<Payload, Type> {
  type: Type
  payload: Partial<Payload>
}

export interface SetEntityErrorsAction<Type> {
  type: Type
  payload: any
}

export type ActionTypes<Type, StateKey extends string> = SetEntityAction<Type, `${StateKey}/SET`>
| UpdateEntityAction<Type, `${StateKey}/UPDATE`>
| SetEntityErrorsAction<`${StateKey}/SET_ERRORS`>
| PatchEntityAction<Type, `${StateKey}/PATCH`>;

export interface EntityState<Type> {
  entity: Partial<Type>
  isDirty: boolean
  errors: any
}

export interface EntityStore<Type, StateKey extends string> {
  stateKey: string
  actions: {
    setEntity: (payload: Partial<Type>) => SetEntityAction<Type, `${StateKey}/SET`>;
    updateEntity: (payload: Partial<Type>, ignoreDirty?: boolean) => UpdateEntityAction<Type, `${StateKey}/UPDATE`>;
    patchEntity: (payload: Partial<Type>) => PatchEntityAction<Type, `${StateKey}/PATCH`>;
    setErrors: (payload: any) => SetEntityErrorsAction<`${StateKey}/SET_ERRORS`>;
  }
  reducer: (
    state: EntityState<Type>,
    action: ActionTypes<Type, StateKey>
  ) => EntityState<Type>
}

export const createStandardEntityStore = <Type, StateKey extends string>(
  stateKey: StateKey,
): EntityStore<Type, StateKey> => {
  const SET_ACTION = `${stateKey}/SET` as `${StateKey}/SET`;
  const UPDATE_ACTION = `${stateKey}/UPDATE` as `${StateKey}/UPDATE`;
  const PATCH_ACTION = `${stateKey}/UPDATE` as `${StateKey}/PATCH`;
  const SET_ERRORS_ACTION = `${stateKey}/SET_ERRORS` as `${StateKey}/SET_ERRORS`;
  const actions = {
    setEntity: (payload: Partial<Type>): SetEntityAction<Type, typeof SET_ACTION> => ({
      type: SET_ACTION,
      payload,
    }),
    updateEntity: (payload: Partial<Type>, ignoreDirty = false): UpdateEntityAction<Type, typeof UPDATE_ACTION> => ({
      type: UPDATE_ACTION,
      payload,
      ignoreDirty,
    }),
    patchEntity: (payload: Partial<Type>): PatchEntityAction<Type, typeof PATCH_ACTION> => ({
      type: PATCH_ACTION,
      payload,
    }),
    setErrors: (payload: any): SetEntityErrorsAction<typeof SET_ERRORS_ACTION> => ({
      type: SET_ERRORS_ACTION,
      payload,
    }),
  };

  const initialState: EntityState<Type> = {
    entity: {},
    errors: undefined,
    isDirty: false,
  };
  const reducer = (state = initialState, action: ActionTypes<Type, StateKey>) => {
    switch (action.type) {
      case SET_ACTION:
        return update(state, {
          entity: { $set: action.payload },
          isDirty: { $set: false },
          errors: { $set: undefined },
        });
      case UPDATE_ACTION:
        return update(state, {
          entity: { $set: { ...action.payload } },
          ...((action as UpdateEntityAction<any, any>).ignoreDirty ? {} : { isDirty: { $set: true } }),
        });
      case PATCH_ACTION:
        return update(state, {
          entity: { $set: { ...state.entity, ...action.payload } },
          isDirty: { $set: true },
        });
      case SET_ERRORS_ACTION:
        return update(state, { errors: { $set: action.payload } });
      default:
        return state;
    }
  };

  return {
    stateKey,
    actions,
    reducer,
  };
};
