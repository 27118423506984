import React from 'react';
import {
  Field,
  Label,
  Text,
} from '@privacy-request/ui';
import { CustomFieldDefinition } from '../CustomFieldDefinition';
import { CustomFieldOptions } from '../CustomFieldOptions';

export interface CustomDisplayField extends CustomFieldOptions {
  type: 'display'
  label: string
  content: string
}

export const display: CustomFieldDefinition<CustomDisplayField> = {
  type: 'display',
  component: (_, { content }) => (
    <Text>{content}</Text>
  ),
  wrapper: (config, {
    first,
    last,
    fluid,
    content,
  }) => (
    <Field last={last} first={first} fluid={fluid} style={last ? { paddingBottom: 0 } : {}} key={config.label}>
      <Label>{config.label}</Label>
      {content}
    </Field>
  ),
};
