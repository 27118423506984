import React from 'react';
import styled from 'styled-components';

import { Text } from '../../atoms';

export interface TabsTab {
  value: string
  text: string
  badge?: boolean
  isActive?: (tab: string) => boolean
}

export interface TabsProps {
  tabs: TabsTab[]
  tab: string
  nav?: boolean
  onTabClicked: (ev: { target: { value: string } }) => void
  secondary?: boolean
  containerStyle?: React.CSSProperties
}

const TabContainer = styled.div<any>`
  width: 100%;
  margin-top: ${({ nav }) => (nav ? '0' : '16px')};
  display: flex;
  justify-content: ${({ secondary }) => (secondary ? 'flex-start' : 'center')};
  border-bottom: 1px solid ${({ theme, secondary }) => (secondary ? 'transparent' : '#efefef')};
  user-select: none;
`;
TabContainer.displayName = 'TabContainer';

const TabItem = styled.div<any>`
  width: ${({ nav }) => (nav ? 'unset' : '100%')};
  height: 48px;
  padding: ${({ nav }) => (nav ? '0 32px' : '0')};
  max-width: ${({ nav }) => (nav ? 'unset' : '140px')};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 3px solid ${({
    theme, active, secondary,
  }) => (active ? theme.CTA_COLOR : (secondary ? 'transparent' : theme.WHITE))};
  transition: border-bottom 0.2s linear;

  &:hover {
    border-bottom: 3px solid ${({ theme, active }) => (active ? theme.CTA_COLOR : theme.CTA_COLOR_LIGHT)};
  }
`;
TabItem.displayName = 'TabItem';

const Badge = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 1000px;
  background-color: ${({ theme }) => theme.CTA_ERROR};
  position: absolute;
  top: -2px;
  right: -10px;
`;
Badge.displayName = 'Badge';

const styles: any = { text: { position: 'relative' } };

export const TabsComponent = ({
  tabs, tab, nav, onTabClicked, secondary, containerStyle,
}: TabsProps) => (
  <TabContainer secondary={secondary} nav={nav} style={containerStyle}>
    {tabs.map(t => (
      <TabItem secondary={secondary} nav={nav} key={t.value} active={t.isActive?.(tab) || t.value === tab} onClick={() => onTabClicked({ target: { value: t.value } })}>
        <Text style={styles.text} center bold={t.value === tab}>
          {t.text}
          {t.badge && (<Badge />)}
        </Text>
      </TabItem>
    ))}
  </TabContainer>
);
