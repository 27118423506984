import update from 'immutability-helper';
import { BulkActionTypes } from './bulk_actions.actions';
import {
  SET_BULK_PROCESSING_ACTIVITIES,
  SET_BULK_SYSTEMS,
} from './bulk_actions.types';

export interface BulkActionPAState {
  ids?: number[];
  purposes_of_processing?: number[];
  cross_border_transfers?: number[];
}

export interface BulkActionSystemState {
  ids?: number[];
  technical_security_measures?: number[];
  organizational_security_measures?: number[];
}

interface BulkActionsState {
  processing_activities: BulkActionPAState;
  systems: BulkActionSystemState;
}

const initialState: BulkActionsState = {
  processing_activities: {},
  systems: {},
};

export default (state = initialState, action: BulkActionTypes) => {
  switch (action.type) {
    case SET_BULK_PROCESSING_ACTIVITIES:
      return update(state, {
        processing_activities: { $set: action.payload },
      });
    case SET_BULK_SYSTEMS:
      return update(state, {
        systems: { $set: action.payload },
      });
    default:
      return state;
  }
};
