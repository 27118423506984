import {
  Button,
  Buttons,
  Field,
  Text,
} from '@privacy-request/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../OverlayProvider/Modal';

interface ConfirmationDialogProps {
  title?: string
  titleVariables?: any
  width?: number
  cancelText?: string
  confirmText?: string
  children?: React.ReactNode
  message: React.ReactNode
  onCancel: any
  onConfirm: (props: ConfirmationDialogProps, ev: any) => any
  disabled?: boolean
  warn?: boolean
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const {
    title = 'common:are_you_sure',
    titleVariables,
    width,
    message,
    cancelText = 'common:form.cancel',
    confirmText = 'common:yes',
    children,
    onCancel,
    onConfirm,
    disabled,
    warn,
  } = props;
  const [t] = useTranslation();

  const messageContent = typeof message === 'string' ? t(message) : message;

  return (
    <Modal title={t(title, titleVariables)} onCancel={disabled ? () => {} : onCancel} style={{ maxWidth: `${width || 300}px` }}>
      {messageContent && (
        <Field>
          <Text>{messageContent}</Text>
        </Field>
      )}
      {children}
      <Field flex right style={{ paddingBottom: 0 }}>
        <Buttons>
          <Button disabled={disabled} secondary onClick={onCancel}>{t(cancelText)}</Button>
          <Button disabled={disabled} onClick={(ev: any) => (onConfirm(props, ev))} warn={warn}>{t(confirmText)}</Button>
        </Buttons>
      </Field>
    </Modal>
  );
};
