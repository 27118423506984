import {
  MutationHookOptions,
  MutationTuple,
  QueryHookOptions,
  useMutation,
  useQuery,
} from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  PagerOptions,
  QueryPagerResult,
  useQueryPager,
} from '../../hooks/useQueryPager/useQueryPager';
import { appendIf } from '../../utils/appendIf';
import _update from 'immutability-helper';
import { useToasts } from 'react-toast-notifications';
import { QueryResult } from '@apollo/react-common';
import { getOnly } from '../../utils/getOnly';
import { useDirtySaveRevert } from '../../components/GlobalDirtySaveRevert/useDirtySaveRevert';
import {
  DefaultRootState,
  useDispatch,
  useSelector,
} from 'react-redux';
import { EntityState, EntityStore } from '../../store/standardEntityStore';

const joinArgs = (prefix: string | undefined, args: string) => {
  if (prefix) {
    return `${prefix}, ${args}`;
  }
  return args;
};

export interface GQLBuilderConfig {
  create?: string
  update?: string
  delete?: string
  get?: string
  page?: string
  path: string
  fragment: DocumentNode
}

export type CommonGQLBuilderConfig = Pick<GQLBuilderConfig, 'fragment' | 'path'>;

export const BuildSimpleGQLBuilder = (config: CommonGQLBuilderConfig) => (_config: Pick<GQLBuilderConfig, 'create' | 'delete' | 'update' | 'page' | 'get'>) => simpleGQLBuilder({ ...config, ..._config });

export const simpleGQLBuilder = (config: GQLBuilderConfig) => {
  const method = (config.create || config.update || config.delete) ? 'mutation' : 'query';
  const resource = config.create || config.update || config.delete || config.get || config.page;
  // @TODO: Allow to pass in fragment definition index to pull a specific fragment value if more than one.
  // @ts-ignore
  const type = config.fragment.definitions[0].typeCondition.name.value;
  // @ts-ignore
  const fragmentKey = config.fragment.definitions[0].name.value;
  const hasID = !!(config.update || config.delete || config.get);

  let gql_path = '';
  let http_method = 'GET';
  let args = '';
  let inputs = '';
  let hasQueryParams = false;

  if (config.create) {
    gql_path = `create_${resource}`;
    http_method = 'POST';
    args = `$input: ${type}!`;
    inputs = 'input: $input';
  }

  if (config.update) {
    gql_path = `update_${resource}`;
    http_method = 'PATCH';
    args = `$input: ${type}!, $id: Int!`;
    inputs = 'input: $input, id: $id';
  }

  if (config.delete) {
    gql_path = `delete_${resource}`;
    http_method = 'DELETE';
    args = '$id: Int!';
    inputs = 'id: $id';
  }

  if (config.get) {
    gql_path = `get_${resource}`;
  }

  if (config.page) {
    gql_path = `page_${resource}`;
    args = '$limit: Int!, $offset: Int!, $filter: String!, $sort: Object!';
    inputs = 'limit: $limit, offset: $offset, filter: $filter, sort: $sort';
    hasQueryParams = true;
  }

  return gql`
    ${method} ${gql_path}${appendIf(!!args, `(${args})`)} {
      ${gql_path}${appendIf(!!inputs, `(${inputs})`)} @rest(
        type: "${type}${appendIf(!!config.page, 'Paged')}" path: "${config.path}${appendIf(hasID, '/{args.id}')}${appendIf(hasQueryParams, '?{args}')}" method: "${http_method}"
      ) {
        ${appendIf(!!config.page, `
          rows @type(name: "${type}") {
            ...${fragmentKey}
          }
          count
        `)}
        ${appendIf(!config.page, `
          ...${fragmentKey}
        `)}
      }
    }

    ${config.fragment}
  `;
};

export type RefetchQueries = [DocumentNode, any][];

export type BuildGQLConfig = {
  /**
   * The singular name of the object.
   */
  singular: string
  /**
   * The plural name of the object. Adds `s` to the end of the `singular` name
   * if not given.
   */
  plural: string
  /**
   * Arbitrary resource name for use in the GQL queries.
   */
  resource: string
  /**
   * The fragment to use when building queries and mutations.
   */
  fragment: DocumentNode
  /**
   * The resource endpoint path
   */
  path: string
  /**
   * Optional additional arguments to add by default
   */
  args?: [string, string]
  /**
   * If set to true, expect the endpoint not to accept a trailing identifier
   */
  noid?: boolean
};

export type GQLQueries<M> = {
  resource: string
  get GetSingle(): DocumentNode
  get GetPaged(): DocumentNode
  get GetAll(): DocumentNode
  get GetPagedIDs(): DocumentNode
  get CreateSingle(): DocumentNode
  get UpdateSingle(): DocumentNode
  get UpdateBulk(): DocumentNode
  get DeleteSingle(): DocumentNode
  get DeleteAll(): DocumentNode
  useGetSingle(options?: QueryHookOptions<M, any>): QueryResult<M>
  useGetPaged(options?: PagerOptions<M, any>): QueryPagerResult<M>
  useGetAll(options?: QueryHookOptions<M, any>): QueryResult<M>
  useCreateSingle(refetchQueries?: RefetchQueries): MutationTuple<M, any>
  useUpdateBulk(refetchQueries?: RefetchQueries): MutationTuple<M, any>
  useUpdateSingle(refetchQueries?: RefetchQueries): MutationTuple<M, any>
  useDeleteSingle(refetchQueries?: RefetchQueries): MutationTuple<M, any>
  useDeleteAll(options?: MutationHookOptions, refetchQueries?: RefetchQueries): MutationTuple<M, any>
};

export const buildGQL = <M>(options: BuildGQLConfig): GQLQueries<M> => {
  options.plural = options.plural || `${options.plural}s`;
  const [args1, args2] = options.args || [];

  // @TODO: Allow to pass in fragment definition index to pull a specific fragment value if more than one.
  // @ts-ignore
  const type = options.fragment.definitions[0].typeCondition.name.value;
  // @ts-ignore
  const fragmentKey = options.fragment.definitions[0].name.value;

  const gqlGenerator = (gql_path: string, httpMethod: string, queryType: string, args: string, inputs: string, hasID: boolean, paged: boolean, hasQueryParams: boolean, idOnly?: boolean, noResult?: boolean) => (
    gql`
      ${queryType} ${gql_path}${appendIf(!!args, `(${args})`)} {
        ${gql_path}${appendIf(!!inputs, `(${inputs})`)} @rest(
          type: "${type}"
          path: "${options.path}${appendIf(hasID && options.noid !== true, '/{args.id}')}${appendIf(hasQueryParams, '?{args}')}"
          method: "${httpMethod}"
          bodyKey: "input"
        ) {
          ${appendIf(!!noResult, `
            NoResult
          `)}
          ${appendIf(!!paged, `
            rows @type(name: "${type}") {
              ...${fragmentKey}
            }
            count
          `)}
          ${appendIf(!paged && !idOnly && !noResult, `
            ...${fragmentKey}
          `)}
          ${appendIf(!!idOnly, `
            ids
          `)}
        }
      }
      ${options.fragment}
    `
  );

  const buildRefetchQueries = (queries?: RefetchQueries, defaults?: RefetchQueries): { refetchQueries: any } => {
    if (!queries && !defaults) {
      return {} as any;
    }
    if (!queries) {
      return buildRefetchQueries(defaults);
    }

    return {
      refetchQueries: queries.map(([query, variables]) => ({ query, variables })),
    };
  };

  return {
    resource: options.resource,
    // TODO Figure out how to strongly-type these names
    get GetSingle() {
      const args = joinArgs(args1, `$input: ${type}!, $id: Int!`);
      const inputs = joinArgs(args2, 'input: $input, id: $id');
      return gqlGenerator(`get_${options.resource}`, 'GET', 'query', args, inputs, true, false, false);
    },
    get GetPaged() {
      const args = joinArgs(args1, '$limit: Int!, $offset: Int!, $filter: String!, $sort: Object!');
      const inputs = joinArgs(args2, 'limit: $limit, offset: $offset, filter: $filter, sort: $sort');
      return gqlGenerator(`page_${options.resource}`, 'GET', 'query', args, inputs, false, true, true);
    },
    get GetAll() {
      const args = joinArgs(args1, '$filter: String!, $sort: Object!');
      const inputs = joinArgs(args2, 'filter: $filter, sort: $sort');
      return gqlGenerator(`get_${options.resource}`, 'GET', 'query', args, inputs, false, false, true);
    },
    get GetPagedIDs() {
      const args = joinArgs(args1, '$filter: String!, $get_ids: Int!');
      const inputs = joinArgs(args2, 'filter: $filter, get_ids: $get_ids');
      return gqlGenerator(`get_${options.resource}_ids`, 'GET', 'query', args, inputs, false, false, true, true);
    },
    get CreateSingle() {
      const args = joinArgs(args1, `$input: ${type}!`);
      const inputs = joinArgs(args2, 'input: $input');
      return gqlGenerator(`create_${options.resource}`, 'POST', 'mutation', args, inputs, false, false, false);
    },
    get UpdateSingle() {
      const args = joinArgs(args1, `$input: ${type}!, $id: Int!`);
      const inputs = joinArgs(args2, 'input: $input, id: $id');
      return gqlGenerator(`update_${options.resource}`, 'PATCH', 'mutation', args, inputs, true, false, false);
    },
    get UpdateBulk() {
      const args = joinArgs(args1, '$input: Obj!');
      const inputs = joinArgs(args2, 'input: $input');
      return gqlGenerator(`bulk_update_${options.resource}`, 'PATCH', 'mutation', args, inputs, false, false, false, false, true);
    },
    get DeleteSingle() {
      const args = joinArgs(args1, '$id: Int!');
      const inputs = joinArgs(args2, 'id: $id');
      return gqlGenerator(`delete_${options.resource}`, 'DELETE', 'mutation', args, inputs, true, false, false);
    },
    get DeleteAll() {
      const args = joinArgs(args1, '$dangerAcknowledged: Boolean!');
      const inputs = joinArgs(args2, 'dangerAcknowledged: $dangerAcknowledged');
      return gqlGenerator(`delete_all_${options.resource}`, 'DELETE', 'mutation', args, inputs, false, false, true);
    },
    useGetSingle(options?: QueryHookOptions<M, any>) {
      return useQuery<M, any>(this.GetSingle, options);
    },
    useGetPaged(options: PagerOptions<M, any>) {
      return useQueryPager<M, any>(this.GetPaged, options);
    },
    useGetAll(options: QueryHookOptions<M, any>) {
      return useQuery<M, any>(this.GetAll, options);
    },
    useCreateSingle(refetchQueries?: RefetchQueries) {
      return useMutation<any, any>(this.CreateSingle, buildRefetchQueries(refetchQueries, []));
    },
    useUpdateBulk(refetchQueries?: RefetchQueries) {
      return useMutation<any, any>(this.UpdateBulk, buildRefetchQueries(refetchQueries, []));
    },
    useUpdateSingle(refetchQueries?: RefetchQueries) {
      return useMutation<any, { system_id: number, id: number, input: Partial<M> }>(this.UpdateSingle, buildRefetchQueries(refetchQueries, []));
    },
    useDeleteSingle(refetchQueries?: RefetchQueries) {
      return useMutation<any, any>(this.DeleteSingle, buildRefetchQueries(refetchQueries));
    },
    useDeleteAll(options?: MutationHookOptions, refetchQueries?: RefetchQueries) {
      return useMutation<any, any>(this.DeleteAll, options);
    },
  };
};

export type EditContainer<Type> = {
  entity: Partial<Type>
  isDirty: boolean
  errors: any
  /**
   * Sets the contents of the store element represented by the container, but
   * does not set the dirty flag.
   * @param payload The payload to replace the contents with
   */
  set: (payload: Partial<Type>) => void
  /**
   * Sets the contents of the store element represented by the container, and
   * sets the dirty flag.
   * @param payload The payload to replace the contents with
   */
  update: (payload: Partial<Type>) => void
  /**
   * Performs a shallow patch of the contents of the store element represented
   * by the container, and sets the dirty flag.
   *
   * If you alter nested objects, you must make sure that the nested object
   * contains _everything_ required. Nested updates are not partial, only
   * top-level updates are partial.
   *
   * @param payload The payload to merge into the contents
   */
  patch: (payload: Partial<Type>) => void
  setErrors: (errors: any) => void
};

export const useReduxEditContainer = <Type, Name extends string>({ stateKey, actions }: EntityStore<Type, Name>): EditContainer<Type> => {
  const dispatch = useDispatch();
  const {
    entity,
    isDirty,
    errors,
  } = useSelector<DefaultRootState, EntityState<Type>>((state: any) => (state[stateKey]));

  const set = useCallback((payload: Partial<Type>) => {
    dispatch(actions.setEntity(payload));
  }, [dispatch, actions]);

  const update = useCallback((payload: Partial<Type>, ignoreDirty = false) => {
    dispatch(actions.updateEntity(payload, ignoreDirty));
  }, [dispatch, actions]);

  const patch = useCallback((payload: Partial<Type>, ignoreDirty = false) => {
    dispatch(actions.updateEntity(_update(
      entity,
      Object.keys(payload).reduce((spec: any, key: string) => {
        spec[key] = { $set: payload[key as keyof typeof payload] };
        return spec;
      }, {} as any),
    ), ignoreDirty));
  }, [dispatch, actions, entity]);

  const setErrors = useCallback((errors: any) => {
    dispatch(actions.setErrors(errors));
  }, [dispatch, actions]);

  return {
    entity,
    isDirty,
    errors,
    set,
    update,
    patch,
    setErrors,
  };
};

export const useStateEditContainer = <Type>(): EditContainer<Type> => {
  // List/history of edits made
  const [entity, setEntity] = useState<Partial<Type>>({});
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [errors, setErrors] = useState<any[]>([]);

  const set = useCallback((payload: Partial<Type>) => {
    setEntity(payload);
    setIsDirty(false);
    setErrors([]);
  }, []);

  const update = useCallback((payload: Partial<Type>) => {
    const changeset = (Object.keys(payload) as [keyof Type])
      .reduce((res, key: keyof Type) => ({
        ...res,
        [key]: { $set: payload[key] },
      }), {} as any);
    setEntity((entity) => (
      _update(entity, changeset)
    ));
    setIsDirty(true);
  }, []);

  const patch = useCallback((payload: Partial<Type>) => {
    const changeset = (Object.keys(payload) as [keyof Type])
      .reduce((res, key: keyof Type) => ({
        ...res,
        [key]: { $set: payload[key] },
      }), {} as any);
    setEntity((entity) => (
      _update(entity, changeset)
    ));
    setIsDirty(true);
  }, []);

  const _setErrors = useCallback((errors: any) => {
    setErrors(errors);
  }, []);

  return {
    entity,
    isDirty,
    errors,
    set,
    update,
    patch,
    setErrors: _setErrors,
  };
};

export type UseEditableOptions<Type> = {
  cacheOnly?: boolean
  /**
   * The id to use when deduplicate dirty-save-revert handlers`
   */
  id: string
  /**
   * If true, always POSTs, rather than PUTting for updates
   */
  noUpdate?: boolean
  /**
   * The container responsible for holding edit information. For usages that
   * may span multiple components, you should prefer the `ReduxEditContainer`.
   * For single usage, you can consider using the simpler `StateEditContainer`.
   */
  editContainer: EditContainer<Type>
} & any;

export const useEditable = <T>(
  Queries: GQLQueries<T>, // TODO Strongly type this
  {
    cacheOnly,
    id,
    noUpdate,
    editContainer,
    ...options
  }: UseEditableOptions<T>,
) => {
  // Helpers
  const { addToast } = useToasts();

  // Set up GraphQL queries
  const {
    data,
    loading,
    variables,
  } = Queries.useGetSingle({
    ...options,
    fetchPolicy: cacheOnly ? 'cache-only' : 'cache-and-network',
  });
  const [createEntity, { loading: creating }] = Queries.useCreateSingle([[Queries.GetSingle, variables]]);
  const [updateEntity, { loading: updating }] = Queries.useUpdateSingle([[Queries.GetSingle, variables]]);

  // State and changes
  const original = useMemo(() => (getOnly(data)), [data]);

  // useEffect(() => {
  //   if (!loading && original && editContainer?.set) {
  //     editContainer.set(structuredClone(original));
  //   }
  // }, [loading, original]);
  const current = useMemo(() => ({
    ...original,
    ...editContainer.entity,
  }), [editContainer.entity, original]);

  // Callbacks
  const onChange = useCallback((e: any) => {
    const { name, value } = e.target;
    editContainer.patch({ [name]: value });
  }, [editContainer]);

  const onRevert = useCallback(() => {
    editContainer.set({});
  }, [editContainer]);

  const replace = useCallback((replacement) => {
    editContainer.set(replacement);
  }, [editContainer]);

  const onSave = useCallback(async (_edits?: any, _variables?: any) => {
    if (creating || updating || !editContainer.isDirty) {
      // TODO Notify user that there's nothing to do
      return;
    }

    // If we were able to retrieve the entity, then we know it exists
    if (original && !noUpdate) {
      try {
        await updateEntity({
          variables: {
            ...options.variables,
            ..._variables,
            input: {
              ...current,
              ..._edits,
            },
          },
          // @ts-expect-error
          optimisticResponse: {
            [`update_${Queries.resource}`]: {
              ...current,
              ..._edits,
            },
          },
        });
        editContainer.set({});
      } catch (e) {
        if (e instanceof Error) {
          addToast(e.message, {
            appearance: 'error',
            autoDismiss: true,
          });
          editContainer.setErrors([e.message]);
        } else {
          editContainer.setErrors([e]);
        }
      }
      return undefined;
    }

    try {
      await createEntity({
        variables: {
          ...options.variables,
          ..._variables,
          input: {
            ...current,
            ..._edits,
          },
        },
        // @ts-expect-error
        optimisticResponse: {
          [`create_${Queries.resource}`]: {
            ...current,
            ..._edits,
          },
        },
      });
      editContainer.set({});
    } catch (e) {
      if (e instanceof Error) {
        addToast(e.message, {
          appearance: 'error',
          autoDismiss: true,
        });
        editContainer.setErrors([e.message]);
      } else {
        editContainer.setErrors([e]);
      }
    }
  }, [creating, updating, editContainer, original, current, noUpdate, updateEntity, options.variables, addToast, createEntity, Queries.resource]);

  const { setDirty, setSaving } = useDirtySaveRevert(id, onSave, onRevert, creating || updating);

  useEffect(() => {
    if (setDirty) {
      setDirty(editContainer.isDirty);
    }
  }, [editContainer.isDirty, setDirty]);

  useEffect(() => {
    if (setSaving) {
      setSaving(creating || updating);
    }
  }, [creating, updating, setSaving]);

  return {
    original,
    current,
    dirty: editContainer.isDirty,
    edits: editContainer.entity,
    // edits: editContainer.edits,
    errors: editContainer.errors,
    loading,
    skipped: !!options.skip,
    saving: creating || updating,
    onSave,
    onChange,
    onRevert,
    replace,
    update: editContainer.update,
  };
};
