/* eslint-disable react/jsx-one-expression-per-line */

import { useQuery } from '@apollo/react-hooks';
import {
  Field, Label, TextArea, Button, CheckMarkIcon, Text, LoadingProvider, LoadingIndicator, Grid,
} from '@privacy-request/ui';
import React, {
  useCallback, useLayoutEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  GetSystem, GetSystemData, GetSystemVariables,
} from '../../../../apollo/systems/systems.queries';
import { prFormatDate } from '../../../../components/DateTime/DateTime';
import FileUpload from '../../../../components/FileUpload/FileUpload';
import { UPLOAD_FILE_SUPPORTED_EXTENSIONS } from '../../../../constants';
import { stripDeleted } from '../../../../utils/stripDeleted';
import { useEditRequest } from '../../hooks/useEditRequest';
import { useEditTask } from '../../hooks/useEditTask';
import { RequestTaskListItemProps } from './RequestTaskListItem';
import { RequestTaskMarkAsComplete } from './RequestTaskMarkAsComplete';
import { usePermissions } from '../../../../hooks/usePermissions';

const styles = {
  infoWrapper: { marginTop: '16px' },
  description: {
    display: 'flex',
    alignItems: 'center',
  },
  notes: { minHeight: '96px' },
};

const Section = styled.div`
  width: 50%
`;

export const RequestTaskListItemDetails = ({ task: _task, isVendor }: RequestTaskListItemProps) => {
  const [open, setOpen] = useState(false);
  const { request } = useEditRequest();
  const { request_type } = request || {};
  const [t] = useTranslation('requests');
  const { data } = useQuery<GetSystemData, GetSystemVariables>(GetSystem, { variables: { id: _task.system_id || 0 }, skip: !_task.system_id });
  const { hasPermission } = usePermissions();

  const system = data?.system || _task.system;

  const {
    onChange,
    onSave,
    onFileChange: _onFileChange,
    saving,
    task: edits,
  } = useEditTask(_task.id || 0, _task.request_id || 0, _task);

  const task = { ..._task, ...edits };
  const allow_upload = isVendor || hasPermission('requests.actions.allow_upload');

  const onFileChange = useCallback((e) => {
    _onFileChange(e);
  }, [_onFileChange]);

  useLayoutEffect(() => {
    const focusRegion = document.getElementById(`focus-region-${_task?.id}`);
    if (focusRegion) {
      focusRegion.focus();
    }
  }, [_task?.id]);

  const cta = useMemo(() => {
    const markAsCompleteButton = (
      <Button padded onClick={() => setOpen(true)} disabled={!allow_upload}>{t('edit.mark_as_complete')}</Button>
    );

    if (!task.completed_at) {
      return markAsCompleteButton;
    }

    if (!!task.completed_at && !task.data_deleted_at && ['data-execute'].includes(request?.status || '')) {
      return markAsCompleteButton;
    }

    const completed = (
      <Text style={{ display: 'flex', alignItems: 'center' }}>
        {t('edit.completed_on', { date: prFormatDate(_task.completed_at, t, 'datetime') })}
        <CheckMarkIcon width="26" height="26" style={{ marginLeft: '8px' }} color="green" />
      </Text>
    );

    if (['data-extract', 'legal-review', 'notify-requester', 'complete'].includes(request.status || '') && !!task.completed_at) {
      return completed;
    }

    if (['data-execute'].includes(request.status || '') && !!task.data_deleted_at) {
      return completed;
    }
  }, [_task.completed_at, allow_upload, request.status, t, task.completed_at, task.data_deleted_at]);

  return (
    <div>
      <LoadingProvider>
        {saving && <LoadingIndicator />}
        <div style={styles.infoWrapper}>
          <Grid fluid innerStyle={{ flexDirection: 'row-reverse' }}>
            <Section>
              <Field>
                {(task.errors?.length || 0) > 0 && (
                  <strong style={{ color: 'red' }}>{t('task.error')}:</strong>
                )}
                {task.errors?.map((e: any, i: number) => (
                  <p style={{ color: 'red' }} key={i}>
                    {typeof e.message === 'object' ? JSON.stringify(e.message) : e.message}
                    &nbsp;
                    {e.status ? `HTTP ${e.status}` : ''}
                  </p>
                ))}
              </Field>
            </Section>
            <Section>
              <Field>
                <Label>
                  {t('edit.task')}
                  :
                </Label>
                <Text style={styles.description}>
                  {t(`task.${request_type}`, { system_name: stripDeleted(_task.system?.name || '') })}
                </Text>
              </Field>
              <Field>
                <Label>
                  {t('edit.data_categories')}
                  :
                </Label>
                <Text>{system?.personal_data_categories?.map(c => c.name.split('|')[1]).join(', ')}</Text>
              </Field>
            </Section>
          </Grid>
          <Field>
            <Label>
              {t('edit.notes')}
              :
            </Label>
            <TextArea name="notes" onBlur={() => onSave()} style={styles.notes} value={task.notes || ''} onChange={onChange} />
          </Field>
          <Field>
            <Label>
              {t('edit.file_upload')}
              :
            </Label>
            <FileUpload
              name="files"
              useFileViewer
              disabled={!allow_upload}
              taskId={task.id}
              disableFileViewer={request_type === 'delete' || request_type === 'do-not-sell'}
              canDownload
              value={task.files || []}
              onChange={onFileChange}
              accept={UPLOAD_FILE_SUPPORTED_EXTENSIONS.join(',')}
              requestType={request_type}
            />
          </Field>
        </div>
        {open && <RequestTaskMarkAsComplete request={request} onCancel={() => setOpen(false)} task={_task} />}
      </LoadingProvider>
      <Field id={`focus-region-${_task.id}`} tabIndex={0} flex right>
        {cta}
      </Field>
    </div>
  );
};
