import {
  ComponentBar,
  Grid,
  ComponentBarItem,
  Dropdown,
} from '@privacy-request/ui';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMe } from '../../../../hooks/useMe';

export interface RequestListFiltersProps {
  filter: any
  onChange: any
}

export const RequestListFilters = ({ filter, onChange }: RequestListFiltersProps) => {
  const [t] = useTranslation('requests');
  const { me } = useMe();
  const organization = me?.organization;
  const multiJurisdiction = organization?.features?.ccpa_enabled && organization?.features?.gdpr_enabled;
  const features = me?.organization?.features;

  const requestTypes = useMemo(() => ([
    !features?.download_disabled && 'download',
    !features?.delete_disabled && 'delete',
    !features?.['opt-out_disabled'] && 'opt-out',
    !features?.['do-not-sell_disabled'] && 'do-not-sell',
  ] as string[]).filter(s => s).map(s => ({
    text: t(`types.${s}`),
    value: s,
  })), [t, features]);

  const jurisdictionTypes = useMemo(() => ['ccpa', 'gdpr'].map(s => ({
    text: t(`jurisdictions.${s}`),
    value: s,
  })), [t]);

  let typeValueDisplay = '';
  const value = (filter.request_type?.$in || []);
  if (!value.length || value.length === requestTypes.length) {
    typeValueDisplay = t('filter.all_types');
  } else if (value.length === 1) {
    typeValueDisplay = t(`types.${value[0]}`);
  } else {
    typeValueDisplay = t('filter.n_selected', { count: value.length });
  }

  let jurisdictionValueDisplay = '';
  const jValue = (filter.jurisdiction?.$in || []);
  if (!jValue.length || jValue.length === jurisdictionTypes.length) {
    jurisdictionValueDisplay = t('filter.all_jurisdictions');
  } else if (jValue.length === 1) {
    jurisdictionValueDisplay = t(`types.${jValue[0]}`);
  }

  return (
    <ComponentBar>
      <Grid innerStyle={{ justifyContent: 'flex-end' }}>
        <ComponentBarItem>
          <Dropdown
            multiple
              // @ts-ignore
            style={{ width: '220px' }}
            name="request_type"
            items={requestTypes}
            onChange={onChange}
            value={filter.request_type?.$in || []}
            displayValue={typeValueDisplay}
            placeholder={t('filter.all_types')}
          />
        </ComponentBarItem>
        {multiJurisdiction && (
          <ComponentBarItem>
            <Dropdown
              multiple
                // @ts-ignore
              style={{ width: '220px' }}
              name="jurisdiction"
              items={jurisdictionTypes}
              onChange={onChange}
              value={filter.jurisdiction?.$in || []}
              displayValue={jurisdictionValueDisplay}
              placeholder={t('filter.all_jurisdictions')}
            />
          </ComponentBarItem>
        )}
      </Grid>
    </ComponentBar>
  );
};
