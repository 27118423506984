import { DataMapEntity } from '@privacy-request/value-objects';
import { approvalRequired } from '@privacy-request/common/src/utils/data_map/approvalRequired';
import { reviewRequired } from '@privacy-request/common/src/utils/data_map/reviewRequired';
import { ToggleComponent, Text } from '@privacy-request/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type DataMapEntityPublicationToggleProps = {
  entity: Partial<DataMapEntity>
  hasPermission: boolean
  onToggle: Function
};

/**
 * The toggle that is displayed in the general info masthead for systems and
 * processing activities.
 * @param entity The system | processing activity to create the toggle for
 * @param hasPermission True if the user has permission to publish the entity
 * @param onToggle The callback fired when the user attempts to toggle the
 *   published state
 */
export const DataMapEntityPublicationToggle = ({
  entity,
  hasPermission,
  onToggle,
}: DataMapEntityPublicationToggleProps) => {
  const [t] = useTranslation('systems');

  if (reviewRequired(entity)) {
    return (<Text>{ t('Review') }</Text>);
  }
  if (approvalRequired(entity)) {
    return (<Text>{ t('Pending Approval') }</Text>);
  }
  if (hasPermission) {
    return (<ToggleComponent checked={!!entity.published} onClick={onToggle} />);
  }
  return (<Text>{ entity.published ? t('Active') : t('Inactive') }</Text>);
};
