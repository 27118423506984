import {
  Field, NoAuthorizationTemplate, Text, TooltipProvider,
} from '@privacy-request/ui';
import React from 'react';
import { Spacer } from '../../components/Spacer/Spacer';
import { RequestEditInfo } from '../RequestsView/components/Edit/RequestEditInfo';
import { RequestTaskListItem } from '../RequestsView/components/Edit/RequestTaskListItem';
import { useVendorToken } from './hooks/useVendorToken';

export const VendorView = () => {
  const { task, error } = useVendorToken();

  if (error) {
    return (
      <NoAuthorizationTemplate noLogo>
        <Field style={{ paddingBottom: 0 }}>
          <Text>{error}</Text>
        </Field>
      </NoAuthorizationTemplate>
    );
  }

  if (!task.request) {
    return (
      <NoAuthorizationTemplate noLogo>
        <Field style={{ paddingBottom: 0 }}>
          <Text>An unknown error occurred.</Text>
        </Field>
      </NoAuthorizationTemplate>
    );
  }

  return (
    <TooltipProvider>
      <NoAuthorizationTemplate noLogo containerStyle={{ minWidth: '1280px', maxWidth: '100%' }}>
        <RequestEditInfo />
        <Spacer />
        <Field>
          <RequestTaskListItem
            request_type={task.request.request_type}
            request_status={task.request.status}
            task={task}
            expanded
            primary
            isVendor
          />
        </Field>
      </NoAuthorizationTemplate>
    </TooltipProvider>
  );
};
