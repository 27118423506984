import { useCallback, useEffect } from 'react';

// eslint-disable-next-line import/no-mutable-exports
export let DOCUMENT_HIDDEN_KEY: keyof Document;
let visibilityChange: any;

if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
  DOCUMENT_HIDDEN_KEY = 'hidden';
  visibilityChange = 'visibilitychange';
  // @ts-ignore
} else if (typeof document.msHidden !== 'undefined') {
  // @ts-ignore
  DOCUMENT_HIDDEN_KEY = 'msHidden';
  visibilityChange = 'msvisibilitychange';
  // @ts-ignore
} else if (typeof document.webkitHidden !== 'undefined') {
  // @ts-ignore
  DOCUMENT_HIDDEN_KEY = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

interface VisibilityChangedHookOptions {
  /**
   * Only invoke the callback when the document is considered visible.
   */
  onlyCallWhenVisible?: boolean;
  /**
   * If true, do not invoke the callback, even when document visibility changes
   */
  skip?: boolean
}
export const useVisibilityChangedCallback = (cb: any, options: VisibilityChangedHookOptions = {}) => {
  const onVisibilityChanged = useCallback(() => {
    if (options.onlyCallWhenVisible && document[DOCUMENT_HIDDEN_KEY]) {
      return;
    }

    cb && cb();
  }, [cb, options.onlyCallWhenVisible]);

  useEffect(() => {
    if (typeof document.addEventListener === 'undefined' || !DOCUMENT_HIDDEN_KEY) {
      return;
    }

    document.addEventListener(visibilityChange, onVisibilityChanged);
    return () => document.removeEventListener(visibilityChange, onVisibilityChanged);
  }, [onVisibilityChanged]);
};
