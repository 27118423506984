import { useQuery } from '@apollo/react-hooks';
import {
  SearchAndSelect, SearchAndSelectProps, SearchAndSelectItem,
} from '@privacy-request/ui';
import { DocumentNode } from 'graphql';
import React, {
  useCallback, useState, useMemo, useRef,
} from 'react';
import { SEARCH_AND_SELECT_FLOATING_BOX_MAX_HEIGHT } from '../../constants';
import { WatchQueryFetchPolicy } from 'apollo-client';

interface GraphQLSearchAndSelectProps extends Partial<SearchAndSelectProps> {
  query: DocumentNode
  formatItem: (entity: any) => SearchAndSelectItem
  filter?: any
  filterItem?: (entity: any) => boolean
  /**
   * Specify an array of IDs to exclude from the
   * search results
   */
  exclude?: any[]
  /**
   * Text-based suggestions will automatically hide if their value exists.
   * If they are selected and don't exist from the data received on the back-end,
   * it will trigger a onCreate call with the value.
   *
   * No idea how this will work if we ever bring paging / searching in to
   * this component... We might need 2 types... a front-end and back-end version
   * of this component.
   */
  suggested?: string[]
  limit?: number
  variables?: any
  /**
   * Allows setting the fetch policy for the underlying apollo query. Useful in
   * instances where a list query will overwrite specific cached objects, as is
   * the case for the SystemEditRelatedSystemsModal where the list will
   * overwrite the cache for the currently-viewed entity.
   */
  fetchPolicy?: WatchQueryFetchPolicy
}

export const GraphQLSearchAndSelect = ({
  query,
  formatItem,
  value,
  exclude,
  add,
  suggested,
  filter,
  filterItem = () => (true),
  limit = 200,
  variables,
  fetchPolicy = 'cache-and-network',
  ...props
}: GraphQLSearchAndSelectProps) => {
  const [search, setSearch] = useState<string>('');
  const formatter = useRef(formatItem);

  const {
    data, refetch, loading,
  } = useQuery(query, {
    variables: {
      offset: 0,
      // TODO POR-95 Actually page & query the back-end.
      limit,
      filter,
      ...variables,
    },
    fetchPolicy,
  });

  const onSearchChange = useCallback((e: any) => {
    setSearch(e.target.value);
  }, []);

  const { onCreate: _onCreate } = props;
  const onCreate = useCallback(async (e: any) => {
    _onCreate && await _onCreate(e);
    refetch();
  }, [refetch, _onCreate]);

  const { onChange: _onChange } = props;
  const onChange = useCallback(async (e: any) => {
    // Regarding "suggested" values, they will have a string value.
    // This will not work if our IDs ever become strings. This is
    // not something I'm overly proud of.
    const stringValue = e.target.value.find((s: any) => typeof s === 'string');
    if (stringValue) {
      _onCreate && await _onCreate(stringValue);
      refetch();
    } else if (data) {
      const key = Object.keys(data)[0];
      const result = data[key].rows.filter((entity: any) => e.target.value.indexOf(entity.id) !== -1);
      _onChange && _onChange(result);
    }
    setSearch('');
  }, [data, _onChange, _onCreate, refetch]);

  const items: SearchAndSelectItem[] = useMemo(() => {
    if (!loading && data) {
      let _data = [];
      if (data.rows) {
        _data = data.rows;
      } else {
        _data = data[Object.keys(data)[0]];
        if (_data.rows) {
          _data = _data.rows;
        }
      }

      let _items: SearchAndSelectItem[] = _data.filter(filterItem).map(formatter.current);

      if (exclude && exclude.length) {
        _items = _items.filter((i: SearchAndSelectItem) => exclude.indexOf(i.value) === -1);
      }

      if (suggested && suggested.length) {
        const filteredSuggested = suggested.filter(suggestion => !_items.find(i => i.text === suggestion));
        _items.push(...filteredSuggested.map(s => ({ text: s, value: s })));
        _items.sort((a: SearchAndSelectItem, b: SearchAndSelectItem) => (
          a.text.localeCompare(b.text)
        ));
      }

      _items.forEach((item) => {
        if (typeof item.value === 'string' && !item.disabled) {
          item.onClick = async (ev, model) => {
            _onCreate && await _onCreate(item.value);
            refetch();
            model.onClose(true);
          };
        }
      });

      if (!search) {
        _items.unshift({
          text: `Type to search${add ? ' or create' : ''}...`,
          value: '-',
          disabled: true,
        });
      }

      return _items;
    }

    return [];
  }, [loading, data, filterItem, exclude, suggested, search, _onCreate, refetch, add]);

  return (
    <SearchAndSelect
      {...props}
      add={add}
      items={items}
      value={value.map(formatter.current).map((i: SearchAndSelectItem) => i.value)}
      onCreate={onCreate}
      onChange={onChange}
      onSearchChange={onSearchChange}
      floatingBoxStyle={{ maxHeight: SEARCH_AND_SELECT_FLOATING_BOX_MAX_HEIGHT }}
    />
  );
};
