import styled from 'styled-components';

export const ToggleRow = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 6px;
  margin: 6px 0;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  background-color: white;
  border-radius: 4px;
  transition: background-color 0.2s linear;
  &:hover {
    background-color: ${({ theme, disabled }) => (disabled ? 'white' : theme.BG_GREY)};
  }
`;
ToggleRow.displayName = 'ToggleRow';
