// ~~~~
// Domain and URL constants
// ~~~~

import { stripEnd } from './utils/strip';

/**
 * The BASE_HOST is the host to which some redirections (e.g. SAML, Azure AD,
 * etc) are directed. Through relay state or other mechanisms, the user may be
 * redirected back to their customary domain. The BASE_HOST is also where the
 * 'Find My Organizations' page lives, where users can enter their email
 * address and receive a list of the organizations they're a part of.
 */
export const BASE_HOST = stripEnd(process.env.REACT_APP_BASE_HOST || '', '/');
/**
 * A string that will be parsed to a RegExp which we use to determine the
 * current subdomain that the user is browsing. We then send this subdomain
 * as part of each request.
 */
export const SUBDOMAIN_REGEX = new RegExp(process.env.REACT_APP_SUBDOMAIN_REGEX || '');
/**
 * The current subdomain the user is viewing.
 */
export const SUBDOMAIN = window.location.origin.match(SUBDOMAIN_REGEX)?.[1] || '';
/**
 * A URL containing the replaceable tag `:subdomain:` which can be used to
 * produce URLs that point to a given subdomain.
 */
export const SUBDOMAIN_URL = process.env.REACT_APP_SUBDOMAIN_URL || '';
/**
 * The base URL for the API instance to communicate with.
 */
export const API_LOCATION = stripEnd(process.env.REACT_APP_API_LOCATION || '', '/');

export const SYSTEMS_BASE_PATH = 'systems';
export const ACTIVITIES_BASE_PATH = 'activities';
export const SERVICES_BASE_PATH = 'services';

/**
 * Default list/pager poll interval
 */
export const DEFAULT_POLL_INTERVAL = 10 * 1000;
/**
 * Default URL Parameter to connect a custom system to a template.
 *
 * Used in `SystemConnectTemplateController` to allow diffing /
 * updating values from a vendor template to a syste.m
 */
export const CONNECT_SYSTEM_ID_PARAM = 'connect_system_id';
/**
 * Default URL parameter to identify the connected / target template.
 *
 * Used in `SystemConnectTemplateController` to allow diffing /
 * updating values from a vendor template to a syste.m
 */
export const CONNECT_SYSTEM_TEMPLATE_ID_PARAM = 'connect_system_template_id';

export const SALESFORCE_CLIENT_ID = process.env.REACT_APP_SALESFORCE_CLIENT_ID || '';

export const SALESFORCE_REDIRECT_URL = process.env.REACT_APP_SALESFORCE_REDIRECT_URL || '';

export const AZURE_CLIENT_ID = process.env.REACT_APP_AZURE_CLIENT_ID || '';

// ~~~~
// Styling
// ~~~~
export const DEFAULT_TAG_PAGE_SIZE = 200;

export const SEARCH_AND_SELECT_FLOATING_BOX_MAX_HEIGHT = '260px';

export const FALLBACK_ASSET_IMAGE_URL = 'https://tcdmb1apv1.s3.eu-west-1.amazonaws.com/fallback.svg';

export const UPLOAD_FILE_SUPPORTED_EXTENSIONS = ['.PDF', '.DOC', '.DOCX', '.XLS', '.XLSX', '.CSV', '.TSV', '.XML', '.JSON', '.HTML'];

// Max File upload Size (in KB)
export const UPLOAD_FILE_MAX_SIZE = 25000;

export const DEFAULT_DATA_MAP_ITEM_QUERY_VARIABLES = () => ({
  fields: ['id', 'name'],
  sort: [['name', 'ASC']],
});
