/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Text,
  Title,
} from '@privacy-request/ui';
import clsx from 'clsx';
import React from 'react';
import './widget-card.scss';

export interface WidgetCardProps {
  title?: string
  description?: string
  onClick?: any
  active?: boolean
}

const textStyle = { margin: '-16px 0 22px 0' };

export const WidgetCard: React.FC<WidgetCardProps> = ({
  title, description, onClick, active,
}) => (
  <div className={clsx('card-widget', { active })} onClick={onClick}>
    <Title size="56" center>{title}</Title>
    <Text center style={textStyle}>{description}</Text>
  </div>
);
