import { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useRouting } from '../../../hooks/useRouting';
import { useIsAdminRoute } from '../../../hooks/useIsAdminRoute';
import { useTagItems } from '../../../hooks/useTagItems';
import { UpdateBulkSystems, BulkActionSystemsVariables } from '../../../apollo/systems/systems.mutations';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { useSelectionContext } from '../../../hooks/useSelectionContext';

export const useSystemBulkActionsEdit = () => {
  const [t] = useTranslation('systems');
  const { goBack } = useRouting();
  const isAdminRoute = useIsAdminRoute();
  const { addToast } = useToasts();
  const [updateBulkSystems] = useMutation<BulkActionSystemsVariables>(UpdateBulkSystems);
  const { selections, replace } = useSelectionContext();
  const systems = Object.keys(selections.systems ?? {});
  const technical_security_measures = Object.keys(selections.technical_security_measures ?? {});
  const organizational_security_measures = Object.keys(selections.organizational_security_measures ?? {});

  const onFinished = useCallback(() => {
    addToast(t('bulk_action_system_complete', { count: systems.length || 0 }), {
      autoDismiss: true,
      appearance: 'success',
    });
  }, [addToast, t, systems.length]);

  const {
    grouped,
  } = useTagItems({ custom: !isAdminRoute });
  const items = useMemo(() => {
    const type = 'purpose_of_processing';
    if (grouped && grouped[type]) {
      return grouped[type].map((i: any) => ({ text: i.name, value: i.id }));
    }
    return [];
  }, [grouped]);

  const onAddSystemBulkContact = useCallback(async (contact: any) => {
    await updateBulkSystems({
      variables: { data: { ids: systems, contacts: [contact] } },
    });
    onFinished();
  }, [updateBulkSystems, systems, onFinished]);

  const onApplyBulkSecurityMeasure = useCallback(async () => {
    if (systems.length && (technical_security_measures?.length || organizational_security_measures?.length)) {
      await updateBulkSystems({
        variables: {
          data: {
            ids: systems,
            technical_security_measures,
            organizational_security_measures,
          },
        },
      });
      onFinished();
    }
    goBack();
  }, [systems, technical_security_measures, organizational_security_measures, goBack, updateBulkSystems, onFinished]);

  const onAddBulkProcessingActivities = useCallback(async (processing_activities: any) => {
    const data = { ids: systems, processing_activities: processing_activities.map((pa: any) => pa.id) };
    if (data?.ids?.length && data?.processing_activities?.length) {
      await updateBulkSystems({
        variables: { data },
      });
      onFinished();
    }
  }, [systems, updateBulkSystems, onFinished]);

  const onApplyReviewRequest = useCallback(async () => {
    await updateBulkSystems({
      variables: { data: { ids: systems, requestReview: true } },
    });
    onFinished();
  }, [updateBulkSystems, systems, onFinished]);

  const onAddSubsidiaries = useCallback(async (sub_organizations: number[]) => {
    await updateBulkSystems({
      variables: { data: { ids: systems, sub_organizations } },
    });
    onFinished();
  }, [updateBulkSystems, systems, onFinished]);

  const onChangeBulkSecurityMeasure = useCallback((e: any) => {
    const { name, value } = e.target;
    replace(name, ...value);
  }, [replace]);

  const onSetPublishedStatus = useCallback(async (published: boolean) => {
    await updateBulkSystems({
      variables: { data: { ids: systems, published } },
    });
    onFinished();
  }, [updateBulkSystems, systems, onFinished]);

  return {
    onAddSystemBulkContact,
    onApplyBulkSecurityMeasure,
    onApplyReviewRequest,
    items,
    onAddBulkProcessingActivities,
    onChangeBulkSecurityMeasure,
    onAddSubsidiaries,
    onSetPublishedStatus,
  };
};
