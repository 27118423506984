import clsx from 'clsx';
import React from 'react';

export type TableHeaderProps = React.DetailedHTMLProps<React.ThHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement> & {
  /**
   * If true, the cell contains only a checkbox, that should be styled appropriately
   */
  checkbox?: boolean
  /**
   * If true, the cell should shrink to fit the contents
   */
  shrink?: boolean
  /**
   * If supplied, allows clicking the cell, and sets the '.clickable' class
   * name on the element
   */
  onClick?: any
  /**
   * If supplied, sets the `.sort-up` or `.sort-down` class names on the
   * element and thereby adds the sorting caret
   */
  sort?: 'up' | 'down'
};

export const TableHeader = React.forwardRef(({
  checkbox,
  shrink,
  onClick,
  sort,
  ...props
}: TableHeaderProps, ref: any) => (
  <th
    className={clsx({
      checkbox,
      shrink,
      clickable: !!onClick,
      [`sort-${sort}`]: !!sort,
    })}
    onClick={onClick}
    ref={ref}
    {...props}
  />
));
