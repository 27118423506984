import gql from 'graphql-tag';
import { SystemFragment } from '../systems.fragments';
import { dataMapItemFragment } from '../data_map_item';

export const ProcessingActivityFragments = gql`
  fragment processing_activity_page on ProcessingActivity {
    ${dataMapItemFragment}
    created_at
    updated_at
    contacts @type(name: "ProcessingActivityContact") {
      id
      user_id
      type
      first_name
      last_name
      email
      phone
      is_reviewer
      user @type(name: "User") {
        id
        first_name
        last_name
        phone
        email
      }
    }
    business_functions
    lineage
    sub_organizations @type(name: "SubOrganization") {
      id
      name
    }
    data_subject_categories @type(name: "[TagItem]") {
      id
      name
    }
    purposes_of_processing @type(name: "[TagItem]") {
      id
      name
    }
    processing_countries @type(name: "[TagItem]") {
      id
      name
    }
    data_disposal_methods @type(name: "[TagItem]") {
      id
      name
    }
    destinations_of_personal_data @type(name: "[TagItem]") {
      id
      name
    }
    lawful_bases @type(name: "[TagItem]") {
      id
      name
    }
    business_functions @type(name: "[TagItem]") {
      id
      name
    }
    personal_data_categories @type(name: "[TagItem]") {
      id
      name
    }
    systems @type(name: "System") {
      id
      name
      image_url
      image_url_location
      likelihood
      website
      email
      updated_at
    }
  }

  fragment processing_activity on ProcessingActivity {
    ...processing_activity_page
    ${dataMapItemFragment}
    dpia_completed
    dpia_reason
    dpia_reason_date
    dpia_completed_at
    dpia
    created_at
    updated_at
    data_retention_period
    retention_policy
    approver @type(name: "User") {
      id
      first_name
      last_name
    }
    systems @type(name: "System") {
      ...system
    }
    tagitems @type(name: "[TagItem]") {
      id
      name
    }
    processing_countries @type(name: "[TagItem]") {
      id
      name
    }
    lawful_bases @type(name: "[TagItem]") {
      id
      name
    }
    business_functions @type(name: "[TagItem]") {
      id
      name
    }
    data_subject_categories @type(name: "[TagItem]") {
      id
      name
    }
    personal_data_categories @type(name: "[TagItem]") {
      id
      name
    }
    destinations_of_personal_data @type(name: "[TagItem]") {
      id
      name
    }
    cross_border_transfers @type(name: "[TagItem]") {
      id
      name
    }
    data_disposal_methods @type(name: "[TagItem]") {
      id
      name
    }
    purposes_of_processing @type(name: "[TagItem]") {
      id
      name
    }
    origins_of_personal_data @type(name: "[TagItem]") {
      id
      name
    }
  }

  ${SystemFragment}
`;
