import React, {
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMe } from '../../../../hooks/useMe';

import { useRouting } from '../../../../hooks/useRouting';
import { RelatedAssetsStep } from './RelatedAssetsStep';
import { DetailsStep } from './DetailsStep';
import { OwnershipSelectionStep } from './OwnershipSelectionStep';
import { WorkflowStep } from '../../../../components/DataMapWorkflowComponents/types/WorkflowStep';
import { OnboardingWorkflow as CoreWorkflow } from '../../../../components/DataMapWorkflowComponents/OnboardingWorkflow/OnboardingWorkflow';
import { useWorkflowController } from '../../../../components/DataMapWorkflowComponents/OnboardingWorkflow/useWorkflowController';
import { useContextualActivity } from '../../hooks/useActivityContext';
import { useToasts } from 'react-toast-notifications';

export const OnboardingWorkflow = () => {
  const { replace } = useRouting();
  const { isISO } = useMe();
  const { addToast } = useToasts();
  const [t] = useTranslation('systems');
  const {
    current: activity,
    onSave,
  } = useContextualActivity();

  const steps = useMemo((): WorkflowStep[] => ([
    {
      text: t('workflow.details'),
      value: 'details',
      Component: DetailsStep,
    },
    {
      text: t('workflow.owners_and_contacts'),
      value: 'contacts',
      Component: OwnershipSelectionStep,
    },
    {
      text: t('pa.workflow.assets'),
      value: 'assets',
      Component: RelatedAssetsStep,
    },
  ]), [t]);

  const forward = useCallback(async ({
    stepIndex,
    visibleSteps,
    forward,
  }) => {
    if (stepIndex + 1 === visibleSteps.length) {
      // If the current index indicates that we're at the end of our list of
      // visible steps, then save the asset and redirect to the asset view
      const activity = await onSave({
        onboarded: true,
      });

      if (activity) {
        addToast(t(`form.${isISO ? 'service' : 'processing_activity'}_saved`), {
          autoDismiss: true,
          appearance: 'success',
        });
        replace(`/activities/${activity.id}`)();
      } else {
        // eslint-disable-next-line no-console
        console.error('Unexpected error saving activity: ', activity);
      }
      return;
    }
    forward();
  }, [addToast, isISO, onSave, replace, t]);

  const visibilityProps = useMemo(() => ({
    // id,
    // hasIntegration,
    // requestsDisabled,
  }), []);

  const controller = useWorkflowController({
    entity: activity,
    steps,
    visibilityProps,
    forward,
  });

  return (
    <CoreWorkflow
      title={t(`workflow_title_${isISO ? 'service' : 'processing_activity'}`)}
      {...controller}
    />
  );
};
