import React from 'react';
import { ListRow, ListText } from '@privacy-request/ui';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from '../Link';

interface NoEntitiesItemProps {
  to: string
  i18nKey?: string
  secondary?: boolean
}

const NoEntitiesItem = ({
  to, secondary, i18nKey: key,
}: NoEntitiesItemProps) => {
  const [t] = useTranslation();

  return (
    <ListRow secondary={secondary} plain>
      <ListText style={{ textAlign: 'center' }}>
        <Trans t={t} i18nKey={key}>
          <Link to={to} />
        </Trans>
      </ListText>
    </ListRow>
  );
};

interface NoEntitiesFoundDisplayProps {
  to: string
  secondary?: boolean
  noEntities: boolean
  noEntitiesKey: string
  noFilteredEntities?: boolean
  noFilteredEntitiesKey?: string
}

export const NoEntitiesFoundDisplay = ({
  to,
  secondary,
  noEntities,
  noEntitiesKey,
  noFilteredEntities,
  noFilteredEntitiesKey,
}: NoEntitiesFoundDisplayProps) => (
  <>
    {noEntities && (
    <NoEntitiesItem
      i18nKey={noEntitiesKey}
      to={to}
      secondary={secondary}
    />
    )}
    {noFilteredEntities && (
    <NoEntitiesItem
      i18nKey={noFilteredEntitiesKey}
      to={to}
      secondary={secondary}
    />
    )}
  </>
);
