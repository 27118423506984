import { RequestStatus, TaskAttributes } from '@privacy-request/value-objects';

export type StatusResult = [string, string, boolean];

export const getDeleteRequestTaskStatus = (request_type: string, request_status: RequestStatus, task: Partial<TaskAttributes>, t: any): StatusResult => {
  if (task.data_deleted_at) {
    return [t('task.data_deleted'), 'green', true];
  }

  if (request_status === 'data-extract' && !task.data_deleted_at) {
    if (!task.completed_at) {
      return [t('task.in_progress'), 'black', false];
    }
    return [t('task.ready_for_deletion'), 'green', false];
  }

  if (request_status === 'legal-review' && !task.data_deleted_at) {
    return [t('task.pending_deletion_approval'), 'green', false];
  }

  if (request_status === 'data-execute' && !task.data_deleted_at) {
    return [t('task.pending_deletion'), 'black', false];
  }

  return ['State Unknown', 'orange', true];
};

export const getDefaultOperationRequestTaskStatus = (request_type: string, request_status: RequestStatus, task: Partial<TaskAttributes>, t: any): StatusResult => {
  if (task.data_deleted_at) {
    return [t('task.completed'), 'green', false];
  }

  if (request_status === 'data-extract') {
    if (!task.completed_at) {
      return [t('task.in_progress'), 'black', false];
    }

    return [t('task.ready_for_approval'), 'green', false];
  }

  if (request_status === 'legal-review') {
    return [t('task.ready_for_approval'), 'green', false];
  }

  if (request_status === 'data-execute') {
    return [t('task.in_progress'), 'black', false];
  }

  return ['State Unknown', 'orange', true];
};

export const getRequestTaskStatus = (request_type: string, request_status: RequestStatus, task: Partial<TaskAttributes>, t: any): StatusResult => {
  if ((task.errors?.length || 0) > 0) {
    return [t('task.error'), 'red', true];
  }

  if (request_type === 'delete') {
    return getDeleteRequestTaskStatus(request_type, request_status, task, t);
  }

  if (['do-not-sell', 'opt-out'].includes(request_type)) {
    return getDefaultOperationRequestTaskStatus(request_type, request_status, task, t);
  }

  if (request_type === 'do-not-sell' && task.has_data && !task.data_deleted_at) {
    return [t('task.ready_to_execute'), 'green', false];
  }

  if (!task.completed_at) {
    return [t('task.in_progress'), 'black', false];
  }

  if (task.data_deleted_at) {
    return [t('task.completed'), 'green', false];
  }

  if (request_type === 'do-not-sell' && task.has_data) {
    return [t('task.ready_to_execute'), 'green', false];
  }

  return [
    task.files?.length ? t('task.data_found') : t('task.no_data'),
    task.files?.length ? 'green' : 'red',
    true,
  ];
};
