import React, { useContext } from 'react';
import styled from 'styled-components';
import { useMe } from '../../hooks/useMe';
import { HotkeyContext } from '../HotkeyProvider/HotkeyContext';

const Details = styled.details`
  margin-top: 16px;
`;

export type DebugEditorProps = {
  children: React.ReactNode
  style?: React.CSSProperties
};

export const DebugView = React.memo(({
  children,
  ...rest
}: DebugEditorProps) => {
  const { me } = useMe();
  const { state } = useContext(HotkeyContext);
  if (!me?.god_mode || !state.debug) {
    return null;
  }

  return (
    <Details {...rest}>
      <summary>Debug</summary>
      {children}
    </Details>
  );
});
