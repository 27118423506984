import { useQuery } from '@apollo/react-hooks';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GetMeData, GetMe } from '../apollo/users/users.queries';
import { setPermissions } from '../store/auth/auth.actions';
import { useSelector } from '../store/useSelector';
import { setCountryLanguage } from '../utils/setCountryLanguage';

interface UseMeOptions {
  updatePermissions?: boolean
}

export const useMe = (options?: UseMeOptions) => {
  const authorized = useSelector(s => s.auth.authorized);
  const dispatch = useDispatch();

  const updatePermissions = options?.updatePermissions;

  const {
    data,
    loading,
    error,
  } = useQuery<GetMeData>(GetMe, {
    skip: !authorized,
    fetchPolicy: 'network-only',
  });

  const country = data?.me?.organization?.country;
  const permissions = data?.me?.permissions;
  const organization = data?.me?.organization;

  useEffect(() => {
    setCountryLanguage(country);
  }, [country]);

  useEffect(() => {
    if (!updatePermissions || !permissions) {
      return;
    }

    dispatch(setPermissions(permissions));
  }, [permissions, updatePermissions, dispatch]);

  return {
    me: data?.me,
    permissions: data?.me.permissions,
    isISO: organization?.framework_context === 'ISO',
    requireAssetApproval: organization?.data_map_setting?.require_asset_approval,
    requireProcessingActivityApproval: organization?.data_map_setting?.require_processing_activity_approval,
    requestsDisabled: organization?.features?.requests_disabled,
    loading,
    error,
  };
};
