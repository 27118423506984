import React from 'react';
import {
  Field,
  ListRow,
  ListText,
  RadioGroup,
} from '@privacy-request/ui';
import { CustomFieldDefinition } from '../CustomFieldDefinition';
import { CustomFieldOptions } from '../CustomFieldOptions';
import { get } from 'lodash';
import { getRest } from '../utils/getRest';

export interface CustomYesNoField extends CustomFieldOptions {
  type: 'yes-no'
  name: string
  label: string
  disabled?: boolean
}

const yesNoItems = [{
  text: 'Yes',
  value: true,
}, {
  text: 'No',
  value: false,
}];

export const yesNo: CustomFieldDefinition<CustomYesNoField> = {
  type: 'yes-no',
  component: (config, { onChange, values }) => (
    <ListRow style={{ paddingLeft: 0, border: 'none' }} plain>
      <ListText>{config.label}</ListText>
      <ListText>
        <RadioGroup
          inline
          left
          disabled={config.disabled}
          itemStyle={{ paddingBottom: 0 }}
          value={get(values, config.name)}
          name={config.name}
          onChange={onChange}
          items={yesNoItems}
          {...getRest(config)}
        />
      </ListText>
    </ListRow>
  ),
  wrapper: (config, {
    first,
    last,
    content,
  }) => (
    <Field last={last} first={first} key={config.name}>
      {content}
    </Field>
  ),
};
