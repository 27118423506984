import gql from 'graphql-tag';
import { Task } from '../../types/request/Task';
import { TaskFragment } from './task.fragments';

export interface GetTaskByVendorTokenData {
  get_task_by_vendor_token: Task
}

export interface GetTaskByVendorTokenVariables {
  token: string
}

export const GetTaskByVendorToken = gql`
  query get_task_by_vendor_token($token: String!) {
    get_task_by_vendor_token(token: $token)
      @rest(type: "Task", path: "requests/vendor/{args.token}") {
        ...vendorTask
      }
  }

  ${TaskFragment}
`;
