import React from 'react';

export const SettingsIcon = ({ color, ...props }: any) => (
  <svg {...props} color={color} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M28.5,23.8125 C28.5,24.1182764 28.2388328,24.3661572 27.9166667,24.3661572 L12.085,24.3661572 C11.8088513,25.3306062 10.8863318,26 9.83333333,26 C8.78033485,26 7.85781539,25.3306062 7.58166667,24.3661572 L4.58333333,24.3661572 C4.26116723,24.3661572 4,24.1182764 4,23.8125 C4,23.5067236 4.26116723,23.2588428 4.58333333,23.2588428 L7.58166667,23.2588428 C7.85781539,22.2943938 8.78033485,21.625 9.83333333,21.625 C10.8863318,21.625 11.8088513,22.2943938 12.085,23.2588428 L27.9166667,23.2588428 C28.2388328,23.2588428 28.5,23.5067236 28.5,23.8125 Z M27.9166667,15.3838428 L24.9183333,15.3838428 C24.6421846,14.4193938 23.7196652,13.75 22.6666667,13.75 C21.6136682,13.75 20.6911487,14.4193938 20.415,15.3838428 L4.58333333,15.3838428 C4.26116723,15.3838428 4,15.6317236 4,15.9375 C4,16.2432764 4.26116723,16.4911572 4.58333333,16.4911572 L20.415,16.4911572 C20.6911487,17.4556062 21.6136682,18.125 22.6666667,18.125 C23.7196652,18.125 24.6421846,17.4556062 24.9183333,16.4911572 L27.9166667,16.4911572 C28.2388328,16.4911572 28.5,16.2432764 28.5,15.9375 C28.5,15.6317236 28.2388328,15.3838428 27.9166667,15.3838428 Z M4.58333333,7.74115724 L7.58166667,7.74115724 C7.85781539,8.70560619 8.78033485,9.375 9.83333333,9.375 C10.8863318,9.375 11.8088513,8.70560619 12.085,7.74115724 L27.9166667,7.74115724 C28.2388328,7.74115724 28.5,7.49327645 28.5,7.1875 C28.5,6.88172355 28.2388328,6.63384276 27.9166667,6.63384276 L12.085,6.63384276 C11.8088513,5.66939381 10.8863318,5 9.83333333,5 C8.78033485,5 7.85781539,5.66939381 7.58166667,6.63384276 L4.58333333,6.63384276 C4.26116723,6.63384276 4,6.88172355 4,7.1875 C4,7.49327645 4.26116723,7.74115724 4.58333333,7.74115724 Z" id="settings-icon" fill={color} fillRule="nonzero" />
    </g>
  </svg>
);

SettingsIcon.defaultProps = {
  width: '32px',
  height: '32px',
  color: '#ffffff',
};
