import { BackArrow } from '@privacy-request/ui';
import React from 'react';
import { TagItemTypes } from '../../types/tagitem/TagItem';
import { RouteConfig } from '../MainView';
import { LoadableAdminView } from './LoadableAdminView';

export const admin_routes = (is_superuser: boolean, isLegalAssistant: boolean) => {
  const routes: RouteConfig[] = [{
    link: '/admin/organizations',
    title: 'organizations',
    component: LoadableAdminView,
    hidden: !is_superuser,
  }, {
    link: '/admin/assets',
    title: 'assets',
    component: LoadableAdminView,
  }, {
    link: '/admin/unconnected',
    title: 'unconnected',
    component: LoadableAdminView,
  }, {
    link: `/admin/entities/${TagItemTypes[0]}`,
    title: 'entities',
    component: LoadableAdminView,
    hidden: isLegalAssistant,
  }, {
    link: '/admin/deployment',
    title: 'deployment',
    component: LoadableAdminView,
    hidden: isLegalAssistant,
  }, {
    link: '/dashboard',
    title: 'back',
    icon: <BackArrow color="#fff" width={34 * 0.8} height={20 * 0.8} />,
  }, {
    link: '/admin',
    title: 'admin',
    hidden: true,
    component: LoadableAdminView,
  }];

  return routes;
};
