import color from 'color';
import React from 'react';
import MaskedInput from 'react-maskedinput';
import AndroidMaskedInput from 'react-text-mask';
import styled, { DefaultTheme } from 'styled-components';
import { SearchIcon } from '../icons';
import {
  GenericInputStyle,
  GenericTextStyle,
} from '../styles';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  /**
   * Attaches a SearchIcon magnifying glass to the end of the input, indicating that it is a search field.
   */
  search?: boolean
  /**
   * Indicates wether to use the Android text-mask node_module since the one we were using was having issues
   * on other operating systems.
   *
   * ### Used in conjunction with the `mask` prop
   */
  android?: boolean
  /**
   * Style overrides to modify the <InputWrap /> component wrapping the native html Inputs.
   */
  wrapperStyle?: React.CSSProperties
  /**
   * React Reference function to the raw <input /> component (for focusing etc.).
   */
  // inputRef?: React.Ref<HTMLInputElement>,
  /**
   * A react element that is an svg / image component to render as an icon for the input.
   */
  buttonIcon?: any
  /**
   * The color of the buttonIcon prop component.
   */
  buttonColor?: string
  /**
   * Centers the number input and adds a left/right side -/+ buttons for easy adjustment.
   */
  quickAdjust?: boolean
  /**
   * Sets a red border on the input, indicating an error with that field.
   */
  error?: boolean
  /**
   * Sets a mask on the input
   */
  mask?: string
  /**
   * Event handler for when the buttonIcon is present and clicked.
   */
  buttonOnClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export interface StyledInputProps extends InputProps {
  theme: DefaultTheme
}

export const InnerInput = styled.input<InputProps>`
  ${GenericTextStyle}
  ${GenericInputStyle}
  width: 100%;

  border: solid 1px ${({ error }) => (error ? 'red' : '#e1e0e0')};

  &:not([disabled]):hover, &:not([disabled]):focus {
    border: solid 1px ${({ error }) => (error ? 'darkred' : '#454543')};
  }
`;

const MaskedStyledInput = styled(MaskedInput)`
  ${GenericTextStyle}
  ${GenericInputStyle}
  width: 100%;
`;
const AndroidMaskedStyledInput = styled(AndroidMaskedInput)`
  ${GenericTextStyle}
  ${GenericInputStyle}
  width: 100%;
`;

InnerInput.displayName = 'Input';

export const InputWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

InputWrap.displayName = 'InputWrap';

const SearchIconWrap = styled.div`
  position: absolute;
  right: 10px;
`;

SearchIconWrap.displayName = 'SearchIconWrap';

const darkenHover = ({ theme, buttonColor }: StyledInputProps) => {
  if (buttonColor) {
    try {
      return color(buttonColor).darken(0.04).rgb().string();
    } catch (e) {
      // No problem here.
    }
  }

  return theme.CTA_COLOR_HOVER;
};
export const IconButtonWrap = styled.div`
  position: absolute;
  right: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 38px;
  height: 38px;
  transition: background-color 0.05s linear;
  background-color: ${({ theme, buttonColor }) => buttonColor || theme.CTA_COLOR};
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-left: 1px solid ${({ theme }) => theme.GREY};

  &:hover {
    background-color: ${darkenHover};
  }
`;
IconButtonWrap.displayName = 'IconButtonWrap';

const InputComponent: React.FC<InputProps> = ({
  search, android, wrapperStyle, buttonIcon: ButtonIcon, buttonColor, buttonOnClick, error, ...props
}) => (
  <InputWrap style={wrapperStyle} className="input-wrapper">
    {props.mask ? (
      android ? (
        <AndroidMaskedStyledInput
          {...props}
          mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        />
      ) : (
        // @ts-ignore
        <MaskedStyledInput {...props} />
      )
    ) : (
      <InnerInput error={error} search={search} {...props} />
    )}
    {search && (
      <SearchIconWrap>
        <SearchIcon />
      </SearchIconWrap>
    )}
    {ButtonIcon && (
      <IconButtonWrap buttonColor={buttonColor} onClick={buttonOnClick}>
        <ButtonIcon width={26} height={26} />
      </IconButtonWrap>
    )}
  </InputWrap>
);

InputComponent.displayName = 'Input';

export default InputComponent;
