import React from 'react';
import styled from 'styled-components';

import { media } from '../media';

export interface GridProps {
  /**
   * Set the style overrides for the inner <GridContent /> component.
   */
  innerStyle?: React.CSSProperties
  /**
   * Ignore the media breakpoints for the left-most item of a Grid.
   *
   * Used specifically for the Top Navigation BACK button.
   */
  ignoreResponsiveness?: boolean
  container?: boolean
  fluid?: boolean
  style?: React.CSSProperties
}

const StyledGrid = styled.div<GridProps>`
  width: 100%;
  ${({ container }) => (container ? '' : 'height: 100%;')}
`;

StyledGrid.displayName = 'Grid';

const margin = (px: string, is_left?: boolean) => ({ fluid, ignoreResponsiveness }: GridProps) => {
  if (is_left && ignoreResponsiveness) {
    return '96px';
  }

  if (fluid) {
    return 0;
  }

  return px;
};

const evaluateJustification = ({ children }: any) => {
  if (typeof children === 'object' && children.length && children.length > 1) {
    return 'space-between';
  }

  return 'flex-start';
};

const GridContent = styled.div<GridProps>`
  display: flex;
  align-items: center;
  justify-content: ${evaluateJustification};
  height: 100%;
  margin-left: ${margin('64px', true)};
  margin-right: ${margin('64px')};

  ${media.landscape`
    margin-left: ${margin('32px', true)};
    margin-right: ${margin('32px')};
  `}

  ${media.portrait`
    margin-left: ${margin('16px', true)};
    margin-right: ${margin('16px')};
  `}
`;

GridContent.displayName = 'GridContent';

export const Grid: React.FC<GridProps> = ({
  innerStyle, ignoreResponsiveness, ...props
}) => (
  <StyledGrid {...props}>
    <GridContent style={innerStyle} ignoreResponsiveness={ignoreResponsiveness} fluid={props.fluid}>
      {props.children}
    </GridContent>
  </StyledGrid>
);
