import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomFields } from '../../../../../components/CustomFields/CustomFields';
import { CustomSection } from '../../../../../components/CustomFields/CustomSectionComponent';
import {
  destinationsOfPersonalDataCustomField, originsOfPersonalDataCustomField, personalDataCategoriesCustomField,
} from '../../../../SystemsView/common/fields';
import { useContextualActivity } from '../../../hooks/useActivityContext';
import { useSuggestedPersonalDataCategories } from '../../../hooks/useSuggestedPersonalDataCategories';
import { ProcessingActivityAssets } from '../ProcessingActivityAssets';

export const ProcessingActivityTabAssets = () => {
  const [t] = useTranslation('systems');
  const {
    current: processingActivity, onChange, canEditPerm,
  } = useContextualActivity();
  const { suggested } = useSuggestedPersonalDataCategories();

  const config = useMemo((): CustomSection[] => [{
    type: 'section',
    storageKey: 'pa-assets-related',
    helpText: t('tooltips.assets', { name: processingActivity.name }),
    title: t('assets.assets'),
    config: [{
      type: 'custom',
      component: ProcessingActivityAssets,
    }],
  }, {
    type: 'section',
    storageKey: 'pa-assets-pdc',
    helpText: t('tooltips.personal_data_categories_pa', { name: processingActivity.name }),
    title: t('personal_data_categories.title'),
    config: [
      personalDataCategoriesCustomField(t, processingActivity, onChange, suggested, { disabled: !canEditPerm('processing_activities.edit.personal_data_categories') }),
      // {
      //   type: 'checkover',
      //   name: 'personal_data_categories',
      //   suggested,
      //   query: GetPersonalDataCategoriesPaged,
      //   mutation: CreatePersonalDataCategory,
      // },
    ],
  }, {
    type: 'section',
    storageKey: 'pa-assets-sopd',
    helpText: t('tooltips.source_of_personal_data', { name: processingActivity.name }),
    title: t('origins_of_personal_data.title'),
    config: [
      originsOfPersonalDataCustomField(t, processingActivity, onChange, { disabled: !canEditPerm('processing_activities.edit.origins_of_personal_data') }),
    ],
  }, {
    type: 'section',
    storageKey: 'pa-assets-dopd',
    helpText: t('tooltips.destination_of_personal_data', { name: processingActivity.name }),
    title: t('destinations_of_personal_data.title'),
    config: [
      destinationsOfPersonalDataCustomField(t, processingActivity, onChange, { disabled: !canEditPerm('processing_activities.edit.destinations_of_personal_data') }),
    ],
  }], [t, suggested, canEditPerm, onChange, processingActivity]);

  return (
    <div>
      <CustomFields config={config} value={processingActivity} onChange={onChange} />
    </div>
  );
};
