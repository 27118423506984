import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableRow } from '@privacy-request/ui/src/atoms/SASSTable';
import { ColumnDefinition, FieldHeaderProps } from './columns/types/ColumnDefinition';
import { useIsAdminRoute } from '../../hooks/useIsAdminRoute';

export type ListHeaderProps = {
  /**
   * If true, allow row selection
   */
  allowRowSelection?: boolean
  /**
   * Function that toggles between selecting and unselecting all elements
   * in the table
   */
  onSelectAll?: any
  /**
   * If true, indicates all rows have been selected
   */
  allSelected?: boolean
  /**
   * List of fields/columns to display, in order
   */
  fields: ColumnDefinition[]
  /**
   * The current sorts
   */
  sort?: any
  /**
   * Function to change sorts
   */
  onSortChange?: any
};

export const ListHeader = ({
  onSelectAll,
  allSelected,
  sort,
  onSortChange,
  fields,
}: ListHeaderProps) => {
  const [t] = useTranslation('systems');
  const isAdminRoute = useIsAdminRoute();

  const builderProps: FieldHeaderProps = useMemo((): FieldHeaderProps => ({
    onSortChange,
    onSelectAll,
    allSelected,
    sort,
    t,
    isAdminRoute,
  }), [allSelected, isAdminRoute, onSelectAll, onSortChange, sort, t]);

  return (
    <TableRow>
      {
        fields.map((field, i) => (<field.Header key={i} {...builderProps} />))
      }
    </TableRow>
  );
};
