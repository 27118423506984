import { ChartData as _ChartData } from 'chart.js';
import { endOfMonth, getQuarter } from 'date-fns';
import update from 'immutability-helper';
import range from 'lodash/range';
import round from 'lodash/round';
import { useEffect, useState } from 'react';
import { ChartData } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { GetDashboardChartsData, ChartData as PRChartData } from '../../../apollo/dashboard/dashboard.queries';
import { ChartTypes } from '../types/ChartTypes';
import { TimeSeries } from '../types/TimeSeries';

const initialChartData: ChartData<_ChartData> = {
  labels: [],
  datasets: [],
};

const days = endOfMonth(new Date()).getDate();

const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

const labels = {
  [TimeSeries.TODAY]: ['12 AM', '1 AM', '2 AM', '3 AM', '4 AM', '5 AM', '6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM', '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM', '6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM'],
  [TimeSeries.THIS_MONTH]: range(days).map(n => (++n).toString()),
  [TimeSeries.THIS_QUARTER]: [
    [months[0], months[1], months[2]],
    [months[3], months[4], months[5]],
    [months[6], months[7], months[8]],
    [months[9], months[10], months[11]],
  ],
  [TimeSeries.THIS_YEAR]: [...months],
  [TimeSeries.LAST_YEAR]: [...months],
};

const getLabels = (time: TimeSeries) => {
  if (time === TimeSeries.THIS_QUARTER) {
    return labels[time][getQuarter(new Date()) - 1];
  }

  return labels[time];
};

const getDataSets = (charts: GetDashboardChartsData, time: TimeSeries, chart: ChartTypes): Chart.ChartDataSets[] => {
  const label = getLabels(time);
  const results = charts.get_dashboard_charts;

  const data = label.map((val, index) => {
    let point: PRChartData | undefined;
    let result = (() => {
      switch (time) {
        case TimeSeries.TODAY:
          point = results.find(r => r.created === index);
          return point?.value || 0;
        case TimeSeries.THIS_MONTH:
          point = results.find(r => r.created === (index + 1));
          return point?.value || 0;
        case TimeSeries.THIS_QUARTER:
          const quarter = getQuarter(new Date());
          point = results.find(r => r.created === ((index + 1) + ((quarter - 1) * 3)));
          return point?.value || 0;
        case TimeSeries.THIS_YEAR:
        case TimeSeries.LAST_YEAR:
          point = results.find(r => r.created === (index + 1));
          return point?.value || 0;
        default:
          return 0;
      }
    })();

    if (chart === ChartTypes.AVG_TIME_TO_RESOLVE) {
      result = round(result / 3600, 2);
    }

    return result;
  });

  return [{
    label: '',
    borderColor: '#1F6187',
    borderWidth: 3,
    fill: false,
    data,
  }];
};

export const useChartTransform = (charts: GetDashboardChartsData | undefined, time: TimeSeries, chart: ChartTypes): ChartData<_ChartData> => {
  const [chartData, setChartData] = useState(initialChartData);
  const [t] = useTranslation('dashboard');

  useEffect(() => {
    if (!charts) {
      return;
    }

    const change = {
      labels: { $set: getLabels(time).map(m => t(`months.${m}`, m)) },
      datasets: { $set: getDataSets(charts, time, chart) },
    };

    setChartData(s => update(s, change));
  }, [chart, time, charts, t]);

  return chartData;
};
