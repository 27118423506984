import gql from 'graphql-tag';
import { Request } from '../../types/request/Request';
import { RequestFragment } from './requests.fragments';

export interface GetRequestsPagedVariables {
}

export const GetRequestsPaged = gql`
  query get_requests_paged($limit: Int!, $offset: Int!, $filter: String!, $sort: Object!) {
    get_requests_paged(limit: $limit, offset: $offset, filter: $filter, sort: $sort)
      @rest(type: "RequestsPaged", path: "requests?{args}") {
        rows @type(name: "Request") {
          ...request_page
        }
        count
      }
  }

  ${RequestFragment}
`;

export interface GetRequestData {
  get_request: Request
}

export interface GetRequestVariables {
  id: number
}

export const GetRequest = gql`
  query get_request($id: Int!) {
    get_request(id: $id) @rest(type: "Request", path: "requests/{args.id}") {
      ...request
    }
  }

  ${RequestFragment}
`;

export interface GetRequestHistoryPagedVariables {
  id: number
}

export const GetRequestHistoryPaged = gql`
  query get_request_history_paged($limit: Int!, $offset: Int!, $id: Int!) {
    get_request_history_paged(id: $id, limit: $limit, offset: $offset)
      @rest(type: "RequestHistory", path: "requests/{args.id}/history?{args}") {
        rows @type(name: "AuditLog") {
          id
          source_type
          user_id
          table_name
          table_row_id
          action
          timestamp
          updated_values
          previous_values
          transaction_id
          task @type(name: "Task") {
            id
            system @type(name: "System") {
              id
              name
            }
          }
          user @type(name: "User") {
            id
            email
          }
        }
        count
      }
  }
`;
