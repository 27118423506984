import React, {
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
// @ts-ignore
import mousetrap from 'mousetrap';
import { HotkeyManager } from './HotkeyManager';
import { Hotkey } from './Hotkey';
import { keysToStringArray } from './utils/keysToStringArray';
import { GlobalHotkeys } from './global/GlobalHotkeys';
import { HotkeyContext } from './HotkeyContext';
import { useToasts } from 'react-toast-notifications';

const arraysEqual = (a: string[], b: string[]) => {
  if (a === b) {
    return true;
  }
  if (a == null || b == null) {
    return false;
  }
  if (a.length !== b.length) {
    return false;
  }
  const a1 = [...a];
  const b1 = [...b];
  a1.sort();
  b1.sort();

  return a1.every((el, i) => (b1[i] === el));
};

type HotkeyState = Omit<Hotkey, 'handler'>;

export const HotkeyProvider = ({ children }: { children: ReactElement }) => {
  const [state, setState] = useState<any>({});
  const _state = useRef<any>(state);
  const hotkeys = useRef<HotkeyState[]>([]);
  const { addToast } = useToasts();

  const bindHotkey = useCallback(({
    keys,
    handler,
    name,
    section,
    description,
  }: Hotkey) => {
    mousetrap.bind(keys, (ev: KeyboardEvent, combo: string) => (handler(ev, combo, {
      state: _state.current, setState, addToast,
    })));
    if (!hotkeys.current.find((hotkey) => (arraysEqual(hotkey.keys, keys)))) {
      hotkeys.current = hotkeys.current.concat([{
        keys,
        name,
        section,
        description,
      }]);
    }
  }, [addToast]);

  const unbindHotkey = useCallback((hotkey: string | string[] | { keys: string } | { keys: string[] }) => {
    const keys = keysToStringArray(hotkey);
    hotkeys.current = hotkeys.current
      // First, remove matching keys
      .map((hotkey) => ({
        ...hotkey,
        keys: hotkey.keys.filter((k) => (keys.indexOf(k) === -1)),
      }))
      // Then, remove handlers that have no keys left
      .filter(({ keys }) => (keys.length > 0));
    mousetrap.unbind(keys);
  }, []);

  const manager = useMemo((): HotkeyManager => ({
    state,
    setState,
    hotkeys: hotkeys.current,
    bindHotkey,
    unbindHotkey,
  }), [bindHotkey, state, unbindHotkey]);

  return (
    <HotkeyContext.Provider value={manager}>
      <GlobalHotkeys>
        {children}
      </GlobalHotkeys>
    </HotkeyContext.Provider>
  );
};
