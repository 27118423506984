import { DataMapEntity } from '@privacy-request/value-objects';
import { approvalRequired } from './approvalRequired';
import { reviewRequired } from './reviewRequired';

export type SimpleStatus = 'review_requested'
| 'approval_requested'
| 'published'
| 'draft'
| 'archived';

/**
 * Produces a simple status representation of where the entity is at in the
 * workflow. Note that it may not take into account certain edge cases, but
 * should be sufficient for list displays.
 * @param entity The entity to evaluate
 * @returns A string representing the entity's current "state"
 */
export const entityStatusSimple = (entity: DataMapEntity): SimpleStatus => {
  if (entity.deleted_at) {
    return 'archived';
  }
  if (reviewRequired(entity)) {
    return 'review_requested';
  }
  if (entity.published) {
    return 'published';
  }
  if (approvalRequired(entity)) {
    return 'approval_requested';
  }
  return 'draft';
};
