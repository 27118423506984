export const dataMapItemFragment = `
  id
  organization_id
  name
  description
  notes
  onboarded
  published
  review_requested
  reviewed_at
  reviewer_id
  approval_requested
  approved_at
  approver_id
  deleted_at

  data_retention_period
  retention_policy

  tagitems
  business_functions
  processing_countries
  data_subject_categories
  cross_border_transfers
  data_disposal_methods
`;
