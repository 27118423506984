import {
  Button, Buttons, CheckBox, Field, Label, Text,
} from '@privacy-request/ui';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { prFormatDate } from '../../../../components/DateTime/DateTime';
import Modal from '../../../../components/OverlayProvider/Modal';
import { Request } from '../../../../types/request/Request';
import { Task } from '../../../../types/request/Task';
import { useEditTask } from '../../hooks/useEditTask';

interface RequestTaskMarkAsCompleteProps {
  onCancel: any
  task: Partial<Task>
  request?: Partial<Request>
}

export const RequestTaskMarkAsComplete = ({
  onCancel, task, request,
}: RequestTaskMarkAsCompleteProps) => {
  const [t] = useTranslation('requests');
  const [checked, setChecked] = useState(false);
  const { onSave, saving } = useEditTask(task.id || 0, task.request_id || 0, task || {});

  const onMarkAsComplete = useCallback(async () => {
    const changes: Partial<Task> = { completed_at: new Date() };
    if (request?.request_type === 'delete' || !!task?.completed_at) {
      changes.data_deleted_at = new Date();
    }
    await onSave(changes);
    onCancel();
  }, [onSave, onCancel, request, task]);

  const disabled = request?.request_type === 'delete' && !checked;

  return (
    <Modal title={t('task.are_you_sure')} style={{ maxWidth: '386px' }} onCancel={onCancel}>
      <Field>
        <Label>{t('task.system')}</Label>
        <Text>{task.system?.name}</Text>
      </Field>
      <Field>
        <Label>{t('task.personal_data')}</Label>
        <Text style={{ color: task.files?.length ? 'green' : 'red', fontWeight: 'bold' }}>{task.files?.length ? t('task.attached') : t('task.none')}</Text>
      </Field>
      <Field>
        <Label>{t('task.date_completed')}</Label>
        <Text>{prFormatDate(new Date(), t, 'datetime')}</Text>
      </Field>
      {request?.request_type === 'delete' && (
        <Field>
          <Label>{t('task.delete_confirmation')}</Label>
          <CheckBox
            checked={checked}
            onChange={() => setChecked(!checked)}
            label={t('task.delete_confirmation_oath')}
          />
        </Field>
      )}

      <Field style={{ paddingBottom: 0 }}>
        <Buttons style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button secondary onClick={onCancel}>{t('common:form.cancel')}</Button>
          <Button padded disabled={saving || disabled} onClick={onMarkAsComplete}>{t('edit.mark_as_complete')}</Button>
        </Buttons>
      </Field>
    </Modal>
  );
};
