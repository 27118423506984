import React, { useMemo } from 'react';
import { BulkActionModal, BulkActionModalProps } from '../BulkActionModal';
import { EntityContactsCreateModal } from '../../../EntityContactsEdit/EntityContactsCreateModal';

export const AddContactsModal: BulkActionModal = ({
  onChange = () => {},
  onCancel = () => {},
  controller,
}: BulkActionModalProps) => {
  const { entityType } = controller;
  const contactKey = useMemo(() => (entityType === 'systems' ? 'system_id' : 'processing_activity_id'), [entityType]);

  return (
    <EntityContactsCreateModal
      contactKey={contactKey}
      contacts={[]}
      onCreate={undefined}
      onChange={onChange}
      name=""
      createUser=""
      setCreateUser={undefined}
      onUserCreated={undefined}
      bulkAction={entityType}
      onCancel={onCancel}
    />
  );
};
