import update from 'immutability-helper';
import { ChartTypes } from '../../views/DashboardView/types/ChartTypes';
import { TimeSeries } from '../../views/DashboardView/types/TimeSeries';
import { DashboardActionTypes } from './dashboard.actions';
import { SET_TIME, SET_CHART } from './dashboard.types';

interface DashboardState {
  chart: ChartTypes
  time: TimeSeries
}

let initialState: DashboardState = {
  chart: ChartTypes.COMPLETED_REQUESTS,
  time: TimeSeries.THIS_MONTH,
};

const savedState = sessionStorage.getItem('dashboard_state');
if (savedState) {
  initialState = JSON.parse(savedState) as DashboardState;
}

export default (state = initialState, action: DashboardActionTypes): DashboardState => {
  const nextState = (() => {
    switch (action.type) {
      case SET_TIME:
        return update(state, { time: { $set: action.payload } });
      case SET_CHART:
        return update(state, { chart: { $set: action.payload } });
      default:
        return state;
    }
  })();

  if (nextState !== state) {
    sessionStorage.setItem('dashboard_state', JSON.stringify(nextState));
  }

  return nextState;
};
