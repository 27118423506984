import React from 'react';
import { SVGComponent } from './SvgProps';

export const UploadIcon: SVGComponent = (props) => (
  <svg {...props} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs />
    <g id="admin-desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Store_My-Shops-New" transform="translate(-1292.000000, -205.000000)" fill={props.color}>
        <g id="Group-3" transform="translate(422.000000, 140.000000)">
          <g id="Group-2-Copy" transform="translate(870.000000, 26.000000)">
            <path d="M17.5195312,49.5175781 C18.0000024,49.5527346 18.4540994,49.6757802 18.8818359,49.8867188 C19.3095725,50.0976573 19.6787094,50.3701155 19.9892578,50.7041016 C20.2998062,51.0380876 20.5458975,51.4218728 20.7275391,51.8554688 C20.9091806,52.2890647 21,52.7519507 21,53.2441406 C21,53.7597682 20.9003916,54.2460915 20.7011719,54.703125 C20.5019521,55.1601585 20.2353532,55.5585921 19.9013672,55.8984375 C19.5673811,56.2382829 19.1718773,56.5078115 18.7148438,56.7070312 C18.2578102,56.906251 17.771487,57.0058594 17.2558594,57.0058594 L7.5,57.0058594 C6.87890314,57.0058594 6.29590116,56.8857434 5.75097656,56.6455078 C5.20605196,56.4052722 4.72851768,56.0830098 4.31835938,55.6787109 C3.90820107,55.274412 3.58593867,54.7998074 3.3515625,54.2548828 C3.11718633,53.7099582 3,53.1269562 3,52.5058594 C3,51.9199189 3.10253804,51.3691432 3.30761719,50.8535156 C3.51269634,50.337888 3.79687318,49.8808614 4.16015625,49.4824219 C4.52343932,49.0839824 4.94823976,48.7558607 5.43457031,48.4980469 C5.92090087,48.2402331 6.44530969,48.0820315 7.0078125,48.0234375 C7.24218867,47.5781228 7.53222483,47.1679706 7.87792969,46.7929688 C8.22363454,46.4179669 8.61327908,46.0986341 9.046875,45.8349609 C9.48047092,45.5712877 9.94628657,45.3662117 10.4443359,45.2197266 C10.9423853,45.0732415 11.4609348,45 12,45 C12.6796909,45 13.3271454,45.1142567 13.9423828,45.3427734 C14.5576203,45.5712902 15.108396,45.8876933 15.5947266,46.2919922 C16.0810571,46.6962911 16.4912093,47.1738254 16.8251953,47.7246094 C17.1591814,48.2753934 17.3906244,48.8730437 17.5195312,49.5175781 Z M13.4941406,51.75 L15.7441406,51.75 L12,48.0058594 L8.25585938,51.75 L10.5058594,51.75 L10.5058594,54.7558594 L13.4941406,54.7558594 L13.4941406,51.75 Z" id="" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

UploadIcon.defaultProps = {
  width: 24,
  height: 24,
  color: '#ffffff',
};
