import { RadioGroup } from '@privacy-request/ui';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { ExpandAndCollapse } from '../../ExpandAndCollapse/ExpandAndCollapse';
import { User } from '../../../types';
import { NextPrev } from '../../NextPrev';
import { useDataMappingContactEdit } from '../../../hooks/shared/useDataMappingContactEdit';
import { useRouting } from '../../../hooks/useRouting';

export type OwnerSelectionProps = {
  workflow: any
  entity: any
  onChange: any
  onSave: any
  disabled?: boolean
  title: string
  loading?: boolean
  onNext: any
  onPrev: any
};

export const OwnerSelection = ({
  workflow,
  entity,
  onChange,
  onSave,
  disabled,
  title,
  loading,
  onNext,
  onPrev,
}: OwnerSelectionProps) => {
  const [t] = useTranslation('systems');
  const { addToast } = useToasts();
  const { goBack } = useRouting();

  if (loading || !title || !onSave || !onChange || !entity || !workflow) {
    throw new Promise(() => {}); // eslint-disable-line @typescript-eslint/no-throw-literal
  }

  const {
    options,
    onRadioChange,
    choice,
    onUserCreated,
    createUser,
  } = useDataMappingContactEdit(entity.contacts || [], false, onChange, entity.id || 0, 'system_id', onSave);

  const validate = useCallback(() => {
    if (!choice || (choice === 'other' && (!entity.contacts?.length && !createUser))) {
      addToast(t('pa.workflow.please_assign_an_owner'), {
        autoDismiss: true,
        appearance: 'error',
      });
      return false;
    }

    return true;
  }, [addToast, t, choice, entity.contacts, createUser]);

  const nextText = choice === 'other' ? t('form.send_invite') : t('common:form.next');

  const _onNext: any = useCallback(async () => {
    if (!validate()) {
      return;
    }
    if (choice === 'other') {
      if (createUser) {
        const user: Partial<User> = { email: createUser };
        return onUserCreated(user);
      }
      const pa = await onSave();
      if (!pa) {
        return;
      }
      return goBack();
    }
    onNext();
  }, [validate, choice, onNext, createUser, onUserCreated, onSave, goBack]);

  return (
    <ExpandAndCollapse expandable={false} storageKey="doesntmatter" title={title}>
      <RadioGroup
        inline
        around
        style={{ marginBottom: '32px', marginTop: '68px' }}
        textStyle={{ width: '386px' }}
        items={options}
        value={choice}
        onChange={onRadioChange}
        topAlign
      />
      <NextPrev validate={validate} workflow={workflow} disabled={disabled} onNext={_onNext} onPrev={onPrev} nextText={nextText} />
    </ExpandAndCollapse>
  );
};
