import React from 'react';
import { SVGComponent } from './SvgProps';

export const SearchIcon: SVGComponent = (props) => (
  <svg {...props} viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="admin-desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-22">
        <rect id="Rectangle-9" fill="#D8D8D8" opacity="0" x="0" y="0" width="19" height="19" />
        <path d="M13.3378039,12.3726164 C15.6842203,9.50003908 15.3686727,5.28982704 12.6203571,2.80004086 C9.87204142,0.310254679 5.65655072,0.415655622 3.03577962,3.03968639 C0.415008518,5.66371715 0.309738507,9.884451 2.79643179,12.6361849 C5.28312506,15.3879189 9.48810703,15.703859 12.357116,13.3545241 L16.8273524,17.8325463 C17.1033085,18.0691629 17.5146645,18.0532542 17.7715693,17.7960299 C18.0284741,17.5388056 18.044363,17.1269379 17.8080403,16.8506386 L13.3378039,12.3726164 Z M7.97531888,13.5634407 C4.90230338,13.5634407 2.4111321,11.0691709 2.4111321,7.99233324 C2.4111321,4.91549558 4.90230338,2.42122583 7.97531888,2.42122583 C11.0483344,2.42122583 13.5395057,4.91549558 13.5395057,7.99233324 C13.5395057,9.46988279 12.9532805,10.8869158 11.9097931,11.9317011 C10.8663056,12.9764864 9.45103297,13.5634407 7.97531888,13.5634407 L7.97531888,13.5634407 Z" id="Shape" fill="#E1E0E0" />
      </g>
    </g>
  </svg>
);

SearchIcon.defaultProps = {
  width: 17,
  height: 17,
};
