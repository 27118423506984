import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  Field, Label, Input, Button, Dropdown,
} from '@privacy-request/ui';
import { DocumentNode } from 'graphql';
import { groupBy } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../components/OverlayProvider/Modal';
import { DEFAULT_TAG_PAGE_SIZE } from '../../../../../constants';
import { TagItem } from '../../../../../types/tagitem/TagItem';

interface NewCreateCheckoverModalProps {
  query: DocumentNode
  mutation: DocumentNode
  onCreate: any
  onCancel: any
  type: string
}

export default ({
  query, mutation, onCreate, onCancel, type,
}: NewCreateCheckoverModalProps) => {
  const [t] = useTranslation('systems');
  const title = t(`${type}.title`);

  const [description, setDescription] = React.useState('');
  const [category, setCategory] = React.useState('');

  const { data, refetch } = useQuery(query, {
    fetchPolicy: 'cache-first',
    variables: { limit: DEFAULT_TAG_PAGE_SIZE, offset: 0 },
  });

  const [createTag] = useMutation(mutation);

  const dropdownItems = React.useMemo(() => {
    if (!data) {
      return [];
    }

    const categories = Object.keys(groupBy(data[Object.keys(data)[0]].rows, (item: TagItem) => item.name.split('|')[0]));

    return categories.map((item: string) => ({
      text: item,
      value: item,
    }));
  }, [data]);

  const onSave = React.useCallback(async () => {
    await Promise.resolve();
    const tagName = `${category ? `${category}|` : ''}${description}`;
    const result = await createTag({ variables: { tag: { name: tagName } } });
    if (!result.data) {
      return;
    }
    const item = result.data[Object.keys(result.data)[0]];
    await refetch();
    onCreate(item);
    onCancel();
  }, [description, category, createTag, onCancel, onCreate, refetch]);

  return (
    <Modal title={title} onCancel={onCancel} style={{ maxWidth: '640px' }}>
      <Field fluid flex>
        <Field style={{ maxWidth: '312px' }}>
          <Label>
            {t('form.description')}
            :
          </Label>
          <Input
            value={description}
            onChange={({ target: { value } }) => setDescription(value)}
          />
        </Field>
        <Field style={{ maxWidth: '312px' }}>
          <Label>
            {t('form.category')}
            :
          </Label>
          <Dropdown
            items={dropdownItems}
            value={category}
            onChange={({ target: { value } }) => setCategory(value)}
          />
        </Field>
      </Field>
      <Field style={{ paddingBottom: 0, marginTop: '16px' }} flex right>
        <Button padded onClick={onSave} disabled={!category || !description}>{t(`${type}.buttonText`)}</Button>
      </Field>
    </Modal>
  );
};
