import React from 'react';
import styled from 'styled-components';
import { Button } from '../Button';
import { ComponentBar } from '../ComponentBar';
import { Grid } from '../Grid';
import { BackArrow } from '../icons';
import { Title } from '../Title';

export interface TitleBarProps {
  /**
   * The title to display.
   */
  title: string
  /**
   * Render additional content after the title text.
   */
  titleRender?: React.ReactNode
  /**
   * Causes the back-button to be rendered, and attaches this event handler.
   */
  onBackClicked?: any
  /**
   * Causes the right-side action button to be a regular button, with this text.
   *
   * Omitting this value causes it to just render a + button (indicating an action that they need to ADD something.)
   */
  buttonText?: string
  /**
   * Disables the right-side action button.
   */
  buttonDisabled?: boolean
  /**
   * Display stuff on the right side of the TitleBar
   */
  right?: any[]
  /**
   * Set the titlebar to be fixed to the top of the view
   */
  fixed?: boolean
}

const DraggableComponentBar = styled<any>(ComponentBar)`
  app-region: drag;
  position: ${({ fixed }) => (fixed ? 'fixed' : 'relative')};
  padding-right: ${({ fixed }) => (fixed ? '196px' : '0')};
  height: 80px;
  background-color: white;
  z-index: 10;
`;

const StyledTitleBar = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const Extra = styled.div`
  display: flex;
  flex-direction: row;
`;

const Spacer = styled.div`
  height: 80px;
`;
Spacer.displayName = 'Spacer';

export const TitleBar: React.FC<TitleBarProps> = ({
  title, onBackClicked, right = [], fixed,
}) => (
  <>
    <DraggableComponentBar fixed={fixed}>
      {onBackClicked && (
        <Button secondary onClick={onBackClicked} className="back"><BackArrow /></Button>
      )}
      <Grid ignoreResponsiveness={!!onBackClicked}>
        <StyledTitleBar>
          <Title>{ title }</Title>
        </StyledTitleBar>
        <Extra>
          {right}
        </Extra>
      </Grid>
    </DraggableComponentBar>
    {fixed && <Spacer />}
  </>
);
