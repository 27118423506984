import React from 'react';
import { Field, Label } from '@privacy-request/ui';
import { get } from 'lodash';
import { dateFormatType, DateTimeInput } from '../../DateTime/DateTime';
import { CustomFieldDefinition } from '../CustomFieldDefinition';
import { CustomFieldOptions } from '../CustomFieldOptions';
import { getRest } from '../utils/getRest';

export interface CustomDateField extends CustomFieldOptions {
  type: 'date'
  name: string
  label: string
  format?: dateFormatType
}

export const date: CustomFieldDefinition<CustomDateField> = {
  type: 'date',
  component: (config, { values }) => (
    <DateTimeInput
      date={get(values, config.name)}
      format={config.format}
      {...getRest(config)}
    />
  ),
  wrapper: (config, {
    first,
    last,
    content,
  }) => (
    <Field last={last} first={first} key={config.name}>
      <Label>{config.label}</Label>
      {content}
    </Field>
  ),
};
