import { Task } from '../request/Task';
import { System } from '../system/System';
import { User } from '../user/User';

export enum SourceType {
  USER = 'USER',
  CRON = 'CRON',
  SYSTEM = 'SYSTEM',
}

export enum Action {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface AuditLog {
  id: string
  source_type: SourceType; // user,cron,etc
  source_id: string
  table_name: string
  table_row_id: string
  action: Action; // create,update,delete
  timestamp: string
  updated_values: any
  previous_values: any
  transaction_id: string

  // Relations
  user: User
  task?: Task
  processing_activity?: any
  system?: System
  processing_activity_any?: any
  cross_border_transfer?: any
  purpose_of_processing?: any
  personal_data_category?: any
  origin_of_personal_data?: any
  contact?: any
  tagitem?: any
}

export interface AggregateAuditLog {
  transaction_id: string
  source_type: SourceType
  timestamp: Date
  user?: User
  logs: AuditLog[]
}
