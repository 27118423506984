import { SubOrganization } from '../../types/sub_organization/SubOrganization';
import { useQueryPager } from '../useQueryPager/useQueryPager';
import { GetSubOrganizationsPaged } from '../../apollo/sub_organizations/sub_organization.queries';
import { useMe } from '../useMe';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomField } from '../../components/CustomFields/CustomField';
import { useIsAdminRoute } from '../useIsAdminRoute';

export type UseSubsidiaryDropdownCustomFieldProps = {
  skip?: boolean
  name?: string
  align?: any
};

export const useSubsidiaryDropdownCustomField = (selected: SubOrganization[] | undefined, {
  skip,
  name,
  align,
}: UseSubsidiaryDropdownCustomFieldProps = {}): CustomField => {
  const [t] = useTranslation('systems');
  const { me } = useMe();
  const isAdminRoute = useIsAdminRoute();
  const multi_org = me?.organization?.features?.multi_org_enabled;
  const { results } = useQueryPager<SubOrganization>(GetSubOrganizationsPaged, {
    skip: !multi_org || skip,
    parameterPrefix: 'ent_',
    limit: 5000,
  });
  const subsidiaryItems = useMemo(() => (
    results.map((suborg) => ({ text: suborg.name, value: suborg.id }))
  ), [results]);
  const displayValue = useMemo(() => {
    if (!multi_org) return '';

    if (!selected?.length) {
      return t('common:none');
    }

    if (selected?.length === 1) {
      const suborg = selected[0];
      const item = results.find((r) => r.id === (suborg.id || suborg));
      return item?.name;
    }

    return t('n_subsidiaries', { count: selected.length });
  }, [multi_org, selected, t, results]);
  if (!multi_org || isAdminRoute) {
    return undefined!;
  }
  return {
    type: 'sass-dropdown',
    multiple: true,
    name: name || 'sub_organizations',
    items: subsidiaryItems,
    displayValue,
    label: 'Subsidiaries',
    persistent: true,
    filter: true,
    // @ts-expect-error
    align,
  };
};
