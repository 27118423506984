import { Hotkey } from '../Hotkey';

export const helpHotkey: Hotkey = {
  keys: ['?', 'alt+?'],
  handler: (ev: KeyboardEvent, combo: string, { setState }) => {
    setState((state: any) => ({
      ...state,
      help: !state.help,
    }));
  },
  name: 'Hotkey help!',
  description: 'Display the hotkey summary.',
};
