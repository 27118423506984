import React, { useCallback } from 'react';
import styled from 'styled-components';
import { PipelineStep } from '@privacy-request/basic-types';
import { InsertButton, PipelineBuilderStep } from './PipelineBuilderStep';
import { randomHexString } from '@privacy-request/utils';
import { PipelineContext } from './PipelineContext';

const PipelineStepWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export type PipelineStepsEditorProps = {
  /**
   * The steps to modify
   */
  value: PipelineStep[]
  /**
   * The callback to invoke when steps are changed
   */
  onChange: ((steps: PipelineStep[]) => (void)) | ((ev: any) => (void))
  /**
   * Name to be used if the steps editor is used as part of a custom form
   */
  name?: string
};

export const PipelineStepsEditor = ({
  value,
  onChange,
  name,
}: PipelineStepsEditorProps) => {
  const onChangeWrapper = useCallback((steps: PipelineStep[]) => {
    if (name) {
      // @ts-expect-error
      onChange({ target: { name, value: steps } });
    } else {
      onChange(steps);
    }
  }, [onChange, name]);

  const _onChange = useCallback((step) => {
    const res = [...(value || [])];
    const idx = res.findIndex((o) => (o.id === step.id));
    res.splice(idx, 1, step);
    onChangeWrapper(res);
  }, [onChangeWrapper, value]);

  const onAdd = useCallback((pos) => {
    const res = [...(value || [])];
    res.splice(pos, 0, {
      id: randomHexString(6),
      handler: '',
      config: {},
    });
    onChangeWrapper(res);
  }, [onChangeWrapper, value]);

  const onDelete = useCallback((step) => {
    const res = [...(value || [])];
    const idx = res.findIndex((o) => (o.id === step.id));
    res.splice(idx, 1);
    onChangeWrapper(res);
  }, [onChangeWrapper, value]);

  return (
    <div style={{ padding: '16px' }}>
      <PipelineContext.Consumer>
        {
          (manager) => (
            <PipelineStepWrapper>
              <InsertButton onClick={() => (onAdd(0))} />
              {
                (value || []).map((step, i) => (
                  <React.Fragment key={step.id || `${step}-${i}`}>
                    <PipelineBuilderStep step={step} integration={manager.integration} onChange={_onChange} onDelete={() => (onDelete(step))} key={step.id} />
                    <InsertButton onClick={() => (onAdd(i + 1))} />
                  </React.Fragment>
                ))
              }
            </PipelineStepWrapper>
          )
        }
      </PipelineContext.Consumer>
    </div>
  );
};
