import React from 'react';
import { Field } from '@privacy-request/ui';
import { get } from 'lodash';
import { PipelineStepsEditor } from '../../PipelineManager/PipelineStepsEditor';
import { CustomFieldDefinition } from '../CustomFieldDefinition';
import { CustomFieldOptions } from '../CustomFieldOptions';
import { getRest } from '../utils/getRest';

export interface CustomPipelineStepsField extends CustomFieldOptions {
  type: 'pipeline-steps'
  name: string
  label: string
  show?: any
  fluid?: boolean
}

export const pipelineSteps: CustomFieldDefinition<CustomPipelineStepsField> = {
  type: 'pipeline-steps',
  component: (config, { onChange, values }) => (
    <PipelineStepsEditor
      value={get(values, config.name) || ''}
      name={config.name}
      onChange={onChange}
      {...getRest(config)}
    />
  ),
  wrapper: (config, {
    first,
    last,
    fluid,
    content,
  }) => (
    <Field last={last} first={first} key={config.name || config.type} fluid={fluid || config.fluid}>
      {content}
    </Field>
  ),
};
