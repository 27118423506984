import { Button, Field } from '@privacy-request/ui';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/OverlayProvider/Modal';
import { useProcessingActivityBulkActionsEdit } from '../../hooks/useProcessingActivityBulkActionsEdit';
import { CustomFieldConfig, CustomFields } from '../../../../components/CustomFields/CustomFields';
import { crossBorderTransfersCustomField } from '../../../SystemsView/common/fields';
import { useSelectionContext } from '../../../../hooks/useSelectionContext';

interface ProcessingActivityTransferMechanismModalProps {
  onCancel(): void,
  items: any,
  type: string,
}

export const ProcessingActivityTransferMechanismModal = ({
  onCancel,
  items,
  type,
}: ProcessingActivityTransferMechanismModalProps) => {
  const [t] = useTranslation('systems');
  const { selections, replace } = useSelectionContext();
  const { onAddTransferMechanism } = useProcessingActivityBulkActionsEdit();

  const onClick = useCallback(async (e) => {
    await onAddTransferMechanism(e);
    onCancel();
  }, [onAddTransferMechanism, onCancel]);

  const onChange = useCallback((e) => {
    const { name, value } = e.target;
    replace(name, ...(Array.from(value as any) as any));
  }, [replace]);

  const fieldConfig = useMemo((): CustomFieldConfig => ([
    crossBorderTransfersCustomField(t, selections, onChange, {
      // disabled: !canEditPerm('processing_activities.edit.cross_border_transfers'),
      add: false,
      value: Object.keys(selections.cross_border_transfers ?? {}).map((o) => (Number(o))),
    }),
  ]), [onChange, selections, t]);

  return (
    <Modal title={t('cross_border_transfers.title')} style={{ maxWidth: '740px' }} onCancel={onCancel}>
      <CustomFields config={fieldConfig} value={{}} onChange={onChange} />
      <Field right>
        <Button style={{ float: 'right', minWidth: '200px' }} onClick={onClick}>{t('cross_border_transfers.buttonText')}</Button>
      </Field>
    </Modal>
  );
};
