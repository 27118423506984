import { ChartTypes } from '../../views/DashboardView/types/ChartTypes';
import { TimeSeries } from '../../views/DashboardView/types/TimeSeries';
import { SET_CHART, SET_TIME } from './dashboard.types';

export interface SetChartAction {
  type: typeof SET_CHART
  payload: ChartTypes
}

export const setChart = (payload: ChartTypes): SetChartAction => ({
  type: SET_CHART,
  payload,
});

export interface SetTimeAction {
  type: typeof SET_TIME
  payload: TimeSeries
}

export const setTime = (payload: TimeSeries): SetTimeAction => ({
  type: SET_TIME,
  payload,
});

export type DashboardActionTypes =
  SetChartAction |
  SetTimeAction;
