import { useQuery } from '@apollo/react-hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  GetTaskByVendorToken, GetTaskByVendorTokenData, GetTaskByVendorTokenVariables,
} from '../../../apollo/task/task.queries';
import { setRequest } from '../../../store/request/request.actions';
import { setSystem } from '../../../store/system/system.actions';
import { Task } from '../../../types/request/Task';

export const useVendorToken = () => {
  const { token } = useParams<any>();
  const dispatch = useDispatch();
  const [t] = useTranslation('requests');
  const [error, setError] = useState('');

  const { data, error: queryError } = useQuery<GetTaskByVendorTokenData, GetTaskByVendorTokenVariables>(GetTaskByVendorToken, { variables: { token } });

  // @ts-ignore
  const errorResult = queryError?.networkError?.result;

  const [task, setTask] = useState<Partial<Task>>(data?.get_task_by_vendor_token || {});

  useEffect(() => {
    setTask(data?.get_task_by_vendor_token || {});
    dispatch(setRequest(data?.get_task_by_vendor_token?.request || {}));
    dispatch(setSystem(data?.get_task_by_vendor_token?.system || {}));
  }, [data, dispatch]);

  useEffect(() => {
    if (errorResult) {
      setError(t(`errors.${errorResult.code}`));
    }
  }, [errorResult, t]);

  return {
    task,
    error,
  };
};
