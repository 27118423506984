import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import update from 'immutability-helper';

export type UseStatefulEditingProps<T> = {
  /**
   * A reducer passed to the bulkUpdate reduce call.
   */
  updateReducer?: (acc: any, current: any) => any
  /**
   * The original, un-edited entity
   */
  original?: Partial<T>
};

const defaultUpdateReducer = (acc: any, [key, value]: any[]) => {
  acc[key] = { $set: value };
  return acc;
};

const empty = {};

/**
 * Callbacks common to asset/activity/etc editing where both Redux and React
 * state are used to manage entity changes
 */
export const useStatefulEditing = <T>({
  updateReducer = defaultUpdateReducer,
  original = empty,
}: UseStatefulEditingProps<T> = {}) => {
  const [edits, setEdits] = useState<Partial<T>>({});
  const [current, setCurrent] = useState<Partial<T>>({ ...original });

  useEffect(() => {
    setCurrent({ ...original, ...edits });
  }, [edits, original]);

  const bulkUpdate = useCallback((changes: any) => {
    const updated = update(edits, Object.entries(changes ?? {}).reduce(updateReducer, {} as any));
    setEdits(updated);
    setCurrent({ ...original, ...updated });
  }, [edits, original, updateReducer]);

  const onChange = useCallback((e) => {
    const { name, value } = e.target;
    bulkUpdate({ [name]: value });
  }, [bulkUpdate]);

  const onRevert = useCallback(() => {
    setCurrent({ ...original });
    setEdits({});
  }, [original]);

  return {
    bulkUpdate,
    onChange,
    onRevert,
    current,
    setCurrent,
    edits,
    dirty: Object.keys(edits).length > 0,
  };
};
