import {
  Card,
  CloseButton,
  Field,
  Title,
} from '@privacy-request/ui';
import React, {
  useEffect, useRef, useCallback,
} from 'react';
import ReactDOM from 'react-dom';

import { useHotkey } from '../../hooks';
import useOverlay from './hooks/useOverlay';

export interface ModalProps {
  children: any
  style?: React.CSSProperties
  title?: string
  onCancel?: () => void
}

export default ({
  children, style, title, onCancel,
}: ModalProps) => {
  const { overlayRef, onMouseDown } = useOverlay({ onCancel });

  const cancelRef = useRef(onCancel);
  useEffect(() => {
    cancelRef.current = onCancel;
  }, [onCancel]);
  const onEscape = useCallback(() => {
    if (!cancelRef.current) {
      return;
    }

    cancelRef.current();
  }, []);
  useHotkey('Escape', onEscape);

  if (!overlayRef.current) {
    return null;
  }

  return ReactDOM.createPortal((
    <Card modal style={style} onMouseDown={onMouseDown}>
      {onCancel && (<CloseButton onClick={onCancel} />)}
      {title && (
        <Field>
          <Title>{title}</Title>
        </Field>
      )}
      {children}
    </Card>
  ), overlayRef.current as Element);
};
