import React, { useCallback } from 'react';
import { CheckBox, Text } from '@privacy-request/ui';
import { FilterComponent, FilterComponentProps } from '../../FilterComponent.d';
import omit from '../../../../utils/omit';
import keycode from 'keycode';

export type BooleanFilterComponentProps = FilterComponentProps & {
  name: string
  title: string
  preset?: boolean

  /**
   * An optional callback that is used to determine whether the control is
   * active based on the given filter data
   * @param filterData {Object} - Existing filter data
   * @returns True if active, false otherwise
   */
  isActive?: (filterData: any) => boolean
  /**
   * An optional callback that is used to update the filter data according
   * to any special rules the control may have
   * @param filterData {Object} - Existing filter data
   * @returns Updated filter data
   */
  setActive?: (filterData: any) => any
};

export const BooleanFilter: FilterComponent<BooleanFilterComponentProps> = ({
  name,
  title,
  preset,
  filterData = {},
  setFilterData,
  isActive,
  setActive,
}: BooleanFilterComponentProps) => {
  const active = isActive ? isActive(filterData) : (Object.prototype.hasOwnProperty.call(filterData, name) ? Boolean(filterData[name]) : Boolean(preset));

  const onClick = useCallback((ev: any) => {
    ev.stopPropagation();
    ev.preventDefault();
    setFilterData((filterData: any) => ({
      ...omit(filterData, name),
      ...(setActive ? setActive(filterData) : ((!active) === preset ? {} : { [name]: !active })),
    }));
  }, [active, name, preset, setActive, setFilterData]);
  const onKeyPress = useCallback((e) => {
    switch (keycode(e)) {
      case 'space':
      case 'enter':
        onClick(e);
        break;
      default:
        break;
    }
  }, [onClick]);

  return (
    <div
      className="flex spaced"
      onClick={onClick}
      onKeyPress={onKeyPress}
      role="button"
      tabIndex={0}
    >
      <CheckBox checked={active} onChange={onClick} />
      <Text style={{ fontSize: '16px' }}>{title}</Text>
    </div>
  );
};

export type CreateBooleanFilterProps = {
  /**
   * The HTML name of the field
   */
  name: string
  /**
   * The title text displayed to the user
   */
  title: string
  /**
   * If set, the preset value of the field when no other value is given. Allows
   * a field to default to true/checked if it should be considered active when
   * no explicit value is given.
   */
  preset?: boolean

  /**
   * An optional callback that is used to determine whether the control is
   * active based on the given filter data
   * @param filterData {Object} - Existing filter data
   * @returns True if active, false otherwise
   */
  isActive?: (filterData: any) => boolean
  /**
   * An optional callback that is used to update the filter data according
   * to any special rules the control may have
   * @param filterData {Object} - Existing filter data
   * @returns Updated filter data
   */
  setActive?: (filterData: any) => any
};

/**
 * A convenience function which creates a standard filter that has a name,
 * and a selected or unselected state.
 */
export const createBooleanFilter = (initialProps: CreateBooleanFilterProps): FilterComponent => (props) => (
  <BooleanFilter
    {...initialProps}
    {...props}
  />
);
