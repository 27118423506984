import gql from 'graphql-tag';
import { SystemFragment } from '../systems.fragments';

/**
 * @deprecated
 */
export const GetSystemTemplates = gql`
  query get_system_templates($limit: Int!, $offset: Int!, $filter: String!, $sort: Object!) {
    get_system_templates(limit: $limit, offset: $offset, filter: $filter, sort: $sort) @rest(type: "SystemTemplatePaged", path: "systems/templates?{args}") {
      count
      rows @type(name: "System") {
        ...system_page
      }
    }
  }
  ${SystemFragment}
`;

/**
 * @deprecated
 */
export const GetSystemTemplate = gql`
  query system($id: Int!) {
    system(id: $id) @rest(type: "System", path: "systems/templates/{args.id}") {
      ...system
    }
  }
  ${SystemFragment}
`;
