import {
  Card,
  Grid,
  LoadingIndicator,
  LoadingProvider,
  Steps,
  TitleBar,
} from '@privacy-request/ui';
import React, { Suspense } from 'react';

import { useRouting } from '../../../hooks/useRouting';
import { OnboardingWorkflowProps } from '../types/WorkflowStep';

export const OnboardingWorkflow = ({
  title,
  steps,
  currentStep,
  ...props
}: OnboardingWorkflowProps) => {
  const { goBack } = useRouting();
  const { Component } = currentStep ?? {};

  return (
    <div>
      <TitleBar title={title} onBackClicked={goBack} />
      <Grid>
        <Card style={{ marginBottom: '256px' }}>
          <Steps steps={steps} value={currentStep.value} />
          <Suspense fallback={<LoadingProvider active><LoadingIndicator /></LoadingProvider>}>
            {Component && (
              <Component
                steps={steps}
                currentStep={currentStep}
                {...props}
              />
            )}
          </Suspense>
        </Card>
      </Grid>
    </div>
  );
};
