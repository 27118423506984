import {
  GenericInputStyle, GenericTextStyle, Text, Tooltip, UploadIcon,
} from '@privacy-request/ui';
import update from 'immutability-helper';
import React, {
  useReducer, useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import styled, { useTheme } from 'styled-components';

import { UPLOAD_FILE_SUPPORTED_EXTENSIONS, UPLOAD_FILE_MAX_SIZE } from '../../constants';
import { doBlobbyStuff } from '../../hooks/useDownloadResponse';
import { useFetch } from '../../hooks/useFetch';
import { useSelector } from '../../store/useSelector';
import { PRFile } from '../../types/file/PRFile';
import { FileViewer } from './FileViewer/FileViewer';
import { backendUrl } from '../../utils/url';
import FileUploadItem from './FileUploadItem';
import evt from '../../utils/evt';
import { FileModification, RequestTaskFileViewer } from '@privacy-request/basic-types';
import { SUPPORTED_FILEVIEWER_FORMATS } from '@privacy-request/common/src/constants';

const evaluateBackgroundColor = ({ globalDrag, inDropZone }: any) => {
  if (inDropZone) {
    return '#ddffdc';
  }
  if (globalDrag) {
    return '#fffedc';
  }
};

const Wrapper = styled.div<any>`
  ${GenericInputStyle}
  ${GenericTextStyle}
  height: ${({ single }) => (single ? '40px' : 'unset')};
  min-height: ${({ single }) => (single ? 'unset' : '120px')};
  ${({ single }) => (single ? 'padding: 0;' : '')}
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  position: relative;
  background-color: ${evaluateBackgroundColor};
`;
Wrapper.displayName = 'Wrapper';

const NoFiles = styled.div<{ single?: boolean }>`
  width: 100%;
  height: ${({ single }) => (single ? '40px' : '100px')};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ single }) => (single ? 'row' : 'column')};
  cursor-events: none;
`;
NoFiles.displayName = 'NoFiles';

const UploadHandler = styled.input`
  position: absolute;
  opacity: 0;
  top: 0; bottom: 0; right: 0; left: 0;
  width: 100%;
  cursor: pointer;
`;
UploadHandler.displayName = 'UploadHandler';
UploadHandler.defaultProps = { type: 'file' };

const styles = {
  text: {
    marginTop: '12px',
    color: 'rgba(0,0,0,0.6)',
  },
  singleText: { color: 'rgba(0,0,0,0.6)' },
  inputError: {
    bottom: '0px',
    left: '0px',
    color: 'rgba(255,0,0,0.6)',
  },
};

const MODIFY_DROP_ZONE = 'fu/MODIFY_DROP_ZONE';
const SET_DROP_ZONE = 'fu/SET_DROP_ZONE';
const ADD_FILE = 'fu/ADD_FILE';
const SET_FILES = 'fu/SET_FILES';
const LONG_FORMAT_TYPE = ['image/jpeg', 'image/jpg', 'image/png'];

const initialState = (fileList: any) => ({
  inDropZone: 0,
  fileList,
});

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case MODIFY_DROP_ZONE:
      return update(state, { inDropZone: { $set: state.inDropZone + action.payload } });
    case SET_DROP_ZONE:
      return update(state, { inDropZone: { $set: action.payload } });
    case ADD_FILE:
      return update(state, { fileList: { $push: action.payload } });
    case SET_FILES:
      return update(state, { fileList: { $set: action.payload } });
    default:
      return state;
  }
};

export interface FileUploadProps {
  name: string;
  accept?: string;
  value: any;
  onChange: any;
  taskId?: number;
  useFileViewer?: boolean;
  disableFileViewer?: boolean;
  disabled?: boolean;
  single?: boolean;
  canDownload?: boolean;
  /**
   * Passed on to the fileviewer, the request type is used to determine how data fields should be shown.
   */
  requestType?: string;
}

export default ({
  name, value, onChange, disabled, taskId, single, canDownload, requestType, useFileViewer = false, disableFileViewer = false, accept,
}: FileUploadProps) => {
  const [state, dispatch] = useReducer(reducer, initialState(value || []));
  const isGlobalDragging = useSelector(s => s.drag.dragging);
  const [t] = useTranslation('common');
  const { addToast } = useToasts();
  const theme = useTheme();

  const isValidFileType = useCallback((file:any) => {
    const fileExt = file && file.name.split('.').pop();
    // To handle id accept is long formated like image/png
    const acceptUpdated: any = [];
    accept?.split(',').map(t => (LONG_FORMAT_TYPE.includes(t) ? acceptUpdated.push(`.${t.split('/')[1].toUpperCase()}`) : acceptUpdated.push(t)));

    // check file type if accept prop is given
    if (accept) {
      return true;
    }
    // check file type if accept prop is not given, apply as default
    if (!accept && !UPLOAD_FILE_SUPPORTED_EXTENSIONS.includes(`.${fileExt?.toUpperCase()}`)) {
      // UnSupportedFile
      addToast(t('file.invalid_file_type'), { appearance: 'error', autoDismiss: true });
      return false;
    }
    return true;
  }, [t, accept, addToast]);

  const isValidFileSize = useCallback((file:any) => {
    if ((Number(file?.size) / 1024) > UPLOAD_FILE_MAX_SIZE) {
      // unSupportedFileSize
      addToast(t('file.invalid_file_size'), { appearance: 'error', autoDismiss: true });
      return false;
    }
    return true;
  }, [t, addToast]);

  useEffect(() => {
    dispatch({ type: SET_FILES, payload: (value || []) });
  }, [value]);

  const onDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.persist();
    e.preventDefault();

    if (!e.dataTransfer.files) {
      return;
    }
    dispatch({ type: SET_DROP_ZONE, payload: 0 });

    if (disabled) {
      return;
    }

    const files = [];
    let isValidType: boolean = false;
    let isValidSize: boolean = false;
    for (let i = 0; i < e.dataTransfer.files.length; i++) {
      const file = e.dataTransfer.files[i];
      isValidType = isValidFileType(file);
      isValidSize = isValidFileSize(file);
      if (isValidType && isValidSize) {
        files.push(file);
      }
    }
    if (!files.length) {
      return;
    }

    if (single) {
      dispatch({ type: SET_FILES, payload: [files[0]] });
      onChange(evt(name, [files[0]]));
    } else {
      dispatch({ type: ADD_FILE, payload: files });
      onChange(evt(name, [...state.fileList, ...files]));
    }
  }, [state.fileList, name, onChange, single, disabled, isValidFileType, isValidFileSize]);

  const onDragOver = useCallback((e) => {
  }, []);

  const onDragEnter = useCallback((e) => {
    e.preventDefault();
    dispatch({ type: MODIFY_DROP_ZONE, payload: 1 });
  }, []);

  const onDragLeave = useCallback((e) => {
    e.preventDefault();
    dispatch({ type: MODIFY_DROP_ZONE, payload: -1 });
  }, []);

  const onDelete = useCallback((index: number) => {
    const files = update(state.fileList, { $splice: [[index, 1]] });
    dispatch({ type: SET_FILES, payload: files });
    onChange(evt(name, files));
  }, [state.fileList, name, onChange]);

  const onSelectFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const files = [];
    let isValidType: boolean = false;
    let isValidSize: boolean = false;
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      isValidType = isValidFileType(file);
      isValidSize = isValidFileSize(file);
      if (isValidType && isValidSize) {
        files.push(file);
      }
    }

    if (single) {
      dispatch({ type: SET_FILES, payload: files });
      onChange(evt(name, files));
    } else {
      dispatch({ type: ADD_FILE, payload: files });
      onChange(evt(name, [...state.fileList, ...files]));
    }
  }, [name, onChange, dispatch, state.fileList, single, isValidFileType, isValidFileSize]);

  const [file, setFile] = useState<PRFile | File>();
  const [content, setContent] = useState<RequestTaskFileViewer[]>();
  const { get, response } = useFetch(backendUrl('systems/files'));
  const { get: getForEdit } = useFetch(backendUrl(`requests/${taskId}/get-for-edit`));
  const onFileClick = useCallback(async (file: PRFile | File) => {
    if (!canDownload || file instanceof File) {
      return;
    }

    const forEdit = SUPPORTED_FILEVIEWER_FORMATS.indexOf(file.type) !== -1 && useFileViewer;
    const result = await (forEdit ? getForEdit : get)(`?file=${encodeURIComponent(JSON.stringify({ ...file, modifications: [] }))}`);
    if (result.ok === false) {
      addToast('Sorry, we had a problem loading that file', { appearance: 'error', autoDismiss: true });
      return;
    }
    if (forEdit) {
      setFile(file);
      setContent(result as RequestTaskFileViewer[]);
    } else {
      doBlobbyStuff(await response.blob(), file.name);
    }
  }, [useFileViewer, response, canDownload, get, getForEdit, addToast]);

  const { fileList } = state;
  const onSaveNewFile = useCallback(async (fileMods: FileModification[]) => {
    const existingIndex = fileList.indexOf(file);
    const nextFiles = update(fileList, { [existingIndex]: { modifications: { $set: fileMods } } });

    dispatch({
      type: SET_FILES,
      payload: nextFiles,
    });
    onChange(evt(name, nextFiles));

    setFile(undefined);
    setContent(undefined);
  }, [file, fileList, name, onChange]);

  const onCancel = useCallback(() => {
    setFile(undefined);
    setContent(undefined);
  }, []);

  const iconSize = single ? 32 : 64;

  return (
    <>
      <Tooltip disabled={single || !state.fileList.length || disabled} content={t('file.click_or_drag')}>
        <Wrapper
          onDrop={onDrop}
          onDragOver={onDragOver}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          disabled={disabled}
          globalDrag={isGlobalDragging}
          inDropZone={state.inDropZone}
          single={single}
        >
          <UploadHandler accept={accept} onChange={onSelectFile} multiple={!single} disabled={disabled} />
          {!!state.fileList.length && state.fileList.map((file: PRFile, i: number) => (
            <FileUploadItem single={single} onClick={onFileClick} canDownload={canDownload} onDelete={() => onDelete(i)} disabled={disabled} key={file.name} file={file} />
          ))}
          {!disabled && !state.fileList.length && (
            <NoFiles single={single}>
              <Text style={single ? styles.singleText : styles.text}>{t('file.click_or_drag')}</Text>
              <UploadIcon width={iconSize} height={iconSize} color={`${theme.CTA_COLOR}aa`} />
            </NoFiles>
          )}
        </Wrapper>
      </Tooltip>
      {!!content && !!file && (
        <FileViewer
          onCancel={onCancel}
          file={file}
          content={content}
          onSave={onSaveNewFile}
          requestType={requestType}
        />
      )}
    </>
  );
};
