import {
  Field, Label, Grid, Title, EditIcon, Input, CheckMarkIcon, CloseIcon, Button, Text,
} from '@privacy-request/ui';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import styled, { useTheme } from 'styled-components';
import evt from '../../utils/evt';
import { PrivacyLink } from '../../views/LoginView/LoginSelectView';
import { DateTime } from '../DateTime/DateTime';
import { approvalRequired } from '@privacy-request/common/src/utils/data_map/approvalRequired';
import { reviewRequired } from '@privacy-request/common/src/utils/data_map/reviewRequired';
import { userToName } from '@privacy-request/common/src/utils/userToName';
import { DataMapEntityPublicationToggle } from '../DataMapEntityPublicationToggle';
import { System } from '../../types/system/System';
import { ProcessingActivity } from '../../types/system/ProcessingActivity';
import { Logo } from '../Logo/Logo';

const Wrapper = styled(Field)`
  display: flex;
  padding-left: 0;
`;
Wrapper.displayName = 'Wrapper';

const FieldLabel = styled(Field)`
  padding-bottom: 0;
`;
FieldLabel.displayName = 'FieldLabel';

const FixedField = styled(Field)`
  height: 40px;
  display: flex;
  align-items: center;
  padding-bottom: 0;
`;
FixedField.displayName = 'FixedField';

const LogoWrapper = styled.div<{ onClick?: any }>`
  border-radius: 50%;
  border: 1px solid rgba(0,0,0,0.1);
  display: flex;
  padding: 22px;
  transition: border 0.1s linear;
  position: relative;

  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};

  ${({ onClick }) => !!onClick && `
    &:hover {
      border: 1px solid rgba(0,0,0,0.3);
    }
  `}
`;
LogoWrapper.displayName = 'LogoWrapper';

const UploadInput = styled.input`
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  cursor: pointer;
`;
UploadInput.defaultProps = {
  type: 'file',
  accept: 'image/svg+xml, image/jpeg, image/png',
};

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;
HeaderWrapper.displayName = 'HeaderWrapper';

const Section = styled.div`
  width: 100%;
`;
Section.displayName = 'Section';

const IconWrapper = styled.span`
  margin-left: 16px;
  cursor: pointer;
  outline: 0px solid transparent;
  &[content-editable] {
    outline: 0px solid transparent;
  }
`;

const ModifiedInput = styled(Input)`
  width: 100%;
`;

const styles = {
  headerMargin: {
    marginLeft: '16px',
    width: '60%',
  },
  headerIcon: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: '0rem',
  },
  title: {
    display: 'inline-block',
    fontSize: '32px',
  },
};

const WebsiteLink = styled(PrivacyLink)`
  margin: 0;
  font-size: 16px;
  text-decoration: none;
`;

function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

function fixUrl(url: string) {
  if (url.startsWith('http')) {
    return url;
  }

  return `https://${url}`;
}

function cleanUrl(url: string) {
  return url.replace(/https?:\/\//, '');
}

type UseEditableTextBoxProps = {
  original?: string
  onChange: (value: string) => Promise<boolean>
  placeholder?: string
  render?: (text: string) => React.ReactNode
  disabled?: boolean
  hasError?: boolean
  style?: React.CSSProperties
};

const EditableTextBox = ({
  original,
  onChange,
  placeholder,
  render = (text) => (<span>{text}</span>),
  disabled,
  hasError,
  style,
}: UseEditableTextBoxProps) => {
  const theme = useTheme();
  const [text, setText] = useState(original || '');
  const [editing, setEditing] = useState(false);
  useEffect(() => {
    if (original) {
      setText(original);
    }
  }, [original]);
  const _onChange = useCallback((e: any) => {
    setText(e.target.value);
  }, []);
  const onEdit = useCallback(() => setEditing(true), []);
  const onSave = useCallback(async () => {
    if (await onChange(text)) {
      setEditing(false);
    }
  }, [onChange, text]);

  const onCancel = useCallback(() => {
    setEditing(false);
    setText(original || '');
  }, [original]);

  if (editing) {
    return (
      <div style={{ whiteSpace: 'nowrap', ...style }}>
        <ModifiedInput
          value={text}
          onChange={_onChange}
          autoFocus
          error={hasError}
          disabled={disabled}
        />
        <IconWrapper onClick={onSave}>
          <CheckMarkIcon color={theme.CTA_GREEN} width="22" height="22" />
        </IconWrapper>
        <IconWrapper onClick={onCancel}>
          <CloseIcon color={theme.CTA_ERROR} />
        </IconWrapper>
      </div>
    );
  }
  return (
    <div style={{ whiteSpace: 'nowrap', ...style }}>
      {
        (text || !placeholder) ? (
          render(text)
        ) : (
          <span style={{ color: 'grey', fontStyle: 'italic' }}>{placeholder}</span>
        )
      }
      {!!_onChange && !disabled && (
        <IconWrapper onClick={onEdit} style={{ verticalAlign: 'center' }}>
          <EditIcon />
        </IconWrapper>
      )}
    </div>
  );
};

interface EntityInfoDisplayProps {
  entity: Partial<System | ProcessingActivity>
  subtitle?: any
  website?: string
  email?: string
  logo?: string
  onChange: any
  onRequestReview: any
  onPublish: any
  validate?: (suggestion: string) => Promise<string | boolean>
  errorKey?: string
  canRequestReview: boolean
  canPublish: boolean
  fields?: Array<'website' | 'email'>
}

export const EntityInfoDisplay = ({
  entity,
  subtitle,
  logo,
  website,
  email,
  onChange,
  onRequestReview,
  onPublish,
  validate,
  errorKey,
  canRequestReview,
  canPublish,
  fields,
}: EntityInfoDisplayProps) => {
  const [t] = useTranslation();
  const [validating, setValidating] = useState(false);
  const [hasError, setError] = useState(false);
  const { addToast, removeAllToasts } = useToasts();

  const onChangeName = useCallback(async (text: string): Promise<boolean> => {
    if (validating) {
      return false;
    }

    if (validate) {
      setValidating(true);
      const result = await validate(text);
      setValidating(false);

      if (result && typeof result === 'string') {
        setError(true);
        return new Promise((resolve, reject) => {
          const onAccept = () => {
            onChange(evt('name', result));
            removeAllToasts();
            setError(false);
            resolve(false);
          };
          addToast((
            <div>
              {t(errorKey || '', { text, suggestion: result })}
              <Button style={{ marginTop: '12px' }} onClick={onAccept} short secondary fluid>{t('systems:errors.use_suggestion', { suggestion: result })}</Button>
            </div>
          ), {
            appearance: 'warning',
            autoDismiss: true,
          });
        });
      }
    }

    onChange(evt('name', text));
    return true;
  }, [onChange, validate, validating, addToast, removeAllToasts, errorKey, t]);

  const onUpload = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }

    const b64 = await getBase64(file);

    onChange(evt('image_url', b64));
  }, [onChange]);

  return (
    <Wrapper>
      <Grid container>
        <Section>
          <HeaderWrapper>
            {logo && (
              <LogoWrapper onClick={() => {}}>
                <Logo
                  url={logo}
                  alt="Asset logo"
                />
                <UploadInput onChange={onUpload} />
              </LogoWrapper>
            )}
            <div style={styles.headerMargin}>
              <EditableTextBox
                original={entity.name}
                onChange={onChangeName}
                render={(text: string) => (
                  <Title style={styles.title}>{text}</Title>
                )}
                disabled={validating}
                hasError={hasError}
              />
              {subtitle}
              {fields?.includes('website') && (
                <EditableTextBox
                  original={website}
                  onChange={async (text: string) => {
                    onChange(evt('website', text));
                    return true;
                  }}
                  placeholder="Website..."
                  render={(text: string) => (
                    <WebsiteLink href={fixUrl(text)} rel="noreferrer" target="_blank">{cleanUrl(text)}</WebsiteLink>
                  )}
                  style={styles.headerIcon}
                />
              )}
              {fields?.includes('email') && (
                <EditableTextBox
                  original={email}
                  onChange={async (text: string) => {
                    onChange(evt('email', text));
                    return true;
                  }}
                  placeholder="Email..."
                  render={(text: string) => (
                    <WebsiteLink href={`mailto:${text}`}>{text}</WebsiteLink>
                  )}
                  style={styles.headerIcon}
                />
              )}
            </div>
          </HeaderWrapper>
        </Section>
        <Section style={{ display: 'flex' }}>
          <div>
            <FieldLabel rightLabel>
              <Label rightLabel>
                {t('systems:form.published')}
              </Label>
            </FieldLabel>
            <FieldLabel rightLabel>
              <Label rightLabel>
                {t('systems:form.approved_by')}
              </Label>
            </FieldLabel>
            <FieldLabel rightLabel>
              <Label rightLabel>
                {t('systems:table.reviewed_at')}
              </Label>
            </FieldLabel>
            <FieldLabel rightLabel>
              <Label rightLabel>
                {t('systems:form.last_updated')}
              </Label>
            </FieldLabel>
            <FieldLabel rightLabel>
              <Label rightLabel>
                {t('systems:form.date_added')}
              </Label>
            </FieldLabel>
          </div>
          <div>
            <FixedField>
              <DataMapEntityPublicationToggle
                entity={entity}
                hasPermission={!!canPublish}
                onToggle={onPublish}
              />
            </FixedField>
            <FixedField>
              <Text>
                {
                  approvalRequired(entity)
                    ? t('systems:form.not_applicable')
                    : (
                      entity.approver
                        ? userToName(entity.approver)
                        : t('systems:form.not_applicable')
                    )
                }
              </Text>
            </FixedField>
            <FixedField>
              <Text>
                {entity.reviewed_at ? (
                  <DateTime date={entity.reviewed_at} />
                ) : (
                  'N/A'
                )}
                {(canRequestReview && !reviewRequired(entity)) && (
                  <Button
                    onClick={onRequestReview}
                    style={{
                      display: 'inline', marginLeft: '16px', padding: '0 12px',
                    }}
                    padded
                    short
                    secondary
                  >
                    {t('systems:request_review')}
                  </Button>
                )}
              </Text>
            </FixedField>
            <FixedField>
              <Text>
                <DateTime date={entity.updated_at} />
              </Text>
            </FixedField>
            <FixedField>
              <Text>
                <DateTime date={entity.created_at} />
              </Text>
            </FixedField>
          </div>
        </Section>
      </Grid>
    </Wrapper>
  );
};
