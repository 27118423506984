import React, { useRef, useState } from 'react';
import { DropdownContent, DropdownContentProps } from '../../atoms/dropdown/DropdownContent';
import { DropdownWrapper, DropdownWrapperProps } from '../../atoms/dropdown/DropdownWrapper';
import { useOnClickOutside } from '../../hooks';
import { clickable } from '../../utils/a11y';

export type StandardDropdownProps = DropdownWrapperProps & {
  children: React.ReactNode[]
  disabled?: boolean

  contentBoxProps?: DropdownContentProps
};

/**
 * A standard dropdown component which accepts two children:
 * 1.  The first child is the control/container, which when clicked causes the
 *     dropdown content to display, and
 * 2.  The second (and any remaining) child is the content to be displayed
 *     within the dropdown content panel.
 */
export const StandardDropdown = (props: StandardDropdownProps) => {
  const {
    children: [control, ...content],
    disabled,
    style,
    tabIndex,
    contentBoxProps,
    ...rest
  } = props;
  const [open, setOpen] = useState(false);
  const wrapper = useRef<HTMLDivElement>(null);
  useOnClickOutside(wrapper, () => (setOpen(false)));

  return (
    <DropdownWrapper
      {...rest}
      tabIndex={tabIndex}
      style={style}
      ref={wrapper}
      disabled={disabled}
    >
      <div {...clickable(() => (setOpen((open) => (!open))))}>
        {control}
      </div>

      {open && (
        <DropdownContent right plain {...contentBoxProps}>
          {content}
        </DropdownContent>
      )}
    </DropdownWrapper>
  );
};
