import React from 'react';
import {
  Field,
  Label,
  TextArea,
} from '@privacy-request/ui';
import { get } from 'lodash';
import { CustomFieldDefinition } from '../CustomFieldDefinition';
import { CustomFieldOptions } from '../CustomFieldOptions';
import { err } from '../utils/err';
import { getRest } from '../utils/getRest';

export interface CustomTextAreaField extends CustomFieldOptions {
  type: 'textarea'
  name: string
  style?: React.CSSProperties
  label: string
  disabled?: boolean
}

export const textarea: CustomFieldDefinition<CustomTextAreaField> = {
  type: 'textarea',
  component: (config, { onChange, values }) => (
    <TextArea
      style={config.style || { minHeight: '86px' }}
      value={get(values, config.name) || ''}
      disabled={config.disabled}
      name={config.name}
      onChange={onChange}
      {...getRest(config)}
    />
  ),
  wrapper: (config, {
    first,
    last,
    fluid,
    content,
    errors,
  }) => (
    <Field last={last} first={first} fluid={fluid} style={last ? { paddingBottom: 0 } : {}} key={config.name || config.label}>
      <Label>{config.label}</Label>
      {err(errors, config.name)}
      {content}
    </Field>
  ),
};
