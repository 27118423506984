import { useEffect, useRef } from 'react';

export const doBlobbyStuff = (blob: Blob, filename: string) => {
  // @ts-ignore
  if (window.navigator.msSaveOrOpenBlob) {
    // @ts-ignore
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }
};

export default (response: any, filename: string) => {
  const downloadResponse = useRef(response);
  useEffect(() => {
    async function go() {
      if (!response.ok) {
        return;
      }
      const blob = await downloadResponse.current.blob();
      doBlobbyStuff(blob, filename);
    }
    go();
  }, [response.ok, filename]);
};
