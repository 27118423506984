import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import update from 'immutability-helper';

export type SelectionContextControllerShape = {
  /**
   * The set of selections currently chosen. A map of "field names" under which
   * is a map of ids and their chosen state.
   */
  selections: { [namespace: string]: { [id: string | number]: boolean } }
  /**
   * Toggle the selection of the given id for the given namespace.
   * @param namespace The namespace to look in
   * @param id The id to toggle selection for
   * @returns The new selected state of the item
   */
  toggle: (namespace: string, id: string | number) => boolean
  select: (namespace: string, ...id: Array<string | number>) => void
  deselect: (namespace: string, ...id: Array<string | number>) => void
  replace: (namespace: string, ...id: Array<string | number>) => void
  clear: (namespace: string) => Array<string | number>
};

export const SelectionContext = React.createContext<SelectionContextControllerShape>({
  selections: {},
  toggle(namespace, id) { throw new Error('SelectionContext not available'); },
  select(namespace, ...id) { throw new Error('SelectionContext not available'); },
  deselect(namespace, ...id) { throw new Error('SelectionContext not available'); },
  replace(namespace, ...id) { throw new Error('SelectionContext not available'); },
  clear(namespace) { throw new Error('SelectionContext not available'); },
});

export const useSelectionContextController = (): SelectionContextControllerShape => {
  const [selections, setSelections] = useState<any>({});

  const toggle = useCallback((namespace: string, id: string | number) => {
    const current = selections[namespace]?.[id];
    setSelections((selections: any) => (
      update(selections, {
        [namespace]: (current: any) => (
          update(current ?? {}, {
            [id]: { $set: !selections[namespace]?.[id] },
          })
        ),
      })
    ));
    return !current;
  }, [selections]);

  const select = useCallback((namespace: string, ...id: Array<string | number>) => {
    setSelections((selections: any) => {
      const result = update(selections, {
        [namespace]: (current: any) => (
          update(current ?? {}, id.reduce((acc, id) => {
            acc[id] = { $set: true };
            return acc;
          }, {} as any))
        ),
      });
      return result;
    });
  }, []);

  const deselect = useCallback((namespace: string, ...id: Array<string | number>) => {
    setSelections((selections: any) => (
      update(selections, {
        [namespace]: (current: any) => (
          update(current ?? {}, {
            $unset: id,
          })
        ),
      })
    ));
  }, []);

  const replace = useCallback((namespace: string, ...id: Array<string | number>) => {
    setSelections((selections: any) => {
      const result = update(selections, {
        [namespace]: {
          $set: id.reduce((acc, id) => {
            acc[id] = { $set: true };
            return acc;
          }, {} as any),
        },
      });
      return result;
    });
  }, []);

  const clear = useCallback((namespace: string, ...id: Array<string | number>) => {
    const ids = Object.keys(selections[namespace] ?? {});
    setSelections((selections: any) => (
      update(selections, {
        [namespace]: { $set: {} },
      })
    ));
    return ids;
  }, [selections]);

  const controller = useMemo(() => ({
    selections,
    toggle,
    select,
    deselect,
    replace,
    clear,
  }), [selections, toggle, select, deselect, replace, clear]);
  return controller;
};

export const useSelectionContext = () => (
  React.useContext(SelectionContext)
);

export const useSelectionContextWithNamespace = (namespace: string, controller?: SelectionContextControllerShape) => {
  const {
    selections,
    toggle,
    select,
    deselect,
    replace,
    clear,
  } = controller ?? React.useContext(SelectionContext);

  return {
    selections: selections[namespace] ?? {},
    toggle: (id: string | number) => (toggle(namespace, id)),
    select: (...id: Array<string | number>) => (select(namespace, ...id)),
    deselect: (...id: Array<string | number>) => (deselect(namespace, ...id)),
    replace: (...id: Array<string | number>) => (replace(namespace, ...id)),
    clear: () => (clear(namespace)),
  };
};
