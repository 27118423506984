import clsx from 'clsx';
import React, {
  useCallback,
  useRef,
  useState,
} from 'react';
import { TableHeader, TableHeaderProps } from './TableHeader';

/**
 * A table cell which, when hovered, presents a drag handle at the end of the
 * cell which can be clicked and dragged to make the cell larger or smaller.
 * This cell is to be used in table headers only
 */
export const ResizableTableHeader = ({
  children,
  style,
  ...props
}: TableHeaderProps) => {
  const ref = useRef<HTMLTableCellElement>(null);
  const [active, setActive] = useState<boolean>(false);
  const [width, setWidth] = useState<number>();
  const onMouseMove = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    // Calculate the column width
    const computedStyle = getComputedStyle(ref.current!);
    const width = e.clientX - (ref.current?.getBoundingClientRect().x || 0) - parseFloat(computedStyle.paddingRight) - parseFloat(computedStyle.paddingLeft);

    if (width >= 10) {
      setWidth(width);
    }
  }, []);
  const onMouseUp = useCallback(() => {
    setActive(false);
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);
  }, []);
  const onMouseDown = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setActive(true);
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  }, []);
  const onClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return (
    <TableHeader {...props} ref={ref} style={{ ...style, width }}>
      <span>{children}</span>
      <div
        style={{ height: '50px' }}
        onMouseDown={onMouseDown}
        onClick={onClick}
        className={clsx('resize-handle', { active })}
        aria-hidden="true"
      />
    </TableHeader>
  );
};
