import { useQuery } from '@apollo/react-hooks';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GetSuggestedPersonalDataCategories, GetSuggestedPersonalDataCategoriesData } from '../../../apollo/systems/processing_activites/processing_activities.queries';
import { Suggestion } from '../../../components/CheckOver/CheckOver';

export const useSuggestedPersonalDataCategories = () => {
  const { id } = useParams<any>();
  const [suggested, setSuggested] = useState<Suggestion[]>([]);

  const { data } = useQuery<GetSuggestedPersonalDataCategoriesData>(GetSuggestedPersonalDataCategories, {
    variables: { id: Number(id) },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setSuggested(data?.get_suggested_personal_data_categories.filter(s => Number(s.count) > 0) || []);
  }, [data?.get_suggested_personal_data_categories]);

  return { suggested };
};
