import styled from 'styled-components';

const BackDrop = styled.div<{ active?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

BackDrop.displayName = 'BackDrop';

export default BackDrop;
