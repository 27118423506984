import gql from 'graphql-tag';
import { TagItem } from '../../types/tagitem/TagItem';
import { TagItemFragment } from './tagitems.fragments';

//
// Save an arbitraty tagitem (probably preferred)
//
export interface UpdateTagItemResult {
  update_tagitem: TagItem
}

export interface UpdateTagItemVariables {
  tagitem: Partial<TagItem>
  id: number
}

export const UpdateTagItem = gql`
  mutation update_tagitem($tagitem: TagItem!, $id: Int!) {
    update_tagitem(input: $tagitem, id: $id) @rest(
      type: "Tagitem" path: "tagitems/{args.id}" method: "PATCH"
    ) {
      ...tagitem
    }
  }
  ${TagItemFragment}
`;

export interface CreateTagItemResult {
  create_tagitem: TagItem
}

export interface CreateTagItemVariables {
  tagitem: Partial<TagItem>
}

export const CreateTagItem = gql`
  mutation create_tagitem($tagitem: TagItem!) {
    create_tagitem(input: $tagitem) @rest(
      type: "TagItem" path: "tagitems" method: "POST"
    ) {
      ...tagitem
    }
  }
  ${TagItemFragment}
`;

export interface DeleteTagItemResult {
  delete_tagitem: {
    __typename?: string
    id: number
  }
}
export interface DeleteTagItemVariables {
  id: number
  replace_id?: number
}

export const DeleteTagItem = gql`
  mutation delete_tagitem($id: Int!, $replace_id: number) {
    delete_tagitem(id: $id, replace_id: $replace_id) @rest(
      type: "NoResult" path: "tagitems/{args.id}?replace_id={args.replace_id}" method: "DELETE"
    ) {
      id
    }
  }
`;
