import {
  useCallback, useState,
} from 'react';
import { DEFAULT_POLL_INTERVAL } from '../constants';
import { DOCUMENT_HIDDEN_KEY, useVisibilityChangedCallback } from './useVisibilityChangedCallback';

const getPollInterval = () => (document[DOCUMENT_HIDDEN_KEY] ? 0 : DEFAULT_POLL_INTERVAL);

export const useSmartPoll = () => {
  const [pollInterval, setPollInterval] = useState(getPollInterval());

  const onVisibilityChanged = useCallback(() => {
    setPollInterval(getPollInterval());
  }, []);

  useVisibilityChangedCallback(onVisibilityChanged);

  return pollInterval;
};
