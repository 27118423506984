export const LOGIN = '@auth/LOGIN';
export const LOGIN_SUCCESS = '@auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = '@auth/LOGIN_ERROR';
export const LOGIN_CANCEL = '@auth/LOGIN_CANCEL';
export const LOCAL_AUTH_INITIALIZED = '@auth/LOCAL_AUTH_INITIALIZED';

export const LOCAL_SET_EMAIL = '@auth/SET_EMAIL';

export const LOGOUT = '@auth/LOGOUT';
export const AUTH_CHANGED = '@auth/AUTH_CHANGED';

export const SET_PERMISSIONS = '@auth/SET_PERMISSIONS';
export const SET_PERMISSION_OVERRIDES = '@auth/SET_PERMISSION_OVERRIDES';

export const REQUIRE_MFA = '@auth/REQUIRE_MFA';
export const REQUIRE_MFA_CONFIGURATION = '@auth/REQUIRE_MFA_CONFIGURATION';
export const FINISH_MFA = '@auth/FINISH_MFA';

export enum Provider {
  NONE = 'NONE',
  LOCAL = 'LOCAL',
  AAD = 'AAD',
}
