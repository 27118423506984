/* eslint-disable react/jsx-one-expression-per-line */
import {
  ListRow,
  ListText,
  TrashIcon,
  EditIcon,
} from '@privacy-request/ui';
import React, { useMemo, useRef } from 'react';
import { useTheme } from 'styled-components';
import { System } from '../../../types/system/System';
import { SystemRelatedSystem } from '../../../types/system/SystemRelatedSystems';
import { ProcessingActivity } from '../../../types/system/ProcessingActivity';
import { SystemProcessingActivity } from '../../../types/system/SystemProcessingActivity';
import { getTarget } from './utils/getTarget';
import { systemImageLocation } from '../../../views/SystemsView/utils/systemImageLocation';
import { Logo } from '../../Logo/Logo';

interface RelatedDataMapItemListItemProps {
  /**
   * The data map item currently being viewed
   */
  currentItem: System | ProcessingActivity
  /**
   * The system to relate
   */
  relation: SystemRelatedSystem | SystemProcessingActivity | ProcessingActivity | System
  /**
   * When supplied with a system or processing activity, produces a callback
   * that navigates to the page indicated by the given entity.
   */
  onClick?: (target: System | ProcessingActivity) => () => void

  /**
   * If given, will cause a pencil icon to appear at the end of the row
   */
  onEdit?: any
  /**
   * If given, will cause a trash icon to appear at the end of the row
   */
  onDelete?: any

  showLogos?: boolean
}

export const RelatedDataMapItemListItem = ({
  currentItem,
  relation,
  onClick,
  onEdit,
  onDelete,
  showLogos = false,
}: RelatedDataMapItemListItemProps) => {
  const themeRef = useRef(useTheme());
  const target = getTarget(relation, currentItem);

  const _onClick = useMemo(() => {
    if (!onClick) {
      return undefined;
    }
    return onClick(target);
  }, [onClick, target]);

  if (!target) {
    return null;
  }

  const logo_url = showLogos ? systemImageLocation(relation) : undefined;

  return (
    <ListRow secondary style={{ marginTop: 0 }} onClick={_onClick} plain>
      {logo_url && (
        <ListText basis="38px" style={{ marginTop: '4px' }}>
          <Logo url={logo_url} alt={`${currentItem.name} logo`} />
        </ListText>
      )}
      <ListText bold>{target.name}</ListText>
      {
        (onDelete || onEdit) && (
          <ListText basis="52px" style={{ paddingRight: '8px', display: 'flex' }} onClick={(e: any) => { e.preventDefault(); e.stopPropagation(); }}>
            {
              onEdit && (
                <EditIcon width="26" height="26" color={themeRef.current.CTA_COLOR} onClick={() => { onEdit(relation.id); }} />
              )
            }
            {
              onDelete && (
                <TrashIcon width="26" height="26" color={themeRef.current.CTA_COLOR} onClick={() => { onDelete(relation.id); }} />
              )
            }
          </ListText>
        )
      }
    </ListRow>
  );
};
