import { func } from 'prop-types';
import React, { MouseEventHandler } from 'react';

interface TooltipProps {
  content: string
  disabled?: boolean
  style?: React.CSSProperties
}

const styles: Record<string, React.CSSProperties> = {
  wrapper: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export class Tooltip extends React.Component<TooltipProps> {
  static contextTypes = {
    onMouseEnter: func.isRequired,
    onMouseMove: func.isRequired,
    onMouseLeave: func.isRequired,
    onMouseDown: func.isRequired,
  };

  handleMouseEnter: MouseEventHandler<HTMLDivElement> = (e: React.MouseEvent<HTMLDivElement>) => {
    if (this.props.disabled) {
      return;
    }
    this.context.onMouseEnter(e, this.props.content || this.props.children, this.props.style || {});
  };

  handleMouseMove: MouseEventHandler<HTMLDivElement> = (e: React.MouseEvent<HTMLDivElement>) => {
    if (this.props.disabled) {
      return;
    }
    const { pageX, pageY } = e;
    requestAnimationFrame(() => {
      this.context.onMouseMove(pageX, pageY);
    });
  };

  handleMouseLeave = () => {
    if (this.props.disabled) {
      return;
    }
    this.context.onMouseLeave();
  };

  handleMnMouseDown = () => {
    if (this.props.disabled) {
      return;
    }
    this.context.onMouseDown();
  };

  componentWillUnmount() {
    this.context.onMouseLeave();
  }

  render() {
    return (
      <div
        style={styles.wrapper}
        {...this.props}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
        onMouseLeave={this.handleMouseLeave}
        onMouseDown={this.handleMnMouseDown}
      />
    );
  }
}
