import gql from 'graphql-tag';

export const AddressFragment = gql`
  fragment address on Address {
    address_line_1
    address_line_2
    phone_number
    city
    state
    country
    zip_code
    vendor
  }
`;
