import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import { ProcessingActivitiesList } from './ProcessingActivitiesList';
import { ActivityPage } from './ActivityPage';

export const ProcessingActivitiesView = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={ActivityPage} />
      <Route path={match.url} component={ProcessingActivitiesList} />
    </Switch>
  );
};
