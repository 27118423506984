/**
 * Converts a regular javascript object in to a FormData
 * response.
 *
 * @param data Any object
 */
export const convertToFormData = (data: any) => {
  const formData = new FormData();
  for (const key in data) {
    if (data[key] instanceof Array) {
      if (!data[key].length) {
        formData.append(`${key}[]`, 'null');
      }
      for (const obj of data[key]) {
        if (obj instanceof File) {
          formData.append(`${key}[]`, obj, obj.name);
        } else {
          formData.append(`${key}[]`, JSON.stringify(obj));
        }
      }
    } else if (typeof data[key] === 'object') {
      formData.append(key, JSON.stringify(data[key]));
    } else {
      formData.append(key, data[key]);
    }
  }
  return formData;
};
