import find from 'lodash/find';
import React from 'react';
import { compose } from 'redux';
import {
  FloatingBox,
  FloatingBoxItem,
  Input,
  SubTitle,
} from '../../atoms';
import {
  DropdownContainer,
  DropdownContent,
  DropdownIcon,
  DropdownWrapper,
} from '../../atoms/dropdown';
import { CheckBox } from '../CheckBox';
import { withSelectability, WithSelectabilityProps } from './withSelectability';

export type DropdownProps = {
  items: any[]
  _value: any
  itemRef: any
  itemFocusIndex: number
  _onFocus: any
  _onClick: any
  _onKeyDown: any
  _onKeyUp: any
  _onSelect: any
  maxHeight: number
  wrapperRef: any
  open: boolean
  displayValue: any
  disabled: boolean
  tabIndex: number
  select?: boolean
};

export const Dropdown = compose(
  withSelectability,
)(
  class Dropdown extends React.Component<WithSelectabilityProps & DropdownProps> {
    /**
     * Render the internal content that is typically displayed inside a
     * container.
     * @param item The item
     * @param i THe index of the item
     * @returns Component
     */
    renderItemContent(item: any, i: number) {
      if (item.render) {
        return item.render();
      }
      if (this.props.multiple && item.value !== 'DIVIDER' && item.selectable !== false) {
        let isSelected = false;
        const values: any[] = this.props._value || [];
        if (values.includes(item.value)) {
          isSelected = true;
        }
        return (
          <CheckBox checked={isSelected} label={item.text} onChange={(ev) => { ev.preventDefault(); ev.stopPropagation(); this.props._onSelect(item.action || item.value)(); }} />
        );
      }
      return item.text;
    }

    renderItem(item: any, i: number) {
      const icon = item.icon ? React.cloneElement(item.icon, {
        width: '16px',
        height: '16px',
        color: 'BLACK',
      }) : null;
      if (item.value === 'DIVIDER') {
        return (
          <SubTitle style={{ padding: '8px 0 8px 16px', fontSize: '16px' }}>
            {this.renderItemContent(item, i)}
          </SubTitle>
        );
      }
      return (
        <FloatingBoxItem ref={this.props.itemRef(i)} key={`${item.value}-${i}`} icon={icon} active={i === this.props.itemFocusIndex} onClick={item.selectable !== false && this.props._onSelect(item.action || item.value)}>
          {this.renderItemContent(item, i)}
        </FloatingBoxItem>
      );
    }

    shouldComponentUpdate(nextProps: Readonly<WithSelectabilityProps & DropdownProps>, nextState: Readonly<{}>, nextContext: any): boolean {
      return this.props.items !== nextProps.items
        || this.props._value !== nextProps._value
        || this.props.open !== nextProps.open;
    }

    render() {
      let displayValue = this.props.placeholder;

      if (this.props.multiple) {
        if (this.props.displayValue && (this.props._value || []).length) {
          displayValue = this.props.displayValue;
        }
      } else if (this.props._value) {
        const item = find(this.props.items, { value: this.props._value });
        if (item) {
          displayValue = item.text;
        }
      }

      const sharedProps = {
        onFocus: (e: React.FocusEvent) => this.props._onFocus(e),
        onClick: (e: React.MouseEvent) => this.props._onClick(e),
        onKeyDown: (e: React.KeyboardEvent) => this.props._onKeyDown(e),
        onKeyUp: (e: React.KeyboardEvent) => this.props._onKeyUp(e),
        disabled: this.props.disabled,
      };

      const dropdownItemsStyle = {
        ...(this.props.select !== false ? {
          right: 0,
          top: '55px',
          minWidth: '100%',
        } : {}),
        zIndex: 20,
        ...(this.props.floatingBoxStyle || {}),
      };

      if (this.props.maxHeight) {
        dropdownItemsStyle.maxHeight = this.props.maxHeight;
      }

      return (
        <DropdownWrapper
          tabIndex={this.props.tabIndex}
          {...sharedProps}
          style={this.props.style}
          ref={this.props.wrapperRef}
          select={this.props.select}
        >
          {this.props.icon ? (
            <DropdownIcon tabIndex={-1} disabled={sharedProps.disabled}>
              {this.props.icon}
            </DropdownIcon>
          ) : (
            <DropdownContainer
              tabIndex={-1}
              disabled={sharedProps.disabled}
              open={this.props.open}
            >
              {displayValue}
            </DropdownContainer>
          )}

          {this.props.open && this.props.items && (
            <DropdownContent right style={dropdownItemsStyle}>
              {this.props.items.map((item: any, i: number) => this.renderItem(item, i))}
            </DropdownContent>
          )}
        </DropdownWrapper>
      );
    }
  },
) as React.FC<WithSelectabilityProps & { select?: boolean }>;
