import gql from 'graphql-tag';
import {
  Credentials,
  RequestPasswordResetOptions,
  PasswordResetOptions,
  ForcePasswordResetOptions,
  RequestOrganizationListOptions,
} from '../../types/auth';

import { BasicResult } from '../../types/common/Common';

export interface LoginResult {
  result: BasicResult
}

export interface LoginVariables {
  credentials: Partial<Credentials>
}

export const Login = gql`
  mutation login($credentials: Credentials!) {
    result(input: $credentials)
      @rest(type: "BasicResult" path: "authentication/login" method: "POST") {
        error
        message
      }
  }
`;

export interface LogoutResult {
  result: BasicResult
}

export interface LogoutVariables {
}

export const Logout = gql`
  mutation logout($options: String!) {
    result(input: $options)
      @rest(type: "BasicResult" path: "authentication/logout" method: "POST") {
        error
        message
      }
  }
`;

export interface ForcePasswordResetResult {
  result: BasicResult
}

export interface ForcePasswordResetVariables {
  input: Partial<ForcePasswordResetOptions>
  organization_id: number
}

export const ForcePasswordReset = gql`
  mutation force_password_reset($input: ForcePasswordResetOptions!, $organization_id: Int!) {
    result(input: $input, organization_id: $organization_id) 
    @rest(type: "BasicResult" path: "authentication/{args.organization_id}/force-password-reset" method: "POST") {
      error
      message
    }
  }
`;

export interface RequestPasswordResetResult {
  result: BasicResult
}

export interface RequestPasswordResetVariables {
  input: Partial<RequestPasswordResetOptions>
}

export const RequestPasswordReset = gql`
  mutation request_password_reset($input: RequestPasswordResetOptions!) {
    result(input: $input) 
    @rest(type: "BasicResult" path: "authentication/request-password-reset" method: "POST") {
      error
      message
    }
  }
`;

export interface PasswordResetResultContainer extends BasicResult {
  errors: string[]
}

export interface PasswordResetResult {
  result: PasswordResetResultContainer
}

export interface PasswordResetVariables {
  input: Partial<PasswordResetOptions>
}

export const PasswordReset = gql`
  mutation password_reset($input: PasswordResetVariables!) {
    result(input: $input)
    @rest(type:"BasicResult" path: "authentication/password-reset" method: "POST") {
      error
      errors
      message
    }
  }
`;

export interface RequestOrganizationListResult {
  result: BasicResult
}

export interface RequestOrganizationListVariables {
  input: Partial<RequestOrganizationListOptions>
}

export const RequestOrganizationList = gql`
  mutation request_organization_list($input: RequestOrganizationListOptions!) {
    result(input: $input) 
    @rest(type: "BasicResult" path: "authentication/request-organization-list" method: "POST") {
      error
      message
    }
  }
`;
