import { getIdToken } from '../config/msal';
import { SUBDOMAIN } from '../constants';
import store from '../store';
// import { msalAuthProvider } from '../config/msalAuthProvider';
import { Provider } from '../store/auth/auth.types';

type HeaderType = object | Headers;
export const augmentHeaders = async<T extends HeaderType>(headers: T, domain?: string) => {
  // Ensure token freshness
  if (store.getState().auth.provider === Provider.AAD && store.getState().auth.authorizing) {
    await getIdToken();
  }
  // Get token
  const token = store.getState().auth.idToken;

  // The support for Headers object allows us to support the fileEncode bit
  // in Apollo
  if (headers instanceof Headers) {
    (headers as Headers).set('x-pr-domain', domain || SUBDOMAIN);
    if (token) {
      (headers as Headers).set('authorization', `Bearer ${token}`);
    }
    return headers;
  }

  return {
    ...headers,
    'authorization': token ? `Bearer ${token}` : '',
    'x-pr-domain': domain || SUBDOMAIN,
  };
};
