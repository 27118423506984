import React from 'react';
import {
  HTMLAnchorAttributes, Button,
} from '../Button/Button';

/**
 * @deprecated Use Button directly instead, specifying `as="a"`
 */
export const ButtonLink: React.FC<Omit<HTMLAnchorAttributes, 'as'>> = ({
  plus,
  padded,
  ...props
}) => (
  <Button {...props} as="a" />
);
