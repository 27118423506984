/**
 * Strip all the given characters from the beginning of the string.
 * @param unstripped The string to remove characters from
 * @param chars A string containing the characters to be removed.
 * @returns The modified string, with characters removed.
 */
export const stripStart = function (unstripped: string, chars: string = '/') {
  if (!unstripped || typeof unstripped !== 'string'
      || !chars || typeof chars !== 'string' || chars.length > 1) {
    return unstripped;
  }

  let start = 0;
  for (let i = start; i < unstripped.length; i++) {
    start = i;
    if (unstripped[i] !== chars) {
      break;
    }
  }
  return unstripped.slice(start, unstripped.length);
};

/**
 * Strip all the given characters from the end of the string.
 * @param unstripped The string to remove characters from
 * @param chars A string containing the characters to be removed.
 * @returns The modified string, with characters removed.
 */
export const stripEnd = function (unstripped: string, chars: string = '/') {
  if (!unstripped || typeof unstripped !== 'string'
      || !chars || typeof chars !== 'string' || chars.length > 1) {
    return unstripped;
  }

  let end = unstripped.length;
  for (let i = end; i >= 0; i--) {
    end = i;
    if (unstripped[i - 1] !== chars) {
      break;
    }
  }
  return unstripped.slice(0, end);
};

/**
 * Strip all the given characters from the beginning and end of the string.
 * @param unstripped The string to remove characters from
 * @param chars A string containing the characters to be removed.
 * @returns The modified string, with characters removed.
 */
export const stripAll = function (unstripped: string, chars: string) {
  return stripEnd(stripStart(unstripped, chars), chars);
};
