import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import QueryString from 'qs';
import { stripStart } from '../strip';

/**
 * Use the current location's query string for stateful storage. The goal of
 * this hook is to replace the use of `useState` or similar where that data can
 * be usefully stored in the query for bookmarking and permalinking. Examples
 * are paging data (e.g. limit, offset, page size, etc), tab progression if not
 * stored as a path parameter, etc.
 *
 * NOTE ON WHY WE DO NOT USE REACT ROUTER FOR MANIPULATION:
 * If you intend to use multiple instances of `useQueryString` or derivatives
 * within the same component/page and you specify a default/initial value for
 * two or more of those instances, you will find that the URL is not properly
 * updated. The reason for this is that React Router-based updates to the URL
 * rely on the customary render cycle. This means that each instance will have
 * the same original representation of the URL, and will update that, and the
 * last instance will win. Take `useQueryPager` for example, where you will
 * find:
 * ```
 * const [limit, setLimit] = useQuerystringKey(k('limit'), 50);
 * const [page, setPage] = useQuerystringKey(k('page'), 1);
 * ```
 * In this case, the page is initialized to `?page=1` rather than
 * `?page=1&limit=50`. Using `window.location` functionality mitigates this
 * issue.
 */
export const useQueryString = () => {
  // NOTE: Previously we did not use state for holding values, and instead
  // pulled the data directly from the querystring. This poses certain problems
  // with properly re-rendering components when that state changes. As such, I
  // have opted to retain the data in state and use that state as the canonical
  // representation of the data, despite my wish to avoid duplication.
  // - Thomas
  const [state, setState] = useState<{ [k: string]: string[] }>(QueryString.parse(stripStart(window.location.search, '?')) as any || {});

  useEffect(() => {
    // Update the querystring based on state change
    const values = QueryString.parse(stripStart(window.location.search, '?'));
    const newValue = QueryString.stringify({ ...values, ...state });
    const newurl = `${window.location.pathname}?${newValue}`;
    window.history.replaceState({}, '', newurl);
  }, [state]);

  //
  // API functions
  //

  const get = useCallback((key: string) => (
    state[key]
  ), [state]);

  const set = useCallback((key: string, value: any) => {
    if (typeof value === 'function') {
      value = value(get(key));
    }
    setState((state) => ({
      ...state,
      [key]: value,
    }));
  }, [get]);

  const add = useCallback((key: string, value: any) => {
    let newValue = get(key) || [];
    if (!Array.isArray(newValue)) {
      newValue = [newValue];
    }
    newValue.push(value);
    set(key, newValue);
  }, [get, set]);

  const remove = useCallback((key: string, value: any) => {
    setState(({ [key]: discard, ...state }) => (state));
  }, []);

  return {
    get,
    set,
    add,
    remove,
    buster: 'deprecated',
    /**
     * Passing in this function in to the dependency array will cause
     * the cache to bust (same as passing `buster`), but the problem
     * with passing in buster is that react thinks that it is an unecessary
     * dependency.
     *
     * Calling this method inside the hook will make react think you're using it
     * or that it is serving some purpose, which is way better than ignoring
     * dependency linting rules, as those lint rules can often bring to the surface major bugs.
     */
    flush: () => ('deprecated'),
  };
};
