// import update from 'immutability-helper';
import { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useIsAdminRoute } from '../../../hooks/useIsAdminRoute';
import { useTagItems } from '../../../hooks/useTagItems';
import { UpdateBulkProcessingActivity, BulkActionProcessingActivityVariables } from '../../../apollo/systems/processing_activites/processing_activities.mutations';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { useSelectionContext } from '../../../hooks/useSelectionContext';

export const useProcessingActivityBulkActionsEdit = () => {
  const [t] = useTranslation('systems');
  const isAdminRoute = useIsAdminRoute();
  const { grouped } = useTagItems({ custom: !isAdminRoute });
  const { addToast } = useToasts();
  const { selections } = useSelectionContext();
  const activities = Object.keys(selections.activities ?? {});
  const purposes_of_processing = Object.keys(selections.purposes_of_processing ?? {});
  const cross_border_transfers = Object.keys(selections.cross_border_transfers ?? {});

  const [updateBulkProcessingActivity] = useMutation<BulkActionProcessingActivityVariables>(UpdateBulkProcessingActivity);

  const onFinished = useCallback(() => {
    addToast(t('bulk_action_pa_complete', { count: activities.length || 0 }), {
      autoDismiss: true,
      appearance: 'success',
    });
  }, [addToast, t, activities.length]);

  const items = useMemo(() => {
    const type = 'purpose_of_processing';
    if (grouped && grouped[type]) {
      return grouped[type].map((i: any) => ({ text: i.name, value: i.id }));
    }
    return [];
  }, [grouped]);

  const onAddPurposeOfProcessing = useCallback(async (n: string) => {
    if (activities.length && purposes_of_processing?.length) {
      await updateBulkProcessingActivity({
        variables: { data: { ids: activities, purposes_of_processing } },
      });
      onFinished();
    }
  }, [activities, purposes_of_processing, updateBulkProcessingActivity, onFinished]);

  const onAddTransferMechanism = useCallback(async (n: string) => {
    if (activities?.length && cross_border_transfers?.length) {
      await updateBulkProcessingActivity({
        variables: { data: { ids: activities, cross_border_transfers } },
      });
      onFinished();
    }
  }, [activities, cross_border_transfers, updateBulkProcessingActivity, onFinished]);

  const onAddPaBulkContact = useCallback(async (contact: any) => {
    await updateBulkProcessingActivity({
      variables: { data: { ids: activities, contacts: [contact] } },
    });
    onFinished();
  }, [updateBulkProcessingActivity, activities, onFinished]);

  const onAddBulkPaAssets = useCallback(async (systems) => {
    const data = { ids: activities, systems: systems.map((system: any) => system.id) };
    if (data?.ids?.length && data?.systems?.length) {
      await updateBulkProcessingActivity({
        variables: { data },
      });
      onFinished();
    }
  }, [activities, updateBulkProcessingActivity, onFinished]);

  const onApplyReviewRequest = useCallback(async () => {
    await updateBulkProcessingActivity({
      variables: { data: { ids: activities, requestReview: true } },
    });
    onFinished();
  }, [updateBulkProcessingActivity, activities, onFinished]);

  const onAddSubsidiaries = useCallback(async (sub_organizations: number[]) => {
    await updateBulkProcessingActivity({
      variables: { data: { ids: activities, sub_organizations } },
    });
    onFinished();
  }, [updateBulkProcessingActivity, activities, onFinished]);

  const onSetPublishedStatus = useCallback(async (published: boolean) => {
    await updateBulkProcessingActivity({
      variables: { data: { ids: activities, published } },
    });
    onFinished();
  }, [updateBulkProcessingActivity, activities, onFinished]);

  return {
    onAddBulkPaAssets,
    onAddPaBulkContact,
    onAddPurposeOfProcessing,
    onAddTransferMechanism,
    onApplyReviewRequest,
    onAddSubsidiaries,
    onSetPublishedStatus,
    items,
  };
};
