import React from 'react';
import styled from 'styled-components';

const Path = styled.path`
  transition: all 0.1s ease-in-out;
`;

export const CheckBoxIndeterminateIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <Path fillRule="evenodd" d="m 12.006147,0 c 0.41,0 0.762,0.147 1.055,0.44 0.293,0.293 0.439,0.65 0.439,1.072 v 10.494 c 0,0.41 -0.146,0.762 -0.44,1.055 -0.292,0.293 -0.644,0.44 -1.054,0.44 H 1.4941465 c -0.41,0 -0.76199999,-0.147 -1.05499999,-0.44 C 0.15337267,12.785066 -0.00552391,12.403208 1.4650667e-4,12.006 V 1.512 C 1.4650667e-4,1.09 0.14614651,0.732 0.44014651,0.44 0.73214651,0.147 1.0841465,0 1.4941465,0 Z M 1.5,6.0010004 H 12 v 1.5 H 1.5 Z" />
  </svg>
);
