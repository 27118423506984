import React from 'react';
import { BulkActionModal, BulkActionModalProps } from '../BulkActionModal';
import { ConfirmationDialog } from '../../../ConfirmationDialog/ConfirmationDialog';
import { useProcessingActivityBulkActionsEdit } from '../../../../views/ProcessingActivitiesView/hooks/useProcessingActivityBulkActionsEdit';
import { useSystemBulkActionsEdit } from '../../../../views/SystemsView/hooks/useSystemBulkActionsEdit';

export const RequestReviewsModal: BulkActionModal = ({
  onChange = () => {},
  onCancel = () => {},
  controller,
}: BulkActionModalProps) => {
  const { onApplyReviewRequest: requestActivityReviews } = useProcessingActivityBulkActionsEdit();
  const { onApplyReviewRequest: requestSystemReviews } = useSystemBulkActionsEdit();

  return (
    <ConfirmationDialog
      message="systems:request_review_confirmation"
      onCancel={onCancel}
      onConfirm={async () => {
        if (controller.entityType === 'activities') {
          await requestActivityReviews();
        } else if (controller.entityType === 'systems') {
          await requestSystemReviews();
        }
        onCancel();
      }}
    />
  );
};
