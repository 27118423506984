import React from 'react';
import { SVGComponent } from './SvgProps';

export const VerticalEllipsesIcon: SVGComponent = (props) => (
  <svg {...props} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs />
    <g id="admin-desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-23">
        <rect id="Rectangle-10" fill="#FFFFFF" opacity="0" x="0" y="0" width="24" height="24" />
        <g id="Group" transform="translate(10.000000, 1.000000)" fill={props.color}>
          <circle id="Oval" cx="2" cy="2" r="2" />
          <circle id="Oval-Copy" cx="2" cy="11" r="2" />
          <circle id="Oval-Copy-2" cx="2" cy="20" r="2" />
        </g>
      </g>
    </g>
  </svg>
);

VerticalEllipsesIcon.defaultProps = {
  width: 24,
  height: 24,
  color: '#1F6187',
};
