import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GetSystemsPaged } from '../../../../apollo/systems/systems.queries';
import { CustomFieldConfig, CustomFields } from '../../../../components/CustomFields/CustomFields';
import { ExpandAndCollapse } from '../../../../components/ExpandAndCollapse/ExpandAndCollapse';
import { NextPrev } from '../../../../components/NextPrev';
import { System } from '../../../../types/system/System';
import { useContextualActivity } from '../../hooks/useActivityContext';
import { StepComponent, StepComponentProps } from '../../../../components/DataMapWorkflowComponents/types/WorkflowStep';

export const RelatedAssetsStep: StepComponent = ({
  steps,
  forward,
  backward,
}: StepComponentProps) => {
  const [t] = useTranslation('systems');
  const {
    current: processingActivity,
    saving,
    onChange,
  } = useContextualActivity();

  const config = useMemo((): CustomFieldConfig => [{
    type: 'graphql-search-and-select',
    name: 'systems',
    multi: true,
    query: GetSystemsPaged,
    formatItem: (s: System) => ({
      text: s.name,
      value: s.id!,
    }),
    variables: {
      fields: ['id', 'name'],
      filter: {
        onboarded: true,
      },
    },
  }], []);

  const nextText = t('form.save_and_review');

  return (
    <ExpandAndCollapse
      title={t('pa.workflow.assets_desc', { name: processingActivity.name })}
      storageKey="pa_asset_desc"
      expandable={false}
    >
      <CustomFields config={config} value={processingActivity} onChange={onChange} fluid />
      <NextPrev workflow={steps} disabled={saving} onNext={forward} onPrev={backward} nextText={nextText} />
    </ExpandAndCollapse>
  );
};
