import React, { useCallback, useState } from 'react';
import Button, { ButtonAttributes } from '../Button';

export type DangerButtonProps = {
  /**
   * The callback to invoke when the button is pressed.
   */
  onClick: Function
  /**
   * The style of the button when it has not been clicked.
   */
  defaultStyle?: 'primary' | 'secondary' | 'alert' | 'warn' | 'error'
  /**
   * The style of the button when it has been clicked the first time.
   */
  warnStyle?: 'primary' | 'secondary' | 'alert' | 'warn' | 'error'
} & ButtonAttributes;

/**
 * A button which requires clicking twice in order to invoke the callback.
 * Typically reserved for situations where the action may result in
 * irreversible loss of data.
 * @param param0 The button props
 * @returns The button
 */
export const DangerButton = ({
  onClick,
  defaultStyle = 'warn',
  warnStyle = 'alert',
  ...rest
}: DangerButtonProps) => {
  const [clicked, setClicked] = useState<boolean>(false);
  let timeout: any;

  const _onClick = useCallback((ev) => {
    if (clicked) {
      clearTimeout(timeout);
      return onClick(ev);
    }

    setClicked(true);
    timeout = setTimeout(() => {
      setClicked(false);
    }, 3000);
  }, [clicked, setClicked]);

  const styles = {
    [defaultStyle]: !clicked,
    [warnStyle]: clicked,
  };

  return (
    <Button {...styles} onClick={_onClick} {...rest} />
  );
};
