import clsx from 'clsx';
import React from 'react';
import styled from 'styled-components';
import { ChevUp, ChevDown } from '../icons';
import { GenericNoWrapStyle } from '../styles';
import { Text } from '../Text';
import './dropdown.scss';

const InputText = styled(Text)`
  ${GenericNoWrapStyle}
`;

InputText.displayName = 'InputText';

export type DropdownContainerProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  /**
   * Wether the dropdown should be showing a down-arrow or an up-arrow to expand the dropdown.
   */
  open?: boolean
  /**
   * Wether the dropdown should appear disabled and be un-usable.
   */
  disabled?: boolean
};

/**
 * Produce a typical "container" for a dropdown select / multiselect component.
 * The container contains the input and chevron.
 */
export const DropdownContainer: React.FC<DropdownContainerProps> = ({
  open, children, disabled, ...props
}) => (
  <div className={clsx('dropdown-container')} tabIndex={disabled ? -1 : 0} {...props}>
    <InputText>{ children }</InputText>
    <div className="chev">
      {open ? <ChevUp /> : <ChevDown />}
    </div>
  </div>
);
