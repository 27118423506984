import { EventType } from '@azure/msal-browser';
import { debug } from '../../utils/logging';
import omit from '../../utils/omit';
import { AuthActionTypes } from './auth.actions';
import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_CANCEL,
  LOGOUT,
  SET_PERMISSIONS,
  Provider,
  SET_PERMISSION_OVERRIDES,
  LOCAL_AUTH_INITIALIZED,
  REQUIRE_MFA,
  REQUIRE_MFA_CONFIGURATION,
  FINISH_MFA,
} from './auth.types';
import { permissionCodes } from '@privacy-request/basic-types';

type ProviderStrings = keyof typeof Provider;

interface AuthState {
  authorized: boolean
  authorizing: boolean
  ad_initializing: boolean
  accessToken: string
  idToken: string
  acquiring_token: boolean
  initializing: boolean
  error: string
  email: string
  provider: ProviderStrings
  permissions?: permissionCodes[]
  permissionOverrides?: permissionCodes[]
  require_mfa?: boolean
  require_mfa_configuration?: boolean
  mfa_secret?: any
}

const initialState: AuthState = {
  authorized: false,
  authorizing: false,
  ad_initializing: true,
  accessToken: '',
  idToken: '',
  acquiring_token: false,
  initializing: true,
  error: '',
  email: '',
  provider: Provider.NONE,
  permissions: undefined,
};

const ad_empty = {
  authorizing: false,
  authorized: false,
  ad_initializing: false,
  email: '',
  accessToken: '',
  idToken: '',
  acquiring_token: false,
  provider: Provider.NONE,
  error: '',
};

export default function (state: AuthState = initialState, action: AuthActionTypes): AuthState {
  const error = '';
  switch (action.type) {
    case LOGIN:
      return {
        ...omit(state, 'error'),
        authorizing: true,
      };

    case LOGIN_SUCCESS:
      return {
        ...omit(state, 'require_mfa', 'require_mfa_configuration', 'mfa_secret'),
        authorizing: false,
        authorized: true,
        initializing: false,
        email: action.payload.email,
        idToken: action.payload.idToken,
        error: '',
        provider: Provider.LOCAL,
      };

    case LOGIN_ERROR:
      return {
        ...(
          action.payload === 'mfa_invalid'
            ? state
            : omit(state, 'require_mfa', 'require_mfa_configuration', 'mfa_secret')
        ),
        authorizing: false,
        authorized: false,
        initializing: false,
        email: '',
        error: action.payload,
        accessToken: '',
        idToken: '',
      };

    case LOGIN_CANCEL:
      return {
        ...omit(state, 'require_mfa', 'require_mfa_configuration', 'mfa_secret'),
        error: '',
      };

    case LOGOUT:
      return {
        ...omit(state, 'require_mfa', 'require_mfa_configuration', 'mfa_secret'),
        authorized: false,
        email: '',
        accessToken: '',
        idToken: '',
        provider: Provider.NONE,
      };

    case LOCAL_AUTH_INITIALIZED:
      return {
        ...omit(state, 'require_mfa', 'require_mfa_configuration', 'mfa_secret'),
        initializing: false,
      };

    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };

    case SET_PERMISSION_OVERRIDES:
      return {
        ...state,
        permissionOverrides: action.payload,
      };

    case REQUIRE_MFA:
      return {
        ...omit(state, 'require_mfa', 'require_mfa_configuration', 'mfa_secret'),
        require_mfa: true,
        authorizing: false,
      };

    case REQUIRE_MFA_CONFIGURATION:
      return {
        ...omit(state, 'require_mfa', 'require_mfa_configuration', 'mfa_secret'),
        require_mfa_configuration: true,
        authorizing: false,
        mfa_secret: action.secret,
      };

    case FINISH_MFA:
      return omit(state, 'require_mfa', 'require_mfa_configuration', 'mfa_secret');

    //
    // Azure AD authentication
    //
    case EventType.LOGIN_START:
      return {
        ...state,
        ...ad_empty,
        authorizing: true,
      };

    case EventType.LOGIN_SUCCESS:
      return {
        ...state,
        ...ad_empty,
        authorized: true,
        provider: Provider.AAD,
        // @ts-ignore
        accessToken: action.payload?.accessToken,
        // @ts-ignore
        idToken: action.payload?.idToken,
        // @ts-ignore
        email: action.payload?.idTokenClaims?.preferred_username,
      };

    case EventType.LOGIN_FAILURE:
      // if (action.payload.errorMessage && action.payload.errorMessage.indexOf('AADSTS50105') !== -1) {
      //   error = 'AADSTS50105';
      // }
      return {
        ...state,
        ...ad_empty,
        error,
      };

    case EventType.LOGOUT_SUCCESS:
      return {
        ...state,
        ...ad_empty,
      };

    case EventType.ACQUIRE_TOKEN_START:
      return {
        ...state,
        authorizing: true,
        acquiring_token: true,
      };

    case EventType.ACQUIRE_TOKEN_SUCCESS:
      return {
        ...state,
        ...ad_empty,
        provider: Provider.AAD,
        authorized: true,
        // @ts-ignore
        accessToken: action.payload?.accessToken,
        // @ts-ignore
        idToken: action.payload?.idToken,
        // @ts-ignore
        email: action.payload?.idTokenClaims?.preferred_username,
      };

    case EventType.ACQUIRE_TOKEN_FAILURE:
      // if (action.payload.errorMessage && action.payload.errorMessage.indexOf('AADSTS50105') !== -1) {
      //   error = 'AADSTS50105';
      // }
      return {
        ...state,
        ...ad_empty,
        authorized: false,
        authorizing: false,
        error,
      };

    case EventType.HANDLE_REDIRECT_START:
      return {
        ...state,
        authorizing: true,
        ad_initializing: true,
      };

    case EventType.HANDLE_REDIRECT_END:
      return {
        ...state,
        authorizing: state.acquiring_token,
        ad_initializing: false,
      };

    case EventType.LOGOUT_START:
    case EventType.LOGOUT_END:
    case EventType.LOGOUT_FAILURE:
    case EventType.SSO_SILENT_START:
    case EventType.SSO_SILENT_SUCCESS:
    case EventType.SSO_SILENT_FAILURE:
      debug('Action: ', action);
      return state;

    default:
      return state;
  }
}
