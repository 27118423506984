import { useState } from 'react';
import { useQueryString } from './useQueryString';

type UseQuerystringKeyResult<T> = [
  T,
  (value: any) => void,
  () => any,
];

/**
 * Use a specific key of the query string as a store of state that can be
 * bookmarked by the client. Useful for reporting, view configuration, etc.
 * @param key The key to constrain updates to
 * @returns [get, set, flush]
 */
export const useQuerystringKey = <T = string | string[]>(key: string, initialValue?: T): UseQuerystringKeyResult<T> => {
  const {
    get,
    set,
    flush,
  } = useQueryString();
  const [initial] = useState(initialValue);

  const getKey = () => (get(key) || initial);
  const setKey = (value: T) => (set(key, value));
  const value = getKey() as any;

  return [
    value,
    setKey,
    flush,
  ];
};
