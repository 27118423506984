import gql from 'graphql-tag';
import { System } from '../../types/system/System';
import { AuditLogFragment } from '../audit_log/audit_log.fragments';
import { SystemFragment } from './systems.fragments';

export interface GetSystemsPagedVariables {
}

export const GetSystemsPaged = gql`
  query systems_paged($limit: Int!, $offset: Int!, $filter: String!, $fields: String!, $relations: String!, $sort: String!) {
    systems_paged(limit: $limit, offset: $offset, filter: $filter, fields: $fields, relations: $relations, sort: $sort)
      @rest(type: "SystemsPaged", path: "systems?{args}") {
        rows @type(name: "SystemPageItem") {
          ...system_page
        }
        count
      }
  }
  ${SystemFragment}
`;

export interface GetSystemData {
  system: System
}

export interface GetSystemVariables {
  id: number
}

export const GetSystem = gql`
  query system($id: Int!) {
    system(id: $id) @rest(type: "System", path: "systems/{args.id}") {
      ...system
    }
  }
  ${SystemFragment}
`;

export interface GetSystemHistoryPagedVariables {
  id: number
}

export const GetSystemHistoryPaged = gql`
  query get_system_history_paged($limit: Int!, $offset: Int!, $id: Int!) {
    get_system_history_paged(id: $id, limit: $limit, offset: $offset)
      @rest(type: "SystemHistory", path: "systems/{args.id}/history?{args}") {
        rows @type(name: "AuditLog") {
          ...audit_log
        }
        count
      }
  }
  ${AuditLogFragment}
`;

export interface GetSystemConnectorSampleVariables {
  id: number
  email: string
}
export interface GetSystemConnectorSampleResult {
  get_system_connector_sample: {
    data: any
  }
}
export const GetSystemConnectorSample = gql`
  query get_system_connector_sample($id: Int!, $email: String!) {
    get_system_connector_sample(id: $id, email: $email) @rest(
      type: "SystemConnectorSample", path: "systems/{args.id}/integration-sample?{args}"
    ) {
      data
    }
  }
`;
