import React from 'react';
import { Button } from '@privacy-request/ui';
import { CustomFieldDefinition } from '../CustomFieldDefinition';
import { CustomFieldOptions } from '../CustomFieldOptions';
import { getRest } from '../utils/getRest';

export interface CustomButtonField extends CustomFieldOptions {
  type: 'button'
  name: string
  label: string
  namedFunction: string
  target: ''
}

export const button: CustomFieldDefinition<CustomButtonField> = {
  type: 'button',
  component: ({
    label,
    namedFunction,
    ...config
  }, opts) => {
    if (!opts.ctx?.namedFunctions?.[namedFunction]) {
      return null;
    }
    return (
      <Button onClick={(ev: any) => (opts.ctx?.namedFunctions?.[namedFunction](ev, opts.ctx?.fullConfig, config, opts.ctx))} {...getRest(config)}>{label}</Button>
    );
  },
  wrapper: (config, { content }) => (
    <div>{content}</div>
  ),
};
