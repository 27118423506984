import { bindMethods } from '@privacy-request/utils';
import { Publisher } from './Publisher';

export class GlobalDirtySaveRevertRegistration {
  public dirty: boolean = false;
  public saving: boolean = false;

  constructor(
    private manager: Publisher,
    public id: string,
    public onSave: Function,
    public onRevert: Function,
    public allowRoutes?: RegExp[],
  ) {
    bindMethods(this);
  }

  setDirty(dirty: boolean) {
    this.dirty = dirty;
    this.manager.notify();
  }

  setSaving(saving: boolean) {
    this.saving = saving;
    this.manager.notify();
  }
}
