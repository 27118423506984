import clsx from 'clsx';
import React from 'react';

export type TableRowProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement> & {
  disabled?: boolean
  clickable?: boolean
};

export const TableRow = ({
  disabled,
  clickable,
  ...props
}: TableRowProps) => (
  <tr
    className={clsx({
      disabled,
      clickable: clickable || props.onClick,
    })}
    {...props}
  />
);
