import clsx from 'clsx';
import React from 'react';
import { CardProps } from '../Card';
import './list.scss';

export type ListRowProps = CardProps & {
  secondary?: boolean
  active?: boolean
  plain?: boolean
};

export const ListRow = ({
  className,
  secondary,
  active,
  plain,
  ...props
}: ListRowProps) => (
  <div
    className={clsx('list-row', className, {
      secondary,
      active,
      plain,
      clickable: props.onClick,
    })}
    {...props}
  />
);
