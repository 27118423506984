import { useMutation } from '@apollo/react-hooks';
import update from 'immutability-helper';
import { useCallback, useState } from 'react';
import {
  RequestPasswordReset, RequestPasswordResetResult, RequestPasswordResetVariables,
} from '../../../apollo/auth/local.mutations';
import { RequestPasswordResetOptions } from '../../../types/auth/Authentication';
import { error } from '../../../utils/logging';

export const useRequestPasswordReset = () => {
  const [result, setResult] = useState<any>();
  const [options, setOptions] = useState<Partial<RequestPasswordResetOptions>>({});
  const [requestPasswordReset, { loading }] = useMutation<RequestPasswordResetResult, RequestPasswordResetVariables>(RequestPasswordReset);

  const onSubmit = useCallback(async (e?: any) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    try {
      const { data } = await requestPasswordReset({ variables: { input: options } });
      setResult({
        error: data?.result?.error,
        message: data?.result?.message,
      });
    } catch (e) {
      setResult({
        error: true,
        message: 'failed_to_send',
      });
      error(e);
    }
  }, [options, requestPasswordReset]);

  const onChange = useCallback((e: any) => {
    const { name, value } = e.target;
    setOptions(o => update(o, { [name]: { $set: value } }));
  }, []);

  return {
    onSubmit,
    onChange,
    options,
    result,
    loading,
  };
};
