import filter from 'lodash/filter';
import find from 'lodash/find';
import without from 'lodash/without';
import {
  useState, useCallback, useEffect, createRef,
} from 'react';
import { SearchAndSelectItem } from '../SearchAndSelectItem';

export type UseSelectedItemsOptions = {
  value: string[]
  items: SearchAndSelectItem[]
  dropdownItems: SearchAndSelectItem[]
  onCreate: (str: string) => void
  setInputValue: React.Dispatch<React.SetStateAction<string>>
  inputRef: any
  inputValue: string
  minimumCharacters: number
  add?: boolean
  addText?: (str: string) => string
  noItemsText: string
  multi?: boolean
  onChange: (ev: any) => void
  onClose: (force?: boolean) => void
  name: string
};

export default ({
  value,
  items,
  dropdownItems,
  onCreate,
  setInputValue,
  inputRef,
  inputValue,
  minimumCharacters,
  add,
  addText,
  noItemsText,
  multi,
  onChange,
  onClose,
  name,
}: UseSelectedItemsOptions) => {
  const [selectedItems, setSelectedItems] = useState<Array<SearchAndSelectItem>>([]);
  const [searchingItems, setSearchingItems] = useState<Array<SearchAndSelectItem & { ref?: any }>>([]);

  const onSelect = useCallback(
    index => {
      setSearchingItems((searching: any) => {
        const item = searching[index];
        if (item.value === 'add') {
          setInputValue(_inputValue => {
            onCreate(_inputValue);
            return '';
          });
          onClose(true);
          return searching;
        }
        setSelectedItems(items => {
          if (item.value === 'N/A') {
            onClose();
            return items;
          }
          const next: any[] = [];
          if (multi) {
            items.push({
              text: item.text,
              value: item.value,
              onClick: item.onClick,
            });
            items.forEach(i => next.push(i));
          } else {
            next.push({
              text: item.text,
              value: item.value,
              onClick: item.onClick,
            });
          }
          setTimeout(() => {
            onChange({
              target: {
                name,
                value: next.map(n => n.value),
              },
            });
            onClose();
          }, 0);
          return next;
        });
        // setTimeout(() => {
        //   inputRef.current && inputRef.current.focus();
        // }, 0);
        return searching;
      });
    },
    [setInputValue, inputRef, multi, name, onChange, onCreate],
  );

  const onDelete = useCallback(
    index => {
      setSelectedItems(items => {
        if (typeof index === 'undefined') {
          index = items.length - 1;
        }
        const item = items[index];
        const next = without(items, item);
        setTimeout(() => {
          onChange({
            target: {
              name,
              value: next.map(n => n.value),
            },
          });
        }, 0);
        return next;
      });
    },
    [onChange, name],
  );

  /**
   * Handle the rendering of selected items which infer their state from the passed in items and internal value state.
   */
  useEffect(() => {
    let _value = value;
    if (!_value) {
      return;
    }
    const next: any[] = [];
    if (typeof _value === 'string') {
      _value = [_value];
    }
    _value.forEach((v, i) => {
      const item = find(items, { value: v });
      if (!item) {
        return;
      }
      next.push({
        text: item.text,
        value: item.value,
      });
    });
    setSelectedItems(next);
  }, [items, value]);

  /**
   * Handle the rendering of searchingItems, which infer their values from internal inputValue and items.
   */
  useEffect(() => {
    if (inputValue.length < minimumCharacters) {
      return;
    }

    const visible: any = [];
    const itms = dropdownItems || items;

    if (
      add
      && !find(itms, i => i.text.toLowerCase() === inputValue.toLowerCase())
      && !find(itms, i => i.search?.toLowerCase() === inputValue.toLowerCase())
      && inputValue.length >= 1
    ) {
      visible.push({
        text: addText?.(inputValue), value: 'add', ref: createRef(),
      });
    }

    itms.forEach(item => {
      if (
        `${item.text}${item.value}${item.search || ''}`
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) !== -1
      ) {
        if (inputValue && item.value === 'DIVIDER') {
          return;
        }
        visible.push({ ...item, ref: createRef() });
      }
    });

    const selectedValues = selectedItems.map((s: any) => s.value);
    const filtered: any = filter(visible, v => selectedValues.indexOf(v.value) === -1);
    if (filtered.length === 0) {
      filtered.push({ text: noItemsText, value: 'N/A' });
    }
    setSearchingItems(filtered);
  }, [
    inputValue,
    items,
    dropdownItems,
    minimumCharacters,
    add,
    addText,
    noItemsText,
    selectedItems,
  ]);

  return {
    selectedItems,
    searchingItems,
    onSelect,
    onDelete,
  };
};
