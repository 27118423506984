import gql from 'graphql-tag';

export const TaskFragment = gql`
  fragment task on Task {
    id
    name
    system_id
    assignee_id
    request_id
    notes
    files
    completed_at
    has_data
    is_active
    errors
    data_deleted_at
    hasPipelines
    system_integration_name
    integration_version
    system @type(name: "System") {
      id
      name
      image_url
      image_url_location
      contacts @type(name: "SystemContact") {
        id
        first_name
        last_name
        email
        user @type(name: "User") {
          id
          first_name
          last_name
          email
        }
      }
      system_template @type(name: "System") {
        id
        image_url
        image_url_location
      }
    }
  }

  fragment vendorTask on Task {
    id
    notes
    files
    completed_at
    data_deleted_at
    system_integration_name
    request @type(name: "Request") {
      id
      email
      status
      request_type
      due_date
      created_at
    }
    system @type(name: "System") {
      id
      name
      image_url
      image_url_location
      personal_data_categories @type(name: "TagItem") {
        id
        name
      }
      system_template @type(name: "System") {
        id
        image_url
        image_url_location
      }
    }
  }
`;
