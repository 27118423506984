import {
  Title, List, ListHead, ListTitle, ListRow, ListText, Button,
} from '@privacy-request/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GetProcessingActivitiesPaged } from '../../apollo/systems/processing_activites/processing_activities.queries';
import { useQueryPager } from '../../hooks/useQueryPager/useQueryPager';
import { useRouting } from '../../hooks/useRouting';
import { ProcessingActivity } from '../../types/system/ProcessingActivity';

export const DashboardProcessingActivitiesPendingApproval = () => {
  const { push } = useRouting();
  const [t] = useTranslation('dashboard');

  const {
    results: systems,
    Pager,
  } = useQueryPager<ProcessingActivity, any>(GetProcessingActivitiesPaged, {
    limit: 5,
    where: { approval_requested: true },
    parameterPrefix: 'pa_pending_',
  });

  return (
    <>
      <Title style={{ marginTop: 0 }}>{t('processing_activities_in_review')}</Title>
      <List>
        <ListHead>
          <ListTitle>{t('processing_activities')}</ListTitle>
        </ListHead>
        {systems.length ? (
          systems.map(system => (
            <ListRow key={system.id} secondary>
              <ListText>{system.name}</ListText>
              <ListText style={{ paddingRight: 0, overflow: 'visible' }}>
                <Button style={{ float: 'right' }} short onClick={push(`/systems/processing-activities/${system.id}`)}>{t('review')}</Button>
              </ListText>
            </ListRow>
          ))
        ) : (
          <ListRow secondary>
            <ListText style={{ textAlign: 'center' }}>
              {t('processing_activities_pending_approval_none')}
            </ListText>
          </ListRow>
        )}
        <Pager />
      </List>
    </>
  );
};
