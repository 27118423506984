import styled from 'styled-components';

export interface FieldProps {
  fluid?: boolean
  flex?: boolean
  rightLabel?: boolean
  right?: boolean
  first?: boolean
  last?: boolean
}

const Field = styled.div<FieldProps>`
  padding-bottom: ${({ fluid, flex }) => (fluid && flex ? '0' : '20px')};
  padding-left: ${({
    fluid, rightLabel, first,
  }) => (fluid || rightLabel ? '0' : first ? 0 : '28px')};
  padding-right: ${({
    fluid, rightLabel, last,
  }) => (fluid || rightLabel ? '0' : last ? 0 : '28px')};
  display: ${({ flex }) => (flex ? 'flex' : 'block')};
  justify-content: ${({ right }) => (right ? 'flex-end' : 'flex-start')};
  box-sizing: border-box;
  width: 100%;
  position: relative;
`;

export default Field;
