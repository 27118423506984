import styled from 'styled-components';

export interface GrowProps {
  /**
   * Sets the flex-grow property directly.
   */
  g: number
}

const Grow = styled.div<GrowProps>`
  flex-grow: ${({ g }) => g};
`;
Grow.displayName = 'Grow';

export default Grow;
