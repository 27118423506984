import keycode from 'keycode';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import without from 'lodash/without';
import React from 'react';
import lifecycle from 'recompose/lifecycle';
import withHandlers from 'recompose/withHandlers';
import withStateHandlers from 'recompose/withStateHandlers';
import { compose } from 'redux';

export type SelectionValue = number | string;

export type SelectionItem = {
  /**
   * The display value of the dropdown item.
   */
  text: string
  /**
   * The representative value of the text, so you can determine which item
   * is selected.
   */
  value: SelectionValue
  /**
   * The icon to go with the dropdown item option.
   */
  icon?: React.ReactNode
  /**
   * Instead of having an onChange handler, (traditional dropdown)
   * you can define an action to be called when a dropdown item
   * is selected.
   */
  action?: () => void
};

export interface WithSelectabilityProps {
  /**
   * Set the dropdown as a multi-select dropdown, allowing you to
   * select multiple items at a time.
   */
  multiple?: boolean
  /**
   * If true, causes the dropdown to remain open after a selection is made
   */
  persistent?: boolean
  /**
   * An array of values (either IDs or strings)
   */
  value?: SelectionValue | SelectionValue[]
  /**
   * Disable the dropdown from being used.
   */
  disabled?: boolean
  /**
   * Style Props
   */
  style?: React.CSSProperties
  placeholder?: string
  /**
   * Name of the dropdown input.
   */
  name?: string
  /**
   * Use an icon instead of the standard dropdown input.
   */
  icon?: any
  /**
   * The items to display in the dropdown.
   *
   * Needs to be an array of items following { text, value, icon }.
   *
   * @see SelectionItem
   */
  items: SelectionItem[]
  /**
   * Override the style of the floating box.
   *
   * The floating box is the box that pops up when you open the dropdown,
   * housing the items.
   */
  floatingBoxStyle?: React.CSSProperties
  /**
   * Tab Index for the dropdown
   */
  tabIndex?: String
  /**
   * Override the placeholder text when an item in a multi-select is selected.
   */
  displayValue?: String
  /**
   * Called in a multiselect dropdown when an item has been added to
   * the array of selected values.
   *
   * The value of the event's is the new array of selected values.
   */
  onSelect?: (e: any) => void
  /**
   * Called in a multiselect dropdown when an item has been removed from
   * the array of selected values.
   *
   * The value of the event's is the new array of selected values.
   */
  onUnselect?: (e: any) => void
  /**
   * Called when the value of the dropdown changes.
   *
   * The value of the event's target is multiselect: the array of new values, or singleselect: the single value.
   */
  onChange?: (e: any) => void

  debug?: boolean
}

export const withSelectability = compose(
  /**
   * Dropdown Internal State
   */
  withStateHandlers(
    ({ value }: any) => ({
      _value: value,
      itemFocusIndex: -1,
      open: false,
    }),
    {
      setValue: () => _value => ({ _value }),
      setOpen: () => open => ({ open }),
      setFocus: () => itemFocusIndex => ({ itemFocusIndex }),
      setState: () => state => state,
    },
  ),
  /**
   * Dropdown Functionality
   */
  withHandlers(() => {
    let _wrapperRef: any;
    const _itemRefs: { [k: string]: any } = {};

    return {
      // Handles Global Clicks to determine if the dropdown should close.
      onGlobalClick: ({
        open,
        setOpen,
      }) => (e: React.MouseEvent) => {
        if (!_wrapperRef) {
          return;
        }

        if (_wrapperRef.contains(e.target)) {
          return;
        }

        if (open) {
          setOpen(false);
        }
      },
      // Increments the active highlighted item of the expanded dropdown.
      incrementIndex: ({
        itemFocusIndex, setFocus, items,
      }) => (amt: number) => {
        let next = itemFocusIndex + amt;
        while (items[next] && items[next].value === 'DIVIDER') {
          next += amt;
        }
        if (next > items.length - 1) {
          next = 0;
          while (items[next] && items[next].value === 'DIVIDER') {
            next += 1;
          }
        } else if (next < 0) {
          next = items.length - 1;
        }
        setFocus(next);
      },
      // Focuses the scrollable dropdown item into view.
      focusItem: ({ itemFocusIndex }) => () => {
        if (itemFocusIndex === -1) {
          return;
        }

        try {
          _itemRefs[`${itemFocusIndex}`].focus();
        } catch (e) {
          // handle error
        }
      },
      // Registers the item ref for scroll focusing.
      itemRef: () => (index: number) => (ref: any) => {
        _itemRefs[`${index}`] = ref;
      },
      // Registers the wrapper ref for outside-click detection.
      wrapperRef: ({ wrapperRef }) => (ref: any) => {
        _wrapperRef = ref;
        if (wrapperRef) {
          wrapperRef(ref);
        }
      },
      // Handles when the Dropdown is focused.
      _onFocus: ({
        open, setOpen, disabled, onFocus,
      }) => (e: React.FocusEvent) => {
        if (!open && !disabled) {
          setOpen(true);
        }

        onFocus && onFocus(e);
      },
      _onClick: ({ onClick }) => (e: React.MouseEvent) => {
        onClick && onClick(e);
      },
      // Handler for when items are selected based on wether its a multi- or single-select.
      _onSelect: ({
        multiple,
        persistent,
        setState,
        name,
        onChange,
        onSelect,
        onUnselect,
        _value,
      }: OnSelectProps | any) => (value: any) => (e: any) => {
        if (typeof value === 'function') {
          value();
          setState({ open: false });
          return;
        }

        const nextState: any = {};
        if (multiple) {
          let values = _value || [];
          if (includes(values, value)) {
            values = without(values, value);
            onUnselect && onUnselect({ target: { name, value } });
          } else {
            const nextValues: any[] = [];
            values.forEach((v: any) => nextValues.push(v));
            nextValues.push(value);
            values = nextValues;
            onSelect && onSelect({ target: { name, value } });
          }
          nextState._value = values;
          nextState.open = persistent || false;
        } else {
          nextState._value = value;
          nextState.open = false;
        }

        if (e) {
          nextState.itemFocusIndex = -1;
        }

        setState(nextState);
        onChange && onChange({ target: { name, value: nextState._value } });
      },
    };
  }),
  /**
   * Keyboard Navigation Functionality.
   *
   * Could be moved into it's own file.
   */
  withHandlers({
    _onKeyDown: ({
      incrementIndex, setOpen, open,
    }: OnKeyDownProps) => (e: React.KeyboardEvent) => {
      let preventDefault = true;
      switch (keycode(e)) {
        case 'esc':
        case 'tab':
          preventDefault = false;
          setOpen(false);
          break;
        case 'right':
        case 'down':
          if (open) {
            incrementIndex(1);
          } else {
            preventDefault = false;
          }
          break;
        case 'up':
        case 'left':
          if (open) {
            incrementIndex(-1);
          } else {
            preventDefault = false;
          }
          break;
        default:
          preventDefault = false;
          break;
      }

      if (preventDefault) {
        e.stopPropagation();
        e.preventDefault();
      }
    },
    _onKeyUp: ({
      open, setOpen, itemFocusIndex, items, _onSelect,
    }: OnKeyUpProps) => (e: React.KeyboardEvent) => {
      switch (keycode(e)) {
        case 'space':
        case 'enter':
          if (!open) {
            setOpen(true);
          } else if (itemFocusIndex === -1) {
            return;
          }

          const item = items[itemFocusIndex];
          _onSelect(item.value)();
          break;
        default:
          break;
      }
    },
  } as any),
  /**
   * Dropdown lifecycle events.
   */
  lifecycle({
    componentDidUpdate(pp: any) {
      if (pp.itemFocusIndex !== this.props.itemFocusIndex) {
        this.props.focusItem();
      }
    },
    UNSAFE_componentWillMount() {
      window.addEventListener('click', this.props.onGlobalClick);
    },
    componentWillUnmount() {
      window.removeEventListener('click', this.props.onGlobalClick);
    },
    UNSAFE_componentWillReceiveProps(np: any) {
      if (!isEqual(np.value, this.props._value)) {
        this.props.setState({
          _value: np.value,
          itemFocusIndex: -1,
        });
      }

      if (!isEqual(np.disabled, this.props.disabled) && this.props.open) {
        this.props.setOpen(false);
      }
    },
  } as any),
) as any;

export type OnKeyUpProps = {
  open: any
  setOpen: (o: any) => void
  itemFocusIndex: number
  items: any[]
  _onSelect: Function
};

export type OnKeyDownProps = {
  incrementIndex: Function
  setOpen: Function
  open: any
};

export type OnSelectProps = {
  multiple: any
  persistent?: boolean
  setState: any
  name: any
  onChange: any
  onSelect: any
  onUnselect: any
  _value: any
};
