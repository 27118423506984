import {
  Button, ErrorMessage, Field, Label,
} from '@privacy-request/ui';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import update from 'immutability-helper';
import { useTranslation } from 'react-i18next';
import { GetSystemsPaged } from '../../../../apollo/systems/systems.queries';
import { GraphQLSearchAndSelect } from '../../../../components/GraphQLSearchAndSelect';
import Modal from '../../../../components/OverlayProvider/Modal';
import { useRouting } from '../../../../hooks/useRouting';
import { System } from '../../../../types/system/System';
import evt from '../../../../utils/evt';
import { useProcessingActivityBulkActionsEdit } from '../../hooks/useProcessingActivityBulkActionsEdit';
import { useContextualActivity } from '../../hooks/useActivityContext';

interface ProcessingActivityAssetsModalProps {
  bulkAction?: any,
  onCancel?(): void,
}

export const ProcessingActivityAssetsModal = ({ bulkAction, onCancel }:ProcessingActivityAssetsModalProps) => {
  const [t] = useTranslation('systems');
  const { goBack } = useRouting();
  const { current: processingActivity, onChange } = useContextualActivity();
  const [systems, setSystems] = useState<any[]>([]);
  const [required, setRequired] = useState(false);
  const { onAddBulkPaAssets } = useProcessingActivityBulkActionsEdit();

  useEffect(() => setRequired(false), [systems]);

  const onAddAssets = useCallback(async () => {
    if (!systems.length) {
      setRequired(true);
      return;
    }
    if (bulkAction) {
      onAddBulkPaAssets(systems);
      onCancel?.();
    } else {
      onChange(evt('systems', update(processingActivity.systems || [], {
        $push: systems,
      })));
      goBack();
    }
  }, [systems, bulkAction, onAddBulkPaAssets, onCancel, onChange, processingActivity?.systems, goBack]);

  return (
    <Modal title={t('assets.relate_assets')} style={{ maxWidth: '640px' }} onCancel={bulkAction ? onCancel : goBack}>
      <Field>
        <Label>&nbsp;</Label>
        {required && (<ErrorMessage>{t('common:errors.required')}</ErrorMessage>)}
        <GraphQLSearchAndSelect
          multi
          query={GetSystemsPaged}
          noItemsText={t('assets.no_assets_found')}
          formatItem={(system: System) => ({
            text: system.name,
            value: system.id!,
          })}
          value={systems}
          onChange={(systems: System[]) => setSystems(systems)}
          exclude={processingActivity.systems?.map(s => s.id)}
          variables={{
            fields: ['id', 'name'],
            filter: {
              onboarded: true,
            },
          }}
        />
      </Field>
      <Field right>
        <Button padded style={{ float: 'right' }} onClick={onAddAssets}>{t('assets.relate_assets')}</Button>
      </Field>
    </Modal>
  );
};
