import { useQuery, useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import update from 'immutability-helper';
import {
  useCallback, useState, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  ForcePasswordResetResult,
  ForcePasswordResetVariables,
  ForcePasswordReset,
} from '../../../apollo/auth/local.mutations';
import {
  GetRolesData, GetRoles, GetOrgRoles,
} from '../../../apollo/roles/roles.queries';
import {
  CreateOrganizationUser,
  UpdateOrganizationUser,
  CreateUserResult,
  CreateUser,
  UpdateUserResult,
  UpdateUser,
} from '../../../apollo/users/users.mutations';
import {
  GetUser,
  GetUserAdmin,
} from '../../../apollo/users/users.queries';
import { useMe } from '../../../hooks/useMe';
import { ForcePasswordResetOptions } from '../../../types/auth/Authentication';
import { User } from '../../../types/user/User';
import { error } from '../../../utils/logging';
import { validateUser } from '../../../utils/users/validateUser';
import { UserEditProps } from '../UserEdit';

const buildUserFromString = (term?: string): Partial<User> => {
  if (term && term.includes('@')) {
    return { email: term };
  }
  if (term && term.length) {
    const [first_name, last_name] = term.split(' ');
    return {
      first_name,
      last_name,
    };
  }
  return {};
};

export const useEditUser = ({
  term,
  onComplete,
  createFromDataMapping,
  onSaveOverride,
  god,
}: Pick<UserEditProps, 'term' | 'createFromDataMapping' | 'onComplete' | 'onSaveOverride' | 'god'>) => {
  const [t] = useTranslation('users');
  const { id, organization_id } = useParams<any>();
  const [dirty, setDirty] = useState(false);
  const { addToast } = useToasts();
  const [errors, setErrors] = useState<any>({});
  const [disableOverride, setDisableOverride] = useState(false);
  const [user, setUser] = useState<Partial<User>>(buildUserFromString(term));
  const { me } = useMe();

  const { data: roles } = useQuery<GetRolesData>(god ? GetOrgRoles : GetRoles, {
    variables: {
      organization_id: Number(organization_id),
    },
  });

  useEffect(() => {
    if (!roles || !roles.roles.length) {
      return;
    }

    if (createFromDataMapping) {
      const dataMappingContactRole = roles.roles.find(r => r.code === 'data-mapping-contact');
      if (!dataMappingContactRole) {
        return;
      }
      setUser(u => ({
        ...u,
        role_id: dataMappingContactRole.id,
      }));
    }
  }, [roles, createFromDataMapping]);

  const { data, loading } = useQuery<any, any>(organization_id ? GetUserAdmin : GetUser, {
    variables: { id: Number(id) || me?.id, organization_id },
    skip: createFromDataMapping || id === 'create',
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!data) {
      return;
    }
    const user = data[Object.keys(data)[0]];
    if (!user) {
      return;
    }
    setUser(user);
  }, [data]);

  // If we have an orgId, means we're creating a user from the superuser org panel
  const createQuery = (organization_id) ? CreateOrganizationUser : CreateUser;
  const [createUser, { loading: creating, error: createError }] = useMutation<CreateUserResult, any>(createQuery, { refetchQueries: ['get_users', 'get_organization_users'] });
  const updateQuery = (organization_id) ? UpdateOrganizationUser : UpdateUser;
  const [updateUser, { loading: updating, error: updateError }] = useMutation<UpdateUserResult, any>(updateQuery);
  const [forcePasswordReset] = useMutation<ForcePasswordResetResult, ForcePasswordResetVariables>(ForcePasswordReset);

  const onSave = useCallback(async (e?: any): Promise<boolean> => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    try {
      validateUser(user);

      if (onSaveOverride) {
        setDisableOverride(true);
        try {
          await onSaveOverride(user);
        } catch (e) {
          error(e);
        }
        setDisableOverride(false);
        return true;
      }

      let createdUser: User | undefined;
      if (id === 'create' || createFromDataMapping) {
        const result = await createUser({
          variables: {
            user: { ...user },
            organization_id,
          },
        });
        createdUser = result.data?.create_user;
        addToast('User created!', { appearance: 'success', autoDismiss: true });
      } else {
        if (!user.id) {
          return false;
        }
        await updateUser({
          variables: {
            user, id: user.id, organization_id,
          },
        });
        addToast('User updated', { appearance: 'success', autoDismiss: true });
      }

      setDirty(false);

      if (onComplete && createdUser) {
        onComplete(createdUser);
      }

      return true;
    } catch (e) {
      if (e instanceof ApolloError) {
        // @ts-ignore
        setErrors(e.networkError.result.errors);
      } else {
        setErrors(e);
      }

      return false;
    }
  }, [id, user, createUser, updateUser, addToast, onSaveOverride, createFromDataMapping, organization_id, onComplete]);

  const onChange = useCallback((e: any) => {
    const { name } = e.target;
    let { value } = e.target;
    setDirty(true);
    if (name === 'phone') {
      value = value.match(/\d+/g)?.join('') || '';
    }
    setUser(o => (
      update(o, { [name]: { $set: value } })
    ));

    setErrors((err: any) => {
      delete err[name];
      return err;
    });
  }, []);

  const onReset = useCallback(async (e: any) => {
    try {
      const options:Partial<ForcePasswordResetOptions> = { id: user.id };
      const { data } = await forcePasswordReset({
        variables: {
          input: options,
          organization_id: organization_id || me?.organization_id,
        },
      });
      if (data?.result.error) {
        throw new Error(data.result.message);
      }
      addToast(t(`create_modify.${data?.result.message}`), { appearance: 'success', autoDismiss: true });
    } catch (e) {
      if (e instanceof ApolloError) {
        // @ts-ignore
        setErrors(e.networkError.result);
      } else if (e instanceof Error) {
        addToast(t(`create_modify.${e.message}`), { appearance: 'error', autoDismiss: true });
      }
    }
  }, [user, forcePasswordReset, addToast, t, me, organization_id]);

  return {
    onSave,
    onChange,
    onReset,
    disableOverride,
    user,
    roles,
    loading,
    saving: creating || updating,
    error: createError || updateError,
    dirty,
    errors,
  };
};
