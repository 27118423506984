import styled from 'styled-components';

export interface FieldsProps {
  negative?: boolean
}

export const Fields = styled.div<FieldsProps>`
  display: flex;
  width: 100%;
  margin-left: ${({ negative }) => (negative ? '-28px' : 0)};
  margin-right: ${({ negative }) => (negative ? '-28px' : 0)};
`;
Fields.displayName = 'Fields';
