import {
  Title, List, ListHead, ListTitle, ListRow, ListText, Button,
} from '@privacy-request/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GetSystemsPaged, GetSystemsPagedVariables } from '../../apollo/systems/systems.queries';
import { useQueryPager } from '../../hooks/useQueryPager/useQueryPager';
import { useRouting } from '../../hooks/useRouting';
import { System } from '../../types/system/System';

export const DashboardSystemsPendingApproval = () => {
  const { push } = useRouting();
  const [t] = useTranslation('dashboard');

  const {
    results: systems,
    Pager,
  } = useQueryPager<System, GetSystemsPagedVariables>(GetSystemsPaged, {
    limit: 5,
    parameterPrefix: 'pending_',
    variables: {
      fields: ['id', 'name'],
      filter: {
        onboarded: true,
        approval_requested: true,
      },
    },
  });

  return (
    <>
      <Title style={{ marginTop: 0 }}>{t('data_sources_pending_approval')}</Title>
      <List>
        <ListHead>
          <ListTitle>{t('data_sources')}</ListTitle>
        </ListHead>
        {(systems?.length || 0) ? (
          systems.map(system => (
            <ListRow key={system.id} secondary>
              <ListText>{system.name}</ListText>
              <ListText style={{ paddingRight: 0, overflow: 'visible' }}>
                <Button style={{ float: 'right' }} short onClick={push(`/systems/${system.id}`)}>{t('review')}</Button>
              </ListText>
            </ListRow>
          ))
        ) : (
          <ListRow secondary>
            <ListText style={{ textAlign: 'center' }}>
              {t('data_sources_pending_approval_none')}
            </ListText>
          </ListRow>
        )}
        <Pager />
      </List>
    </>
  );
};
