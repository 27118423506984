import keycode from 'keycode';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { DropdownProps } from './DropdownProps';

type StateShape = {
  value: any
  currentIndex: number
  open: boolean
};

export const useKeyboardState = () => {
  const [state, setState] = useState<StateShape>({
    value: null,
    currentIndex: -1,
    open: false,
  });

  return {
    state,
    setState,
    open: state.open,
    setOpen: (open: boolean) => (setState((state) => ({ ...state, open }))),
    currentIndex: state.currentIndex,
    setCurrentIndex: (currentIndex: number) => (setState((state) => ({ ...state, currentIndex }))),
    value: state.value,
    setValue: (value: any) => (setState((state) => ({ ...state, value }))),
  };
};

export const useKeyboardNavigation = (props: DropdownProps) => {
  const {
    state,
    setState,
    open,
    setOpen,
    currentIndex,
    setCurrentIndex,
    value,
  } = useKeyboardState();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const itemRefs: { [k: string]: any } = {};
  const openRef = React.useRef<boolean>(open);
  useEffect(() => {
    openRef.current = open;
  }, [open]);

  // Handles Global Clicks to determine if the dropdown should close.
  const onOutsideClick = (e: Event) => {
    if (!wrapperRef.current) {
      return;
    }

    if (wrapperRef.current!.contains(e.target as any)) {
      return;
    }

    if (openRef.current) {
      setOpen(false);
    }
  };

  // Increments the active highlighted item of the expanded dropdown.
  const incrementIndex = useCallback((amt: number) => {
    let next = currentIndex + amt;
    while (props.items[next] && props.items[next].value === 'DIVIDER') {
      next += amt;
    }
    if (next > props.items.length - 1) {
      next = 0;
      while (props.items[next] && props.items[next].value === 'DIVIDER') {
        next += 1;
      }
    } else if (next < 0) {
      next = props.items.length - 1;
    }
    setCurrentIndex(next);
  }, [props.items, currentIndex, setCurrentIndex]);

  // Focuses the scrollable dropdown item into view.
  const focusItem = useCallback(({ currentIndex }) => () => {
    if (currentIndex === -1) {
      return;
    }

    try {
      itemRefs[`${currentIndex}`].focus();
    } catch (e) {
      // handle error
    }
  }, []);

  // Registers the item ref for scroll focusing.
  const setItemRef = useCallback((index: number) => (ref: any) => {
    itemRefs[`${index}`] = ref;
  }, []);

  // Handles when the Dropdown is focused.
  const onFocus = useCallback((e: React.FocusEvent) => {
    if (!open && !props.disabled) {
      setOpen(true);
    }

    props.onFocus && props.onFocus(e);
  }, []);

  // Handler for when items are selected based on wether its a multi- or single-select.
  const onChange = useCallback((e: any) => {
    const { value } = e.target;
    if (typeof value === 'function') {
      value();
      setOpen(false);
      return;
    }

    const { name } = props;
    const nextState: any = {
      open: false,
    };
    let values = props.value || [];
    if (props.multiple) {
      if (values.find((o: any) => (o === value))) {
        values = values.filter((o: any) => (o !== value));
      } else {
        values = [...values, value];
      }
      nextState.open = props.persistent || false;
      nextState.currentIndex = props.persistent ? currentIndex : -1;
    }

    setState(nextState);
    props.onChange && props.onChange({ ...e, target: { name, value: values } });
  }, [props.value, props.multiple, props.persistent, props.onChange, currentIndex]);

  const onKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (['esc', 'tab', 'right', 'down', 'up', 'left', 'space', 'enter'].indexOf(keycode(e)) !== -1) {
      e.preventDefault();
      e.stopPropagation();
    }
  }, []);

  const onKeyUp = useCallback((e: React.KeyboardEvent) => {
    let preventDefault = true;
    switch (keycode(e)) {
      case 'esc':
      case 'tab':
        preventDefault = false;
        setOpen(false);
        break;
      case 'right':
      case 'down':
        if (open) {
          incrementIndex(1);
        } else {
          preventDefault = false;
        }
        break;
      case 'up':
      case 'left':
        if (open) {
          incrementIndex(-1);
        } else {
          preventDefault = false;
        }
        break;
      case 'space':
      case 'enter':
        if (!open) {
          setOpen(true);
        } else if (currentIndex === -1) {
          return;
        }

        e.preventDefault();
        e.stopPropagation();
        const { name } = props;
        const { value } = props.items[currentIndex];
        onChange({ ...e, target: { name, value } });
        break;
      default:
        preventDefault = false;
        break;
    }

    if (preventDefault) {
      e.stopPropagation();
      e.preventDefault();
    }
  }, [setOpen, currentIndex, props.items, onChange]);

  useEffect(() => {
    // componentWillMount
    window.addEventListener('click', onOutsideClick);

    return () => {
      // componentWillUnmount
      window.removeEventListener('click', onOutsideClick);
    };
  }, []);

  return {
    open,
    currentIndex,
    setCurrentIndex,
    value,
    onOutsideClick,
    incrementIndex,
    focusItem,
    setItemRef,
    wrapperRef,
    onFocus,
    onClick: props.onClick,
    onChange,
    onKeyDown,
    onKeyUp,
  };
};
