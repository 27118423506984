import {
  Button,
  ErrorMessage,
  extendCardWithClass,
} from '@privacy-request/ui';
import React, {
  useCallback, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import styled from 'styled-components';
import './dirtysaverevert.scss';

interface DirtySaveRevertProps {
  dirty: boolean
  saving: boolean
  onSave: any
  onRevert?: any
  onCancel?: any
  errors?: any
  ctaText?: string
  ctaOnClick?: any
  children?: any
  disabled?: boolean
  noPrompt?: boolean
  /**
   * Pass in a Regular Expression to allow POP to these routes.
   */
  allowRoutes?: RegExp
}

const Wrapper = styled.div<{ dirty: boolean }>`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: ${({ dirty }) => (dirty ? '32px' : '-76px')};
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: 8;

  transition: bottom 0.2s ease-out;
`;
Wrapper.displayName = 'Wrapper';

const DirtySaveRevertCard = extendCardWithClass('dirtysaverevert');

const FloatingCardContents = styled.div`
  display: flex;
  width: unset;
  align-items: center;
  justify-content: space-between;
`;
FloatingCardContents.displayName = 'FloatingCardContents';

export const DirtySaveRevert = ({
  saving, dirty, onSave, onRevert, onCancel, errors, allowRoutes, ctaText, ctaOnClick, children, noPrompt, disabled,
}: DirtySaveRevertProps) => {
  const [t] = useTranslation('common');

  const onAnimationEnd = useCallback(() => {
    const card = document.getElementById('dirty_save_revert');
    card?.classList.remove('shake');
  }, []);

  const onMessage = useCallback((location: any, action: any) => {
    if (action === 'POP') {
      if (allowRoutes && allowRoutes.test(location.pathname)) {
        return true;
      }
      window.history.forward();
    }
    if (action === 'REPLACE') {
      return true;
    }
    if (action === 'PUSH' && allowRoutes && allowRoutes.test(location.pathname)) {
      return true;
    }
    const card = document.getElementById('dirty_save_revert');
    card?.classList.add('shake');
    return false;
  }, [allowRoutes]);

  useEffect(() => {
    if (dirty && process.env.NODE_ENV === 'production') {
      window.onbeforeunload = function () {
        return t('form.unsaved_changes');
      };
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [dirty, t]);

  return (
    <Wrapper dirty={dirty || (errors && errors.general) || ctaText || !!children}>
      {!noPrompt && (
        <Prompt
          when={dirty}
          message={onMessage}
        />
      )}
      <DirtySaveRevertCard id="dirty_save_revert" onAnimationEnd={onAnimationEnd}>
        <FloatingCardContents>
          {dirty && (
            <>
              <span style={{ marginRight: '16px' }}>{t('form.unsaved_changes')}</span>
              {onCancel && (<Button style={{ backgroundColor: 'white', marginRight: '16px' }} onClick={onCancel} secondary>{t('form.cancel')}</Button>)}
              {onRevert && (<Button style={{ backgroundColor: 'white', marginRight: '16px' }} onClick={onRevert} secondary>{t('form.revert')}</Button>)}
              <Button onClick={() => onSave()} disabled={disabled || saving || !dirty}>{saving ? t('form.saving') : t('form.save')}</Button>
            </>
          )}
          {!dirty && ctaText && (
            <>
              <Button padded onClick={ctaOnClick} disabled={saving}>{ctaText}</Button>
            </>
          )}
          {!dirty && children}
        </FloatingCardContents>
        {errors && errors.general && <ErrorMessage style={{ position: 'static', marginTop: '24px' }}>{errors.general}</ErrorMessage>}
      </DirtySaveRevertCard>
    </Wrapper>
  );
};
