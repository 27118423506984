import React from 'react';
import { useSelectionContextWithNamespace } from '../../../hooks/useSelectionContext';
import { BulkActionController } from './useBulkActionController';
import { Dropdown } from '@privacy-request/ui';

export const BulkActionField = ({
  entityType,
  selectionItems,
  selectAction,
}: BulkActionController) => {
  const { selections } = useSelectionContextWithNamespace(entityType);
  if (Object.keys(selections).length > 0) {
    return (
      <Dropdown
        items={selectionItems}
        placeholder="Actions..."
        style={{
          marginLeft: '16px',
        }}
        onChange={({ target: { value } }) => {
          selectAction(value);
        }}
      />
    );
  }
  return null;
};
