import {
  NoAuthorizationTemplate, Label, Input, ErrorText, Button, Field, Text,
} from '@privacy-request/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { PROCESSING_ACTIVITY_RESUME_KEY, SYSTEM_RESUME_KEY } from '../../hooks/useSystemResume';
import { usePasswordReset } from './hooks/usePasswordReset';

const Form = styled.form`
  margin-top: 16px;
`;
Form.displayName = 'Form';

export default () => {
  const {
    onSubmit,
    onChange,
    options,
    loading,
    result,
    tokenStatus,
    sendingReset,
    onResend,
  } = usePasswordReset();

  const [t] = useTranslation('auth');
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const system_resume = search.get(SYSTEM_RESUME_KEY);
  const pa_resume = search.get(PROCESSING_ACTIVITY_RESUME_KEY);
  const history = useHistory();
  const loginPage = async () => {
    history.push('/login/email');
  };

  if (system_resume) {
    sessionStorage.setItem(SYSTEM_RESUME_KEY, system_resume);
  }

  if (pa_resume) {
    sessionStorage.setItem(PROCESSING_ACTIVITY_RESUME_KEY, pa_resume);
  }

  if (tokenStatus?.error) {
    return (
      <NoAuthorizationTemplate noLogo>
        <Field>
          <Text>{t(`reset_password.${tokenStatus.message}`)}</Text>
        </Field>
        <Field>
          <Text>{t('reset_password.token_status_follow_up')}</Text>
        </Field>
        <Field>
          <Button onClick={() => onResend().then(() => loginPage())} disabled={sendingReset} fluid>{t('form.resend_password_reset_link')}</Button>
        </Field>
        <Field style={{ paddingBottom: 0 }}>
          <Button disabled={sendingReset} onClick={loginPage} secondary fluid>{t('common:form.cancel')}</Button>
        </Field>
      </NoAuthorizationTemplate>
    );
  }

  return (
    <NoAuthorizationTemplate>
      {result && result.error && (
        <Field style={{ paddingBottom: 0 }}>
          <ErrorText>{t(`reset_password.${result.message}`)}</ErrorText>
        </Field>
      )}
      {
        result?.errors?.map(([message, count]: any) => (
          <Field style={{ paddingBottom: 0 }}>
            <ErrorText>{t(`reset_password.${message}`, { count })}</ErrorText>
          </Field>
        ))
      }
      {result && result.error === false && (
        <Field>
          <Text>{t(`reset_password.${result.message}`)}</Text>
        </Field>
      )}
      { (!result || result.message !== 'reset_success') && (
        <Form onSubmit={onSubmit}>
          <Field>
            <Label>
              {t('form.password')}
              :
            </Label>
            <Input
              autoFocus
              name="password"
              value={options.password || ''}
              type="password"
              onChange={onChange}
            />
          </Field>
          <Field>
            <Label>
              {t('form.password_again')}
              :
            </Label>
            <Input
              name="password_again"
              value={options.password_again || ''}
              type="password"
              onChange={onChange}
            />
          </Field>

          <Field>
            <Button onClick={onSubmit} disabled={loading} type="submit" fluid>{loading ? t('common:form.looking_up') : t('common:form.continue')}</Button>
          </Field>
          <Field style={{ paddingBottom: 0 }}>
            <Button onClick={loginPage} disabled={loading} secondary fluid>{t('common:form.cancel')}</Button>
          </Field>
        </Form>
      )}
      { (result && result.message === 'reset_success') && (
        <Field style={{ paddingBottom: 0 }}>
          <Button onClick={loginPage} fluid>{t('common:form.continue')}</Button>
        </Field>
      )}
    </NoAuthorizationTemplate>
  );
};
