import React from 'react';
import { Table } from '@privacy-request/ui/src/atoms/SASSTable';
import { NoEntitiesFoundDisplay } from '../../../../components/NoEntitiesDisplay/NoEntitiesFoundDisplay';
import { usePermissions } from '../../../../hooks/usePermissions';
import { RequestListHeader } from './RequestListHeader';
import { RequestListProps } from './RequestListProps';
import { RequestListRow } from './RequestListRow';

/**
 * A composite component used to display a list of requests
 */
export const RequestList = ({
  requests,
  activeSystemCount,
  ...props
}: RequestListProps) => {
  const { has } = usePermissions();

  return (
    <>
      <Table striped>
        <thead>
          <RequestListHeader {...props} />
        </thead>
        <tbody>
          {
            requests.map((request) => (
              <RequestListRow
                key={request.id}
                request={request}
                {...props}
              />
            ))
          }
        </tbody>
      </Table>

      {activeSystemCount && (
        <NoEntitiesFoundDisplay
          to="/systems/workflow/new"
          noEntities
          noEntitiesKey={has('systems.actions.create') ? 'requests:no_systems' : 'requests:no_systems_unauthorized'}
        />
      )}
    </>
  );
};
