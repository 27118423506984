import { ProcessingActivity } from '../../types/system/ProcessingActivity';
import {
  CHANGE_PROCESSING_ACTIVITY,
  SET_PROCESSING_ACTIVITY,
  SET_PROCESSING_ACTIVITY_ERRORS,
} from './processing_activity.types';

export interface SetProcessingActivityAction {
  type: typeof SET_PROCESSING_ACTIVITY
  payload: Partial<ProcessingActivity>
}

export const setProcessingActivity = (payload: Partial<ProcessingActivity>): SetProcessingActivityAction => ({
  type: SET_PROCESSING_ACTIVITY,
  payload,
});

export interface ChangeProcessingActivityAction {
  type: typeof CHANGE_PROCESSING_ACTIVITY
  payload: Partial<ProcessingActivity>
}

export const changeProcessingActivity = (payload: Partial<ProcessingActivity>): ChangeProcessingActivityAction => ({
  type: CHANGE_PROCESSING_ACTIVITY,
  payload,
});

export interface SetProcessingActivityErrorsAction {
  type: typeof SET_PROCESSING_ACTIVITY_ERRORS
  payload: any
}

export const setProcessingActivityErrors = (payload: any): SetProcessingActivityErrorsAction => ({
  type: SET_PROCESSING_ACTIVITY_ERRORS,
  payload,
});

export type ProcessingActivityActionTypes =
  SetProcessingActivityAction |
  ChangeProcessingActivityAction |
  SetProcessingActivityErrorsAction;
