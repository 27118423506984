import gql from 'graphql-tag';

export const PRFileFragment = gql`
  fragment prFile on PRFile {
    lastModified
    name
    size
    type
    md5
    path
  }
`;
