import gql from 'graphql-tag';

export const RoleFragment = gql`
  fragment role on Role {
    id
    organization_id
    code
    name
    description
    organization_role_permissions @type(name: "OrganizationRolePermission") {
      organization_id
      permission_id
      role_id
    }
  }
`;
