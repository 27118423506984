import { useEffect, useCallback } from 'react';

export interface UseHotkeyConfig {
  cmd?: boolean
  stopPropagation?: boolean
}

export type useHotkeyFunction = (key: string, cb: Function, config?: UseHotkeyConfig) => void;

export const useHotkey: useHotkeyFunction = (key, cb, config = {}) => {
  const handler = useCallback((e: KeyboardEvent) => {
    if (e.key === key) {
      if (!config.cmd) {
        if (config.stopPropagation) {
          e.stopPropagation();
        }
        cb();
      } else if ((config.cmd && e.metaKey) || (config.cmd && e.ctrlKey)) {
        if (config.stopPropagation) {
          e.stopPropagation();
        }
        e.preventDefault();
        cb();
      }
    }
  }, [cb, key, config.cmd, config.stopPropagation]);

  useEffect(() => {
    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  }, [handler]);
};
