import clsx from 'clsx';
import React from 'react';

export type TableCellProps = React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableDataCellElement>, HTMLTableDataCellElement> & {
  /**
   * If true, the cell contains only a checkbox, that should be styled appropriately
   */
  checkbox?: boolean
  /**
   * If true, the cell should shrink to fit the contents
   */
  shrink?: boolean
  /**
   * If true, the cell should avoid wrapping
   */
  nowrap?: boolean
  /**
   * If true, the cell should truncate its contents
   */
  truncate?: boolean
};

export const TableCell = ({
  checkbox,
  shrink,
  nowrap,
  truncate,
  ...props
}: TableCellProps) => (
  <td
    className={clsx({
      checkbox,
      shrink,
      nowrap,
      truncate,
    })}
    {...props}
  />
);
