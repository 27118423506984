import React, { Component } from 'react';
import styled from 'styled-components';
import { EditIcon, Text } from '../../atoms';

export interface LetterAvatarProps {
  text?: string
  collapsed?: boolean
  onClick?: (e: any) => void
  style?: React.CSSProperties
  img?: string
  /**
   * Controls whether the settings cog should show
   */
  avatar?: boolean
}

const LetterText = styled(Text)`
  color: ${({ theme }) => theme.BG_GREY};
`;

const Wrapper = styled.div<any>`
  width: 100%;
  height: ${({ collapsed }) => (collapsed ? '60px' : '148px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.CTA_COLOR_HOVER};
`;
Wrapper.displayName = 'Wrapper';

const CogWrapper = styled.div<any>`
  position: absolute;
  background-color: white;
  border-radius: 100px;
  width: 22px;
  height: 22px;
  right: 2px;
  bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLetterAvatar = styled.div<LetterAvatarProps>`
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 64px;
  font-size: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: gray;
  border: 1px solid ${({ theme }) => theme.CTA_COLOR_HOVER};
  transition: border 0.1s linear;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};

  & > ${CogWrapper} {
    border: 2px solid ${({ theme }) => theme.CTA_COLOR};
    transition: border 0.1s linear;
  }

  &:hover > ${CogWrapper} {
    border: 2px solid #ccc;
  }
`;
StyledLetterAvatar.displayName = 'StyledLetterAvatar';

const StyledAvatar = styled.img<LetterAvatarProps>`
  width: ${({ collapsed }) => (collapsed ? '34px' : '96px')};
  height: ${({ collapsed }) => (collapsed ? '34px' : '96px')};
  border-radius: 64px;
  border: 1px solid ${({ theme }) => theme.CTA_COLOR_HOVER};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
`;
StyledAvatar.displayName = 'Avatar';

export class LetterAvatar extends Component<LetterAvatarProps, any> {
  static defaultProps = { text: null };

  constructor(props: LetterAvatarProps) {
    super(props);
    this.state = { text: props.text };
  }

  UNSAFE_componentWillReceiveProps(nextProps: LetterAvatarProps) {
    if (nextProps.text !== this.state.text) {
      this.setState({ text: nextProps.text });
    }
  }

  render() {
    return (
      <Wrapper>
        { this.props.text
          ? (
            <StyledLetterAvatar onClick={this.props.onClick}>
              <LetterText style={{ fontSize: '40px' }}>{this.props.text}</LetterText>
              <CogWrapper>
                <EditIcon width={20} height={20} color="black" />
              </CogWrapper>
            </StyledLetterAvatar>
          )
          : <StyledAvatar src={this.props.img} onClick={this.props.onClick} />}
      </Wrapper>
    );
  }
}
