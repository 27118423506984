import gql from 'graphql-tag';

export interface GetDashboardStatsData {
  get_dashboard_stats: {
    download_requests: number
    deletion_requests: number
    opt_out_requests: number
    completed_requests: number
    avg_time_to_resolve: number
  }
}

export const GetDashboardStats = gql`
  query get_dashboard_stats($time: String!) {
    get_dashboard_stats(time: $time) @rest(path: "dashboard/stats?{args}" type: "DashboardStat") {
      download_requests
      deletion_requests
      opt_out_requests
      completed_requests
      avg_time_to_resolve
    }
  }
`;

export interface GetDashboardSystemStatsData {
  get_dashboard_system_stats: {
    processing_activities: number
    assets: number
    processing_activities_in_review: number
    assets_in_review: number
  }
}

export const GetDashboardSystemStats = gql`
  query get_dashboard_stats {
    get_dashboard_system_stats @rest(path: "dashboard/system-stats" type: "DashboardSystemStat") {
      processing_activities
      assets
      processing_activities_in_review
      assets_in_review
    }
  }
`;

export interface DataMapSummary {
  new: number;
  review: number;
  approval: number;
  active: number;
}

export interface GetDashboardSystemSumByStatusData {
  get_dashboard_system_sum_by_status: DataMapSummary
}

export const GetDashboardSystemSumByStatus = gql`
  query get_dashboard_system_sum_by_status {
    get_dashboard_system_sum_by_status @rest(path: "dashboard/system-sum-by-status" type: "DashboardSystemSumStatus") {
      new
      waiting_review
      waiting_approval
      active
    }
  }
`;

export interface GetDashboardPASumByStatusData {
  get_dashboard_processing_activity_sum_by_status: DataMapSummary
}

export const GetDashboardPASumByStatus = gql`
  query get_dashboard_processing_activity_sum_by_status {
    get_dashboard_processing_activity_sum_by_status @rest(path: "dashboard/processing-activity-sum-by-status" type: "DashboardPASumStatus") {
      new
      waiting_review
      waiting_approval
      active
    }
  }
`;

export interface ChartData {
  id: number
  created: number
  value: number
}

export interface GetDashboardChartsData {
  get_dashboard_charts: ChartData[]
}

export const GetDashboardCharts = gql`
  query get_dashboard_charts($time: String!, $chart: Int!) {
    get_dashboard_charts(time: $time, chart: $chart) @rest(path: "dashboard/charts?{args}" type: "[DashboardChart]") {
      id
      created
      value
    }
  }
`;
