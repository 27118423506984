import {
  useState, useCallback, useMemo,
} from 'react';
import { SelectionContextControllerShape, useSelectionContextWithNamespace } from '../useSelectionContext';

type Identifier = string | number;

interface UseSelectionOptions<T> {
  selectionNamespace?: string
  onSelectAllFilter?: (obj: T) => boolean
  controller?: SelectionContextControllerShape
}

/**
 * The `useResultSelections` hook builds on the core selection context hook to
 * simplify support for selection in tables by handling record identifiers,
 * allowing range-based selection, handling mouse events, etc. In this case,
 * `onSelect` is the handler that is called when an row checkbox is clicked,
 * and may represent either a selection or a deselection.
 */
export const useResultSelections = <T>(results: Array<T>, {
  selectionNamespace = 'default',
  onSelectAllFilter = () => (true),
  controller,
}: UseSelectionOptions<T> = {}) => {
  const {
    selections,
    select,
    deselect,
    clear,
  } = useSelectionContextWithNamespace(selectionNamespace, controller);
  const [lastSelected, setLastSelected] = useState<Identifier>();

  const onSelect = useCallback((id: Identifier, e: React.MouseEvent<HTMLDivElement>) => {
    const shift = e.shiftKey;
    e.stopPropagation();
    e.preventDefault();

    if (shift && lastSelected) {
      const lastIndex = results.findIndex((r: any) => r.id === lastSelected);
      const currentIndex = results.findIndex((r: any) => r.id === id);
      if (lastIndex) {
        const from = Math.min(lastIndex, currentIndex);
        const to = Math.max(lastIndex, currentIndex);
        const ids = Array(to - from + 1)
          .fill(1)
          .map((_val, i) => (
            (results[i + from] as any).id
          ));
        if (selections[lastSelected]) {
          select(...ids);
        } else {
          deselect(...ids);
        }
      }
    } else if (selections[id]) {
      deselect(id);
    } else {
      select(id);
    }

    if (!shift) {
      setLastSelected(id);
    }
  }, [lastSelected, selections, results, deselect, select]);

  const allSelected = useMemo(() => {
    if (results) {
      const selectable = results.filter(onSelectAllFilter);
      return !!selectable.length && selectable.every((r: any) => !!selections[r.id]);
    }
  }, [results, onSelectAllFilter, selections]);

  const onSelectAll = useCallback(() => {
    const selectable = results.filter(onSelectAllFilter);

    if (allSelected) {
      deselect(...selectable.map((r: any) => (r.id)));
    } else {
      select(...selectable.map((r: any) => (r.id)));
    }
  }, [results, onSelectAllFilter, allSelected, deselect, select]);

  const onClear = useCallback(() => {
    clear();
  }, [clear]);

  return {
    allSelected,
    onSelect,
    onSelectAll,
    onClear,

    /** @deprecated */
    selections,
  };
};
