import {
  Title, Grid, CaretDownIcon, Tooltip, HelpIcon,
} from '@privacy-request/ui';
import React from 'react';
import styled, { useTheme } from 'styled-components';

const Content = styled.div`
  width: 100%;
`;
Content.displayName = 'Content';

const MyTitle = styled(Title)`
  margin-top: 6px;
  margin-bottom: 6px;
  cursor: inherit;
`;
MyTitle.displayName = 'MyTitle';

const TitleBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'initial')};
  background-color: rgba(0,0,0,0);
  transition: background-color 0.1s linear;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
`;
TitleBar.displayName = 'TitleBar';

export const IconWrapper = styled.div<{ expanded: boolean }>`
  width: 32px;
  height: 32px;
  transform: rotate(${({ expanded }) => (expanded ? '0' : '-180deg')});
`;
IconWrapper.displayName = 'IconWrapper';

const ErrorMessage = styled.span`
  color: red;
  font-weight: normal;
  font-size: 14px;
  flex-grow: 1;
`;
ErrorMessage.displayName = 'ErrorMessage';

const InfoMessage = styled.span`
  color: rgba(0,0,0,0.6);
  font-weight: 100;
  font-size: 16px;
  flex-grow: 1;
  margin-left: 16px;
`;
InfoMessage.displayName = 'InfoMessage';

interface ExpandAndCollapseProps {
  title: React.ReactNode
  storageKey: string
  children: any
  helpText?: string
  fluid?: boolean
  error?: boolean | string
  info?: string
  expandable?: boolean
  collapsed?: boolean
  style?: any
}

export const ExpandAndCollapse = ({
  title, storageKey, children, error, info, expandable = true, fluid, helpText, collapsed, style,
}: ExpandAndCollapseProps) => {
  const [expanded, setExpanded] = React.useState(!collapsed && sessionStorage.getItem(`eac_${storageKey}`) !== 'collapsed');
  const theme = useTheme();
  React.useEffect(() => {
    sessionStorage.setItem(`eac_${storageKey}`, expanded ? 'expanded' : 'collapsed');
  }, [expanded, storageKey]);
  const size = 32;
  return (
    <Grid style={{ marginTop: '1rem', ...style }} container fluid={fluid}>
      <Content>
        <TitleBar onClick={expandable ? () => setExpanded(!expanded) : () => {}}>
          <MyTitle>
            {title}
            {helpText && (
              <Tooltip style={{ display: 'inline-flex', marginLeft: '6px' }} content={helpText}>
                <HelpIcon width={22} height={22} style={{ position: 'relative', top: '2px' }} color={theme.CTA_COLOR} />
              </Tooltip>
            )}
          </MyTitle>
          {error && (
          <ErrorMessage>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {error}
          </ErrorMessage>
          )}
          {info && <InfoMessage>{info}</InfoMessage>}
          {expandable && (
            <IconWrapper expanded={expanded}>
              <CaretDownIcon width={size} height={size} color="rgba(0,0,0,0.8)" />
            </IconWrapper>
          )}
        </TitleBar>
        {expanded && children}
      </Content>
    </Grid>
  );
};
