import update from 'immutability-helper';
import { ProcessingActivity } from '../../types/system/ProcessingActivity';
import { ProcessingActivityActionTypes } from './processing_activity.actions';
import {
  CHANGE_PROCESSING_ACTIVITY,
  SET_PROCESSING_ACTIVITY,
  SET_PROCESSING_ACTIVITY_ERRORS,
} from './processing_activity.types';

interface ProcessingActivityState {
  processing_activity: Partial<ProcessingActivity>
  isDirty: boolean
  errors: any
}

const initialState: ProcessingActivityState = {
  processing_activity: {},
  errors: undefined,
  isDirty: false,
};

export default (state = initialState, action: ProcessingActivityActionTypes) => {
  switch (action.type) {
    case SET_PROCESSING_ACTIVITY:
      return update(state, {
        processing_activity: { $set: action.payload },
        isDirty: { $set: false },
        errors: { $set: undefined },
      });
    case CHANGE_PROCESSING_ACTIVITY:
      return update(state, {
        processing_activity: { $set: action.payload },
        isDirty: { $set: true },
      });
    case SET_PROCESSING_ACTIVITY_ERRORS:
      return update(state, { errors: { $set: action.payload } });
    default:
      return state;
  }
};
