import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomFieldConfig, CustomFields } from '../../../../../components/CustomFields/CustomFields';
import { UPLOAD_FILE_SUPPORTED_EXTENSIONS } from '../../../../../constants';
import { useMe } from '../../../../../hooks/useMe';
import {
  businessFunctionCustomField, dataSubjectCategoriesCustomField, purposesOfProcessingCustomField,
} from '../../../../SystemsView/common/fields';
import { useContextualActivity } from '../../../hooks/useActivityContext';
import { ProcessingActivityContacts } from '../ProcessingActivityContacts';
import { createCommonDetailFields } from '../../../../../utils/data_map_entity/commonDetailFields';
import { useSubsidiaryDropdownCustomField } from '../../../../../hooks/fields/useSubsidiaryDropdownCustomField';

export const ProcessingActivityTabDetails = () => {
  const {
    current: processingActivity, onChange, canEditPerm,
  } = useContextualActivity();
  const [t] = useTranslation('systems');
  const { me } = useMe();

  const accept = UPLOAD_FILE_SUPPORTED_EXTENSIONS.join(',');
  const gdpr_enabled = me?.organization?.features?.gdpr_enabled;

  const subsidiaryField = useSubsidiaryDropdownCustomField(processingActivity.sub_organizations);

  const retentionPolicyIsTextMode = typeof processingActivity.retention_policy === 'string';

  const config = useMemo((): CustomFieldConfig => {
    const _config: CustomFieldConfig = [
      {
        type: 'section',
        title: t('pa.information'),
        storageKey: 'pa_informationyeeee',
        config: [
          {
            type: 'text',
            label: t('form.description'),
            name: 'description',
            disabled: !canEditPerm('processing_activities.edit.information'),
          },
          businessFunctionCustomField(t, processingActivity, onChange, { disabled: !canEditPerm('processing_activities.edit.information') }),
          subsidiaryField,
          {
            type: 'textarea',
            width: 2,
            disabled: !canEditPerm('processing_activities.edit.information'),
            name: 'notes',
            label: t('form.additional_notes'),
          },
        ],
      },
      {
        type: 'section',
        title: t('contacts.title'),
        storageKey: 'pa_contacts',
        helpText: t('tooltips.contacts', { name: processingActivity.name }),
        config: [{
          type: 'custom',
          component: ProcessingActivityContacts,
        }],
      },
      ...(gdpr_enabled ? [{
        type: 'section',
        title: t('data_subject_categories.title'),
        helpText: t('tooltips.data_subject', { name: processingActivity.name }),
        storageKey: 'like_it_matters',
        config: [dataSubjectCategoriesCustomField(t, processingActivity, onChange, { disabled: !canEditPerm('processing_activities.edit.data_subjects') })],
      }] as CustomFieldConfig : []),
      {
        type: 'section',
        title: t('purposes_of_processing.pa_title'),
        storageKey: 'pa_purposes_of_processing',
        helpText: t('tooltips.purposes_of_processing', { name: processingActivity.name }),
        config: [
          purposesOfProcessingCustomField(t, processingActivity, onChange, { disabled: !canEditPerm('processing_activities.edit.purposes_of_processing') }),
        ],
      },
      ...createCommonDetailFields({
        isLegalAssistant: false,
        dataMapEntity: processingActivity,
        t,
        onChange,
        canEditProcessingCountries: canEditPerm('processing_activities.edit.processing_countries'),
        canEditDataRetention: canEditPerm('processing_activities.edit.data_retention_and_disposal_policy'),
        canEditTransfers: canEditPerm('processing_activities.edit.cross_border_transfers'),
        retentionPolicyIsTextMode,
        accept,
      }),
    ];

    const idx = _config.findIndex(c => c.type === 'section' && c.storageKey === 'pa_contacts');
    if (idx === -1) {
      throw new Error('Did you just change the pa_contacts storagekey? I was kinda using that for something');
    }

    return _config;
  }, [t, canEditPerm, processingActivity, onChange, subsidiaryField, retentionPolicyIsTextMode, accept, gdpr_enabled]);

  return (
    <div>
      <CustomFields config={config} value={processingActivity} onChange={onChange} />
    </div>
  );
};
