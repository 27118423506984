import {
  extendCardWithClass, TrashIcon,
} from '@privacy-request/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DateTime } from '../DateTime/DateTime';
import './assetcard.scss';

interface AssetCardProps {
  id: string,
  title: string,
  created_at?: string,
  description?: string,
  showDeleteButton?: boolean,
  tagStyle?: any,
  type?: string,
  assetType?: any,
  tags?: any,
  position?: number
  laneId?: string,
  index?: number,
  onRemoveCard?: (index: any, id:any) => void,
}

const Card = extendCardWithClass('asset-card');

const Tag = styled.div`
background-color: ${props => props.color};
  border-radius: 4px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  padding: 3px;
  color: #FFF;
  margin-right: 5px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 12px;
  border-bottom: 1px solid #ddd;

  &:last-of-type {
    border-bottom: none;
  }
`;

Tag.displayName = 'Tag';

export const AssetCard = ({
  id,
  title,
  created_at,
  description,
  showDeleteButton,
  tagStyle,
  type,
  assetType,
  tags,
  position,
  laneId,
  onRemoveCard,
}: AssetCardProps) => {
  const [t] = useTranslation('systems');

  return (
    <Card>
      <header
        style={{
          borderBottom: '1px solid #eee',
          paddingTop: 4,
          paddingBottom: 8,
          marginBottom: 10,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          overflowWrap: 'break-word',
        }}
      >
        <div style={{
          fontSize: 14, fontWeight: 'bold', display: 'flex', overflowWrap: 'break-word', whiteSpace: 'initial',
        }}
        >
          {title}
        </div>
        { false && (<div style={{ fontSize: 10 }}><DateTime date={created_at} /></div>)}
      </header>
      <div style={{ fontSize: 12, color: '#4C4C4C' }}>
        <div>
          { description && (<div style={{ padding: '5px 0px', whiteSpace: 'initial' }}>{description}</div>)}
          { assetType && (
            <div style={{ padding: '3px 0px' }}>
              <b>
                {`${t('form.hosting_types')}: `}
              </b>
              {assetType}
            </div>
          )}
          { type && (
            <div style={{ padding: '3px 0px 5px 0px' }}>
              <b>
                {`${t('form.internal_or_third_party')}: `}
              </b>
              {type}
            </div>
          )}
        </div>
        <div style={{
          borderTop: '1px solid #eee',
          paddingTop: 6,
          marginTop: 10,
          display: 'flex',
        }}
        >
          <div>
            <TrashIcon width="20" height="20" onClick={() => (onRemoveCard ? onRemoveCard(id, laneId) : null)} />
          </div>
          { tags && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'row',
                flexWrap: 'wrap',
                width: '90%',
              }}
            >
              { tags.map((tag: any) => (
                <Tag color={tag.bgcolor} key={tag.title}>{tag.title}</Tag>
              ))}
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};
