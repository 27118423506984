import React from 'react';
import { PipelineStepUpdate } from '@privacy-request/basic-types';
import { Chip } from '@privacy-request/ui';
import styled from 'styled-components';
import { PipelineLog } from './usePipelineDebugger';

const evaluateColor = ({ appearance }: { appearance: PipelineStepUpdate['appearance'] }) => {
  switch (appearance) {
    case 'success':
      return '#bff5bf';
    case 'error':
      return '#f7b2b2';
    case 'info':
      return '#e0e0ff';
    case 'warn':
      return '#ffe3b1';
    default:
      return '#ccc';
  }
};

const Wrapper = styled.div`
  width: 590px;
  padding-top: 6px;
`;

const Log = styled.div<{ appearance: PipelineStepUpdate['appearance'] }>`
  margin: 0;
  padding-left: 6px;
  margin-bottom: 2px;
  border: 1px solid #ddd;
  border-radius: 2px;
  background-color: ${evaluateColor};
`;

interface PipelineLogDisplayProps {
  log: PipelineLog
}
const PipelineLogDisplay = ({ log }: PipelineLogDisplayProps) => (
  <Log appearance={log.appearance}>
    {(() => {
      switch (log.type) {
        case 'file':
          return log.message;
        case 'message':
          return log.value;
        case 'object':
          return log.message;
        case 'status':
        default:
          return '';
      }
    })()}
    {log.type === 'object' && (
      <Chip
        style={{
          height: '9px', fontSize: '12px', marginBottom: '3px', marginLeft: '6px',
        }}
        // eslint-disable-next-line no-console
        onClick={() => console.log(JSON.parse(log.value))}
        color={evaluateColor({ appearance: log.appearance })}
      >
        View Data in Console
      </Chip>
    )}
    {log.type === 'file' && (
      <Chip
        style={{
          height: '9px', fontSize: '12px', marginBottom: '3px', marginLeft: '6px',
        }}
        // eslint-disable-next-line no-console
        onClick={() => console.log(log.contents)}
        color={evaluateColor({ appearance: log.appearance })}
      >
        View File in Console
      </Chip>
    )}
  </Log>
);

interface PipelineLogsDisplayProps {
  logs: PipelineLog[]
}
export const PipelineLogsDisplay = ({ logs }: PipelineLogsDisplayProps) => (
  <Wrapper>
    {logs.map((log) => (
      <PipelineLogDisplay log={log} />
    ))}
  </Wrapper>
);
