import { DocumentNode } from 'graphql';

interface QueryParams {
  type: 'number' | 'string'
  key: string
}

export const validateDocumentNode = (GraphQLQuery: DocumentNode) => {
  const definition = GraphQLQuery.definitions[0];

  if (definition.kind !== 'OperationDefinition') {
    throw new Error('Invalid GraphQL Pager Query passed to useQueryPager()');
  }

  const key = definition.name?.value;

  if (!key) {
    throw new Error('Could not find the query definition\'s key.');
  }

  const params: QueryParams[] = [];

  if (!definition.variableDefinitions) {
    throw new Error('Your pager query has no variables defined.');
  }

  for (const variable of definition.variableDefinitions) {
    if (variable.type.kind === 'NonNullType' && variable.type.type.kind === 'NamedType') {
      params.push({
        type: variable.type.type.name.value === 'Int' ? 'number' : 'string',
        key: variable.variable.name.value,
      });
    } else {
      throw new Error(`${variable.type.kind} is not implemented.`);
    }
  }

  return {
    key,
    params,
  };
};
