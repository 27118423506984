import React from 'react';
import styled from 'styled-components';
import { SVGComponent } from './SvgProps';
import { Svg } from './utils';

const TrashIconSvg = styled(Svg)`
  path {
  }
`;

export const TrashIcon: SVGComponent = ({
  fill, color, ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 16 16">
    <path style={{ fill: color || fill }} fillRule="evenodd" d="M4.5 12.25c0 .319.114.592.342.82.228.228.501.342.82.342h4.676c.319 0 .592-.114.82-.342.228-.228.342-.501.342-.82v-7h-7v7zm7.588-8.75h-2.05l-.575-.588H6.537l-.574.588h-2.05v1.162h8.175V3.5z" />
  </svg>
);

TrashIcon.defaultProps = {
  width: 16,
  height: 16,
};
