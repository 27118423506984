import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { RestLink } from 'apollo-link-rest';
import { augmentHeaders } from '../utils/http';
import { backendUrl } from '../utils/url';
import { convertToFormData } from './utils/convertToFormData';

const authLink = setContext(async (_, { headers }) => ({ headers: await augmentHeaders(headers || {}) }));

const restLink = new RestLink({
  uri: backendUrl(),
  bodySerializers: {
    fileEncode: (data: any, headers: Headers) => {
      headers.set('Accept', '*/*');
      augmentHeaders(headers);
      return { body: convertToFormData(data), headers };
    },
  },
  credentials: 'include',
  // customFetch: async (uri, options) => {
  //   console.log(uri, options);
  //   const response = await fetch(uri, options);
  //   Object.defineProperty(response, 'ok', {
  //     writable: true,
  //   });
  //   // @ts-ignore
  //   const r2 = response.clone();
  //   console.log(response);
  //   return {
  //     ok: true,
  //     body: response.body,

  //   };
  // },
});

const cache = new InMemoryCache({
  dataIdFromObject: (object: any) => {
    switch (object.__typename) {
      case 'SuggestedPersonalDataCategory':
        return `SPDC:${object.name}-${object.count}`;
      case 'UserOrganization':
        return `UserOrganization:${object.user_id}-${object.organization_id}-${object.role_id}`;
      case 'PRFile':
        return `PRFile:${object.md5}-${object.path}`; // Path is incase same file uploaded to multiple systems
      case 'OrganizationRolePermission':
        return `OrganizationRolePermission:${object.organization_id}-${object.role_id}-${object.permission_id}`;
      case 'SystemTemplate':
        return `SystemTemplate:${object.name}`;
      default:
        return defaultDataIdFromObject(object);
    }
  },
});

export const client = new ApolloClient({
  cache,
  link: ApolloLink.from([authLink, restLink]),
  typeDefs: [],
});

export * from './organizations';
