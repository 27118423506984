import clsx from 'clsx';
import React, {
  useCallback,
  useState,
} from 'react';
import './RawJsonEditor.scss';

export type RawJsonEditorProps = {
  name: string
  value: any
  onChange: (ev: any) => (void)
  type?: 'object' | 'string'
  style?: any
};

export const RawJsonEditor = ({
  name,
  value,
  onChange,
  type,
  style,
  ...rest
}: RawJsonEditorProps) => {
  const [state, setState] = useState<string | undefined>(JSON.stringify(value, null, 2));
  const [valid, setValid] = useState(true);

  const _onChange = useCallback(({ target: { name, value } }) => {
    setState(value);
    try {
      const obj = JSON.parse(value === '' ? null : value);
      setValid(true);
      onChange({ target: { name, value: obj } });
      setState(undefined);
    } catch (err) {
      // Wait until it's a valid object
      setValid(false);
    }
  }, [onChange]);

  return (
    <div className={clsx('json-editor-wrapper', style, { valid })}>
      <textarea
        name={name}
        spellCheck={false}
        value={state}
        onChange={_onChange}
        {...rest}
      />
    </div>
  );
};
