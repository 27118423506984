import React, { useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  NoAuthorizationTemplate, Text, ErrorText, Button, ButtonLink, Field, LoadingProvider, LoadingIndicator,
} from '@privacy-request/ui';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { Link } from '../../components/Link';
import {
  GetAuthenticationStrategies, GetAuthenticationStrategiesResult, GetAuthenticationStrategiesVariables,
} from '../../apollo/auth/local.queries';
import Divider from '../../components/Divider';
import { loginRedirect } from '../../config/msal';
import { useSelector } from '../../store/useSelector';
import LocalAuthView from './components/LocalAuthView';

export const PrivacyLink = styled.a`
  font-size: 12px;
  margin: 0 6px;
  color: ${({ theme }) => theme.CTA_COLOR};

  &:visited {
    color: ${({ theme }) => theme.CTA_COLOR};
  }
`;
PrivacyLink.defaultProps = {
  target: '_blank',
  rel: 'noreferrer',
};

export default () => {
  const authorized = useSelector(s => s.auth.authorized);
  const authorizing = useSelector(s => s.auth.authorizing);
  const error = useSelector(s => s.auth.error);
  const domain = window.location.hostname.substr(0, window.location.hostname.indexOf('.'));
  const { data, loading } = useQuery<GetAuthenticationStrategiesResult, GetAuthenticationStrategiesVariables>(GetAuthenticationStrategies, { variables: { domain } });

  const [t] = useTranslation('auth');
  const aadLogin = useCallback(() => {
    loginRedirect();
  }, []);

  if (authorized) {
    return <Redirect to="/" />;
  }

  return (
    <NoAuthorizationTemplate>
      <LoadingProvider>
        {loading && <LoadingIndicator />}
        {error && (
          <Field>
            <ErrorText>{t(`common:errors.${error}`)}</ErrorText>
          </Field>
        )}
        { error !== 'password_expired' && (<LocalAuthView />) }
        { data?.result?.local && error === 'password_expired' && (
          <Field>
            <Link to="/login/request-password-reset">
              <Text style={{ fontSize: '16px', textAlign: 'center' }}>
                {t('login.reset_your_password')}
              </Text>
            </Link>
          </Field>
        ) }

        { data?.result?.local && (data?.result?.azure || data?.result?.strategies?.length > 0) && (<Divider style={{ margin: '2em' }} />) }

        { data?.result?.azure && (
          <Field>
            <Button secondary onClick={aadLogin} disabled={authorizing} fluid>{authorizing ? t('common:form.looking_up') : t('form.microsoft')}</Button>
          </Field>
        ) }
        { data?.result?.strategies?.map(({ name, login_url }, i) => (
          <Field>
            <ButtonLink secondary fluid href={login_url}>{name}</ButtonLink>
          </Field>
        )) }
        <Field style={{
          paddingBottom: 0, display: 'flex', justifyContent: 'center',
        }}
        >
          <PrivacyLink href="https://privacyrequest.com/privacy-policy">{t('privacy_policy')}</PrivacyLink>
          <Text style={{ transform: 'translateY(-2px)' }}>|</Text>
          <PrivacyLink href="https://privacyrequest.com/acceptable-use-policy">{t('applicable_use')}</PrivacyLink>
        </Field>
      </LoadingProvider>
    </NoAuthorizationTemplate>
  );
};
