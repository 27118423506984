import React, { useMemo } from 'react';
import { TableCell, ResizableTableHeader } from '@privacy-request/ui/src/atoms/SASSTable';
import {
  FieldBuilderComponent,
  FieldBuilderProps,
  FieldHeaderComponent,
  FieldHeaderProps,
} from './types/ColumnDefinition';
import { LinkedTableCell } from '../../LinkedTableCell/LinkedTableCell';
import { sortProps } from '../../../hooks/useQueryPager/pagerUtils';

export const Header: FieldHeaderComponent = ({
  sort,
  onSortChange,
  t,
}: FieldHeaderProps) => (
  <ResizableTableHeader key="name" {...sortProps('name', sort, onSortChange)}>{t('table.system')}</ResizableTableHeader>
);

export const Cell: FieldBuilderComponent = ({
  getRowLink,
  onRowClick,
  row,
  archived,
}: FieldBuilderProps) => {
  const content = useMemo(() => (
    <strong>
      {
        ([] as any).concat(row.name).join(', ')
      }
    </strong>
  ), [row.name]);
  if (!archived && getRowLink(row)) {
    return (
      <LinkedTableCell
        key="name"
        to={getRowLink(row)}
        onClick={onRowClick}
      >
        {content}
      </LinkedTableCell>
    );
  }
  return (
    <TableCell
      key="name"
      onClick={onRowClick}
    >
      {content}
    </TableCell>
  );
};

export const fields = ['name'];
