import {
  NoAuthorizationTemplate, Label, Input, ErrorText, Button, Field, Text,
} from '@privacy-request/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useForgotOrganization } from './hooks/useForgotOrganization';

const Form = styled.form`
  margin-top: 16px;
`;
Form.displayName = 'Form';

export default () => {
  const {
    onSubmit,
    onChange,
    options,
    loading,
    result,
  } = useForgotOrganization();

  const [t] = useTranslation('auth');
  const history = useHistory();
  const back = async () => {
    history.push('/');
  };

  if (result?.error) {
    return (
      <NoAuthorizationTemplate noLogo>
        <Field>
          <Text>{t(`forgot_organization.${result.message}`)}</Text>
        </Field>
        <Field>
          <Button onClick={onSubmit} disabled={loading} fluid>{t('common:form.try_again')}</Button>
        </Field>
        <Field style={{ paddingBottom: 0 }}>
          <Button disabled={loading} onClick={back} secondary fluid>{t('common:form.cancel')}</Button>
        </Field>
      </NoAuthorizationTemplate>
    );
  }

  return (
    <NoAuthorizationTemplate noLogo>
      {result && result.error && (
        <Field style={{ paddingBottom: 0 }}>
          <ErrorText>{t(`forgot_organization.${result.message}`)}</ErrorText>
        </Field>
      )}
      {result && result.error === false && (
        <Field>
          <Text>{t(`forgot_organization.${result.message}`)}</Text>
        </Field>
      )}
      { (!result || result.message !== 'success') && (
        <Form onSubmit={onSubmit}>
          <Field>
            <Text>{t('forgot_organization.hint')}</Text>
          </Field>
          <Field>
            <Label>
              {t('form.email')}
              :
            </Label>
            <Input
              autoFocus
              name="email"
              value={options.email || ''}
              type="email"
              onChange={onChange}
            />
          </Field>

          <Field>
            <Button onClick={onSubmit} disabled={loading} type="submit" fluid>{loading ? t('common:form.looking_up') : t('common:form.continue')}</Button>
          </Field>
          <Field style={{ paddingBottom: 0 }}>
            <Button onClick={back} disabled={loading} secondary fluid>{t('common:form.cancel')}</Button>
          </Field>
        </Form>
      )}
    </NoAuthorizationTemplate>
  );
};
