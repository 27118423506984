import update from 'immutability-helper';
import { System } from '../../types/system/System';
import { SystemActionTypes } from './system.actions';
import {
  SET_SYSTEM, CHANGE_SYSTEM, SET_SYSTEM_ERRORS,
} from './system.types';

interface SystemState {
  system: Partial<System>
  isDirty: boolean
  errors: any
}

const previousState = null;
// let previousState: SystemState | string | null = sessionStorage.getItem('system_reducer');

// if (previousState && process.env.NODE_ENV === 'development') {
//   previousState = JSON.parse(previousState) as SystemState;
// } else {
//   previousState = null;
// }

const initialState: SystemState = previousState || {
  system: {},
  errors: undefined,
  isDirty: false,
};

export default (state = initialState, action: SystemActionTypes): SystemState => {
  const nextState = (() => {
    switch (action.type) {
      case SET_SYSTEM:
        return update(state, {
          system: { $set: action.payload },
          isDirty: { $set: false },
          errors: { $set: undefined },
        });
      case CHANGE_SYSTEM:
        return update(state, {
          system: { $set: action.payload },
          isDirty: { $set: Object.keys(action.payload || {}).length > 0 },
        });
      case SET_SYSTEM_ERRORS:
        return update(state, { errors: { $set: action.payload } });
      default:
        return state;
    }
  })();

  sessionStorage.setItem('system_reducer', JSON.stringify(nextState));

  return nextState;
};
