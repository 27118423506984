import gql from 'graphql-tag';
import { User } from '../../types';
import { Organization } from '../../types/organization/Organization';
import { AddressFragment } from '../address/address.fragments';

/** @deprecated */
export const OrganizationFragment = gql`
  fragment organization on Organization {
    id
    ...address
    company_name
    phone
    azure_tenant_id
    timezone
    framework_context
    god_mode
    privacy_email
    use_privacy_email
    domain
    created_at
    is_active
    saml
    assets_last_updated
    ms_graph_delta
    intake_form
    features
    settings
    communication_preferences
    private_storage_config
    users @type(name: "User") {
      id
      first_name
      last_name
      email
      phone
    }
  }

  ${AddressFragment}
`;

export interface GetUserOrgsData {
  user_orgs: Organization[]
}

export const GetUserOrgs = gql`
  query user_orgs($email: String!) {
    user_orgs(email: $email) @rest(type: "[Organization]", path: "users/{args.email}/organizations") {
      id
      domain
    }
  }
`;

export interface GetOrganizationsData {
  organizations: Organization[]
}

export const GetOrganizations = gql`
  query organizations {
    organizations @rest(type: "[Organization]", path: "organizations") {
      ...organization
    }
  }
  ${OrganizationFragment}
`;

export interface GetOrganizationData {
  organization: Organization
}

export interface GetOrganizationVariables {
  id: number
}

export const GetOrganization = gql`
  query organization($id: Int) {
    organization(id: $id) @rest(type: "Organization", path: "organizations/{args.id}") {
      ...organization
    }
  }
  ${OrganizationFragment}
`;

export interface UsersPaged {
  count: number
  rows: User[]
}

export interface GetUsersData {
  get_users: UsersPaged
}

export interface GetUsersVariables {
  id: number
}

export const GetUsers = gql`
  query get_organization_users($limit: Int!, $offset: Int!, $filter: String!, $id: Int!) {
    get_organization_users(limit: $limit, offset: $offset, filter: $filter, id: $id) @rest(type: "UsersPaged", path: "organizations/{args.id}/users?{args}") {
      rows @type(name: "User") {
        email
        enabled
        first_name
        god_mode
        id
        last_name
        phone
        require_mfa
        settings
      }
      count
    }
  }
`;
