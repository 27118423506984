import React, {
  MouseEventHandler,
  useContext,
} from 'react';
import {
  Caret,
  Chip,
  Dropdown,
  TrashIcon,
} from '@privacy-request/ui';
import { TFunction, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { startCase } from 'lodash';
import { PipelineDebugStatus, usePipelineDebugger } from './usePipelineDebugger';
import { HotkeyContext } from '../HotkeyProvider/HotkeyContext';
import { PipelineLogsDisplay } from './PipelineLogs';
import { PipelineStep } from '@privacy-request/basic-types';
import { Tag } from '../Tag/Tag';

export const stepNameToTitle = (t: TFunction, name: string) => (
  t(`integrations:steps.${name}.title`, startCase(name))
);

interface StatusIndicatorProps {
  status?: PipelineDebugStatus['status']
}

const evaluateColor = ({ status }: StatusIndicatorProps) => {
  switch (status) {
    case 'running':
      return 'orange';
    case 'fatal':
    case 'error':
      return 'red';
    case 'success':
      return 'green';
    case 'waiting':
    default:
      return '#ccc';
  }
};

const StatusIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-right: 16px;
  background-color: ${evaluateColor};
`;

const StepHandler = styled.div<StatusIndicatorProps>`
  background: white;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  padding: 8px 16px;
  cursor: pointer;
  min-height: 32px;
  border: 1px solid ${evaluateColor};
  border-radius: 4px;
  box-shadow: 0px 5px 4px -4px rgba(0,0,0,0.2);
  width: 656px;

  .trash {
    path {
      fill: #ddd !important;
      transition: fill 0.15s ease-in-out;
    }
  }

  &:hover .trash {
    path {
      fill: ${({ theme }) => (theme.CTA_WARN)} !important;
    }
  }

  .trash:hover {
    path {
      fill: ${({ theme }) => (theme.CTA_ERROR)} !important;
    }
  }
`;

export type PipelineStepHeaderProps = {
  form: any
  config: any
  step: PipelineStep
  open: boolean
  integration: any
  onClick: (e: any) => void
  onChange: (name: string, value: any) => void
  onDelete: MouseEventHandler<SVGSVGElement>
};

export const PipelineStepHeader = React.memo(({
  form,
  config,
  step,
  open,
  integration,
  onClick,
  onChange,
  onDelete,
}: PipelineStepHeaderProps) => {
  const [t] = useTranslation();
  const { state } = useContext(HotkeyContext);
  const { debug: { steps } } = usePipelineDebugger();
  const debug = steps[step.id];

  if (!integration) {
    return (<div />);
  }

  if (!step.handler) {
    return (
      <div style={{ width: '690px' }}>
        <Dropdown
          items={integration.handlers.map((name: string) => ({ text: stepNameToTitle(t, name), value: name }))}
          onChange={({ target: { value } }) => (onChange('handler', value))}
          placeholder="Click to set..."
        />
      </div>
    );
  }

  return (
    <>
      <StepHandler status={debug?.status} onClick={onClick}>
        <div style={{
          display: 'flex', flexGrow: 1, alignItems: 'center', height: '100%',
        }}
        >
          {debug && (
            <StatusIndicator status={debug.status} />
          )}
          <span>
            {step.name || stepNameToTitle(t, step.handler)}
            {state.debug && (
              <code style={{ fontWeight: 100, marginRight: '6px' }}>
  &nbsp;&nbsp;#
                {step.id}
              </code>
            )}
          </span>
          <TrashIcon style={{ marginRight: 'auto' }} height={24} width={24} className="trash" onClick={onDelete} />
          {debug?.after && (
            // eslint-disable-next-line no-console
            <Chip color="#f3e9da" onClick={(e: any) => { e.stopPropagation(); console.log(debug.after); }}>
              Step Result
            </Chip>
          )}
          {debug?.status === 'fatal' && (
            <Chip color="red" textColor="white">
              FATAL SERVER ERROR
            </Chip>
          )}
          <Tag style={{ float: 'right' }} mute={!step.sampler} clickable onClick={() => (onChange('sampler', !step.sampler))}>SAMPLE</Tag>
        </div>

        {form && config ? (<Caret expanded={open} />) : null}
      </StepHandler>
      {!!debug?.logs?.length && (
        <PipelineLogsDisplay logs={debug?.logs} />
      )}
    </>
  );
});
