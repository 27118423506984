import { List, ListHead } from '@privacy-request/ui';
import React from 'react';
import { System } from '../../../types/system/System';
import { ProcessingActivity } from '../../../types/system/ProcessingActivity';
import { SystemRelatedSystem } from '../../../types/system/SystemRelatedSystems';
import { SystemProcessingActivity } from '../../../types/system/SystemProcessingActivity';
import { useDataPager } from '../../../hooks/useDataPager/useDataPager';
import { RelatedDataMapItemListItem } from './RelatedDataMapItemListItem';
import { NoEntitiesFoundDisplay } from '../../NoEntitiesDisplay/NoEntitiesFoundDisplay';
import { getTarget } from './utils/getTarget';

export type RelatedDataMapItemListProps = {
  /**
   * The data mapping item currently being viewed
   */
  currentItem: System | ProcessingActivity
  /**
   * The relations to display as rows
   */
  relations: (SystemRelatedSystem | SystemProcessingActivity | ProcessingActivity | System)[]
  /**
   * The id of the currently logged-in user
   */
  userId?: number
  /**
   * True if the current user can edit these relations
   */
  canEdit?: boolean
  /**
   * True if the current user can list data map items that they aren't an owner of. This determines whether or not they're allowed to navigate through to the related item
   */
  canListOthers?: boolean

  /**
   * If given, will navigate to the page that represents the data map item that
   * was clicked, if the user has permission to view it
   */
  onClick?: (target: System | ProcessingActivity) => () => void
  /**
   * If given, will cause a pencil icon to appear at the end of the row
   */
  onEdit?: any
  /**
   * If given, will cause a trash icon to appear at the end of the row
   */
  onDelete?: any
  /**
   * If given, will cause an "Add New" button to display at the bottom of the list
   */
  CreateButton?: any

  showLogos?: boolean
};

export const RelatedDataMapItemList = ({
  currentItem,
  relations,
  userId,
  canEdit,
  canListOthers,
  onClick,
  onEdit,
  onDelete,
  CreateButton,
  showLogos,
}: RelatedDataMapItemListProps) => {
  const { results, Pager } = useDataPager(relations);

  return (
    <div style={{ userSelect: 'none' }}>
      <List>
        <ListHead style={{ height: 0 }} />
        {
          results.map((relation, i) => {
            const target = getTarget(relation, currentItem);
            const canNavigate = canListOthers || !!target.contacts?.find((c: any) => c.user_id === userId);
            // @ts-expect-error
            const key = relation.id || `${relation.system_id}-${relation.related_system_id}`;
            return (
              <RelatedDataMapItemListItem
                key={key}
                currentItem={currentItem}
                relation={relation}
                onClick={canNavigate ? onClick : undefined}
                onEdit={onEdit}
                onDelete={onDelete}
                showLogos={showLogos}
              />
            );
          })
        }
        {
          results.length === 0 && (
            <NoEntitiesFoundDisplay
              to="/add/assets"
              secondary
              noEntities
              noEntitiesKey={canEdit ? 'systems:table.no_assets_related' : 'systems:table.no_assets_related_unauthorized'}
            />
          )
        }
        <Pager />
      </List>
      {CreateButton && (CreateButton)}
    </div>
  );
};
