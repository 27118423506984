import React from 'react';
import {
  Field,
  Label,
  SearchAndSelect,
  SearchAndSelectItem,
} from '@privacy-request/ui';
import { get } from 'lodash';
import { CustomFieldDefinition } from '../CustomFieldDefinition';
import { CustomFieldOptions } from '../CustomFieldOptions';
import { getRest } from '../utils/getRest';

const _onChange = (config: CustomSearchAndSelectField, onChange: any) => (ev: any) => {
  if (config.noarray) {
    // eslint-disable-next-line prefer-destructuring
    ev.target.value = ev.target.value[0];
  }
  return onChange(ev);
};

export interface CustomSearchAndSelectField extends CustomFieldOptions {
  type: 'search-and-select'
  label?: string
  name: string
  multi?: boolean
  /**
   * If true, converts the result from an array to a scalar
   */
  noarray?: boolean
  disabled?: boolean
  items: SearchAndSelectItem[]
}

export const searchAndSelect: CustomFieldDefinition<CustomSearchAndSelectField> = {
  type: 'search-and-select',
  component: (config, { onChange, values }) => (
    <SearchAndSelect
      items={config.items}
      floatingBoxStyle={{ maxHeight: '300px' }}
      name={config.name}
      onChange={_onChange(config, onChange)}
      disabled={config.disabled}
      value={get(values, config.name) || []}
      multi={config.multi}
      {...getRest(config)}
    />
  ),
  wrapper: (config, {
    first,
    last,
    fluid,
    content,
  }) => (
    <Field fluid={fluid} last={last} first={first} key={config.name}>
      {config.label && (<Label>{config.label}</Label>)}
      {content}
    </Field>
  ),
};
