import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  getChangedFields, PropertyDiff, TransformerProps, commonRelationTransformerFactory, contactAddedTransformerFactory, tagitemRelationTransformerFactory,
} from '../../../../../components/AuditLogView/common-transformers';
import { AuditLog } from '../../../../../types/audit/AuditLog';
import { ProcessingActivity } from '../../../../../types/system/ProcessingActivity';

const fields: (keyof ProcessingActivity)[] = [
  'name', 'description', 'dpia_completed', 'dpia_reason', 'dpia_reason_date',
  'dpia_completed_at', 'retention_policy', 'published',
  'data_retention_period',
];

/**
 * Display an audit message for a request-related email that was sent to a user.
 */
export const ProcessingActivityChange = ({ log, isISO }: TransformerProps) => {
  const [t] = useTranslation('common');
  let message;

  if (log.action === 'CREATE') {
    message = t(`systems:audit.${isISO ? 'service' : 'processing_activity'}.created`, { name: log.updated_values.name });
  } else if (log.previous_values.status !== log.updated_values.status) {
    message = (
      <span>
        {t(`systems:audit.processing_activity.status_change.${log.previous_values.status}.${log.updated_values.status}`)}
      </span>
    );
  } else if (log.previous_values.approver_id !== log.updated_values.approver_id) {
    message = t(`systems:audit.${isISO ? 'service' : 'processing_activity'}.approved`);
  } else if (log.action === 'UPDATE') {
    message = t(`systems:audit.${isISO ? 'service' : 'processing_activity'}.updated`, { count: getChangedFields(log, fields).length });
  }

  return PropertyDiff({
    log,
    message,
    fields,
    t,
    fieldPrefix: 'systems:audit.processing_activity.field',
  });
};
ProcessingActivityChange.evaluate = (log: AuditLog) => (
  log.table_name === 'processing_activities'
);

/**
 * Display an audit message for a services assignment to a system.
 */
const systemAttributes = { system_processing_activity: 'system_relations' } as any;
export const SystemRelationUpdated = commonRelationTransformerFactory(systemAttributes, 'systems:audit.processing_activity.relation');

/**
 * Display an audit message for a purpose of processing assignment to a PA
 */
export const ContactAddedToPA = contactAddedTransformerFactory('processing_activity_contacts');

/**
 * Display a created or updated tagitem
 */
export const TagitemRelationUpdated = tagitemRelationTransformerFactory('processing_activity_tagitems', 'systems:audit.processing_activity.relation');
