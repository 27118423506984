import clsx from 'clsx';
import React from 'react';
import './Banner.scss';

export type BannerProps = {
  children: React.ReactNode
  className?: string
  yellow?: boolean
};

export const Banner = ({
  children,
  className,
  yellow,
}: BannerProps) => (
  <div className={clsx('banner', className, { yellow })}>{ children }</div>
);
