/**
 * After introducing soft deletes and an INDEX on many `entity.name` fields,
 * we had to prepend [deleted:<timestamp>] to entities. Sometimes these entities
 * still need to be shown on the front-end. If that is the case, use this function
 * to strip any nasty residual deletion gunk away!
 *
 * @param name The entity name, potentially with our [deleted:<timestamp>] string at the start.
 * @returns The entity name, without the [deleted:<timestamp>] subtext.
 */
export const stripDeleted = (name: string) => name.replace(/\[deleted:\d+\]/, '');
