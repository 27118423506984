import { useMutation, useQuery } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';
import update from 'immutability-helper';
import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  CreateRequest, CreateRequestVariables, UpdateRequest, UpdateRequestVariables,
} from '../../../apollo/request/requests.mutations';
import {
  GetRequest, GetRequestData, GetRequestVariables,
} from '../../../apollo/request/requests.queries';
import { changeRequest, setRequest as clearRequest } from '../../../store/request/request.actions';
import { useSelector } from '../../../store/useSelector';
import { Request } from '../../../types/request/Request';
import { RequestListRefreshContext } from '../RequestsList';

interface EditRequestOptions {
  poll?: boolean
  pollIntervalOverride?: number
  updateMutation?: DocumentNode
}

/**
 * Helpers to easily edit a request.
 *
 * If you specify ID, we are assuming you are not in a request view,
 * and will not fetch the request from the server.
 *
 * Use Case:
 *   - `RequestListItem.tsx`, we do not want to fetch every item in the list,
 *     but we still want update functionality!
 *
 * @param _id The ID of the request
 */
export const useEditRequest = (_id?: number, options: EditRequestOptions = { poll: false, pollIntervalOverride: undefined }) => {
  const { poll } = options;
  const { id = _id } = useParams<any>();
  const onRefetch = useContext(RequestListRefreshContext);
  const requestEdits = useSelector(s => s.request.request);
  const isDirty = useSelector(s => s.request.isDirty);
  const [t] = useTranslation('requests');
  const dispatch = useDispatch();
  const [updateRequest, { loading: updating }] = useMutation<any, UpdateRequestVariables>(options.updateMutation || UpdateRequest);
  const [createRequest, { loading: creating }] = useMutation<any, CreateRequestVariables>(CreateRequest);
  const skip = !!_id || id === 'new' || !id;
  const { addToast } = useToasts();
  const [shouldPoll, setShouldPoll] = useState(false);

  const {
    data, loading, refetch,
  } = useQuery<GetRequestData, GetRequestVariables>(GetRequest, {
    variables: { id },
    skip,
    pollInterval: options.pollIntervalOverride || (shouldPoll ? 3500 : undefined),
  });

  const status = data?.get_request.status;
  useEffect(() => {
    if (!status || !poll) {
      return;
    }

    setShouldPoll(true);
  }, [poll, status]);

  const [request, setRequest] = useState<Partial<Request>>({ ...(data?.get_request || {}), ...requestEdits });

  useEffect(() => {
    if (!loading && data) {
      setRequest({ ...data.get_request, ...requestEdits });
    } else {
      setRequest({ ...requestEdits });
    }
  }, [loading, requestEdits, data, skip]);

  const onSave = useCallback(async (additionalEdits?: Partial<Request>, opts?: any) => {
    const edits = {
      ...requestEdits,
      ...additionalEdits,
    };

    let result;
    if (id !== 'new') {
      result = await updateRequest({
        variables: {
          request: edits,
          id,
        },
      });
    } else {
      result = await createRequest({ variables: { request: edits } });
    }

    if (opts && !opts.skipToast) {
      addToast(t('request_updated'), {
        appearance: 'success',
        autoDismiss: true,
      });
    }

    dispatch(clearRequest({}));

    onRefetch();

    return result;
  }, [updateRequest, createRequest, requestEdits, id, onRefetch, dispatch, addToast, t]);

  const onRevert = useCallback(() => {
    dispatch(clearRequest({}));
  }, [dispatch]);

  const onChange = useCallback((e: any) => {
    const { name, value } = e.target;

    const changes = { [name]: { $set: value } };

    const nextRequest = update(requestEdits, changes);
    setRequest({ ...data?.get_request, ...nextRequest });
    dispatch(changeRequest(nextRequest));
  }, [requestEdits, dispatch, data]);

  return {
    saving: updating || creating,
    isDirty,
    loading,
    request,
    onSave,
    onRevert,
    onChange,
    refetch,
  };
};
