import {
  Label, Field, Input, Text, EditIcon,
} from '@privacy-request/ui';
import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { userToName } from '@privacy-request/common/src/utils/userToName';
import { GetUsers } from '../../apollo/users/users.queries';
import { GraphQLSearchAndSelect } from '../../components/GraphQLSearchAndSelect';
import { User } from '../../types';
import { SystemContact } from '../../types/system/SystemContact';
import evt from '../../utils/evt';
import { validateEmail } from '../../utils/validateEmail';
import { useRouting } from '../useRouting';
import { useMe } from '../useMe';

export const useDataMappingContactEdit = (contacts: any[], loading: boolean, onChange: any, entity_id: number, entity_key: string, onSave: (e: any) => Promise<any>, isProcessingActivity: boolean = false) => {
  const [choice, setChoice] = useState<'self' | 'other' | ''>('');
  const { goBack } = useRouting();
  const { addToast } = useToasts();
  const [t] = useTranslation('systems');
  const [createUser, setCreateUser] = useState('');
  const {
    me,
    isISO,
    loading: profileLoading,
  } = useMe();

  useEffect(() => {
    if (!!choice || !!profileLoading || !!loading || !me || !contacts) {
      return;
    }

    if (contacts.find(sc => sc.user_id === me.id)) {
      setChoice('self');
    } else if (contacts.length) {
      setChoice('other');
    }
  }, [me, contacts, loading, profileLoading, choice]);

  const onOwnerChange = useCallback((users: User[], invite?: boolean) => {
    if (!users.length) {
      return onChange(evt('contacts', []));
    }

    if (contacts && contacts.length && contacts[0].user_id === users[0].id) {
      return;
    }

    const sendInvite = typeof invite === 'boolean' ? invite : choice === 'other';

    onChange(evt<Partial<SystemContact>[]>('contacts', [{
      user_id: users[0].id,
      user: users[0],
      type: 'Owner',
      [entity_key]: entity_id,
      invite: sendInvite,
    }]));
  }, [onChange, entity_id, entity_key, contacts, choice]);

  const onRadioChange = useCallback((e: any) => {
    setChoice(e.target.value);
    if (e.target.value === 'self' && !!me) {
      onOwnerChange([me], false);
    }
    if (e.target.value === 'other') {
      onOwnerChange([], false);
    }
  }, [onOwnerChange, me]);

  const onUserCreated = useCallback(async (user: Partial<User>) => {
    await onSave({
      contacts: [{
        user_id: user.id,
        create_user: user,
        user,
        type: 'Owner',
        [entity_key]: entity_id,
      }],
    });
    goBack();
  }, [entity_key, entity_id, goBack, onSave]);

  const onCreateUser = useCallback((term: string) => {
    if (validateEmail(term)) {
      setCreateUser(term);
    } else {
      addToast(t('pa.workflow.email_format_matcher'), {
        autoDismiss: true,
        appearance: 'error',
      });
    }
  }, [t, addToast]);

  const NameInput = useMemo(() => (
    <div>
      <Label style={{ marginTop: '12px' }}>
        {t('common:fields.name')}
        :
      </Label>
      <span style={{ display: createUser ? 'none' : 'block' }}>
        <GraphQLSearchAndSelect
          add
          addText={(search) => t('workflow.not_found_invite', { search })}
          onCreate={onCreateUser}
          query={GetUsers}
          formatItem={(u: User) => ({
            text: userToName(u),
            value: u.id,
            search: u.email,
          })}
          value={(choice === 'other' && (contacts || []).map(c => c.user)) || []}
          onChange={onOwnerChange}
        />
      </span>
      <span style={{ display: !createUser ? 'none' : 'block' }}>
        <Field style={{
          width: '100%', paddingLeft: '0px', display: 'inline-flex',
        }}
        >
          <Input
            search
            value={createUser}
            disabled={createUser.length > 0}
          />
          <Text style={{ margin: '5px 5px' }} onClick={() => setCreateUser && setCreateUser('')}><EditIcon /></Text>
        </Field>
      </span>
    </div>
  ), [choice, contacts, createUser, onCreateUser, onOwnerChange, t]);

  const options = useMemo(() => ['self', 'other'].map(type => ({
    value: type,
    text: t(`workflow.confirm_ownership.${type}.title`),
    subtext: t(`workflow.confirm_ownership.${type}.desc${isProcessingActivity ? '_pa' : ''}${(isISO && isProcessingActivity) ? '_service' : ''}`),
    extra: type === 'other' ? NameInput : null,
  })), [t, isProcessingActivity, isISO, NameInput]);

  return {
    options,
    onRadioChange,
    choice,
    onUserCreated,
    createUser,
    NameInput,
  };
};
