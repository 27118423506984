import React from 'react';
import { EntityContactsEdit } from '../../../../components/EntityContactsEdit/EntityContactsEdit';
import { useContextualActivity } from '../../hooks/useActivityContext';

export const ProcessingActivityContacts = () => {
  const {
    current: processingActivity, original: originalProcessingActivity, onChange, canEditPerm,
  } = useContextualActivity();

  return (
    <EntityContactsEdit
      contacts={processingActivity.contacts}
      original_contacts={originalProcessingActivity?.contacts}
      onChange={onChange}
      name="contacts"
      contactKey="processing_activity_id"
      canEdit={canEditPerm('processing_activities.edit.contacts')}
      disabled={!canEditPerm('processing_activities.edit.contacts')}
    />
  );
};
