import React, {
  useCallback,
  useState,
} from 'react';
import { ComplexFilterDropdown } from '../ComplexFilterDropdown/ComplexFilterDropdown';
import {
  Button,
  ChevDown,
  ComponentBar,
  ComponentBarItem,
  Grid,
  Input,
  StandardDropdown,
} from '@privacy-request/ui';
import { useTranslation } from 'react-i18next';
import omit from '../../utils/omit';
import { Dispatch, SetStateAction } from '../../types/SetStateAction';
import { FilterComponent } from '../ComplexFilterDropdown/FilterComponent.d';
import { BulkActionController } from '../DataMapEntityList/bulk_actions/useBulkActionController';
import { BulkActionField } from '../DataMapEntityList/bulk_actions/BulkActionField';

/**
 * Wait `ms` milliseconds after the last keystroke before making a call
 * @param ms {Number} - The number of milliseconds to wait
 */
export const useThrottleInput = (cb: Function, ms: number) => {
  const [timeoutId, setTimeoutId] = useState<number>();

  return useCallback((...args: any[]) => {
    clearTimeout(timeoutId);
    setTimeoutId(setTimeout(() => (cb(...args)), ms) as any);
  }, [cb, ms, timeoutId]);
};

export type DataMapEntityListFilterParProps = {
  filters: FilterComponent[]
  filterData: any
  setFilterData: SetStateAction<any>
  bulkActionsController: BulkActionController
};

export const DataMapEntityListFilterBar = ({
  filters,
  filterData,
  setFilterData,
  bulkActionsController,
}: DataMapEntityListFilterParProps) => {
  const [t] = useTranslation('systems');

  const _setFilterData: Dispatch<SetStateAction<any>> = (cb) => {
    const result = cb(filterData);
    if (result.published === false && result.draft === false && !result.archived) {
      // Default to published+draft if none are selected
      return setFilterData(omit(result, 'published', 'draft'));
    }
    return setFilterData(result);
  };

  const onChangeSearchFilter = useCallback((q: string) => {
    setFilterData((filterData: any) => ({
      ...filterData,
      q,
    }));
  }, [setFilterData]);
  const throttledOnChangeSearchFilter = useThrottleInput(onChangeSearchFilter, 500);

  return (
    <ComponentBar>
      <Grid innerStyle={{ justifyContent: 'space-between' }}>
        <ComponentBarItem>
          <Input
            search
            placeholder={t('common:form.search')}
            defaultValue={filterData.q || ''}
            onChange={(e) => throttledOnChangeSearchFilter(e.target.value)}
            style={{ width: '220px' }}
          />
          <BulkActionField {...bulkActionsController} />
        </ComponentBarItem>

        {filters.length > 0 && (
          <ComponentBarItem>
            <StandardDropdown contentBoxProps={{ style: { padding: 0 } }} className="filter-dropdown">
              <Button secondary nav>
                Filters
                &nbsp;
                (5)
                <ChevDown />
              </Button>
              <ComplexFilterDropdown
                filters={filters}
                filterData={filterData}
                setFilterData={_setFilterData}
              />
            </StandardDropdown>
          </ComponentBarItem>
        )}
      </Grid>
    </ComponentBar>
  );
};
