import { CustomFieldsContext } from '../CustomFields';

export const opts = (
  values: any,
  onChange: any,
  last: boolean,
  first: boolean,
  fluid: boolean,
  mask:string,
  errors: any,
  t: any,
  i18n: any,
  ctx?: CustomFieldsContext,
) => ({
  values,
  onChange,
  last,
  first,
  fluid,
  mask,
  errors,
  t,
  i18n,
  ctx,
});
