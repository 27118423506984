import React, { useEffect, useCallback } from 'react';

export default (ref: any, cb: any, debug = false) => {
  useEffect(() => {
    const handler = (ev: any) => {
      if (!ref.current) {
        debug && console.log('No ref');
        return;
      }

      if (ref.current.contains(ev.target)) {
        debug && console.log('Ref contains target');
        return;
      }

      cb(false, ev);
    };

    window.addEventListener('mouseup', handler);
    return () => window.removeEventListener('mouseup', handler);
  }, [ref]);
};
