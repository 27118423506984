import gql from 'graphql-tag';

export const TagItemFragment = gql`
  fragment tagitem on TagItem {
    id
    organization_id
    name
    type
    count
  }
`;
