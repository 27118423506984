import React from 'react';
import styled from 'styled-components';
import { SVGComponent } from './SvgProps';
import { Svg } from './utils';

const Path = styled.path`
  fill: ${({ color, fill }) => (color || fill)};
  transition: fill 0.3s;
`;

export const ClipboardIcon: SVGComponent = ({
  fill, color, ...props
}) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
    <Path fill={fill} color={color} d="M17,9H7V7H17M17,13H7V11H17M14,17H7V15H14M12,3A1,1 0 0,1 13,4A1,1 0 0,1 12,5A1,1 0 0,1 11,4A1,1 0 0,1 12,3M19,3H14.82C14.4,1.84 13.3,1 12,1C10.7,1 9.6,1.84 9.18,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3Z" />
  </Svg>
);

ClipboardIcon.defaultProps = {
  width: 16,
  height: 16,
  fill: '#454543',
};
