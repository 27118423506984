import { bindMethods } from '@privacy-request/utils';
import { GlobalDirtySaveRevertRegistration } from './GlobalDirtySaveRevertRegistration';
import { Publisher } from './Publisher';

export class GlobalDirtySaveRevertManager implements Publisher {
  public registrations: GlobalDirtySaveRevertRegistration[] = [];
  public subscribers: Function[] = [];

  constructor() {
    bindMethods(this);
  }

  // Registration
  // ~~~~
  register(id: string, onSave: Function, onRevert: Function, allowRoutes?: RegExp[]): GlobalDirtySaveRevertRegistration {
    const existing = this.registrations.find((r) => (r.id === id));
    if (existing) {
      return existing;
    }
    const registration = new GlobalDirtySaveRevertRegistration(this, id, onSave, onRevert, allowRoutes);
    this.registrations.push(registration);
    this.notify();
    return registration;
  }

  terminate(remove: GlobalDirtySaveRevertRegistration): void {
    this.registrations = this.registrations.filter((reg) => (reg !== remove));
    this.notify();
  }

  // State derivation
  // ~~~~
  isDirty(): boolean {
    return this.registrations.some((reg) => (reg.dirty));
  }

  isSaving(): boolean {
    return this.registrations.some((reg) => (reg.saving));
  }

  onSave(): Promise<any[]> {
    return Promise.all(
      this.registrations
        .filter((reg) => (!!reg.onSave))
        .map((reg) => (reg.onSave())),
    );
  }

  onRevert(): Promise<any[]> {
    return Promise.all(
      this.registrations
        .filter((reg) => (!!reg.onRevert))
        .map((reg) => (reg.onRevert())),
    );
  }

  // Publisher
  // ~~~~
  subscribe(fn: Function) {
    this.subscribers.push(fn);
  }

  unsubscribe(fn: Function) {
    this.subscribers = this.subscribers.filter((sub) => (sub !== fn));
  }

  notify() {
    this.subscribers.forEach((fn) => (fn()));
  }
}
