import { RequestStatus } from '@privacy-request/value-objects';
import {
  Button, TitleBar,
} from '@privacy-request/ui';
import update from 'immutability-helper';
import intersection from 'lodash/intersection';
import React, {
  createContext, useCallback, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';
import { GetRequestsPaged, GetRequestsPagedVariables } from '../../apollo/request/requests.queries';
import { GetSystemsPaged } from '../../apollo/systems/systems.queries';
import { Link } from '../../components/Link';
import { usePermissions } from '../../hooks/usePermissions';
import { useQueryPager } from '../../hooks/useQueryPager/useQueryPager';
import { useSmartPoll } from '../../hooks/useSmartPoll';
import { useVisibilityChangedCallback } from '../../hooks/useVisibilityChangedCallback';
import { Request } from '../../types/request/Request';
import { Field } from './components/List/fieldBuilders';
import { RequestList } from './components/List/RequestList';
import { RequestListFilters } from './components/List/RequestListFilters';

export const RequestListRefreshContext = createContext(() => {});

const deriveOperationFromValue = (value: any[], operation: any) => {
  const requestValues = ['download', 'delete', 'opt-out'];
  const jurisdictionValues = ['ccpa', 'gdpr'];
  const nextRequest = intersection(requestValues, value);
  const nextJurisdiction = intersection(jurisdictionValues, value);
  const pairings: [string, any[]][] = [['request_type', nextRequest], ['jurisdiction', nextJurisdiction]];
  for (const [field, next] of pairings) {
    if (next.length) {
      operation[field] = { $set: { $in: next } };
    } else {
      if (!operation.$unset) {
        operation.$unset = [];
      }
      operation.$unset.push(field);
    }
  }
};

interface MapStruct {
  title: string
  info: string
  statuses: RequestStatus[]
  fields: Field[]
}
const map: { [key: string]: MapStruct } = {
  new: {
    title: 'new_requests',
    info: 'new_requests_count',
    statuses: ['new', 'verified'],
    fields: ['email', 'type', 'status', 'submitted', 'action', 'due'],
  },
  in_progress: {
    title: 'existing_requests',
    info: 'existing_requests_count',
    statuses: ['data-extract', 'legal-review', 'notify-requester', 'data-execute'],
    fields: ['email', 'type', 'status', 'submitted', 'action', 'due'],
  },
  complete: {
    title: 'completed_requests',
    info: 'complete_requests_count',
    statuses: ['complete'],
    fields: ['email', 'type', 'status', 'submitted', 'due'],
  },
};

export const RequestsList = () => {
  const [t] = useTranslation('requests');
  const location = useLocation();
  const { has } = usePermissions();
  const search = new URLSearchParams(location.search);
  const type = search.get('type');

  const [filter, setFilter] = useState<any>({});
  const onFilterChange = useCallback((e) => {
    const { name, value } = e.target;
    const operation: any = {};
    if (name === 'special') {
      deriveOperationFromValue(value, operation);
    } else if (value.length) {
      operation[name] = { $set: { $in: value } };
    } else {
      operation.$unset = [name];
    }
    setFilter((f: any) => update(f, operation));
  }, []);

  const where = useMemo(() => ({
    status: { $in: map[type || '']?.statuses || [] },
    ...filter,
  }), [filter, type]);

  const {
    results,
    Pager,
    refetch,
    sort,
    onSortChange,
  } = useQueryPager<Request, GetRequestsPagedVariables>(GetRequestsPaged, {
    where,
    defaultSort: [['created_at', 'DESC']],
    skip: !type,
    pollInterval: useSmartPoll(),
    parameterPrefix: 'req_',
    pageSizes: [10, 25, 50, 100],
  });
  useVisibilityChangedCallback(refetch, { onlyCallWhenVisible: true });

  const { count: systemCount } = useQueryPager(GetSystemsPaged, {
    limit: 1,
    parameterPrefix: 'sys_',
    variables: {
      filter: {
        onboarded: true,
        published: true,
      },
    },
  });

  const onRefetch = useCallback(() => {
    refetch();
  }, [refetch]);

  const getRowLink = useCallback((request: Request) => (`/requests/${request.id}`), []);

  if (!type) {
    if (has('requests.list.new') || has('requests.list.verified')) {
      return <Redirect to="/requests?type=new" />;
    }

    if (
      has('requests.list.data-extract')
      || has('requests.list.legal-review')
      || has('requests.list.data-execute')
    ) {
      return <Redirect to="/requests?type=in_progress" />;
    }

    return <Redirect to="/requests?type=complete" />;
  }

  return (
    <div>
      <RequestListRefreshContext.Provider value={onRefetch}>
        <TitleBar
          title={t(map[type].title)}
          right={[
            systemCount !== 0 && <Link key="0" to="/requests/workflow/new"><Button key="0" padded>{t('create_request')}</Button></Link>,
          ]}
        />
        <RequestListFilters filter={filter} onChange={onFilterChange} />
        <RequestList
          requests={results}
          sort={sort}
          onSortChange={onSortChange}
          activeSystemCount={(type !== 'complete' && systemCount === 0) || false}
          fields={map[type].fields}
          getRowLink={getRowLink}
        />
        <Pager />
      </RequestListRefreshContext.Provider>
    </div>
  );
};
