import React from 'react';

export const DiscoveryIcon = (props: any) => (
  <svg {...props} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M235.16036,-81.5355685 C219.50836,-217.575569 53.9703602,-283.335569 -52.5796398,-200.355569 C-111.58764,-154.402569 -136.24364,-74.3055685 -113.00964,-3.09556854 C-106.85344,15.7594315 -97.5366398,33.5454315 -85.6076398,49.3884315 C-85.7990498,52.2165315 -92.1037398,58.0056315 -93.7014398,60.1224315 C-109.93144,81.6264315 -126.19344,103.095431 -142.51044,124.528431 C-160.50244,148.173431 -178.49044,171.813431 -196.85844,195.161431 C-217.90144,221.907431 -188.57724,263.688431 -159.00644,268.536431 C-129.31144,273.403631 -112.80744,243.903431 -97.7724398,223.712431 C-79.2924398,198.900431 -61.3934398,173.653431 -43.6474398,148.314431 C-33.8544398,134.330431 -24.1044398,120.314431 -14.4564398,106.228431 C-15.4916398,107.740131 24.2275602,119.314431 45.4225602,120.580431 C69.4735602,122.017931 93.7155602,118.814831 116.46556,110.865631 C193.57856,83.9126315 243.75556,5.42563146 235.81556,-75.9043685 C235.59681,-77.7754685 235.37806,-79.6582685 235.15931,-81.5332685 C234.92884,-83.5332685 236.16711,-74.0723685 235.15931,-81.5332685 L235.16036,-81.5355685 Z M59.5303602,98.5054315 C-10.7546398,98.5054315 -74.2896398,50.2274315 -92.9496398,-17.5855685 C-111.75864,-85.9565685 -81.4296398,-160.305569 -20.2776398,-196.105569 C41.0313602,-232.000569 121.13236,-221.574569 171.33236,-171.394569 C221.54336,-121.206569 231.96936,-41.1245685 196.05536,20.1854315 C167.89536,68.2554315 115.24336,98.5054315 59.5253602,98.5054315 C-27.7986398,98.5054315 146.84136,98.5054315 59.5253602,98.5054315 L59.5303602,98.5054315 Z" id="Shape" fill="#000000" fillRule="nonzero" />
      <path d="M28.3676388,10.7371523 C27.5016402,3.21028944 18.342703,-0.428100003 12.4474719,4.16304287 C9.1826592,6.70554454 7.81848447,11.1371759 9.10398235,15.0771046 C9.44459447,16.1203198 9.96007723,17.1043891 10.6200886,17.9809555 C10.6094982,18.1374294 10.2606704,18.4577305 10.1722724,18.5748495 C9.27429394,19.7646294 8.37454502,20.9524728 7.47175304,22.1383244 C6.47628615,23.4465622 5.48104057,24.7545234 4.46477024,26.0463287 C3.30049668,27.5261396 4.92295501,29.8378116 6.5590573,30.1060432 C8.20203136,30.3753371 9.11516973,28.743141 9.94703082,27.6260072 C10.9694979,26.2532012 11.9598193,24.8563275 12.9416754,23.4543635 C13.4835055,22.6806525 14.0229565,21.9051709 14.556764,21.1258164 C14.4994881,21.2094562 16.6970842,21.8498426 17.8697677,21.9198883 C19.2004688,21.9994227 20.5417376,21.8222006 21.8004566,21.3823848 C26.0669883,19.891121 28.8431967,15.5485682 28.4038899,11.048717 C28.3917869,10.9451922 28.3796838,10.8410201 28.3675807,10.7372795 C28.3548292,10.6266229 28.4233406,11.1500785 28.3675807,10.7372795 L28.3676388,10.7371523 Z M18.6503284,20.6985159 C14.7615783,20.6985159 11.2462944,18.0273759 10.2138682,14.2753975 C9.17319806,10.4925458 10.8512503,6.37894155 14.2346869,4.39818818 C17.62681,2.41217861 22.0586627,2.98903154 24.8361437,5.76540596 C27.6142333,8.54222301 28.1910862,12.9730245 26.2040254,16.3652029 C24.6459803,19.0248346 21.7328343,20.6985159 18.6500517,20.6985159 C13.8185627,20.6985159 23.4810981,20.6985159 18.6500517,20.6985159 L18.6503284,20.6985159 Z" id="Shape" fill="#FFFFFF" fillRule="nonzero" />
    </g>
  </svg>
);

DiscoveryIcon.defaultProps = {
  width: '32px',
  height: '32px',
};
