import React from 'react';
import { Field, Fields } from '@privacy-request/ui';
import { CustomFieldDefinition } from '../CustomFieldDefinition';
import { CustomFieldOptions } from '../CustomFieldOptions';

export interface CustomBlankField extends CustomFieldOptions {
  type: 'blank'
}

export const blank: CustomFieldDefinition<CustomBlankField> = {
  type: 'blank',
  component: () => (
    // <Fields last={last} first={first} />
    <Fields />
  ),
  wrapper: (_, { first, last }) => (
    <Field last={last} first={first} />
  ),
};
