import React from 'react';
import { BulkActionModal, BulkActionModalProps } from '../BulkActionModal';
import { ProcessingActivityTransferMechanismModal } from '../../../../views/ProcessingActivitiesView/components/ProcessingActivityEdit/ProcessingActivityTransferMechanismModal';
import { useProcessingActivityBulkActionsEdit } from '../../../../views/ProcessingActivitiesView/hooks/useProcessingActivityBulkActionsEdit';

export const AddTransfersModal: BulkActionModal = ({
  onChange = () => {},
  onCancel = () => {},
}: BulkActionModalProps) => {
  const { items } = useProcessingActivityBulkActionsEdit();

  return (
    <ProcessingActivityTransferMechanismModal
      items={items}
      onCancel={onCancel}
      type="cross_border_transfers"
    />
  );
};
