import { useQuery } from '@apollo/react-hooks';
import {
  Button,
  Field,
  Text,
  ToggleComponent,
} from '@privacy-request/ui';
import update from 'immutability-helper';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { GetPermissions, GetPermissionsData } from '../../apollo/permissions/permissions.queries';
import { usePermissions } from '../../hooks/usePermissions';
import { setPermissionOverrides } from '../../store/auth/auth.actions';
import Modal from '../OverlayProvider/Modal';
import { ToggleRow } from '../ToggleRow/ToggleRow';
import { permissionCodes } from '@privacy-request/basic-types';

const Scroller = styled.div`
  max-height: 540px;
  overflow-y: auto;
`;
Scroller.displayName = 'Scroller';

export const PermissionPickerModal = ({ onCancel }: any) => {
  const { data } = useQuery<GetPermissionsData>(GetPermissions);
  const { permissions: actual } = usePermissions();
  const dispatch = useDispatch();
  const [t] = useTranslation('settings');

  const permissions = data?.permissions || [];

  const onToggle = useCallback((permission: permissionCodes) => {
    if (!actual) {
      return;
    }
    const index = actual.indexOf(permission);
    let next;
    if (index === -1) {
      next = update(actual, { $push: [permission] });
    } else {
      next = update(actual, { $splice: [[index, 1]] });
    }
    dispatch(setPermissionOverrides(next));
  }, [actual, dispatch]);

  const onRevert = useCallback(() => {
    dispatch(setPermissionOverrides());
    onCancel();
  }, [dispatch, onCancel]);

  return (
    <Modal title="Alter Permissions (Cosmetic)" onCancel={onCancel} style={{ maxWidth: '726px' }}>
      <Field>
        <Text>
          Note: Altering these roles is strictly cosmetic, and for testing only. It may put your app in states that are otherwise impossible and cause errors.
          <br />
          <br />
          Be sure to test any bugs you've found with actual roles assigned with permissions you've found faulty.
        </Text>
      </Field>

      <Field>
        <Button short padded onClick={onRevert}>Revert</Button>
        <Text>
          <br />
          (Permissions scroll)
        </Text>
      </Field>

      <Field>
        <Scroller>
          {permissions.map((permission) => {
            const checked = actual?.indexOf(permission.code) !== -1;

            return (
              <ToggleRow style={{ padding: '8px 6px' }} onClick={() => onToggle(permission.code)}>
                <div>
                  <Text>
                    <strong>{permission.code.split('.')[0]}</strong>
                    {' '}
                    |
                    {' '}
                    {t(`permissions.${permission.code.replace(/\./g, '-')}_text`)}
                  </Text>
                </div>
                <ToggleComponent
                  checked={checked}
                />
              </ToggleRow>
            );
          })}
        </Scroller>
      </Field>
    </Modal>
  );
};
