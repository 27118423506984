import React from 'react';
import { SVGComponent } from './SvgProps';

export const CheckMarkIcon: SVGComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 14 11">
    <path fill={props.color} fillRule="evenodd" d="M12.844.125a.297.297 0 0 1 .25-.125c.104 0 .187.042.25.125l.375.344a.457.457 0 0 1 .093.281.338.338 0 0 1-.093.25l-9.375 9.375a.373.373 0 0 1-.266.125.373.373 0 0 1-.266-.125L.094 6.656A.338.338 0 0 1 0 6.406c0-.104.031-.198.094-.281l.375-.344a.297.297 0 0 1 .25-.125c.104 0 .187.042.25.125l3.093 3.094 8.782-8.75z" />
  </svg>
);

CheckMarkIcon.defaultProps = {
  width: 14,
  height: 11,
  color: '#fff',
};
