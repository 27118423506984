import React from 'react';
import { TableCell, ResizableTableHeader } from '@privacy-request/ui/src/atoms/SASSTable';
import {
  FieldBuilderComponent,
  FieldBuilderProps,
  FieldHeaderComponent,
  FieldHeaderProps,
} from './types/ColumnDefinition';
import { sortProps } from '../../../hooks/useQueryPager/pagerUtils';
import { DateTime } from '../../DateTime/DateTime';

export const Header: FieldHeaderComponent = ({
  sort,
  onSortChange,
  t,
}: FieldHeaderProps) => (
  <ResizableTableHeader key="updated_at" {...sortProps('updated_at', sort, onSortChange)}>{t('table.last_updated')}</ResizableTableHeader>
);

export const Cell: FieldBuilderComponent = ({
  row,
}: FieldBuilderProps) => (
  <TableCell key="updated_at"><DateTime format="human" date={row.updated_at} /></TableCell>
);

export const fields = ['updated_at'];
