import {
  SideNavigation, SideNavigationItem, TitleBarProps, TitleBar, ExitIcon, NoAuthorizationTemplate, Text, Button, Field, ErrorText, SideNavigationSubItem, TooltipProvider, UploadIcon,
} from '@privacy-request/ui';
import React, {
  ReactNode, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Route, Switch, useLocation, Redirect,
} from 'react-router-dom';

import { MainContent } from '../../components';
import AppBackground from '../../components/AppBackground/AppBackground';
import { Avatar } from '../../components/Avatar';
import { Feedback } from '../../components/Feedback/Feedback';
import { DashboardIcon } from '../../components/icons/DashboardIcon';
import { DiscoveryIcon } from '../../components/icons/DataDiscoveryIcon';
import { DataSourcesIcon } from '../../components/icons/DataSourcesIcon';
import { RequestsIcon } from '../../components/icons/RequestsIcon';
import { SettingsIcon } from '../../components/icons/SettingsIcon';
import { TasksIcon } from '../../components/icons/TasksIcon';
import { UsersIcon } from '../../components/icons/UsersIcon';
import { Link } from '../../components/Link';
import QuickSwitcher from '../../components/QuickSwitcher/QuickSwitcher';
import { useAuth } from '../../hooks/useAuth';
import { usePermissions } from '../../hooks/usePermissions';
import {
  PROCESSING_ACTIVITY_RESUME_KEY, SYSTEM_RESUME_KEY, URL_RESUME, useLinkResume,
} from '../../hooks/useSystemResume';
import { useSelector } from '../../store/useSelector';
import { appendIf } from '../../utils/appendIf';
import { admin_routes } from '../AdminView/admin_routes';
import { LoadableAdminView } from '../AdminView/LoadableAdminView';
import { DashboardView } from '../DashboardView/DashboardView';
import { LoadableImportView } from '../ImportView/LoadableImportView';
import { LoadableReportsView } from '../ReportsView';
import { LoadableRequestsView } from '../RequestsView/LoadableRequestsView';
import { LoadableSettingsView } from '../SettingsView/LoadableSettingsView';
import { LoadableSystemsDiscoveredView } from '../SystemsView/LoadableSystemDiscoveredView';
import { LoadableSystemsView } from '../SystemsView/LoadableSystemsView';
import { LoadableUserView } from '../UserView/LoadableUserView';
import { UserEdit } from '../UserView/UserEdit';
import { Scrollbar } from 'react-scrollbars-custom';
import {
  SYSTEMS_BASE_PATH,
  ACTIVITIES_BASE_PATH,
  SERVICES_BASE_PATH,
} from '../../constants';
import { ProcessingActivitiesView } from '../ProcessingActivitiesView/ProcessingActivitiesView';
import { Banner } from '@privacy-request/ui/src/atoms/Banner/Banner';

const render = (props: TitleBarProps) => () => <TitleBar {...props} />;

export interface RouteConfig {
  link: string
  icon?: ReactNode
  title: string
  iso_title?: string
  subroutes?: RouteConfig[]
  component?: ReactNode
  hidden?: boolean
  disabled?: boolean
}

const active = (url: string, location: any) => {
  if (url === '/systems/discovered') {
    return !!location.pathname.startsWith(url) || (location.pathname.startsWith('/systems') && location.pathname.indexOf('systems/workflow') !== -1);
  }
  if (url === '/systems/existing') {
    return !!location.pathname.startsWith(url) || (location.pathname.startsWith('/systems') && !!(location.pathname as string).match(/\/systems\/\d+/g));
  }
  if (url.startsWith('/requests?') && location.pathname !== url) {
    const search = new URLSearchParams(location.search);
    const type = search.get('type') || '';
    return !!url.endsWith(`?type=${type}`);
  }
  return !!location.pathname.startsWith(url);
};

const buildRoutesFor = (route: any, t: any) => {
  const result = [];
  const props: any = {};
  if (route.component) {
    props.component = route.component;
  } else {
    props.render = render({ title: t(`nav.${route.title}`) });
  }
  result.push(
    <Route key={route.link} path={route.link} {...props} />,
  );
  if (route.subroutes) {
    return result.concat(...route.subroutes.map((subroute: any) => (buildRoutesFor(subroute, t))));
  }
  return result;
};

export const MainView = () => {
  const initializing = useSelector(s => s.auth.initializing || s.auth.ad_initializing);
  const authorized = useSelector(s => s.auth.authorized);

  const collapsed = false;
  const location = useLocation();
  const [t] = useTranslation('common');

  const {
    onLogout, me, loading, error,
  } = useAuth();

  const {
    hasPermission, hasModule, permissions,
  } = usePermissions();

  useLinkResume();

  const is_org_god_mode = me?.organization?.god_mode;
  const is_admin = me?.role?.code === 'admin';
  const isLegalAssistant = me?.role.code === 'legal-assistant';
  const is_superadmin = me?.god_mode;
  const requests_disabled = me?.organization?.features?.requests_disabled;
  const systems_disabled = me?.organization?.features?.systems_disabled;
  const is_ISO = me?.organization?.framework_context === 'ISO';
  const discover_assets_enabled = me?.organization?.features?.discover_assets_enabled;
  const imports_enabled = me?.organization?.features?.imports_enabled;
  const is_admin_route = location.pathname.startsWith('/admin') && is_org_god_mode;
  const [open, setOpen] = useState(true);

  const routes: RouteConfig[] = useMemo(() => {
    if (is_admin_route) {
      return admin_routes(!!is_superadmin, isLegalAssistant);
    }

    const _routes: RouteConfig[] = [
      {
        link: '/dashboard',
        icon: <DashboardIcon />,
        title: 'dashboard',
        component: DashboardView,
      },
      ...(!(requests_disabled || !hasModule('requests.list')) ? [{
        link: '/requests',
        icon: <RequestsIcon />,
        title: 'requests',
        component: LoadableRequestsView,
        subroutes: [
          ...appendIf(hasPermission('requests.list.new') || hasPermission('requests.list.verified'), [{
            link: '/requests?type=new',
            title: 'requests_new',
          }], []),
          ...appendIf(
            hasPermission('requests.list.data-extract')
            || hasPermission('requests.list.legal-review')
            || hasPermission('requests.list.data-execute'), [{
              link: '/requests?type=in_progress',
              title: 'requests_in_progress',
            }],
            [],
          ),
          ...appendIf(hasPermission('requests.list.complete'), [{
            link: '/requests?type=complete',
            title: 'requests_complete',
          }], []),
        ],
      }] : []),
      ...(discover_assets_enabled ? [{
        link: '/discovered',
        icon: <DiscoveryIcon />,
        title: 'systems_discovered',
        component: LoadableSystemsDiscoveredView,
      }] : []),
      ...(!systems_disabled ? [{
        link: `/${SYSTEMS_BASE_PATH}`,
        icon: <DataSourcesIcon />,
        title: 'systems',
        subroutes: [
          {
            link: `/${SYSTEMS_BASE_PATH}/existing`,
            title: 'systems_vendors_and_assets',
            component: LoadableSystemsView,
          },
          {
            link: `/${is_ISO ? SERVICES_BASE_PATH : ACTIVITIES_BASE_PATH}`,
            title: 'processing_activities',
            iso_title: 'services',
            component: ProcessingActivitiesView,
          },
        ],
        component: LoadableSystemsView,
      }] : []),
      ...(hasPermission('reports.run_reports') && !systems_disabled ? [{
        link: '/reports',
        icon: <TasksIcon />,
        title: 'reports',
        component: LoadableReportsView,
      }] : []),
      ...(is_admin ? [
        {
          link: '/users',
          icon: <UsersIcon />,
          title: 'users',
          component: LoadableUserView,
        },
        {
          link: '/settings',
          icon: <SettingsIcon />,
          title: 'settings',
          component: LoadableSettingsView,
        },
      ] : []),
      ...(is_admin && imports_enabled ? [{
        link: '/import',
        icon: <UploadIcon height="32" width="32" />,
        title: 'import',
        component: LoadableImportView,
      }] : []),
      ...((is_superadmin || hasPermission('admin.template.view')) ? [{
        link: '/admin',
        icon: () => <div />,
        title: 'admin',
        component: LoadableAdminView,
      }] : []),
    ];

    return _routes;
  }, [
    is_admin,
    requests_disabled,
    systems_disabled,
    is_admin_route,
    is_superadmin,
    discover_assets_enabled,
    hasModule,
    isLegalAssistant,
    hasPermission,
    imports_enabled,
    is_ISO,
  ]);

  if (!initializing && !authorized) {
    const search = new URLSearchParams(location.search);
    const system_resume = search.get(SYSTEM_RESUME_KEY);
    const pa_resume = search.get(PROCESSING_ACTIVITY_RESUME_KEY);
    if (system_resume) {
      sessionStorage.setItem(SYSTEM_RESUME_KEY, system_resume);
    } else if (pa_resume) {
      sessionStorage.setItem(PROCESSING_ACTIVITY_RESUME_KEY, pa_resume);
    } else {
      sessionStorage.setItem(URL_RESUME, location.pathname);
    }
    return <Redirect to="/login" />;
  }

  if (!loading && !me && error) {
    return (
      <NoAuthorizationTemplate noLogo>
        <Field>
          <Text>{t('unexpected_error')}</Text>
          {/*
          // @ts-ignore */}
          <ErrorText style={{ position: 'relative', top: '5px' }}>{error.networkError?.result?.message || error.message}</ErrorText>
          {/* Helpful Development-only Message */}
          {error.message.indexOf('401') !== -1 && process.env.NODE_ENV === 'development' && (
            <Text style={{ marginTop: '16px' }}>
              Did you remember to set your
              <strong>AZURE_CLIENT_ID</strong>
              ?
            </Text>
          )}
        </Field>
        <Field style={{ paddingBottom: 0 }}>
          <Button fluid onClick={onLogout}>{t('logout')}</Button>
        </Field>
      </NoAuthorizationTemplate>
    );
  }

  if (loading || !me || !permissions) {
    return <NoAuthorizationTemplate noLogo />;
  }

  if (!loading && !me?.organization) {
    return (
      <NoAuthorizationTemplate noLogo>
        <Field>
          <Text>{t('do_not_belong_to_domain')}</Text>
        </Field>
        <Field style={{ paddingBottom: 0 }}>
          <Button fluid onClick={onLogout}>{t('logout')}</Button>
        </Field>
      </NoAuthorizationTemplate>
    );
  }
  if (!loading && (!me?.first_name || !me?.last_name) && open) {
    return (
      <UserEdit onCancel={() => setOpen(false)} profileActionTitle="Complete Profile" />
    );
  }

  return (
    <AppBackground>
      <Banner yellow>
        <p>
          <strong>
            👋&nbsp;
            <a href="https://www.g2.com/products/opsware-data/reviews?utm_source=review-widget" target="blank">
              Are you a fan of Opsware? Leave a review on G2, and in return, enjoy a $25 Amazon gift card!
            </a>
          </strong>
        </p>
      </Banner>
      <TooltipProvider>
        <SideNavigation
          collapsed={collapsed}
          footer={[
            <Feedback key="-1" />,
            <SideNavigationItem key="0" icon={<ExitIcon />} onClick={onLogout}>{t('logout')}</SideNavigationItem>,
          ]}
        >
          <Avatar collapsed={collapsed} />
          {routes.map((route) => {
            if (route.hidden) {
              return <div key={route.link} />;
            }

            const content = (
              <SideNavigationItem
                active={active(route.link, location)}
                icon={route.icon}
                disabled={route.disabled}
              >
                {!collapsed && t(`nav.${route.title}`)}
              </SideNavigationItem>
            );

            return (
              <React.Fragment key={route.link}>
                <Link key={route.link} to={route.link}>
                  {content}
                </Link>
                {route.subroutes?.map((subroute, i) => (
                  <Link key={subroute.link} to={subroute.link}>
                    <SideNavigationSubItem active={active(subroute.link, location)} first={i === 0}>
                      {t(`nav.${(is_ISO && subroute.iso_title) ? subroute.iso_title : subroute.title}`)}
                    </SideNavigationSubItem>
                  </Link>
                ))}
              </React.Fragment>
            );
          })}
        </SideNavigation>

        <MainContent collapsed={collapsed}>
          <Scrollbar
            style={{
              width: '100%', height: '100%',
            }}
            removeTracksWhenNotUsed
            minimalThumbSize={30}
            scrollDetectionThreshold={100}
          >

            <Switch>
              {routes.flatMap((route) => (
                buildRoutesFor(route, t)
              ))}
              <Route path="/" render={() => <Redirect to="/dashboard" />} />
            </Switch>
          </Scrollbar>
          <QuickSwitcher routes={routes} />
        </MainContent>
      </TooltipProvider>
    </AppBackground>
  );
};
