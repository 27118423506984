import {
  Button, ErrorMessage, Field, Label,
} from '@privacy-request/ui';
import update from 'immutability-helper';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { GetProcessingActivitiesPaged } from '../../../../../apollo/systems/processing_activites/processing_activities.queries';
import { GraphQLSearchAndSelect } from '../../../../../components/GraphQLSearchAndSelect';
import Modal from '../../../../../components/OverlayProvider/Modal';
import { useMe } from '../../../../../hooks/useMe';
import { useRouting } from '../../../../../hooks/useRouting';
import { ProcessingActivity } from '../../../../../types/system/ProcessingActivity';
import { appendIf } from '../../../../../utils/appendIf';
import evt from '../../../../../utils/evt';
import { useSystemBulkActionsEdit } from '../../../hooks/useSystemBulkActionsEdit';
import { DEFAULT_DATA_MAP_ITEM_QUERY_VARIABLES } from '../../../../../constants';
import { useContextualSystem } from '../../../hooks/useSystemContext';

export const SystemEditProcessingActivityModal = ({ bulkAction, onCancel }: any) => {
  const [t] = useTranslation('systems');
  const { goBack } = useRouting();
  const [required, setRequired] = useState(false);
  const { current: system, onChange } = useContextualSystem();
  const [processingActivities, setProcessingActivities] = useState<any[]>([]);
  const { isISO } = useMe();
  const { onAddBulkProcessingActivities } = useSystemBulkActionsEdit();
  useEffect(() => setRequired(false), [processingActivities]);
  const onAddAssets = useCallback(() => {
    if (!processingActivities.length) {
      return setRequired(true);
    }
    if (bulkAction) {
      onAddBulkProcessingActivities(processingActivities);
      onCancel?.();
    } else {
      onChange(evt('processing_activities', update(system.processing_activities || [], {
        $push: processingActivities,
      })));
      goBack();
    }
  }, [processingActivities, bulkAction, goBack, onAddBulkProcessingActivities, onCancel, onChange, system?.processing_activities]);
  return (
    <Modal title={t(`pa.relate_processing_activities${appendIf(isISO, '_services')}`)} style={{ maxWidth: '640px' }} onCancel={bulkAction ? onCancel : goBack}>
      <Field>
        <Label>&nbsp;</Label>
        {required && (<ErrorMessage>{t('common:errors.required')}</ErrorMessage>)}
        <GraphQLSearchAndSelect
          multi
          query={GetProcessingActivitiesPaged}
          formatItem={(system: ProcessingActivity) => ({
            text: system.name,
            value: system.id,
          })}
          noItemsText={t(`pa.no_pa_found${appendIf(isISO, '_service')}`)}
          value={processingActivities}
          onChange={(systems: ProcessingActivity[]) => setProcessingActivities(systems)}
          exclude={system?.processing_activities?.map(s => s.id)}
          variables={DEFAULT_DATA_MAP_ITEM_QUERY_VARIABLES()}
        />
      </Field>
      <Field right>
        <Button style={{ float: 'right' }} padded onClick={onAddAssets}>{t(`pa.relate_processing_activities${appendIf(isISO, '_services')}`)}</Button>
      </Field>
    </Modal>
  );
};
