import { useQuery } from '@apollo/react-hooks';
import {
  Field, Input, Label, LoadingProvider, Pagination,
} from '@privacy-request/ui';
import {
  ApolloError,
  ApolloQueryResult,
  WatchQueryFetchPolicy,
} from 'apollo-client';
import { DocumentNode } from 'graphql';
import React, {
  useCallback, useRef, useState, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { validateDocumentNode } from './validateDocumentNode';
import { ConfirmationDialog } from '../../components/ConfirmationDialog/ConfirmationDialog';
import { useQuerystringKey } from '../../utils/browser/useQueryStringKey';

export interface PagerResult<T> {
  rows: T[]
  count: number
}

type pagerQuery<T, TKey extends string> = {
  [key in TKey]: PagerResult<T>
};

type SortDefinition = [string, 'ASC' | 'DESC'];
export type Sort = SortDefinition[];

interface QueryVariables {
  limit: number
  offset?: number
  sort?: string
  [key: string]: string | number | undefined
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface PagerOptions<T, F> {
  limit?: number
  page?: number
  pageSizes?: number[]
  where?: any
  group?: string
  parameterPrefix: string
  variables?: F
  defaultSort?: Sort
  skip?: boolean
  fetchPolicy?: WatchQueryFetchPolicy
  pollInterval?: number
  getIDQuery?: any
}

const defaults: Required<PagerOptions<any, any>> = {
  limit: 10,
  page: 1,
  pageSizes: [],
  where: {},
  group: '',
  parameterPrefix: '',
  variables: {},
  defaultSort: [],
  skip: false,
  pollInterval: 0,
  fetchPolicy: 'cache-and-network',
  getIDQuery: undefined,
};

export type QueryPagerResult<T> = {
  results: T[]
  loading: boolean
  error?: ApolloError
  count: number
  refetch(variables?: any): Promise<ApolloQueryResult<pagerQuery<T, string>>>
  onSortChange(e: any): void
  sort: Sort
  Pager(props: any): JSX.Element
  refetchData: [DocumentNode, any]

  // Server-side selection
};

/**
 * A helper to reduce duplication around all our pager queries.
 *
 * @param GraphQLQuery The pager query
 */
export const useQueryPager = function<T, F = any>(GraphQLQuery: DocumentNode, options: PagerOptions<T, F>): QueryPagerResult<T> {
  const [t] = useTranslation('common');
  const [chooser, setChooser] = useState(false);
  const [pageValue, setPageValue] = useState('');
  const parameterPrefix = options.parameterPrefix || defaults.parameterPrefix;
  const where = useMemo(() => options.where || defaults.where, [options.where]);
  const fetchPolicy = options.fetchPolicy || defaults.fetchPolicy;
  const { pollInterval } = options;

  // Using this key prefixer, we can have multiple pagers using URL parameters
  // on one page! Pretty nifty, though the URL becomes mildly ugly!
  const _k = useRef((key: string) => `${parameterPrefix}${key}`);
  const k = _k.current;

  // // Get the URL Search Params from react-router-dom
  const [sort, setSort, flushSort] = useQuerystringKey<[string, 'ASC' | 'DESC'][]>(k('sort'), options.defaultSort);
  const [limit, setLimit, flushLimit] = useQuerystringKey(k('limit'), options.limit || defaults.limit);
  const [page, setPage, flushPage] = useQuerystringKey(k('page'), defaults.page);

  // Handle the pre-built pager component internal changes.
  const onPagerChange = useCallback((number: number, limit?: number) => {
    setPage(number.toString());
    if (limit) {
      setLimit(limit.toString());
    }
  }, [setPage, setLimit]);

  const onSortChange = useCallback((e: any) => {
    const { name } = e.target;

    setSort((s: string[] = []) => {
      const srt = s.find((_s) => (_s[0] === name));

      if (!srt) {
        return [[name, 'DESC']];
      }

      if (srt[1] === 'DESC') {
        return [[name, 'ASC']];
      }

      return undefined;
    });
  }, [setSort]);

  // Validate the GraphQLQuery schema passed in has all
  // the pager requirements.
  const { key } = validateDocumentNode(GraphQLQuery);

  // Iterate through the parameters of the DocumentNode variables
  // and fetch their respective values from the URLSearchParams.
  //
  // One special use case is offset, since I wanted to display ?page=2 in the browser instead.
  const query: QueryVariables = useMemo(() => {
    flushPage();
    flushLimit();
    flushSort();
    return {
      limit: Number(limit),
      ...(page && limit ? { offset: Math.round((Number(page) - 1) * Number(limit)) } : {}),
      ...(Object.keys(where).length ? { filter: JSON.stringify(options.where) } : {}),
      ...(sort?.length ? { sort: JSON.stringify(sort) } : {}),
      ...(options.group ? { group: options.group } : {}),
    };
  }, [flushLimit, flushPage, flushSort, limit, options.group, options.where, page, sort, where]);

  // React complains about a "conditional" hook here because validateDocumentNode
  // throws errors on validation issues. Those errors are only dev-related and are
  // not possible runtime errors. (Well unless you suck at coding)
  const variables = {
    ...options.variables,
    ...query,
  };
  const variableRef = useRef<any>(variables);
  variableRef.current = variables;
  const {
    data, loading, error, refetch,
  } = useQuery<pagerQuery<T, typeof key>>(GraphQLQuery, {
    variables,
    fetchPolicy,
    pollInterval,
    skip: options.skip,
  });

  const [results, count] = useMemo((): [T[], number] => {
    if (data?.[key]) {
      return [data[key].rows, data[key].count];
    }

    return [[], 0];
  }, [data, key]);

  const onConfirmPageChange = useCallback((e) => {
    if (e instanceof Event) {
      e.stopPropagation();
      e.preventDefault();
    }

    const totalPages = Math.ceil(count / query.limit);
    const nextPage = Math.max(1, Math.min(totalPages, Number(pageValue)));

    onPagerChange(nextPage);

    setChooser(false);
    setPageValue('');
  }, [onPagerChange, pageValue, count, query.limit]);

  const onCancelPageChange = useCallback(() => {
    setChooser(false);
    setPageValue('');
  }, []);

  return {
    results,
    loading,
    error,
    count,
    refetch,
    onSortChange,
    sort,
    Pager: ({ style, noModal }: any) => ((count <= query.limit && !options.pageSizes) ? <div /> : (
      <LoadingProvider overlayStyle={{ backgroundColor: 'transparent' }} style={{ marginTop: '40px', ...style }}>
        <Pagination
          onRequestPageEntry={() => setChooser(true)}
          onChange={onPagerChange}
          pageNumber={Math.round((query.offset || 0) / query.limit) + 1}
          pageSize={query.limit}
          totalEntries={count}
          totalPages={Math.ceil(count / query.limit)}
          noRecordsText={t('no_results_found')}
          loading={loading}
          pageSizes={options.pageSizes || defaults.pageSizes}
        />
        {chooser && !noModal && (
          <ConfirmationDialog
            message=""
            title="Jump to page..."
            confirmText="Jump!"
            onCancel={onCancelPageChange}
            onConfirm={onConfirmPageChange}
          >
            <form onSubmit={onConfirmPageChange}>
              <Field>
                <Label>Page:</Label>
                <Input autoFocus value={pageValue} onChange={(e) => setPageValue(e.target.value)} />
              </Field>
            </form>
          </ConfirmationDialog>
        )}
      </LoadingProvider>
    )),
    refetchData: [GraphQLQuery, variables] as [DocumentNode, any],
  };
};
