import {
  useRef, useEffect, useState, useCallback, useLayoutEffect, useMemo,
} from 'react';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import useKeyboardNavigation from './useKeyboardNavigation';
import useSelectedItems, { UseSelectedItemsOptions } from './useSelectedItems';

const defaultAddText = (val: any) => `Add "${val}"`;

export type UseSearchAndSelectOptions = {
  onSearchChange: any
} & UseSelectedItemsOptions;

export default ({
  add = false,
  multi,
  onCreate,
  items: _items,
  dropdownItems,
  minimumCharacters = 0,
  noItemsText = 'No results.',
  addText = defaultAddText,
  onSearchChange,
  onChange,
  value,
  name,
}: UseSearchAndSelectOptions) => {
  const wrapperRef = useRef(null);
  const inputRef = useRef(null);
  const canvasRef = useRef(document.createElement('canvas'));
  const [focused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [inputWidth, setInputWidth] = useState('4px');
  const [timer, setTimer] = useState<any>(null);

  const items = useMemo(() => {
    if (_items) {
      return _items;
    }

    if (Array.isArray(value)) {
      return value.map((v) => ({ text: v, value: v }));
    }
    return [];
  }, [_items, value]);

  /**
   * Set the input value to empty, and if the input does not support multi-
   * select, or if the force flag is true, set focused state to false.
   */
  const onClose = useCallback((force?: boolean) => {
    setInputValue('');
    if (!multi || force) {
      setFocused(false);
    }
  }, []);

  // Close the Search if the element is clicked out-side of.
  useOnClickOutside(wrapperRef, () => (onClose(true)));

  const _onCreate = useCallback((str: string) => {
    onClose();
    onCreate(str);
  }, [onCreate, onClose]);

  // Calculate the selected and searchable items.
  const {
    onSelect, onDelete, selectedItems, searchingItems,
  } = useSelectedItems({
    value,
    items,
    dropdownItems,
    setInputValue,
    inputRef,
    inputValue,
    minimumCharacters,
    add,
    addText,
    noItemsText,
    multi,
    onChange,
    name,
    onCreate: _onCreate,
    onClose,
  });
  const searchOpen = useMemo(() => (inputValue.length > 0 || focused), [inputValue, focused]);

  // Handle Input Changes
  const onInputChange = useCallback(
    e => {
      if (!multi && selectedItems.length) {
        return;
      }
      setInputValue(e.target.value);
      onSearchChange
        && onSearchChange({
          target: {
            name,
            value: e.target.value,
          },
        });
    },
    [multi, selectedItems.length, onSearchChange, name],
  );

  // Handle keyboard navigation
  const {
    focusedItemIndex, onKeyDown, onKeyUp, setFocusIndex,
  } = useKeyboardNavigation({
    searchOpen,
    inputValue,
    setInputValue,
    searchingItems,
    onSelect,
    onDelete,
  });

  const onBlur = useCallback(() => {
    setFocusIndex(0);
    const timeout = setTimeout(() => {
      onClose();
      onSearchChange
        && onSearchChange({
          target: {
            name,
            value: '',
          },
        });
    }, 150);
    setTimer(timeout);
  }, [onClose]);

  const onFocus = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setFocusIndex(0);
    setFocused(true);
    clearTimeout(timer);
  }, [timer]);

  // Focus the input which moves around any time the input is clicked.
  const onSetInputFocus = useCallback(() => {
    if (!inputRef.current) {
      return;
    }
    // @ts-ignore
    inputRef.current.focus();
  }, []);

  // Set the width of the span so the input doens't break to next line so early unless necessary.
  useLayoutEffect(() => {
    const context = canvasRef.current.getContext('2d');
    if (!context) {
      return;
    }
    context.font = '16px "arial", sans-serif';
    const metrics = context.measureText(inputValue);
    setInputWidth(`${Math.ceil(metrics.width) + 4}px`);
  }, [inputValue]);

  return {
    selectedItems,
    onSelect,
    onDelete,
    inputValue,
    searchingItems,
    searchOpen,
    wrapperRef,
    inputRef,
    inputWidth,
    onInputChange,
    onSetInputFocus,
    focusedItemIndex,
    onKeyDown,
    onKeyUp,
    onBlur,
    onFocus,
    onClose,
  };
};
