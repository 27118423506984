import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomField } from '../../../../../components/CustomFields/CustomField';
import { CustomFieldConfig, CustomFields } from '../../../../../components/CustomFields/CustomFields';
import { UPLOAD_FILE_SUPPORTED_EXTENSIONS } from '../../../../../constants';
import { useContextualActivity } from '../../../hooks/useActivityContext';

const accept = UPLOAD_FILE_SUPPORTED_EXTENSIONS.join(',');

export const ProcessingActivityTabDPIA = () => {
  const [t] = useTranslation('systems');
  const {
    current: processingActivity, onChange, canEditPerm,
  } = useContextualActivity();

  const config = useMemo((): CustomFieldConfig => {
    const canEdit = canEditPerm('processing_activities.edit.dpia');
    const fields: CustomField[] = processingActivity.dpia_completed ? [{
      type: 'fileupload',
      label: t('dpia.link_to_dpia'),
      name: 'dpia',
      single: true,
      disabled: !canEdit,
      accept,
    }, {
      type: 'date',
      name: 'dpia_completed_at',
      label: t('dpia.date'),
      format: 'datetime',
    }] : [{
      type: 'text',
      label: t('dpia.reason'),
      name: 'dpia_reason',
      disabled: !canEdit,
    }, {
      type: 'date',
      name: 'dpia_reason_date',
      label: t('dpia.date'),
      format: 'datetime',
    }];

    return [{
      type: 'section',
      title: 'DPIA',
      storageKey: 'pa_dpia',
      config: [{
        type: 'yes-no',
        name: 'dpia_completed',
        label: t('dpia.dpia_completed'),
        disabled: !canEdit,
      }, { type: 'blank' }, ...fields],
    }];
  }, [t, processingActivity.dpia_completed, canEditPerm]);

  return (
    <div>
      <CustomFields
        config={config}
        onChange={onChange}
        value={processingActivity}
      />
    </div>
  );
};
