import React from 'react';
import { TableCell, TableHeader } from '@privacy-request/ui/src/atoms/SASSTable';
import { DateTime } from '../../../../components/DateTime/DateTime';
import { sortProps } from '../../../../hooks/useQueryPager/pagerUtils';
import { Request } from '../../../../types/request/Request';
import { Link } from '../../../../components/Link';

type FieldBuilderProps = {
  request: Request
  getRowLink: any
  isAdminRoute: boolean
  t: any
  getStatus: any
  getDueDateDisplay: any
  getAction: any
};

const asElementTypes = <T extends {}>(et: { [K in keyof T]: (props: FieldBuilderProps) => JSX.Element }) => et;

export const fieldBuilders = asElementTypes({
  email: ({ request, getRowLink }) => (
    <TableCell key="email">
      <Link to={getRowLink(request)}>
        {request.email}
      </Link>
    </TableCell>
  ),
  type: ({ request, t }) => (
    <TableCell key="type">{t(`types.${request.request_type}`)}</TableCell>
  ),
  status: ({
    request,
    getStatus,
    t,
  }) => (
    <TableCell key="status">{t(`status.${getStatus(request)}`)}</TableCell>
  ),
  submitted: ({ request }) => (
    <TableCell key="submitted"><DateTime format="human-date" date={request.created_at} /></TableCell>
  ),
  action: ({ request, getAction }) => (
    <TableCell key="action">{getAction(request, { disableVerify: true, viewPage: 'list' })}</TableCell>
  ),
  due: ({ request, getDueDateDisplay }) => (
    <TableCell key="due">{getDueDateDisplay(request)}</TableCell>
  ),
});

export type Field = keyof typeof fieldBuilders;

type HeaderBuilderProps = {
  onSortChange: any
  sort: any
  t: any
};

export const headerBuilders: { [k in Field]: (props: HeaderBuilderProps) => JSX.Element } = {
  email: ({
    sort,
    onSortChange,
    t,
  }) => (
    <TableHeader key="email" {...sortProps('email', sort, onSortChange)}>
      {t('table.requester_email')}
    </TableHeader>
  ),
  type: ({
    sort,
    onSortChange,
    t,
  }) => (
    <TableHeader key="type" {...sortProps('request_type', sort, onSortChange)}>{t('table.type')}</TableHeader>
  ),
  status: ({ t }) => (// Only in discovery
    <TableHeader key="status">{t('table.status')}</TableHeader>
  ),
  submitted: ({
    sort,
    onSortChange,
    t,
  }) => (
    <TableHeader key="submitted" {...sortProps('created_at', sort, onSortChange)}>{t('table.submitted')}</TableHeader>
  ),
  action: ({ t }) => (
    <TableHeader key="action">{t('table.action')}</TableHeader>
  ),
  due: ({
    sort,
    onSortChange,
    t,
  }) => (
    <TableHeader key="due" {...sortProps('due_date', sort, onSortChange)}>{t('table.due')}</TableHeader>
  ),
};
