import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useMe } from '../../../../hooks/useMe';
import { appendIf } from '../../../../utils/appendIf';
import { useContextualActivity } from '../../hooks/useActivityContext';
import { OwnerSelection } from '../../../../components/DataMapWorkflowComponents/OwnerSelection/OwnerSelection';
import { LoadingIndicator, LoadingProvider } from '@privacy-request/ui';
import { StepComponent, StepComponentProps } from '../../../../components/DataMapWorkflowComponents/types/WorkflowStep';

export const OwnershipSelectionStep: StepComponent = ({
  steps,
  forward,
  backward,
}: StepComponentProps) => {
  const [t] = useTranslation('systems');
  const {
    current: processingActivity,
    loading,
    onChange,
    onSave,
    saving,
  } = useContextualActivity();
  const { isISO } = useMe();

  return (
    <Suspense fallback={<LoadingProvider active><LoadingIndicator /></LoadingProvider>}>
      <OwnerSelection
        workflow={steps}
        entity={processingActivity}
        onChange={onChange}
        onSave={onSave}
        disabled={saving}
        loading={loading}
        title={t(`pa.workflow.owners_and_contacts_desc${appendIf(isISO, '_service')}`, { name: processingActivity.name })}
        onNext={forward}
        onPrev={backward}
      />
    </Suspense>
  );
};
