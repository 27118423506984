import {
  Field, Label, Input, Button, Text, EditIcon, CheckBox,
} from '@privacy-request/ui';
import update from 'immutability-helper';
import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { userToName } from '@privacy-request/common/src/utils/userToName';
import { GetUsers } from '../../apollo/users/users.queries';
import { useRouting } from '../../hooks/useRouting';
import { User } from '../../types';
import { SystemContact } from '../../types/system/SystemContact';
import evt from '../../utils/evt';
import { CustomFields } from '../CustomFields/CustomFields';
import Divider from '../Divider';
import { GraphQLSearchAndSelect } from '../GraphQLSearchAndSelect';
import Modal from '../OverlayProvider/Modal';
import { useExternalContactEdit } from './hooks/useExternalContactEdit';
import { useSystemBulkActionsEdit } from '../../views/SystemsView/hooks/useSystemBulkActionsEdit';
import { useProcessingActivityBulkActionsEdit } from '../../views/ProcessingActivitiesView/hooks/useProcessingActivityBulkActionsEdit';

interface EntityContactsCreateModalProps {
  contacts: any[] | undefined;
  onChange: any;
  name: string;
  contactKey: 'system_id' | 'processing_activity_id';
  onCreate?: (term: string) => void;
  createUser: string;
  setCreateUser?: (term: string) => void;
  onUserCreated?: (user: Partial<User>) => void;
  bulkAction?: 'systems' | 'activities';
  onCancel?: any;
}

export const EntityContactsCreateModal = ({
  contacts, onChange, contactKey: key, name, onCreate, createUser, setCreateUser, onUserCreated, bulkAction, onCancel,
}: EntityContactsCreateModalProps) => {
  const { goBack } = useRouting();
  const { index, id } = useParams<any>();
  const [editMode, setEditMode] = useState<'unknown' | 'user' | 'contact'>('unknown');
  const { addToast } = useToasts();
  const [t] = useTranslation('systems');
  const isNew = !index;

  const [contact, setContact] = React.useState<Partial<SystemContact>>(contacts?.[Number(index)] || {
    [key]: Number(id),
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    type: '',
    is_reviewer: false,
  });
  useEffect(() => {
    if (contacts?.length && index) {
      setContact(contacts[Number(index)]);
    }
  }, [contacts, index]);

  const { onAddSystemBulkContact } = useSystemBulkActionsEdit();
  const { onAddPaBulkContact } = useProcessingActivityBulkActionsEdit();

  const onAddUser: any = React.useCallback((opts: { convert_to_user?: boolean } = {}) => {
    const next = contacts || [];
    const nextDude = contact;

    if (opts.convert_to_user) {
      nextDude.convert_to_user = true;

      if (!nextDude.email) {
        addToast(t('errors.email_is_required'), {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }
    }
    if (index) {
      const i = Number(index);
      onChange(evt(name, update<any>(next, { [i]: { $set: nextDude } })));
    } else {
      const user : Partial<User> = { email: createUser };
      if (createUser && onUserCreated) {
        nextDude.create_user = user;
        nextDude.email = createUser;
        onUserCreated(user);
      }
      onChange(evt(name, update<any>(next, {
        $push: [nextDude],
      })));
      setCreateUser && setCreateUser('');
    }
    goBack();
  }, [contacts, name, onChange, contact, goBack, index, addToast, t, createUser, setCreateUser, onUserCreated]);

  const onAddBulkActionUser: any = useCallback(async () => {
    const contacts = contact;
    if (bulkAction === 'activities') {
      await onAddPaBulkContact(contacts);
    }
    if (bulkAction === 'systems') {
      await onAddSystemBulkContact(contacts);
    }
    onCancel();
  }, [onCancel, contact, bulkAction, onAddPaBulkContact, onAddSystemBulkContact]);

  const onCancelClick = useCallback(() => {
    if (bulkAction) {
      onCancel();
    } else {
      setCreateUser && setCreateUser('');
      goBack();
    }
  }, [bulkAction, onCancel, setCreateUser, goBack]);

  const onUserChange = React.useCallback((users: User[]) => {
    const user = users[0];
    if (!user) {
      setEditMode('unknown');
      return setContact((c: any) => update(c, {
        user: { $set: undefined },
        user_id: { $set: undefined },
        invite: { $set: false },
      }));
    }

    if (contacts?.some(c => c.user_id === user.id)) {
      addToast(t('errors.email_already_a_contact'), {
        autoDismiss: true,
        appearance: 'warning',
      });
      return;
    }

    setEditMode('user');
    setContact((c: any) => update(c, {
      user: { $set: user },
      user_id: { $set: user.id },
      invite: { $set: true },
    }));
  }, [contacts, addToast, t]);

  const customFields: (keyof SystemContact)[] = ['first_name', 'last_name', 'phone', 'email'];
  const valid = !!contact.user_id || createUser;
  const isEditingUserRelation = isNew || (!!index && !!contact.user_id) || editMode === 'user';
  const isEditingInlineContact = isNew || !isEditingUserRelation || editMode === 'contact';
  const emailExists = !!contact?.email && !!contacts?.filter((c, i) => i !== Number(index) || c.email?.toLowerCase() !== contact?.email?.toLowerCase()).find(c => (c.user?.email?.toLowerCase() || c.email?.toLowerCase()) === contact.email?.toLowerCase());
  const customValid = customFields.some(c => !!contact?.[c]) && !emailExists;

  const {
    onCustomContactChange,
    config,
  } = useExternalContactEdit({
    setContact,
    setEditMode,
  });

  return (
    <Modal title={isNew ? t('form.add_contact') : t('form.edit_contact')} onCancel={onCancelClick} style={{ maxWidth: '640px' }}>
      {isEditingUserRelation && (
        <>
          <Field fluid flex>
            <Field>
              <Label>
                {t('form.select_existing_user')}
                :
              </Label>
              <span style={{ display: createUser ? 'none' : 'block' }}>
                <GraphQLSearchAndSelect
                  add={!index}
                  addText={(search) => t('workflow.not_found_invite', { search })}
                  onCreate={onCreate}
                  query={GetUsers}
                  formatItem={(user: User) => ({
                    text: userToName(user),
                    value: user.id,
                    search: user.email,
                  })}
                  disabled={index}
                  value={contact.user ? [contact.user] : []}
                  onChange={onUserChange}
                />
              </span>
              <span style={{ display: !createUser ? 'none' : 'inline-flex' }}>
                <Input
                  search
                  value={createUser}
                  disabled={createUser.length > 0}
                />
                <Text style={{ margin: '5px 5px' }} onClick={() => setCreateUser && setCreateUser('')}><EditIcon /></Text>
              </span>
            </Field>
            <Field>
              <Label>
                {t('form.contact_type')}
                :
              </Label>
              <Input
                name="type"
                value={contact.type || ''}
                onChange={({ target: { value: type } }) => setContact((c: any) => ({ ...c, type }))}
              />
            </Field>
          </Field>
          <Field>
            <CheckBox
              checked={contact.is_reviewer}
              label={t('form.is_reviewer')}
              sublabel={t('form.is_reviewer_desc')}
              onChange={({ target: { value } }) => setContact((c: any) => ({ ...c, is_reviewer: value }))}
            />
          </Field>
          <Field flex right style={{ paddingBottom: 0 }}>
            <Button padded disabled={!valid} onClick={bulkAction ? onAddBulkActionUser : onAddUser}>{t(index ? 'form.update_user' : 'form.add_user')}</Button>
          </Field>
        </>
      )}
      {isEditingInlineContact && (
        <>
          {isEditingUserRelation && (<Divider style={{ margin: '16px 0' }} />)}
          <Field>
            <CustomFields
              config={config}
              onChange={onCustomContactChange}
              value={contact}
              errors={emailExists ? { email: t('errors.email_already_a_contact') } : undefined}
            />
          </Field>
          <Field flex right style={{ paddingBottom: 0 }}>
            {index && <Button secondary padded style={{ marginRight: '16px' }} onClick={() => onAddUser({ convert_to_user: true })}>{t('form.convert_to_user')}</Button>}
            <Button padded disabled={!customValid} onClick={bulkAction ? onAddBulkActionUser : onAddUser}>{t(index ? 'form.update_custom_contact' : 'form.add_custom_contact')}</Button>
          </Field>
        </>
      )}
    </Modal>
  );
};
