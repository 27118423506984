import React from 'react';
import { Field } from '@privacy-request/ui';
import { DocumentNode } from 'graphql';
import { get } from 'lodash';
import { CheckOver, Suggestion } from '../../CheckOver/CheckOver';
import { CustomFieldDefinition } from '../CustomFieldDefinition';
import { CustomFieldOptions } from '../CustomFieldOptions';
import { getRest } from '../utils/getRest';

export interface CustomCheckoverField extends CustomFieldOptions {
  type: 'checkover'
  name: string
  suggested?: Suggestion[]
  suggestionKey?: string
  translationArgs?: any
  hideCreate?: boolean
  query: DocumentNode
  mutation: DocumentNode
  disabled?: boolean
}

export const checkover: CustomFieldDefinition<CustomCheckoverField> = {
  type: 'checkover',
  component: (config, { onChange, values }) => (
    <CheckOver
      name={config.name}
      suggested={config.suggested}
      disabled={config.disabled}
      onChange={onChange}
      query={config.query}
      hideCreate={config.hideCreate}
      translationArgs={config.translationArgs}
      suggestionKey={config.suggestionKey}
      mutation={config.mutation}
      value={get(values, config.name)}
      {...getRest(config)}
    />
  ),
  wrapper: (config, {
    first,
    last,
    content,
  }) => (
    <Field last={last} first={first} key={config.name}>
      {content}
    </Field>
  ),
};
