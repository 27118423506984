import { useQuery } from '@apollo/react-hooks';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  GetAuthenticatedResult, GetAuthenticatedVariables, GetAuthenticated,
} from '../apollo/auth/local.queries';
import { localAuthInitialized, loginSuccess } from '../store/auth/auth.actions';
import { useSelector } from '../store/useSelector';

export const useLocalAuthInitialize = () => {
  const dispatch = useDispatch();
  const { data, loading } = useQuery<GetAuthenticatedResult, GetAuthenticatedVariables>(GetAuthenticated);
  const auth = useSelector(s => s.auth);

  useEffect(() => {
    if (loading || auth.ad_initializing || auth.authorizing) {
      return;
    }

    if (data?.result?.error === false) {
      dispatch(loginSuccess({ email: data.result.payload, idToken: auth.idToken }));
    } else if (auth.initializing) {
      dispatch(localAuthInitialized());
    }
  }, [data, dispatch, loading, auth.idToken, auth.ad_initializing, auth.authorizing, auth.initializing]);
};
