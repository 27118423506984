import styled from 'styled-components';

interface OverlayProps {
  rawOverlay?: boolean
  active?: boolean
}

const Overlay = styled.div<OverlayProps>`
  position: absolute;
  z-index: 2;
  pointer-events: ${({ active }) => (active ? 'auto' : 'none')};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${({ rawOverlay }) => (rawOverlay
    ? ''
    : `
    display: flex;
    align-items: center;
    justify-content: center;
  `)}
  opacity: ${({ active }) => (active ? '1' : '0')};
  transition: all 0.2s ease-in-out;
  background-color: rgba(0, 0, 0, ${({ active }) => (active ? '0.5' : '0')});
`;

Overlay.displayName = 'Overlay';

export default Overlay;
