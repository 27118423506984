/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Get all remaining non-configuration options that may have been passed to a
 * component/field.
 * @param config The config object
 * @returns The remaining options after removing all known options
 */
export const getRest = (config: any) => {
  const {
    type,
    inputType,
    secure,
    readOnly,
    name,
    label,
    disabled,
    placeholder,
    autoFocus,
    valueOverride,
    render,
    component,
    fields,
    bold,
    sublabel,
    displayValue,
    multiple,
    items,
    text,
    value,
    single,
    multi,
    query,
    filter,
    add,
    onCreate,
    formatItem,
    suggested,
    suggestionKey,
    translationArgs,
    hideCreate,
    mutation,
    format,

    // Component/Wrapper args
    values,
    onChange,
    first,
    last,
    fluid,
    mask,
    errors,
    t,
    i18n,
    content,
    ...rest
  } = config;
  rest.key = config.name;
  return rest;
};
