import gql from 'graphql-tag';
import { Task } from '../../types/request/Task';
import { TaskFragment } from './task.fragments';

export interface SaveTaskVariables {
  task: Partial<Task>
  id: number
  request_id: number
}

export const SaveTask = gql`
  mutation save_task($task: Task!, $id: Int!, $request_id: Int!) {
    save_task(input: $task, id: $id, request_id: $request_id) @rest(
      type: "Task" path: "requests/{args.request_id}/tasks/{args.id}" method: "PATCH" bodySerializer: "fileEncode"
    ) {
      ...task
    }
  }

  ${TaskFragment}
`;

export const SaveTaskWithVendorToken = gql`
  mutation save_task($token: String!, $task: Task!) {
    save_task(input: $task, token: $token) @rest(
      type: "Task" path: "requests/vendor/{args.token}" method: "PATCH" bodySerializer: "fileEncode"
    ) {
      ...vendorTask
    }
  }

  ${TaskFragment}
`;
