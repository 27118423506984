import {
  Button, Buttons, Field, Text,
} from '@privacy-request/ui';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/OverlayProvider/Modal';
import { useContextualActivity } from '../../hooks/useActivityContext';

interface ProcessingActivityCompleteReviewDialogProps {
  onCancel: any
  quantity: number
  name: string
}
export const ProcessingActivityCompleteReviewDialog = ({
  onCancel,
  quantity,
  name,
}: ProcessingActivityCompleteReviewDialogProps) => {
  const [t] = useTranslation('systems');
  const { onSave, saving } = useContextualActivity();
  const onConfirm = useCallback(async (yup?: boolean) => {
    if (yup) {
      await onSave({ setApproved: true, reviewRelatedAssets: true });
    } else {
      await onSave({ setApproved: true });
    }
    onCancel();
  }, [onSave, onCancel]);
  return (
    <Modal title={t('review.modal_title')} style={{ maxWidth: '540px' }} onCancel={onCancel}>
      <Field>
        <Text>{t('review.modal_desc', { count: quantity, name })}</Text>
      </Field>
      <Field style={{ paddingBottom: 0 }}>
        <Buttons style={{ justifyContent: 'flex-end' }}>
          <Button disabled={saving} onClick={() => onConfirm()} secondary>{t('common:no')}</Button>
          <Button disabled={saving} onClick={() => onConfirm(true)}>{t('common:yes')}</Button>
        </Buttons>
      </Field>
    </Modal>
  );
};
