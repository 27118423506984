import {
  combineReducers, createStore, applyMiddleware,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import auth from './auth';
import dashboard from './dashboard';
import drag from './drag';
import processing_activity from './processing_activity';
import request from './request';
import role from './role';
import system from './system';
import bulk_actions from './bulk_actions';
import { systemIntegrationStore } from './system_integration';
import { myOrganizationStore } from './my_organization';

export const rootReducer = combineReducers({
  auth,
  drag,
  system,
  role,
  request,
  dashboard,
  processing_activity,
  bulk_actions,
  system_integration: systemIntegrationStore.reducer,
  my_organization: myOrganizationStore.reducer,
});

export const enhancer = composeWithDevTools(
  applyMiddleware(thunk),
);

export type RootState = ReturnType<typeof rootReducer>;

const store = createStore(
  rootReducer,
  enhancer,
);

export default store;
