import React from 'react';
import { TableCell, ResizableTableHeader } from '@privacy-request/ui/src/atoms/SASSTable';
import {
  FieldBuilderComponent,
  FieldBuilderProps,
  FieldHeaderComponent,
  FieldHeaderProps,
} from './types/ColumnDefinition';

export const Header: FieldHeaderComponent = ({
  t,
}: FieldHeaderProps) => (
  <ResizableTableHeader key="organization">{t('table.organization')}</ResizableTableHeader>
);

export const Cell: FieldBuilderComponent = ({
  row,
}: FieldBuilderProps) => (
  <TableCell key="organization" truncate>
    {
      [].concat(row.organization)
        .filter((o) => (!!o))
        // @ts-expect-error
        .map((o) => (o.name || o))
        .join(', ')
    }
  </TableCell>
);

export const relations = ['organization'];
