import {
  Field, Label, Input, Button, Dropdown,
} from '@privacy-request/ui';
import { groupBy } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TagItemConfig } from '../../types/tagitem/TagItem';
import Modal from '../OverlayProvider/Modal';
import { SelectableItem } from './SelectableItem';

interface CheckOverCreateElementModalProps {
  /**
   * The items belonging to the parent that opened this modal, from which
   * we'll get categories
   */
  items: SelectableItem[]
  /**
   * Called when we try to create the new element
   */
  onCreate: (name: string) => Promise<any>
  /**
   * Called when we cancel the modal
   */
  onCancel: any
  /**
   * The type of the checkover elements
   */
  type: string
}

export const CheckOverCreateElementModal = ({
  items,
  onCreate,
  onCancel,
  type,
}: CheckOverCreateElementModalProps) => {
  const [t] = useTranslation('systems');
  const title = t(`${type}.title`);

  const { hasCategories, defaultCategory } = (TagItemConfig as any)[type] || {};
  const [description, setDescription] = React.useState('');
  const [category, setCategory] = React.useState(defaultCategory);

  const dropdownItems = React.useMemo(() => {
    const categories = Object.keys(groupBy(items, (item: SelectableItem) => item.text.split('|')[0]));

    return categories.map((item: string) => ({
      text: item,
      value: item,
    }));
  }, [items]);

  const onSave = React.useCallback(async () => {
    const result = await onCreate(`${category ? `${category}|` : ''}${description}`);
    if (!result.data) {
      return;
    }
    onCancel();
  }, [description, category, onCancel, onCreate]);

  return (
    <Modal title={title} onCancel={onCancel} style={{ maxWidth: '640px' }}>
      <Field fluid flex>
        <Field style={{ maxWidth: hasCategories ? '312px' : 'auto' }}>
          <Label>
            {t('form.description')}
            :
          </Label>
          <Input
            value={description}
            onChange={({ target: { value } }) => setDescription(value)}
          />
        </Field>
        {hasCategories && (
        <Field style={{ maxWidth: '312px' }}>
          <Label>
            {t('form.category')}
            :
          </Label>
          <Dropdown
            items={dropdownItems}
            value={category}
            onChange={({ target: { value } }) => setCategory(value)}
          />
        </Field>
        )}
      </Field>
      <Field style={{ paddingBottom: 0, marginTop: '16px' }} flex right>
        <Button padded onClick={onSave} disabled={(hasCategories && !category) || !description}>{t(`${type}.buttonText`)}</Button>
      </Field>
    </Modal>
  );
};
