import { Field, Text } from '@privacy-request/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { DateTime, prFormatDate } from '../../../../components/DateTime/DateTime';
import { useEditRequest } from '../../hooks/useEditRequest';
import { useRequestListItemHelper } from '../../hooks/useRequestListItemHelper';

const Flex = styled.div`
  display: flex;
  width: 100%;
`;
Flex.displayName = 'Flex';

export const DaysRemaining = styled.div`
  height: 32px;
  padding: 0 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.BG_GREY};
  border: 1px solid ${({ theme }) => theme.GREY};
  border-radius: 4px;
`;
DaysRemaining.displayName = 'DaysRemaining';

const DetailsText = styled<any>(Text)`
  padding-bottom: 22px;
  position: relative;
  white-space: nowrap;
`;
DetailsText.displayName = 'DetailsText';

const Span = styled.span`
  position: absolute;
  font-size: 11px;
  top: 20px;

  &>span {
    font-weight: bold;
  }
`;
Span.displayName = 'Span';

const nbsp = <span>&nbsp;</span>;

interface RequestEditInfoProps {
  hideDueDate?: boolean
  showPreviousRequests?: boolean
}
export const RequestEditInfo = ({
  hideDueDate,
  showPreviousRequests,
}: RequestEditInfoProps) => {
  const [t] = useTranslation('requests');
  const { request } = useEditRequest();
  const theme = useTheme();

  const { getStatus, getDaysRemaining } = useRequestListItemHelper(request);

  let daysRemaining;
  if (request.completed_at) {
    daysRemaining = t('edit.completed_at', { at: prFormatDate(request.completed_at, t) });
  } else if (request.rejected_at) {
    daysRemaining = t('edit.rejected_at', { at: prFormatDate(request.rejected_at, t) });
  } else if (request.due_date) {
    daysRemaining = t('edit.days_remaining', { count: getDaysRemaining(request) });
  }

  return (
    <Flex>
      <Field style={{ display: 'flex', padding: 0 }}>
        <div style={{ width: '328px', paddingLeft: '16px' }}>
          {request.status && (
            <DetailsText bold right>
              {t('table.status')}
              :
            </DetailsText>
          )}
          {request.errors?.length && (
            <DetailsText bold right style={{ color: theme.CTA_ERROR }}>
              {t('table.error')}
              :
            </DetailsText>
          )}
          <DetailsText bold right>
            {t('table.requester_email')}
            :
          </DetailsText>
          {request.first_name && (
            <DetailsText bold right>
              {t('edit.requester_name')}
              :
            </DetailsText>
          )}
          <DetailsText bold right>
            {t('edit.privacy_right')}
            :
          </DetailsText>
          {request.updated_at && (
            <DetailsText bold right>
              {t('edit.last_updated')}
              :
            </DetailsText>
          )}
          {request.external_data?.id && (
            <DetailsText bold right>
              {t('edit.external_id')}
              :
            </DetailsText>
          )}
          {showPreviousRequests && (
            <DetailsText bold right>
              {t('edit.previous_requests')}
              :
            </DetailsText>
          )}
        </div>
        <div style={{ width: '100%', paddingLeft: '16px' }}>
          {request.status && (<DetailsText bold>{request.status && t(`status.${getStatus(request)}`)}</DetailsText>)}
          {request.errors?.length && (
            <DetailsText style={{ color: theme.CTA_ERROR }}>{request.errors?.[0].message}</DetailsText>
          )}
          <DetailsText>
            {request.verified_at ? (
              <>
                <Span>
                  {t('edit.verified_on')}
                  &nbsp;
                  <DateTime date={request.verified_at} />
                </Span>
                {request.email}
              </>
            ) : <span>{request.email}</span>}
          </DetailsText>
          {request.first_name && (
            <DetailsText>
              { request.first_name }
              {' '}
              {request.last_name}
            </DetailsText>
          )}
          <DetailsText>{request.request_type ? t(`types.${request.request_type}`) : nbsp}</DetailsText>
          {request.updated_at && (
            <DetailsText>
              <DateTime date={request.updated_at} />
            </DetailsText>
          )}
          {request.external_data?.id && (
            <DetailsText>
              {request.external_data.id}
            </DetailsText>
          )}
          {showPreviousRequests && (
            <DetailsText>{t('edit.n_in_last_twelve_months', { count: request.last_twelve_months || 0 })}</DetailsText>
          )}
        </div>
      </Field>
      <Field style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {!hideDueDate && (
          <DaysRemaining>
            <Text>{daysRemaining}</Text>
            {request.created_at && (
              <Text style={{
                fontSize: '12px', position: 'absolute', top: '36px',
              }}
              >
                {t('edit.submitted', { date: prFormatDate(request.created_at, t) })}
              </Text>
            )}
          </DaysRemaining>
        )}
      </Field>
    </Flex>
  );
};
