import { CustomField } from '../CustomField';

export const autoTranslate = (config: CustomField, t: any, i18n: any): CustomField => {
  const res: any = { ...config };
  [
    'label',
    'placeholder',
  ].forEach((field) => {
    if (res[field]) {
      res[field] = i18n.exists(res[field]) ? t(res[field]) : res[field];
    }
  });

  if (res.items) {
    res.items = res.items.map((i: any) => ({
      ...i,
      text: i18n.exists(i.text) ? t(i.text) : i.text,
    }));
  }

  return res;
};
