import {
  Button, Card, Grid, LoadingIndicator, LoadingProvider, TitleBar,
} from '@privacy-request/ui';
import React, {
  useCallback, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { CachePolicies } from 'use-http';
import { DirtySaveRevert } from '../../../../components/DirtySaveRevert';
import { useFetch } from '../../../../hooks/useFetch';
import { useMe } from '../../../../hooks/useMe';
import { usePermissions } from '../../../../hooks/usePermissions';
import { useRouting } from '../../../../hooks/useRouting';
import { backendUrl } from '../../../../utils/url';
import { useContextualActivity } from '../../hooks/useActivityContext';
import { ProcessingActivityCompleteReviewDialog } from './ProcessingActivityCompleteReviewDialog';
import { ProcessingActivityEditInfo } from './ProcessingActivityEditInfo';
import { ProcessingActivityTabs } from './ProcessingActivityTabs';
import { reviewRequired } from '@privacy-request/common/src/utils/data_map/reviewRequired';
import { approvalRequired } from '@privacy-request/common/src/utils/data_map/approvalRequired';

export const ProcessingActivityEdit = () => {
  const { goBack } = useRouting();
  const [t] = useTranslation('systems');
  const { isISO, me } = useMe();
  const [assetsInReview, setAssetsInReview] = useState<number>();
  const { hasPermission } = usePermissions();
  const match = useRouteMatch();
  const {
    dirty,
    saving,
    onSave,
    onRevert,
    loading,
    canEdit,
    current: processingActivity,
  } = useContextualActivity();

  const { get, loading: fetching } = useFetch(backendUrl(`processing-activities/${processingActivity.id}/assets-in-review`), {
    cachePolicy: CachePolicies.NETWORK_ONLY,
  });
  const onCompleteReview = useCallback(async () => {
    const result = await get();
    if (result.count > 0) {
      setAssetsInReview(result.count);
      return;
    }
    await onSave({ setReviewed: true });
  }, [onSave, get]);

  const onMarkAsActive = useCallback(() => {
    onSave({ published: true });
  }, [onSave]);

  let ctaText = '';
  let ctaCallback: any;
  const requireProcessingActivityApproval = me?.organization?.data_map_setting?.require_processing_activity_approval;

  if ((approvalRequired(processingActivity) && hasPermission('processing_activities.actions.approver')) || (!loading && approvalRequired(processingActivity) && !requireProcessingActivityApproval)) {
    ctaText = t('mark_as_active');
    ctaCallback = onMarkAsActive;
  }

  if (reviewRequired(processingActivity) && canEdit) {
    ctaText = t('complete_review');
    ctaCallback = onCompleteReview;
  }

  const right: any[] = useMemo(() => {
    const result = [];
    if (dirty) {
      if (ctaText) {
        result.push((<Button key="2" padded onClick={ctaCallback} disabled={saving || fetching}>{ctaText}</Button>));
      }
    } else if (ctaText) {
      result.push((<Button key="2" padded onClick={ctaCallback} disabled={saving || fetching}>{ctaText}</Button>));
    }
    return result;
  }, [dirty, saving, ctaText, ctaCallback, fetching]);

  const allowRoutes = useMemo(() => new RegExp(match.url.substring(0, match.url.lastIndexOf('/'))), [match]);

  return (
    <LoadingProvider>
      {(saving || loading) && <LoadingIndicator />}
      <TitleBar
        fixed
        title={t(`title_${isISO ? 'service' : 'processing_activity'}_details`)}
        onBackClicked={goBack}
        right={right}
      />
      <Grid>
        <Card style={{ marginBottom: '256px' }}>
          <ProcessingActivityEditInfo />
          <ProcessingActivityTabs />
        </Card>
      </Grid>
      <DirtySaveRevert
        dirty={dirty}
        saving={saving}
        onSave={onSave}
        onRevert={onRevert}
        allowRoutes={allowRoutes}
      />
      {assetsInReview && (
        <ProcessingActivityCompleteReviewDialog
          onCancel={() => setAssetsInReview(undefined)}
          quantity={assetsInReview}
          name={processingActivity.name!}
        />
      )}
    </LoadingProvider>
  );
};
