import { FALLBACK_ASSET_IMAGE_URL } from '../../../constants';
import { stripEnd } from '../../../utils/strip';

export const systemImageLocation = (system: any) => {
  if (!system) {
    return FALLBACK_ASSET_IMAGE_URL;
  }
  // NOTE: When a user is adding an image, the value is set to a data URL
  //   before the image is actually saved.
  if (system.image_url?.startsWith('data:')) {
    return system.image_url;
  }
  const location = system.system_template?.image_url_location ?? system.system_template?.image_url_location ?? system.image_url_location;
  if (system.image_url && location) {
    return `${stripEnd(location)}/${system.image_url}`;
  }
  if (system.system_template?.image_url && location) {
    return `${stripEnd(location)}/${system.system_template.image_url}`;
  }
  if (system.template?.image_url && location) {
    return `${stripEnd(location)}/${system.template.image_url}`;
  }
  return FALLBACK_ASSET_IMAGE_URL;
};
