import { Dropdown } from '@privacy-request/ui';
import React, { useMemo } from 'react';
import { GroupedTagItemMap, useTagItems } from '../../hooks/useTagItems';
import { useIsAdminRoute } from '../../hooks/useIsAdminRoute';

export interface TagItemDropdownProps {
  /**
   * The name of the field
   */
  name: string
  /**
   * An array of ids representing the contents of the field
   */
  value?: number[]
  /**
   * The tag item type to list
   */
  type: keyof GroupedTagItemMap // or TagitemType
  /**
   * Whether or not the element is disabled and unable to change
   */
  disabled?: boolean
  /**
   * Whether or not to allow selecting multiple values
   */
  multiple?: boolean
  onChange: (ev: any) => void
  items?: any[]
  displayValue?: string
  // TOD Use Dropdown props
  hideGlobalIfUnused?: boolean
  global?: boolean
  custom?: boolean
  [k: string]: any
}

export const TagItemDropdown: React.FC<TagItemDropdownProps> = ({
  name,
  value,
  type,
  disabled,
  multiple,
  onChange,
  items: _items,
  displayValue: _displayValue,
  global,
  custom,
  ...props
}) => {
  const isAdminRoute = useIsAdminRoute();
  const val = useMemo(() => (value || []), [value]);
  const { grouped } = useTagItems({
    custom: !isAdminRoute,
    global: true,
    value: val,
  });

  const items = useMemo(() => {
    if (_items) {
      return _items;
    }
    if (grouped && grouped[type]) {
      return grouped[type].map((i: any) => ({ text: i.name, value: i.id }));
    }
    return [];
  }, [grouped, type, _items]);

  const displayValue = useMemo(() => {
    if (_displayValue) {
      return _displayValue;
    }
    const filtered = items.filter((o) => (val.find((val: any) => (val === o.value || val.id === o.value))));
    return (filtered.length >= 2 ? [filtered.length, 'Functions'].join(' ') : filtered[0]?.text);
  }, [items, val, _displayValue]);

  return (
    <Dropdown
      items={items}
      name={name}
      disabled={disabled}
      value={multiple ? val : val[0]}
      onChange={onChange}
      multiple={multiple}
      displayValue={displayValue}
      {...props}
    />
  );
};
