import { css } from 'styled-components';

export interface MediaSizes {
  mobile: number
  portrait: number
  landscape: number
  desktop: number
}

export const sizes: MediaSizes = {
  mobile: 600,
  portrait: 900,
  landscape: 1300,
  desktop: 1800,
};

// @ts-ignore
export const media: any = Object.keys(sizes).reduce((acc: any, label: keyof MediaSizes) => {
  acc[label] = (arg0: any, arg1: any) => css`
      @media (max-width: ${sizes[label] / 16}em) {
        ${css(arg0, arg1)}
      }
    ` as any;

  return acc;
}, {});
