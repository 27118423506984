import React, { useRef, useState } from 'react';
import './ComplexFilterDropdown.scss';
import {
  useOnClickOutside,
} from '@privacy-request/ui';
import clsx from 'clsx';
import { useHotkey } from '../../hooks';
import { FilterComponent } from './FilterComponent.d';
import { Dispatch, SetStateAction } from '../../types/SetStateAction';

export type ComplexFilterDropdownProps = {
  filters: FilterComponent[]
  filterData: any
  setFilterData: Dispatch<SetStateAction<any>>
};

export const ComplexFilterDropdown = ({
  filters,
  filterData = {},
  setFilterData,
}: ComplexFilterDropdownProps) => {
  const [Tray, setTray] = useState<any>();
  const [open, setOpen] = useState<any>();
  const ref = useRef<HTMLDivElement>(null);

  const onCloseFilter = () => {
    setOpen(false);
    setTimeout(() => (setTray(false)), 0);
  };
  useOnClickOutside(ref, onCloseFilter);
  useHotkey('Escape', onCloseFilter, { stopPropagation: true });

  const delayedSetOpen = (tray: any) => {
    setTray(tray);
    setTimeout(() => (setOpen(true)), 0);
  };

  const onCloseTray = (_: any, ev: any) => {
    ev.preventDefault();
    ev.stopPropagation();
    setOpen(false);
    setTimeout(() => (setTray(false)), 500);
  };

  return (
    <div className={clsx('filter-content', { open })}>
      {
        Tray && (
          <Tray
            filterData={filterData}
            setFilterData={setFilterData}
            setOpen={delayedSetOpen}
            isTrayOpen={!!Tray}
            onCloseTray={onCloseTray}
          />
        )
      }
      <ul className="filter-list">
        {
          filters.map((Filter, i) => (
            <li>
              <Filter
                key={i}
                filterData={filterData}
                setFilterData={setFilterData}
                setOpen={delayedSetOpen}
                isTrayOpen={!!Tray}
                onCloseTray={onCloseTray}
              />
            </li>
          ))
        }
      </ul>
    </div>
  );
};
