import React from 'react';
import { TableCell, ResizableTableHeader } from '@privacy-request/ui/src/atoms/SASSTable';
import { entityStatusSimple } from '@privacy-request/common/src/utils/data_map/entityStatusSimple';
import {
  FieldBuilderComponent,
  FieldBuilderProps,
  FieldHeaderComponent,
  FieldHeaderProps,
} from './types/ColumnDefinition';

export const Header: FieldHeaderComponent = ({
  t,
}: FieldHeaderProps) => (
  <ResizableTableHeader key="status">{t('table.status')}</ResizableTableHeader>
);

export const Cell: FieldBuilderComponent = ({
  row,
  t,
}: FieldBuilderProps) => (
  <TableCell key="status">{t(`status_column.${entityStatusSimple(row)}`)}</TableCell>
);

export const fields = ['published', 'deleted_at', 'review_requested', 'approval_requested'];
