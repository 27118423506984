import styled from 'styled-components';

const Divider = styled.hr`
  height: 2px;
  background-color: ${({ theme }) => theme.BG_GREY};
  border: none;
  outline: 0;
  width: 100%;
`;

Divider.displayName = 'Divider';

export default Divider;
