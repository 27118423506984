import { Request } from '../../types/request/Request';
import { CHANGE_REQUEST, SET_REQUEST } from './request.types';

export interface SetRequestAction {
  type: typeof SET_REQUEST
  payload: Partial<Request>
}

export const setRequest = (payload: Partial<Request>): SetRequestAction => ({
  type: SET_REQUEST,
  payload,
});

export interface ChangeRequestAction {
  type: typeof CHANGE_REQUEST
  payload: Partial<Request>
}

export const changeRequest = (payload: Partial<Request>): ChangeRequestAction => ({
  type: CHANGE_REQUEST,
  payload,
});

export type RequestActionTypes =
  SetRequestAction |
  ChangeRequestAction;
