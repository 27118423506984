import { useMutation, useQuery } from '@apollo/react-hooks';
import update from 'immutability-helper';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  PasswordReset, PasswordResetResult, PasswordResetVariables, RequestPasswordReset, RequestPasswordResetResult, RequestPasswordResetVariables,
} from '../../../apollo/auth/local.mutations';
import { GetPasswordResetTokenStatus } from '../../../apollo/auth/local.queries';
import { PasswordResetOptions } from '../../../types/auth/Authentication';
import { error } from '../../../utils/logging';
import { verifyPasswordComplexity } from './password';

export const usePasswordReset = () => {
  const { search } = useLocation<any>();
  const params = new URLSearchParams(search);
  const [result, setResult] = useState<any>();
  const [options, setOptions] = useState<Partial<PasswordResetOptions>>({
    email: params.get('email') || '',
    token: params.get('token') || '',
  });
  const [passwordReset, { loading }] = useMutation<PasswordResetResult, PasswordResetVariables>(PasswordReset);
  const [sendReset, { loading: sendingReset }] = useMutation<RequestPasswordResetResult, RequestPasswordResetVariables>(RequestPasswordReset);

  const { data: tokenStatus } = useQuery(GetPasswordResetTokenStatus, {
    variables: {
      token: params.get('token'),
      email: params.get('email'),
    },
  });

  const onSubmit = useCallback(async (e?: any) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (!options.password || !options.password_again) {
      return setResult({
        error: true,
        message: 'password_empty',
      });
    } if (options.password !== options.password_again) {
      return setResult({
        error: true,
        message: 'password_mismatch',
      });
    }

    const errors = verifyPasswordComplexity(options.password);
    if (errors && errors.length > 0) {
      return setResult({
        error: true,
        message: 'failed_to_reset',
        errors,
      });
    }

    try {
      const { data } = await passwordReset({ variables: { input: options } });
      setResult({
        error: data?.result.error,
        message: data?.result.message,
        errors: data?.result.errors,
      });
    } catch (e) {
      setResult({
        error: true,
        message: 'failed_to_send',
      });
      error(e);
    }
  }, [options, passwordReset]);

  const onChange = useCallback((e: any) => {
    const { name, value } = e.target;
    setOptions(o => update(o, { [name]: { $set: value } }));
  }, []);

  const email = params.get('email');
  const onResend = useCallback(() => sendReset({ variables: { input: { email: email || '' } } }), [email, sendReset]);

  return {
    onSubmit,
    onChange,
    options,
    result,
    loading,
    sendingReset,
    onResend,
    tokenStatus: tokenStatus?.reset_token_status,
  };
};
