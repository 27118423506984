import React, { useCallback, useMemo } from 'react';
import { useIsAdminRoute } from '../../hooks/useIsAdminRoute';
import { GroupedTagItemMap, useTagItems } from '../../hooks/useTagItems';
import { TagItem } from '../../types/tagitem/TagItem';
import evt from '../../utils/evt';
import { StandardCheckOver } from '../CheckOver/StandardCheckOver';

export interface TagItemCheckOverProps {
  /**
   * The name of the field
   */
  name: string
  /**
   * An array of ids representing the contents of the field
   */
  value?: number[]
  /**
   * The tag item type to list
   */
  type: keyof GroupedTagItemMap // or TagitemType
  /**
   * Whether or not the element is disabled and unable to change
   */
  disabled?: boolean
  /**
   * Called when a selection is made
   */
  onChange: (ev: any) => void
  /**
   * Whether or not the user should be able to create on the fly from the
   * checkover element
   */
  add?: boolean
  suggested?: TagItem[]
  headers?: boolean
}

export const TagItemCheckOver = ({
  name,
  value,
  type,
  disabled,
  onChange,
  add = true,
  suggested,
  headers,
  ...rest
}: TagItemCheckOverProps) => {
  const isAdminRoute = useIsAdminRoute();
  const {
    grouped,
    createTagitem,
  } = useTagItems({ custom: !isAdminRoute, global: true });
  const val = useMemo(() => (value || []), [value]);

  const items = useMemo(() => {
    if (grouped && grouped[type]) {
      return grouped[type]
        .filter((i: any) => (value?.includes(i.id) || (i.organization_id !== null || isAdminRoute))) // Exclude global tagitems unless they're used directly in the asset
        .map((i: any) => ({ text: i.name, value: i.id }));
    }
    return [];
  }, [grouped, type, value, isAdminRoute]);

  const _suggested = useMemo(() => (
    (suggested || []).map((o) => ({ text: o.name, count: o.count }))
  ), [suggested]);

  const onCreate = useCallback(async (n: string) => {
    const result = await createTagitem(n, type);
    if (!result.data) {
      // TODO Should display an error
      return;
    }
    onChange(evt(name, [...val, (result.data as any)[Object.keys(result.data)[0]]]));
    return result;
  }, [createTagitem, name, onChange, type, val]);

  return (
    <StandardCheckOver
      name={name}
      items={items}
      suggested={_suggested}
      disabled={disabled}
      onChange={onChange}
      onCreate={onCreate}
      add={add}
      value={val}
      headers={headers}
      {...rest}
    />
  );
};
