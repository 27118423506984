import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  LoadingProvider,
  LoadingIndicator,
  SubTitle,
  Text,
  CheckBox,
  Button,
  ErrorMessage,
} from '@privacy-request/ui';
import { DocumentNode } from 'graphql';
import update from 'immutability-helper';
import findIndex from 'lodash/findIndex';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import React, {
  Fragment, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DEFAULT_TAG_PAGE_SIZE } from '../../constants';
import { TagItem } from '../../types/tagitem/TagItem';
import { appendIf } from '../../utils/appendIf';
import evt from '../../utils/evt';
import NewCreateCheckoverModal from '../../views/SystemsView/components/SystemEditors/SystemEdit/NewCreateCheckoverModal';

const Flex = styled.div`
  display: flex;
`;
Flex.displayName = 'Flex';

const LeftFlexItem = styled.div`
  position: relative;
  width: 100%;
  &::before, &::after {
    border-left: 15px solid #e1e0e0;
    display: block;
    position: absolute;
    right: -18px;
    content: '';
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
  }
  &::before {
    top: 40%;
  }
  &::after {
    bottom: 30%;
  }
`;

const RightFlexItem = styled.div`
  width: 100%;
`;
RightFlexItem.displayName = 'Flexitem';

const ScrollableBox = styled.div<any>`
  height: 300px;
  border: 1px solid ${({ theme }) => theme.GREY};
  border-radius: 4px;
  padding: 6px;
  margin: 6px;
  overflow-y: scroll;
  display: ${({ centered }) => (centered ? 'flex' : 'box')};
  justify-content: center;
  align-items: center;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0,0,0,.5);
      box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
  &[disabled] {
    background-color: #f3f3f3;
  }
`;
ScrollableBox.displayName = 'ScrollableBox';

const CheckBoxWrapper = styled.div`
  margin: 12px;
`;
CheckBoxWrapper.displayName = 'CheckBoxWrapper';

const styles: { [key: string]: React.CSSProperties } = {
  subtitle: {
    paddingLeft: '6px',
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  lightText: { color: 'rgba(0,0,0,0.5)' },
  text: { margin: '12px' },
  button: { margin: '12px 0' },
  header: {
    paddingLeft: '18px',
    marginTop: '12px',
    marginBottom: '18px',
    color: 'rgba(0,0,0,0.57)',
  },
  leftBox: { marginRight: '12px' },
  rightBox: { marginLeft: '12px' },
};

export interface Suggestion {
  name: string
  count: number
}

export interface CheckOverProps {
  disabled?: boolean
  query: DocumentNode
  suggested?: Suggestion[]
  suggestionKey?: string
  translationArgs?: any
  hideCreate?: boolean
  mutation: DocumentNode
  left?: any
  value: any[]
  name: string
  error?: any
  onChange: any
}

export const CheckOver = ({
  disabled, query, mutation, left, value, name, onChange, hideCreate, error, suggested = [], suggestionKey = 'found_in_n_assets', translationArgs,
}: CheckOverProps) => {
  const [t] = useTranslation('systems');
  const [open, setOpen] = useState(false);

  const { data, loading } = useQuery(query, {
    variables: {
      limit: DEFAULT_TAG_PAGE_SIZE,
      offset: 0,
    },
  });

  const selections = useMemo(() => {
    if (!data) {
      return {};
    }

    const result = data[Object.keys(data)[0]];

    const suggestions: any = {};
    if (suggested.length) {
      suggestions.Suggested = [];
      suggested.forEach((suggestion) => {
        const tag = result.rows.find((r: TagItem) => r.name === suggestion.name);
        const item: TagItem = {
          ...(tag || {
            id: `new|${suggestion.name}`,
            name: suggestion.name,
          }),
          new: !!tag,
          count: suggestion.count,
        };
        suggestions.Suggested.push(item);
      });
    }

    const group = {
      ...suggestions,
      ...groupBy(result.rows, (r: TagItem) => (r.name || '').split('|')[0]),
    };

    for (const [key, value] of Object.entries<any>(group)) {
      group[key] = value.sort((a: TagItem, b: TagItem) => a.name.localeCompare(b.name));
    }

    return group;
  }, [data, suggested]);

  const grouping = useMemo(() => {
    const group: any = {};
    (value || []).forEach((purpose: TagItem) => {
      const [section, details] = (purpose.name || '').split('|');
      group[section] = (group[section] || []);
      group[section].push(details);
    });
    Object.keys(group).forEach((k) => {
      group[k] = group[k].sort((a: string, b: string) => a.localeCompare(b));
    });
    return group;
  }, [value]);

  const onCreate = React.useCallback((item: TagItem) => {
    onChange(evt(name, update(value || [], { $push: [item] })));
  }, [onChange, name, value]);

  const [createTag, { loading: saving }] = useMutation(mutation as DocumentNode, { refetchQueries: [{ query, variables: { offset: 0, limit: DEFAULT_TAG_PAGE_SIZE } }] });
  const onChecked = React.useCallback(async (e) => {
    if (!data) {
      return;
    }

    // eslint-disable-next-line prefer-const
    let { name: _value, value: checked } = e.target;

    if (typeof _value === 'string' && _value.startsWith('new')) {
      const [,category, description] = (_value || '').split('|');
      const result = await createTag({ variables: { tag: { name: `${category}|${description}` } } });
      if (!result.data) {
        return;
      }
      const newItem = result.data[Object.keys(result.data)[0]];
      return onCreate(newItem);
    }
    _value = parseInt(_value, 10);
    const items = data[Object.keys(data)[0]].rows;

    const item = items.find((i: TagItem) => i.id === _value);

    if (!item) {
      return;
    }

    if (checked) {
      onChange(evt(name, update((value || []), { $push: [item] })));
    } else {
      const index = findIndex(value, (i: TagItem) => i.id === _value);
      onChange(evt(name, update(value || [], { $splice: [[index, 1]] })));
    }
  }, [onChange, value, data, onCreate, name, createTag]);

  return (
    <LoadingProvider>
      {!!(loading || saving) && <LoadingIndicator />}
      <Flex>
        {!disabled && (
          <LeftFlexItem style={{ ...styles.leftBox, opacity: disabled ? 0 : 1 }}>
            <SubTitle style={styles.header}>{t(`${name}.descriptor`)}</SubTitle>
            {error && <ErrorMessage style={{ top: '14px' }}>{error}</ErrorMessage>}
            <ScrollableBox disabled={disabled}>
              {Object.entries<any>(selections).map(([title, purposes]) => (
                <Fragment key={title}>
                  <SubTitle style={styles.subtitle}>{title}</SubTitle>
                  {purposes.map((purpose: TagItem) => {
                    const checked = !!(value || []).find((item: TagItem) => item.id === purpose.id);
                    return (
                      <CheckBoxWrapper key={purpose.id}>
                        <CheckBox
                          label={(
                            <>
                              {(purpose.name || '|').split('|')[1]}
                              {purpose.count && (
                                <span>
                                  {' - '}
                                  <strong>
                                    {t(suggestionKey, {
                                      count: Number(purpose.count),
                                      ...translationArgs,
                                    })}
                                  </strong>
                                </span>
                              )}
                            </>
                          ) as any}
                          name={purpose.id?.toString()}
                          onChange={onChecked}
                          checked={checked}
                        />
                      </CheckBoxWrapper>
                    );
                  })}
                </Fragment>
              ))}
            </ScrollableBox>
          </LeftFlexItem>
        )}
        <RightFlexItem style={styles.rightBox}>
          <SubTitle style={styles.header}>{t(`${name}.description`, { count: (value || []).length })}</SubTitle>
          <ScrollableBox disabled={disabled} centered={isEmpty(grouping)}>
            {isEmpty(grouping) && (
              <Text style={styles.lightText}>{t(`select_from_the_left_checkover${appendIf(disabled || false, '_disabled')}`)}</Text>
            )}
            {Object.keys(grouping).map((key) => (
              <Fragment key={key}>
                <SubTitle style={styles.subtitle}>{key}</SubTitle>
                {grouping[key].map((purpose:string) => (
                  <Text key={purpose} style={styles.text}>
                    •&nbsp;&nbsp;
                    {purpose}
                  </Text>
                ))}
              </Fragment>
            ))}
          </ScrollableBox>
        </RightFlexItem>
      </Flex>
      {!disabled && (!!mutation) && (!hideCreate) && (
        <Flex style={{ justifyContent: left ? 'space-between' : 'flex-end' }}>
          {left}
          <Button disabled={disabled} style={styles.button} padded onClick={() => setOpen(true)}>{t(`${name}.buttonText`)}</Button>
        </Flex>
      )}
      {open && !!mutation && (
        <NewCreateCheckoverModal
          query={query}
          mutation={mutation}
          onCancel={() => setOpen(false)}
          onCreate={onCreate}
          type={name}
        />
      )}
    </LoadingProvider>
  );
};
