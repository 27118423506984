import {
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  BackwardCallback,
  ForwardCallback,
  WorkflowStep,
} from '../types/WorkflowStep';

const getVisibleSteps = (steps: WorkflowStep[], entity: any, visibilityProps: any) => (
  steps.filter((step) => (!step.visible || step.visible(entity, visibilityProps)))
);

export type ControlledNavigationCallback = (props: UseWorkflowControllerResult) => Promise<void>;

export type UseWorkflowControllerProps = {
  /**
   * The steps that may be presented to the user
   */
  steps: WorkflowStep[]
  /**
   * The entity currently being edited. Passed in here to accurately determine
   * which steps should be visible based on current and previous selections.
   */
  entity: any
  /**
   * Any props to be passed to the step visibility callback
   */
  visibilityProps: any
  /**
   * If provided, the callback is called when the user moves forward in the
   * workflow. It is provided with a set of props which allow the callback to
   * inspect the state of the workflow and set the step index accordingly
   */
  forward?: ControlledNavigationCallback
  /**
   * If provided, the callback is called when the user moves backward in the
   * workflow. It is provided with a set of props which allow the callback to
   * inspect the state of the workflow and set the step index accordingly
   */
  backward?: ControlledNavigationCallback
};

export type UseWorkflowControllerResult = {
  /**
   * The list of steps as provided in the `steps` prop
   */
  steps: WorkflowStep[]
  /**
   * The entity as provided in the `entity` prop
   */
  entity: any
  /**
   * The list of steps that should be currently visible to the user. This may
   * be a subset of the complete list supplied by the `steps` argument
   */
  visibleSteps: WorkflowStep[]
  /**
   * The index of the current step
   */
  stepIndex: number
  /**
   * Manually set the current step index
   */
  setStepIndex: (idx: number) => void
  /**
   * The current step object
   */
  currentStep: WorkflowStep
  /**
   * Move the wizard/workflow forward one step
   */
  forward: ForwardCallback
  /**
   * Move the wizard/workflow backward one step
   */
  backward: BackwardCallback
};

export const useWorkflowController = ({
  steps,
  entity,
  visibilityProps,
  forward,
  backward,
}: UseWorkflowControllerProps): UseWorkflowControllerResult => {
  // NOTE: While it would be preferable to track the state as a reference to
  // the actual entry itself, this causes issues in various callbacks when
  // those callbacks update state, and then expect to perform an action based
  // on the updated state. Therefore, we track the index of the step we're on
  // and memoize the object values accordingly.
  const visibleSteps = useMemo(() => (
    getVisibleSteps(steps, entity, visibilityProps)
  ), [steps, entity, visibilityProps]);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const currentStep = useMemo(() => (
    visibleSteps[stepIndex]
  ), [stepIndex, visibleSteps]);

  const setStepIndexGuarded = useCallback((idx: number) => (
    setStepIndex(Math.max(0, Math.min(visibleSteps.length - 1, idx)))
  ), [visibleSteps.length]);

  const _forward = useCallback(async () => {
    setStepIndex((idx) => (
      Math.min(++idx, visibleSteps.length - 1)
    ));
  }, [visibleSteps.length]);

  const _backward = useCallback(async () => {
    setStepIndex((idx) => (
      Math.max(0, --idx)
    ));
  }, []);

  const props = useMemo(() => ({
    steps,
    entity,
    stepIndex,
    setStepIndex: setStepIndexGuarded,
    visibleSteps,
    currentStep,
  }), [steps, entity, stepIndex, setStepIndexGuarded, visibleSteps, currentStep]);

  const __forward = useMemo(() => {
    if (forward) {
      return async () => (
        forward({
          ...props,
          forward: _forward,
          backward: _backward,
        })
      );
    }
    return _forward;
  }, [forward, _forward, _backward, props]);

  const __backward = useMemo(() => {
    if (backward) {
      return async () => (
        backward({
          ...props,
          forward: _forward,
          backward: _backward,
        })
      );
    }
    return _backward;
  }, [backward, _forward, _backward, props]);

  return {
    ...props,
    forward: __forward,
    backward: __backward,
  };
};
