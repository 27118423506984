import React from 'react';
import { RawJsonEditor } from '../RawJsonEditor/RawJsonEditor';
import { DebugView } from '../DebugView/DebugView';

export type DebugEditorProps = {
  value: any
  onChange: (ev: any) => void
  name?: string
  style?: React.CSSProperties
};

export const DebugEditor = React.memo(({
  value,
  onChange,
  name,
  ...rest
}: DebugEditorProps) => (
  <DebugView>
    <RawJsonEditor name={name || 'debug'} onChange={onChange} value={value} />
  </DebugView>
));
