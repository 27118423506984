const defaultOptions: ComplexityOptions = {
  minLength: 8,
  maxLength: 26,
  minLower: 1,
  minUpper: 1,
  minNumeric: 1,
  minSymbol: 1,
};

export interface ComplexityOptions {
  minLength?: number
  maxLength?: number
  minLower?: number
  minUpper?: number
  minNumeric?: number
  minSymbol?: number
}

export const verifyPasswordComplexity = (value: string, {
  minLength = 0,
  maxLength = 0,
  minLower = 0,
  minUpper = 0,
  minNumeric = 0,
  minSymbol = 0,
}: ComplexityOptions = defaultOptions) => {
  const errors = [];

  if (minLength && value.length < minLength) {
    errors.push(['password_complexity.min_length', minLength]);
  }
  if (maxLength && value.length > maxLength) {
    errors.push(['password_complexity.max_length', maxLength]);
  }
  if ((value.match(/[a-z]/g)?.length || 0) < minLower) {
    errors.push(['password_complexity.min_lower', minLower]);
  }
  if ((value.match(/[A-Z]/g)?.length || 0) < minUpper) {
    errors.push(['password_complexity.min_upper', minUpper]);
  }
  if ((value.match(/[0-9]/g)?.length || 0) < minNumeric) {
    errors.push(['password_complexity.min_numeric', minNumeric]);
  }
  if ((value.match(/[^a-zA-Z0-9]/g)?.length || 0) < minSymbol) {
    errors.push(['password_complexity.min_symbol', minSymbol]);
  }

  return errors;
};
