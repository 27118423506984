import gql from 'graphql-tag';

export const AuditLogFragment = gql`
  fragment audit_log on AuditLog {
    id
    source_type
    user_id
    table_name
    table_row_id
    action
    timestamp
    updated_values
    previous_values
    transaction_id
    user @type(name: "User") {
      id
      email
    }
    processing_activity @type(name: "ProcessingActivity") {
      id
      name
    }
    system @type(name: "System") {
      id
      name
    }
    system_relations @type(name: "System") {
      id
      name
    }
    cross_border_transfer @type(name: "TagItem") {
      id
      name
    }
    purpose_of_processing @type(name: "TagItem") {
      id
      name
    }
    personal_data_category @type(name: "TagItem") {
      id
      name
    }
    origin_of_personal_data @type(name: "TagItem") {
      id
      name
    }
    processing_activity_any @type(name: "ProcessingActivity") {
      id
      name
    }
    technical_security_measure @type(name: "TagItem") {
      id
      name
    }
    organizational_security_measure @type(name: "TagItem") {
      id
      name
    }
    hosting_provider @type(name: "TagItem") {
      id
      name
    }
    data_disposal_method @type(name: "TagItem") {
      id
      name
    }
    contact @type(name: "User") {
      id
      email
    }
    tagitem @type(name: "TagItem") {
      id
      name
      type
    }
  }
`;
