import {
  Dropdown,
  Field,
  Label,
  SelectionItem,
} from '@privacy-request/ui';
import { get } from 'lodash';
import React from 'react';
import { CustomFieldDefinition } from '../CustomFieldDefinition';
import { CustomFieldOptions } from '../CustomFieldOptions';
import { err } from '../utils/err';
import { getRest } from '../utils/getRest';

export interface CustomDropdownField extends CustomFieldOptions {
  type: 'dropdown'
  name: string
  placeholder?: string;
  style?: React.CSSProperties;
  label: string
  disabled?: boolean
  displayValue?: string
  multiple?: boolean
  persistent?: boolean
  items: SelectionItem[]
  debug?: boolean
}

export const dropdown: CustomFieldDefinition<CustomDropdownField> = {
  type: 'dropdown',
  component: (config, { onChange, values }) => {
    let value = get(values, config.name);
    if (Array.isArray(value)) {
      value = value.map((i: any) => i.id || i);
    }
    return (
      <Dropdown
        items={config.items}
        name={config.name}
        disabled={config.disabled}
        value={value || config.defaultValue}
        onChange={onChange}
        displayValue={config.displayValue}
        multiple={config.multiple}
        persistent={config.persistent}
        debug={config.debug}
        {...getRest(config)}
      />
    );
  },
  wrapper: (config, {
    first,
    last,
    fluid,
    content,
    errors,
  }) => (
    <Field last={last} first={first} fluid={fluid} style={last ? { paddingBottom: 0 } : {}} key={config.name || config.label}>
      <Label>{config.label}</Label>
      {err(errors, config.name)}
      {content}
    </Field>
  ),
};
