import { Pagination } from '@privacy-request/ui';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useResultSelections } from '../shared/useResultSelections';

export interface DataPagerOptions<T> {
  limit?: number
  pageSizes?: number[]
  onSelectAllFilter?: (record: T) => boolean
}

const defaults: Required<DataPagerOptions<unknown>> = {
  limit: 10,
  pageSizes: [],
  onSelectAllFilter: () => true,
};

export const useDataPager = function<T>(data: Array<T>, options: DataPagerOptions<T> = {}) {
  const [page, setPage] = useState(0);
  const [limit] = useState(options.limit || defaults.limit);
  const [results, setResults] = useState(data.slice(page * limit, limit));
  const [count, setCount] = useState(data.length);

  useEffect(() => {
    setPage(0);
  }, [data]);

  useEffect(() => {
    const start = page * limit;
    const end = start + limit;
    setResults(data.slice(start, end));
  }, [data, page, limit]);

  useEffect(() => {
    setCount(data.length);
  }, [data.length]);

  const onPagerChange = useCallback((n: number) => {
    setPage(n - 1);
  }, []);

  const onRequestPageEntry = useCallback(() => {}, []);

  const selection = useResultSelections(results, {
    onSelectAllFilter: options.onSelectAllFilter,
  });

  return {
    results,
    ...selection,
    Pager: ({ style }: any) => ((count <= limit && !options.pageSizes) ? <div /> : (
      <div style={{ marginTop: '40px', ...(style || {}) }}>
        <Pagination
          onRequestPageEntry={onRequestPageEntry}
          onChange={onPagerChange}
          pageNumber={page + 1}
          pageSize={limit}
          totalEntries={count}
          totalPages={Math.ceil(count / limit)}
          pageSizes={options.pageSizes || defaults.pageSizes}
        />
      </div>
    )),
  };
};
