import { useMutation } from '@apollo/react-hooks';
import update from 'immutability-helper';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  RequestOrganizationList, RequestOrganizationListResult, RequestOrganizationListVariables,
} from '../../../apollo/auth/local.mutations';
import { RequestOrganizationListOptions } from '../../../types/auth/Authentication';
import { error } from '../../../utils/logging';

export const useForgotOrganization = () => {
  const { search } = useLocation<any>();
  const params = new URLSearchParams(search);
  const [result, setResult] = useState<any>();
  const [options, setOptions] = useState<Partial<RequestOrganizationListOptions>>({ email: params.get('email') || '' });
  const [send, { loading }] = useMutation<RequestOrganizationListResult, RequestOrganizationListVariables>(RequestOrganizationList);

  const onSubmit = useCallback(async (e?: any) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    try {
      const { data } = await send({ variables: { input: options } });
      setResult({
        error: data?.result.error,
        message: data?.result.message,
      });
    } catch (e) {
      setResult({
        error: true,
        message: 'failed_to_send',
      });
      error(e);
    }
  }, [options, send]);

  const onChange = useCallback((e: any) => {
    const { name, value } = e.target;
    setOptions(o => update(o, { [name]: { $set: value } }));
  }, []);

  return {
    onSubmit,
    onChange,
    options,
    result,
    loading,
  };
};
