/* eslint-disable react/jsx-one-expression-per-line */
import React, { useCallback, useState } from 'react';
import {
  Button,
  OverlayModal,
  RadioGroup,
} from '@privacy-request/ui';
import { prFormatDate } from '../DateTime/DateTime';
import { useTaskRetry } from './useTaskRetry';
import { useTranslation } from 'react-i18next';
// import { SystemIntegrationAttributes } from '@privacy-request/value-objects';

export type TaskRetryModalProps = {
  requestId: number
  taskId: number
  current: any
  latest: any
  onCancel: any
};

export const TaskRetryModal = ({
  requestId,
  taskId,
  latest,
  current,
  onCancel,
}: TaskRetryModalProps) => {
  const { triggerRetry } = useTaskRetry(requestId, taskId);
  const [t] = useTranslation();
  const [value, setValue] = useState(0);

  const anchorStyles = {
    display: 'block',
    paddingBottom: '24px',
  };

  const onConfirm = useCallback(async (ev) => {
    await triggerRetry(value || latest.version);
    onCancel();
  }, [latest.version, onCancel, triggerRetry, value]);

  const common = (
    <>
      <p>Note that retrying tasks can have unexpected consequences under certain circumstances, especially in those cases where the task partially succeeded, or where the task relies on data from other services tha may have already been deleted. Please reference the following resources for more information:</p>

      <a href="https://www.notion.so/opsware/Automatic-task-retries-b8f35a4802f34c939734449d493bee87?pvs=4" target="_blank" rel="noreferrer" style={anchorStyles}>Automatic task retries</a>
      <a href="https://www.notion.so/opsware/Manually-retrying-tasks-747e4713965a4b949ff90ff205323bb4?pvs=4" target="_blank" rel="noreferrer" style={anchorStyles}>Manually retrying failed tasks</a>

      <div style={{ display: 'flex' }}>
        <Button padded onClick={onConfirm} style={{ marginRight: '16px' }} disabled={value <= 0 && current.version !== latest.version}>Retry task</Button>
        <Button padded secondary onClick={onCancel}>Cancel</Button>
      </div>
    </>
  );

  if (current.version === latest.version) {
    return (
      <OverlayModal title="Retry Task" subtitle="Retry a failed or hung task" onCancel={onCancel} style={{ width: '550px' }}>
        <div style={{ padding: '0 28px' }}>
          <p>The most recent integration configuration will be used to retry the task.</p>

          {common}
        </div>
      </OverlayModal>
    );
  }

  return (
    <OverlayModal title="Retry Task" subtitle="Retry a failed or hung task" onCancel={onCancel} style={{ width: '550px' }}>
      <div style={{ padding: '0 28px' }}>
        <p>One or more updates have been made to the integration since this task was created. Please select the version you wish to use:</p>

        <RadioGroup
          items={[
            {
              text: `Original (#${current.version} - ${prFormatDate(current.created_at, t, 'human-datetime')})`,
              value: current.version,
              subtext: 'The version used to run the task originally',
            },
            {
              text: `Latest (#${latest.version} - ${prFormatDate(latest.created_at, t, 'human-datetime')})`,
              value: latest.version,
              subtext: 'The most recent version of the integration configuration',
            },
          ]}
          value={value}
          onChange={(ev) => (setValue(ev.target.value))}
          topAlign
        />

        {common}
      </div>
    </OverlayModal>
  );
};
