import clsx from 'clsx';
import React from 'react';

import './Tag.scss';

export interface TagProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  /**
   * Attribute alias used to apply the `mute` className to the element.
   */
  mute: boolean
  /**
   * Attribute alias used to apply the `clickable` className to the element.
   */
  clickable: boolean
}

export const Tag = ({
  mute,
  clickable,
  ...props
}: TagProps) => (
  <span
    className={clsx('tag', {
      mute,
      clickable,
    })}
    {...props}
  />
);
