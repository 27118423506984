import React from 'react';
import {
  CheckBox,
  Field,
  Label,
} from '@privacy-request/ui';
import { get } from 'lodash';
import { CustomFieldOptions } from '../CustomFieldOptions';
import { CustomFieldDefinition } from '../CustomFieldDefinition';
import { getRest } from '../utils/getRest';
import { DisablerHack } from '../../DisablerHack/DisablerHack';

export interface CustomSingleCheckboxField extends CustomFieldOptions {
  type: 'checkbox-single'
  name: string
  label?: string
  text?: string
  disabled?: boolean
}

export const checkboxSingle: CustomFieldDefinition<CustomSingleCheckboxField> = {
  type: 'checkbox-single',
  component: (config, { values, onChange }) => (
    <CheckBox
      style={{ marginBottom: '8px' }}
      label={config.text}
      name={config.name}
      checked={get(values, config.name)}
      onChange={onChange}
      {...getRest(config)}
    />
  ),
  wrapper: (config, {
    last,
    first,
    content,
  }) => (
    <Field last={last} first={first} key={config.name || config.label}>
      <DisablerHack disabled={config.disabled}>
        {config.label && (<Label>{config.label}</Label>)}
        {content}
      </DisablerHack>
    </Field>
  ),
};
