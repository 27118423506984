import gql from 'graphql-tag';
import { User } from '../../types';
import { DataMapSettingFragment } from '../organizations/data_map_settings.combined';
import { UserFragment } from './users.fragments';

export interface GetMeData {
  me: User
}

export const GetMe = gql`
  query me {
    me @rest(type: "User", path: "users/me") {
      id
      first_name
      last_name
      email
      phone
      permissions
      enabled
      require_mfa
      settings
      organization_id
      organization @type(name: "Organization") {
        id
        domain
        company_name
        phone
        god_mode
        framework_context
        intake_form
        features
        settings
        communication_preferences
        data_map_setting @type(name: "DataMapSetting") {
          ...data_map_setting_fragment
        }
      }
      role_id
      role @type(name: "Role") {
        id
        code
      }
      god_mode
      subsidiaries
    }
  }

  ${DataMapSettingFragment}
`;

export interface UsersPaged {
  count: number
  rows: User[]
}

export interface GetUsersData {
  get_users: UsersPaged
}

export interface GetUsersVariables {
}

export const GetUsers = gql`
  query get_users($limit: Int!, $offset: Int!, $sort: Object!) {
    get_users(limit: $limit, offset: $offset, sort: $sort)
      @rest(type: "UsersPaged", path: "users?{args}") {
        rows @type(name: "User") {
          id
          first_name
          last_name
          email
          phone
          enabled
          role @type(name: "Role") {
            id
            name
          }
        }
        count
    }
  }
`;

export interface GetUserData {
  user: User
}

export interface GetUserVariables {
  id: number
}

export const GetUser = gql`
  query user($id: Int) {
    user(id: $id) @rest(type: "User", path: "users/{args.id}") {
      ...user
    }
  }
  ${UserFragment}
`;

export interface GetUserAdminVariables {
  id: number
  organization_id: number
}

export interface GetUserAdminResult {
  get_user_as_admin: User
}

export const GetUserAdmin = gql`
  query get_user_as_admin($id: Int, $organization_id: Int) {
    get_user_as_admin(id: $id, organization_id: $organization_id) @rest(type: "User", path: "organizations/{args.organization_id}/users/{args.id}") {
      ...user
    }
  }
  ${UserFragment}
`;
