import styled from 'styled-components';

const Paragraph = styled.p<{ fontSize?: string }>`
  font-family: 'Verdana', sans-serif;
  font-weight: 400;
  margin: 5px 0;
  color: ${({ theme }) => theme.BLACK};
  font-size: ${({ fontSize }) => fontSize};
  letter-spacing: 0.2px;
`;

Paragraph.defaultProps = { fontSize: '16px' };

Paragraph.displayName = 'Paragraph';

export default Paragraph;
