import {
  Button,
  Field,
  SearchAndSelect,
} from '@privacy-request/ui';
import update from 'immutability-helper';
import React, {
  useCallback,
  useState,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/OverlayProvider/Modal';
import { useContextualActivity } from '../../hooks/useActivityContext';
import { find } from 'lodash';
import evt from '../../../../utils/evt';

interface ProcessingActivityLineageAssetsModalProps {
  onCloseAssetModel?: () => void,
  laneId?: string,
}
export const ProcessingActivityLineageAssetsModal = ({ onCloseAssetModel, laneId } : ProcessingActivityLineageAssetsModalProps) => {
  const [t] = useTranslation('systems');
  const {
    current: processingActivity,
    onChange,
  } = useContextualActivity();
  const [systems, setSystems] = useState<any[]>([]);

  const onAddAssets = useCallback(async () => {
    const lineages: any = [];
    // eslint-disable-next-line array-callback-return
    systems.map((id: any, index: number) => {
      const isExist = find(processingActivity?.lineage, {
        system_id: id,
        processing_activity_id: processingActivity?.id,
        lane_name: laneId,
      });
      if (!isExist) {
        lineages.push({
          processing_activity_id: processingActivity?.id,
          system_id: Number(id),
          lane_name: laneId,
          lane_order: Number(index) + 4, // order must start with 1
        });
      }
    });
    const updatedLineage = update(processingActivity.lineage, { $push: lineages });
    onChange(evt('lineage', updatedLineage));
    onCloseAssetModel && onCloseAssetModel();
    // await onSave({ lineage: updatedLineage, systems: updatedSystems });
  }, [onChange, processingActivity, systems, laneId, onCloseAssetModel]);

  const items: any = useMemo(() => {
    const assets = processingActivity.systems;
    const exclude = processingActivity.lineage?.map((l) => l.lane_name === laneId && l.system_id) || []; // && processingActivity?.systems?.find(e => e.id === s.system_id)?.id ) : [];
    const items:any = [];
    // eslint-disable-next-line array-callback-return
    assets?.map((s: any, index: number) => {
      if (exclude?.indexOf(Number(s.id)) === -1) {
        items.push({
          text: s.name,
          value: s.id,
        });
      }
    });
    return items || [];
  }, [laneId, processingActivity]);

  return (
    <Modal title={t('assets.add_assets')} style={{ maxWidth: '640px' }} onCancel={onCloseAssetModel}>
      <Field>
        <SearchAndSelect
          multi
          items={items}
          name="systems"
          onChange={({ target: { name, value } }) => setSystems(value)}
          onSearchChange={() => {}}
          minimumCharacters={0}
        />
      </Field>
      <Field right>
        <Button style={{ float: 'right' }} onClick={onAddAssets}>{t('assets.add_assets')}</Button>
      </Field>
    </Modal>
  );
};
