import React from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { GenericTextStyle } from '../styles';

export interface FloatingBoxItemProps {
  active?: boolean
  selected?: boolean
  disabled?: boolean
  icon?: any
  id?: any
  onClick?: Function
  children?: any
  style?: React.CSSProperties
}

export interface StyledFloatingBoxItemProps extends FloatingBoxItemProps {
  theme: DefaultTheme
}

const evaluateBackgroundColor = ({
  theme, active, selected,
}: StyledFloatingBoxItemProps) => {
  if (active) {
    return theme.BG_GREY;
  } if (selected) {
    return theme.GREY;
  }
  return theme.WHITE;
};

const evaluateColor = ({ theme }: StyledFloatingBoxItemProps) => theme.BLACK;

const evaluateHoverBackgroundColor = ({ theme, selected }: StyledFloatingBoxItemProps) => {
  if (selected) {
    return theme.BG_GREY_HOVER;
  }
  return theme.BG_GREY;
};

const evaluateDisabledAttributes = ({
  theme, disabled, selected,
}: StyledFloatingBoxItemProps) => {
  if (disabled) {
    return;
  }

  return `
    cursor: pointer;

    &:hover {
      background-color: ${evaluateHoverBackgroundColor({ theme, selected })};
    }
  `;
};

const StyledFloatingBoxItem = styled.div<any>`
  display: flex;
  align-items: center;
  height: 32px;
  position: relative;
  transition: all 0.1s ease-in-out;
  padding-left: 20px;
  padding-right: 20px;
  white-space: nowrap;
  background-color: ${evaluateBackgroundColor};
  color: ${evaluateColor};
  left: 0;
  right: 0;
  outline: none;
  user-select: none;

  ${GenericTextStyle}

  font-size: 14px;

  ${evaluateDisabledAttributes}
`;

StyledFloatingBoxItem.displayName = 'FloatingBoxItem';

const IconWrapper = styled.div`
  margin-right: 10px;
  height: 16px;
`;

IconWrapper.displayName = 'IconWrapper';

export type FloatingBoxItemRef = HTMLDivElement;

export const FloatingBoxItem = React.forwardRef<FloatingBoxItemRef, FloatingBoxItemProps>(({
  icon, children, ...props
}, ref) => (
  <StyledFloatingBoxItem tabIndex={-1} ref={ref} {...props}>
    {icon && (
      <IconWrapper>{icon}</IconWrapper>
    )}
    {children}
  </StyledFloatingBoxItem>
));
