import React from 'react';
import { CustomField } from '../../../components/CustomFields/CustomField';
import { TagItemCheckOver } from '../../../components/TagItemCheckOver/TagItemCheckOver';
import { TagItemCustomField } from '../../../components/TagItemCustomField/TagItemCustomField';
import { TagItemDropdown } from '../../../components/TagItemDropdown/TagItemDropdown';
import { TagItemSelect } from '../../../components/TagItemSelect/TagItemSelect';

export const hostingLocationsField = (t: any, entity: any, onChange: any, props?: any) => (
  <TagItemCustomField
    component={TagItemDropdown}
    type="hosting_location"
    name="hosting_locations"
    label={t('data_hosting.location')}
    entity={entity}
    onChange={onChange}
    hideCategories
    {...props}
  />
);

export const hostingProvidersField = (t: any, entity: any, onChange: any, props?: any) => (
  <TagItemCustomField
    component={TagItemSelect}
    type="hosting_provider"
    name="hosting_providers"
    label={t('data_hosting.provider')}
    entity={entity}
    onChange={onChange}
    add
    hideCategories
    {...props}
  />
);

export const hostingCountriesField = (t: any, entity: any, onChange: any, props?: any) => (
  <TagItemCustomField
    component={TagItemSelect}
    type="country"
    name="hosting_countries"
    label={t('data_hosting.countries')}
    entity={entity}
    onChange={onChange}
    hideCategories
    add={false}
    {...props}
  />
);

export const technicalSecurityMeasuresField = (t: any, entity: any, onChange: any, props?: any) => (
  <TagItemCustomField
    component={TagItemSelect}
    type="technical_security_measure"
    name="technical_security_measures"
    label={t('security_measures.technical_security_measures')}
    entity={entity}
    onChange={onChange}
    multiple
    add
    hideCategories
    {...props}
  />
);

export const organizationalSecurityMeasuresField = (t: any, entity: any, onChange: any, props?: any) => (
  <TagItemCustomField
    component={TagItemSelect}
    type="organizational_security_measure"
    name="organizational_security_measures"
    label={t('security_measures.organizational_security_measures')}
    entity={entity}
    onChange={onChange}
    multiple
    add
    hideCategories
    {...props}
  />
);

export const hostTypesCustomField = (t: any, entity: any, onChange: any, isLegalAssistant: boolean, props?: any): CustomField => {
  if (isLegalAssistant) {
    return {
      type: 'dropdown',
      items: [{ text: 'Vendor', value: '3549' }],
      name: 'hosting_types',
      label: t('form.system_type'),
    };
  }
  return {
    type: 'custom',
    name: 'hosting_types',
    width: 1,
    render: () => (
      <TagItemCustomField
        component={TagItemDropdown}
        type="hosting_type"
        name="hosting_types"
        label={t('form.system_type')}
        entity={entity}
        onChange={onChange}
        {...props}
      />
    ),
  };
};

export const personalDataCategoriesCustomField = (t: any, entity: any, onChange: any, suggested: any, props?: any): CustomField => ({
  type: 'custom',
  name: 'personal_data_categories',
  render: () => (
    <TagItemCustomField
      component={TagItemCheckOver}
      type="personal_data_category"
      name="personal_data_categories"
      entity={entity}
      onChange={onChange}
      suggested={suggested}
      add
      {...props}
    />
  ),
});

// Storage formats
export const storageFormatsField = (t: any, entity: any, onChange: any, props?: any) => (
  <TagItemCustomField
    component={TagItemDropdown}
    type="storage_format"
    name="storage_formats"
    label={t('form.storage_format')}
    entity={entity}
    onChange={onChange}
    hideCategories
    {...props}
  />
);
export const storageFormatsCustomField = (t: any, entity: any, onChange: any, props?: any): CustomField => ({
  type: 'custom',
  name: 'storage_formats',
  width: 1,
  render: () => (storageFormatsField(t, entity, onChange, props)),
});

// Data subjects
export const dataSubjectCategoriesField = (t: any, entity: any, onChange: any, props?: any) => (
  <TagItemCustomField
    component={TagItemCheckOver}
    type="subject_category"
    name="data_subject_categories"
    entity={entity}
    onChange={onChange}
    multiple
    hideCategories
    {...props}
  />
);
export const dataSubjectCategoriesCustomField = (t: any, entity: any, onChange: any, props?: any): CustomField => ({
  type: 'custom',
  name: 'data_subject_categories',
  render: () => (!props.hide && dataSubjectCategoriesField(t, entity, onChange, props)),
});

// Purposes of processing
export const purposesOfProcessingField = (t: any, entity: any, onChange: any, props?: any) => (
  <TagItemCustomField
    component={TagItemCheckOver}
    type="purpose_of_processing"
    name="purposes_of_processing"
    entity={entity}
    onChange={onChange}
    multiple
    {...props}
  />
);
export const purposesOfProcessingCustomField = (t: any, entity: any, onChange: any, props?: any): CustomField => ({
  type: 'custom',
  name: 'purposes_of_processing',
  render: () => (purposesOfProcessingField(t, entity, onChange, props)),
});

// Processing countries
export const processingCountriesField = (t: any, entity: any, onChange: any, props: any) => (
  <TagItemCustomField
    component={TagItemSelect}
    type="country"
    name="processing_countries"
    entity={entity}
    onChange={onChange}
    multiple
    add={false}
    hideCategories
    {...props}
  />
);
export const processingCountriesCustomField = (t: any, entity: any, onChange: any, props?: any): CustomField => ({
  type: 'custom',
  name: 'processing_countries',
  width: 2,
  render: () => (processingCountriesField(t, entity, onChange, props)),
});

// Data disposal methods
export const dataDisposalMethodsField = (t: any, entity: any, onChange: any, props: any) => (
  <TagItemCustomField
    component={TagItemSelect}
    type="data_disposal_method"
    name="data_disposal_methods"
    label={t('data_retention.disposal')}
    entity={entity}
    onChange={onChange}
    multiple
    add
    hideCategories
    {...props}
  />
);
export const dataDisposalMethodsCustomField = (t: any, entity: any, onChange: any, props?: any): CustomField => ({
  type: 'custom',
  name: 'data_disposal_methods',
  width: 1,
  render: () => (dataDisposalMethodsField(t, entity, onChange, props)),
});

// Cross border transfers
export const crossBorderTransfersField = (t: any, entity: any, onChange: any, props: any) => (
  <TagItemCustomField
    component={TagItemCheckOver}
    type="cross_border_transfer"
    name="cross_border_transfers"
    entity={entity}
    onChange={onChange}
    multiple
    add
    hideCategories
    {...props}
  />
);
export const crossBorderTransfersCustomField = (t: any, entity: any, onChange: any, props?: any): CustomField => ({
  type: 'custom',
  name: 'cross_border_transfers',
  render: () => (crossBorderTransfersField(t, entity, onChange, props)),
});

// Origins of personal data
export const originsOfPersonalDataField = (t: any, entity: any, onChange: any, props: any) => (
  <TagItemCustomField
    component={TagItemCheckOver}
    type="origin_of_personal_data"
    name="origins_of_personal_data"
    entity={entity}
    onChange={onChange}
    multiple
    add
    hideCategories
    {...props}
  />
);
export const originsOfPersonalDataCustomField = (t: any, entity: any, onChange: any, props?: any): CustomField => ({
  type: 'custom',
  name: 'origins_of_personal_data',
  render: () => (originsOfPersonalDataField(t, entity, onChange, props)),
});

// Destinations of personal data
export const destinationsOfPersonalDataField = (t: any, entity: any, onChange: any, props: any) => (
  <TagItemCustomField
    component={TagItemCheckOver}
    type="origin_of_personal_data"
    name="destinations_of_personal_data"
    entity={entity}
    onChange={onChange}
    multiple
    add
    hideCategories
    {...props}
  />
);
export const destinationsOfPersonalDataCustomField = (t: any, entity: any, onChange: any, props?: any): CustomField => ({
  type: 'custom',
  name: 'destinations_of_personal_data',
  render: () => (destinationsOfPersonalDataField(t, entity, onChange, props)),
});

// Lawful basis
export const lawfulBasesField = (t: any, entity: any, onChange: any, props: any) => (
  <TagItemCustomField
    component={TagItemCheckOver}
    type="lawful_basis"
    name="lawful_bases"
    entity={entity}
    onChange={onChange}
    multiple
    add
    headers={false}
    hideCategories
    {...props}
  />
);
export const lawfulBasesCustomField = (t: any, entity: any, onChange: any, props?: any): CustomField => ({
  type: 'custom',
  name: 'lawful_bases',
  render: () => (lawfulBasesField(t, entity, onChange, props)),
});

// Business functions
export const businessFunctionsField = (t: any, entity: any, onChange: any, props?: any) => (
  <TagItemCustomField
    component={TagItemDropdown}
    type="business_function"
    name="business_functions"
    label={t('form.function')}
    entity={entity}
    onChange={onChange}
    multiple
    hideCategories
    {...props}
  />
);
export const businessFunctionCustomField = (t: any, entity: any, onChange: any, props?: any): CustomField => ({
  type: 'custom',
  name: 'business_functions',
  width: 1,
  render: () => (businessFunctionsField(t, entity, onChange, props)),
});
