/* eslint-disable jsx-a11y/heading-has-content */
import clsx from 'clsx';
import React from 'react';

import './SubTitle.scss';

export type SubTitleProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> & {
  /**
   * If true, text alignment is centered
   */
  center?: boolean
  /**
   * If true, given the primary/brand color
   */
  primary?: boolean
  /**
   * If true, given a muted color
   */
  mute?: boolean

  small?: boolean
  normal?: boolean
  large?: boolean
};

export const SubTitle: React.FC<SubTitleProps> = ({
  className,
  center,
  primary,
  mute,
  small,
  normal,
  large,
  ...props
}: SubTitleProps) => (
  <h2
    className={clsx('subtitle', {
      center,
      primary,
      mute,
      small,
      normal,
      large,
    })}
    {...props}
  />
);
