import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomFields } from '../../../../../components/CustomFields/CustomFields';
import { CustomSection } from '../../../../../components/CustomFields/CustomSectionComponent';
import { lawfulBasesCustomField } from '../../../../SystemsView/common/fields';
import { useContextualActivity } from '../../../hooks/useActivityContext';

export const ProcessingActivityTabLawfulBasis = () => {
  const [t] = useTranslation('systems');
  const {
    current: processingActivity, onChange, canEditPerm,
  } = useContextualActivity();

  const config = useMemo((): CustomSection[] => [{
    type: 'section',
    title: t('pa.tabs.lawful-basis'),
    helpText: t('tooltips.lawful_basis', { name: processingActivity.name }),
    storageKey: 'lawful_basis_checks',
    config: [
      lawfulBasesCustomField(t, processingActivity, onChange, { disabled: !canEditPerm('processing_activities.edit.lawful_basis') }),
    ],
  }], [t, onChange, processingActivity, canEditPerm]);

  return (
    <div>
      <CustomFields config={config} value={processingActivity} onChange={onChange} />
    </div>
  );
};
