/* eslint-disable no-console */

const noop = () => {};
const functionFallback = console?.log || noop;

export const trace = console?.trace || functionFallback;

export const debug = console.debug || functionFallback;

export const info = console.info || functionFallback;

export const warn = console.warn || functionFallback;

export const error = console.error || functionFallback;
