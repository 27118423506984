import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Dispatch, SetStateAction } from '../../types/SetStateAction';
import { useMe } from '../../hooks/useMe';
import update from 'immutability-helper';

export type UseManagedFiltersProps = {
  /**
   * An optional key used to store and retrieve filter values from local storage
   */
  storageKey?: string
  /**
   * A set of predetermined filters to fall back on
   */
  filters?: any
};

export const useManagedFilters = ({
  filters = {},
}: UseManagedFiltersProps) => {
  const { me } = useMe();
  const is_admin = me?.role?.code === 'admin';
  const multi_org = me?.organization?.features?.multi_org_enabled;

  const [_filters, _setFilters] = useState<any>(filters || {});
  const setFilters = useCallback((cb: Dispatch<SetStateAction<any>>) => {
    _setFilters((prevData: any) => {
      let result: any;
      if (typeof cb === 'function') {
        result = cb(prevData);
      } else {
        result = cb;
      }
      return result;
    });
  }, []);

  // Automatically filter by subsidiaries that the user belongs to, if they
  // explicitly belong to any subsidiaries
  useEffect(() => {
    if (!is_admin && me?.subsidiaries && multi_org) {
      const userSubsidiaries = me?.subsidiaries.map(sub => sub.id);
      if (userSubsidiaries.length) {
        setFilters((f: any) => {
          if (!f.subsidiaries?.length) {
            return update(f, {
              subsidiaries: {
                $set: userSubsidiaries,
              },
            });
          }
          return f;
        });
      }
    }
  }, [filters.subsidiaries?.length, is_admin, me?.subsidiaries, multi_org, setFilters]);

  return {
    filters: _filters,
    setFilters,
  };
};
