import { useMutation, useQuery } from '@apollo/react-hooks';
import { useMemo } from 'react';
import {
  CreateTagItem,
  CreateTagItemResult,
  CreateTagItemVariables,
  DeleteTagItem,
  DeleteTagItemResult,
  DeleteTagItemVariables,
  UpdateTagItem,
  UpdateTagItemResult,
  UpdateTagItemVariables,
} from '../apollo/tagitems/tagitems.mutations';
import { GetTagItems, GetTagItemsData } from '../apollo/tagitems/tagitems.queries';
import { TagItem, TagItemType } from '../types/tagitem/TagItem';
import { useIsAdminRoute } from './useIsAdminRoute';
import { useMe } from './useMe';

interface UseTagitemsOptions {
  /**
   * By default, the look-up will only use the cache. Set this to false when
   * you want it to query the back-end. This should only be used at the top
   * level component that manages when a "system edit view" is visible or not.
   */
  cacheOnly?: boolean
  /**
   * Retrieve global tagitems
   */
  global?: boolean
  /**
   * Retrieve custom tagitems
   */
  custom?: boolean
  /**
   * Optional list of ids that are currently in use. If specified, allows us
   * we filter out global tags that aren't a match, leaving only ones that are
   * used, and custom elements that belong to the organization
   */
  value?: number[]
}

export const regions = ['Global', 'North America', 'South America', 'Europe', 'EEA', 'EMEA', 'ASEAN', 'ANZ', 'LATAM', 'VISTA'].reduce((acc: any, cur) => {
  acc[cur] = true;
  return acc;
}, {});
const countrySort = (a: TagItem, b: TagItem) => {
  const aIsRegion = regions[a.name];
  const bIsRegion = regions[b.name];

  if (aIsRegion && !bIsRegion) {
    return -1;
  }

  if (bIsRegion && !aIsRegion) {
    return 1;
  }

  return a.name.localeCompare(b.name);
};

const defaultSystemEditOptions: UseTagitemsOptions = {
  cacheOnly: false,
  global: true,
  custom: true,
};

export class GroupedTagItemMap {
  'storage_format': TagItem[] = [];
  'hosting_type': TagItem[] = [];
  'hosting_location': TagItem[] = [];
  'country': TagItem[] = [];
  'hosting_provider': TagItem[] = [];
  'organizational_security_measure': TagItem[] = [];
  'technical_security_measure': TagItem[] = [];

  'lawful_basis': TagItem[] = [];
  'subject_category': TagItem[] = [];
  'destination_of_personal_data': TagItem[] = [];
  'cross_border_transfer': TagItem[] = [];
  'data_disposal_method': TagItem[] = [];
  'purpose_of_processing': TagItem[] = [];
  'origin_of_personal_data': TagItem[] = [];

  'business_function': TagItem[] = [];
  'personal_data_category': TagItem[] = [];
}

const groupBy = (items: any[], key: string | Function) => (
  items.reduce((res, item) => {
    const v = key instanceof Function ? key(item) : item[key];
    (res[v] = res[v] || []).push(item);
    return res;
  }, {})
);
export const useTagItems = ({
  cacheOnly,
  global,
  custom,
  value,
}: UseTagitemsOptions = defaultSystemEditOptions) => {
  const { me } = useMe();
  const isAdminRoute = useIsAdminRoute();
  const organization_id = isAdminRoute ? undefined : me?.organization_id;

  const {
    data,
    loading,
    error,
    refetch,
  } = useQuery<GetTagItemsData>(GetTagItems, {
    fetchPolicy: cacheOnly ? 'cache-only' : 'cache-and-network',
    variables: {
      limit: 5000,
      filter: {
        ...(global ? { global } : {}),
        ...(!custom ? { custom } : {}),
      },
    },
  });
  const [_createTagitem, { loading: creating }] = useMutation<CreateTagItemResult, CreateTagItemVariables>(CreateTagItem, {
    update: (store, result) => {
      const { data: { create_tagitem } } = result as any;
      // Read the data from our cache for this query.
      const data = store.readQuery<GetTagItemsData>({
        query: GetTagItems,
        variables: { limit: 5000 },
      });
      if (!data) {
        return;
      }
      // Add our comment from the mutation to the end.
      data.get_tagitems.rows = [...data?.get_tagitems.rows, create_tagitem];
      // Write our data back to the cache.
      store.writeQuery({
        query: GetTagItems,
        data,
        variables: { limit: 5000 },
      });
    },
  });
  const [_updateTagitem, { loading: updating }] = useMutation<UpdateTagItemResult, UpdateTagItemVariables>(UpdateTagItem, {
    update: (store, result) => {
      const { data: { update_tagitem } } = result as any;
      // Read the data from our cache for this query.
      const data = store.readQuery<GetTagItemsData>({
        query: GetTagItems,
        variables: { limit: 5000 },
      });
      if (!data) {
        return;
      }
      // Add our comment from the mutation to the end.
      data.get_tagitems.rows = data?.get_tagitems.rows.map((r) => {
        if (r.id === update_tagitem.id) {
          return update_tagitem;
        }
        return r;
      });
      // Write our data back to the cache.
      store.writeQuery({
        query: GetTagItems,
        data,
        variables: { limit: 5000 },
      });
    },
  });
  const [_deleteTagitem, { loading: deleting }] = useMutation<DeleteTagItemResult, DeleteTagItemVariables>(DeleteTagItem, {
    update: (store, result) => {
      const { data: { delete_tagitem } } = result as any;
      // Read the data from our cache for this query.
      const data = store.readQuery<GetTagItemsData>({
        query: GetTagItems,
        variables: { limit: 5000 },
      });
      if (!data) {
        return;
      }
      // Add our comment from the mutation to the end.
      data.get_tagitems.rows = data?.get_tagitems.rows.filter((o) => (o.id !== delete_tagitem.id));
      // Write our data back to the cache.
      store.writeQuery({
        query: GetTagItems,
        data,
        variables: { limit: 5000 },
      });
    },
  });

  const createTagitem = (name: string, type: keyof typeof TagItemType) => (
    _createTagitem({
      variables: {
        tagitem: {
          name,
          type,
          organization_id,
        },
      },
      optimisticResponse: {
        create_tagitem: {
          // @ts-ignore
          __typename: 'TagItem',
          id: -1,
          name,
          type,
          organization_id: -1,
          count: 0,
        },
      },
    })
  );
  const updateTagitem = (tagitem: TagItem) => (
    _updateTagitem({
      variables: {
        id: tagitem.id || -1,
        tagitem: {
          ...tagitem,
          organization_id: tagitem.organization_id,
        },
      },
      optimisticResponse: {
        update_tagitem: {
          // @ts-ignore
          __typename: 'TagItem',
          ...tagitem,
        },
      },
    })
  );
  const deleteTagitem = (id: number, replace_id?: number) => (
    _deleteTagitem({
      variables: {
        id: id || -1,
        replace_id,
      },
      optimisticResponse: {
        delete_tagitem: {
          __typename: 'DeleteTagItemResponse',
          id,
        },
      },
    })
  );

  const result = useMemo(() => {
    if (global !== false && custom !== false) {
      if (value) {
        // Deduplicate tagitems, perferring custom tagitems over global
        return data?.get_tagitems.rows
          .filter((t) => (
            value.includes(t.id) || (t.organization_id !== null || isAdminRoute)
          ));
      }
      return data?.get_tagitems.rows;
    }
    if (global !== false) {
      return data?.get_tagitems.rows.filter((o: any) => (!o.organization_id));
    }
    if (custom !== false) {
      return data?.get_tagitems.rows.filter((o: any) => (!!o.organization_id));
    }
    return [];
  }, [data?.get_tagitems.rows, custom, global, value, isAdminRoute]);

  const grouped: GroupedTagItemMap = useMemo(() => {
    const _grouped = {
      ...new GroupedTagItemMap(),
      ...groupBy(result || [], 'type'),
    };

    if (_grouped.country.length) {
      _grouped.country.sort(countrySort);
    }

    return _grouped;
  }, [result]);

  return {
    loading: !data && loading,
    data: data?.get_tagitems.rows,
    grouped,
    error,
    refetch,

    createTagitem,
    updateTagitem,
    deleteTagitem,
    creating,
    updating,
    deleting,
  };
};
