import clsx from 'clsx';
import React from 'react';
import './button.scss';

export type ButtonProps = {
  // Colouration
  plain?: boolean
  /**
   * Style the button as per the secondary theme
   */
  secondary?: boolean
  /**
   * Style the button as per the alert theme
   */
  alert?: boolean
  warn?: boolean
  error?: boolean

  // Shape/size
  /**
   * Makes the button have equal width and height. Useful for icons.
   */
  square?: boolean
  round?: boolean
  short?: boolean
  padded?: boolean
  plus?: boolean
  nav?: boolean
  /**
   * Sets the button to take the full width of the container.
   */
  fluid?: boolean
  onClick?: any
};

export type HTMLAnchorAttributes = ButtonProps & React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & { as: 'a' };
export type HTMLButtonAttributes = ButtonProps & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & { as?: 'button' };
export type ElementButtonAttributes<T> = ButtonProps & T & { as: React.ReactElement<T> };
export type ButtonAttributes<T = any> = HTMLAnchorAttributes | HTMLButtonAttributes | ElementButtonAttributes<T>;

export const Button: React.FunctionComponent<ButtonAttributes> = ({
  as = 'button',
  plus,
  nav,
  plain,
  padded,
  fluid,
  square,
  round,
  short,
  secondary,
  error,
  warn,
  alert,
  type = 'button',
  className,
  ...props
}): JSX.Element => {
  const As = as as any;
  // eslint-disable-next-line jsx-a11y/control-has-associated-label
  return (
    <As
      className={clsx('button', className, {
        padded,
        fluid,
        plus,
        nav,
        plain,
        square,
        round,
        short,
        secondary,
        error,
        warn,
        alert,
      })}
      type={As === 'button' ? type as any : undefined}
      {...props}
    />
  );
};
