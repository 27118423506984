import React, {
  useMemo,
} from 'react';
import { usePermissions } from '../../../hooks/usePermissions';
import { useMe } from '../../../hooks/useMe';
import { useMutation } from '@apollo/react-hooks';
import { UpdateSystem } from '../../../apollo/systems/systems.mutations';
import { ColumnDefinition } from '../../../components/DataMapEntityList/columns/types/ColumnDefinition';
import { ListRow } from '../../../components/DataMapEntityList/ListRow';
import { ProcessingActivity } from '../../../types/system/ProcessingActivity';

export type ProcessingActivityListRowProps = {
  row: ProcessingActivity
  fields: ColumnDefinition[]
  disabled?: boolean
  onSelect?: any
  onDelete: any
  onUndelete: any
  isSelected?: boolean
  getRowLink: any
  setEditing: any
};

export const ProcessingActivityListRow = (props: ProcessingActivityListRowProps) => {
  const {
    row,
    fields,
    disabled,
    onSelect,
    onDelete,
    onUndelete,
    isSelected,
    getRowLink,
    setEditing,
  } = props;
  const { hasPermission } = usePermissions();
  const { me } = useMe();
  const [onSave] = useMutation(UpdateSystem);

  const canView = useMemo(() => hasPermission('processing_activities.list.others') || row.contacts?.some(c => c.user_id === me?.id), [hasPermission, me?.id, row.contacts]);
  const canEdit = useMemo(() => hasPermission('processing_activities.list.others') || row.contacts?.some(c => c.user_id === me?.id), [hasPermission, row.contacts, me?.id]);

  return (
    <ListRow
      fields={fields}
      row={row}
      isRowDisabled={disabled}
      isRowSelected={isSelected}
      canViewRow={canView}
      canEditRow={canEdit}
      onRowSelect={onSelect}
      onRowArchive={onDelete}
      onRowUnarchive={onUndelete}
      onRowSave={onSave}
      getRowLink={getRowLink}
      setEditing={setEditing}
    />
  );
};
