import {
  useContext,
  useEffect,
  useRef,
} from 'react';
import { GlobalDirtySaveRevertContext } from './GlobalDirtySaveRevertContext';
import { GlobalDirtySaveRevertRegistration } from './GlobalDirtySaveRevertRegistration';

export const useDirtySaveRevert = (id: string, onSave: Function, onRevert: Function, saving: boolean, allowRoutes?: RegExp[]): GlobalDirtySaveRevertRegistration => {
  const { register, terminate } = useContext(GlobalDirtySaveRevertContext);
  const registration = useRef<GlobalDirtySaveRevertRegistration>({} as any);

  useEffect(() => {
    registration.current = register(id, onSave, onRevert, allowRoutes);
    return () => {
      terminate(registration.current);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    registration.current.onSave = onSave;
    registration.current.onRevert = onRevert;
    registration.current.saving = saving;
  }, [onSave, onRevert, saving]);

  return registration.current;
};
