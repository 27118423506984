import { TabsComponent, TabsTab } from '@privacy-request/ui';
import React, {
  useCallback, useContext, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Redirect, Switch, useParams, Route,
} from 'react-router-dom';
import { HotkeyContext } from '../../../../components/HotkeyProvider/HotkeyContext';
import { useMe } from '../../../../hooks/useMe';

import { useRouting } from '../../../../hooks/useRouting';
import { ProcessingActivityTabAssets } from './tabs/ProcessingActivityTabAssets';
import { ProcessingActivityTabAuditLog } from './tabs/ProcessingActivityTabAuditLog';
import { ProcessingActivityTabDetails } from './tabs/ProcessingActivityTabDetails';
import { ProcessingActivityTabDPIA } from './tabs/ProcessingActivityTabDPIA';
import { ProcessingActivityTabLawfulBasis } from './tabs/ProcessingActivityTabLawfulBasis';
import { ProcessingActivityTabLineage } from './tabs/ProcessingActivityTabLineage';
import { useNavigationUrls } from '../../../../hooks/useNavigationUrls';

export const ProcessingActivityTabs = () => {
  const { id, tab } = useParams<any>();
  const [t] = useTranslation('systems');
  const { replace } = useRouting();
  const { me } = useMe();
  const { state: { debug } } = useContext(HotkeyContext);
  const gdpr_enabled = me?.organization?.features?.gdpr_enabled;
  const { activityUrl } = useNavigationUrls();

  const onTabClicked = useCallback((e) => {
    replace(activityUrl(`${id}/${e.target.value}`))();
  }, [replace, activityUrl, id]);

  const config: [string, any][] = useMemo(() => ([
    ['details', ProcessingActivityTabDetails],
    ['assets', ProcessingActivityTabAssets],
    ...gdpr_enabled ? [
      ['lawful-basis', ProcessingActivityTabLawfulBasis],
      ['dpia', ProcessingActivityTabDPIA],
    ] : [],
    ...([['lineage', ProcessingActivityTabLineage]]),
    ...(debug ? [['audit-log', ProcessingActivityTabAuditLog]] : []),
  ] as [string, any][]), [gdpr_enabled, debug]);

  const tabs = useMemo((): TabsTab[] => config.map(([value]) => ({
    text: t(`pa.tabs.${value}`),
    value,
  })), [t, config]);

  return (
    <div>
      <TabsComponent tabs={tabs} tab={tab} onTabClicked={onTabClicked} />
      <Switch>
        {config.map(([path, component]) => (
          <Route key={path} path={activityUrl(`:id/${path}`)} component={component} />
        ))}
        <Route path={activityUrl(':id')} exact render={() => <Redirect to={activityUrl(`${id}/${config[0][0]}`)} />} />
      </Switch>
    </div>
  );
};
