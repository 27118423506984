import React from 'react';
import { SVGComponent } from './SvgProps';

export const WarningIcon: SVGComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path fill={props.color} d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
  </svg>
);

WarningIcon.defaultProps = {
  width: 24,
  height: 24,
  color: '#000000',
};
