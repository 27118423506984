import {
  LoadingIndicator, LoadingProvider, Text,
} from '@privacy-request/ui';
// @ts-ignore
import byteSize from 'byte-size';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
// @ts-ignore
import { PRFile } from '../../types/file/PRFile';

const Item = styled.div<{ disabled?: boolean; single?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ single }) => (single ? 'center' : 'flex-start')};
  width: ${({ single }) => (single ? 'unset' : '88px')};
  border: 1px solid transparent;
  padding: 6px;
  padding-right: ${({ single }) => (single ? '30px' : '6px')};
  padding-left: ${({ single }) => (single ? '14px' : '6px')};
  border-radius: 4px;
  transition: border 0.1s linear;
  &:hover {
    border: 1px solid ${({ disabled }) => (disabled ? 'transparent' : 'rgba(0,0,0,0.2)')};
  }
`;
Item.displayName = 'Item';

const Icon = styled.i`
  color: ${({ theme }) => theme.CTA_COLOR};
  font-size: 54px;
  text-align: center;
`;
Icon.displayName = 'Icon';

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
IconWrapper.displayName = 'IconWrapper';

const fontAwesomeFileTypes = ['word', 'video', 'powerpoint', 'pdf', 'image', 'excel', 'code', 'audio', 'archive', 'alt', 'upload', 'signature', 'prescription', 'medical-alt', 'medical', 'invoice-dollar', 'invoice', 'import', 'export', 'download', 'csv', 'contract'];

const I = ({ type }: any) => {
  const [_type, mime] = type.split('/');
  let clss = `-${mime}`;
  if (mime === 'json') {
    clss = '-alt';
  }
  if (_type === 'image') {
    clss = '-image';
  }
  if (_type === 'audio') {
    clss = '-audio';
  }
  if (_type === 'video') {
    clss = '-video';
  }
  if (_type === 'text') {
    clss = '-alt';
  }
  if (mime === 'zip') {
    clss = '-archive';
  }
  if (mime === 'msword' || mime === 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
    clss = '-word';
  }
  if (mime === 'vnd.ms-excel' || mime === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    clss = '-excel';
  }
  if (mime === 'vnd.ms-powerpoint' || mime === 'vnd.openxmlformats-officedocument.presentationml.presentation') {
    clss = '-powerpoint';
  }
  if (fontAwesomeFileTypes.indexOf(clss.split('-')[1]) === -1) {
    clss = '';
  }
  return <Icon className={`fas fa-file${clss}`} />;
};

const DeleteIcon = styled.i`
  position: relative;
  color: white;
  margin: 0;
  padding: 0;
`;
DeleteIcon.displayName = 'DeleteIcon';
DeleteIcon.defaultProps = { className: 'fas fa-times fa-xs' };

const DeleteIconWrapper = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.CTA_ERROR};
  border: 1px solid ${({ theme }) => theme.GREY};
  position: absolute;
  top: ${({ single }) => (single ? '8px' : '4px')};
  right: 4px;
  margin: 0;
  padding: 0;
  opacity: 0;
  transition: opacity 0.1s linear;
  ${Item}:hover & {
    opacity: 1;
  }
`;
DeleteIconWrapper.displayName = 'DeleteIconWrapper';

const styles: { [key: string]: React.CSSProperties } = {
  name: {
    fontSize: '12px',
    overflowWrap: 'anywhere',
    marginTop: '8px',
  },
  size: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
  },
};

export default ({
  file, disabled, onDelete, single, onClick: _onClick,
}: any) => {
  const size = byteSize(file.size);
  const [loading, setLoading] = useState(false);

  const _onDelete = useCallback((e: any) => {
    e.stopPropagation();
    onDelete();
  }, [onDelete]);

  const onClick = useCallback((_file: PRFile) => {
    setLoading(true);
    _onClick(_file).finally(() => setLoading(false));
  }, [_onClick]);

  return (
    <LoadingProvider>
      <Item single={single} disabled={disabled} onClick={() => onClick(file)}>
        {loading && <LoadingIndicator />}
        {!single && (
          <IconWrapper>
            <I type={file.type} />
          </IconWrapper>
        )}
        <Text style={single ? {} : styles.name} center={!single} bold={!single}>{file.name}</Text>
        {!single && (
          <Text center style={styles.size}>
            {size.value}
            {size.unit}
          </Text>
        )}
        {!disabled && (
          <DeleteIconWrapper single={single} onClick={_onDelete}>
            <DeleteIcon />
          </DeleteIconWrapper>
        )}
      </Item>
    </LoadingProvider>
  );
};
