import React, {
  useCallback,
  useMemo,
  useRef,
} from 'react';
import {
  ChevRight,
  Chip,
  useOnClickOutside,
} from '@privacy-request/ui';
import { FilterComponent, FilterComponentProps } from '../../ComplexFilterDropdown/FilterComponent.d';
import { useTheme } from 'styled-components';
import { useMe } from '../../../hooks/useMe';
import { useQueryPager } from '../../../hooks/useQueryPager/useQueryPager';
import { SubOrganization } from '../../../types/sub_organization/SubOrganization';
import { GetSubOrganizationsPaged } from '../../../apollo/sub_organizations/sub_organization.queries';
import { MultiselectFilter } from '../../ComplexFilterDropdown/filters/utils/createMultiselectFilter';
import keycode from 'keycode';

export const SubsidiariesFilterTray = (props: FilterComponentProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { onCloseTray } = props;
  useOnClickOutside(ref, onCloseTray);

  const { me } = useMe();
  const multi_org = me?.organization?.features?.multi_org_enabled;
  const is_admin = me?.role?.code === 'admin';

  const { results } = useQueryPager<SubOrganization>(GetSubOrganizationsPaged, {
    skip: !multi_org || !is_admin,
    parameterPrefix: 'org_',
    limit: 999,
  });
  const subs = useMemo(() => {
    if (is_admin) {
      return results;
    }

    return me?.subsidiaries || [];
  }, [results, is_admin, me?.subsidiaries]);

  return (
    <div className="filter-config" ref={ref}>
      <ul className="filter-list compact">
        {
          subs.map((s) => (
            <li><MultiselectFilter name="subsidiaries" title={s.name} value={s.id} allSelectedIfEmpty {...props} /></li>
          ))
        }
      </ul>
    </div>
  );
};

export const SubsidiariesFilter: FilterComponent = ({
  filterData,
  setOpen,
  isTrayOpen,
}: FilterComponentProps) => {
  const theme = useTheme();
  const onClick = useCallback((ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setOpen(() => (SubsidiariesFilterTray));
  }, [setOpen]);
  const onKeyPress = useCallback((e) => {
    switch (keycode(e)) {
      case 'space':
      case 'enter':
        onClick(e);
        break;
      default:
        break;
    }
  }, [onClick]);

  const count = [].concat(filterData.subsidiaries || []).length || 'All';

  return (
    <div
      className="flex spaced"
      onClick={isTrayOpen ? undefined : onClick}
      onKeyPress={onKeyPress}
      role="button"
      tabIndex={0}
    >
      <Chip
        color={theme.CTA_COLOR}
        textColor={count ? '#ffffff' : undefined}
      >
        {count}
      </Chip>
      <span>
        Subsidiaries
        <ChevRight />
      </span>
    </div>
  );
};

export const subsidiariesFilter = SubsidiariesFilter;
