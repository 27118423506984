import { Button } from '@privacy-request/ui';
import React, { useCallback, useState } from 'react';
import { backendUrl } from '../../utils/url';
import { useFetch } from '../../hooks/useFetch';
import { CachePolicies } from 'use-http';
// import { SystemIntegrationAttributes } from '@privacy-request/value-objects';
import { useDialog } from '../../hooks/useDialog';
import { TaskRetryModal } from './TaskRetryModal';
import { useToasts } from 'react-toast-notifications';

export type TaskRetryButtonProps = {
  systemId: number
  taskId: number
  requestId: number
  integrationVersion: number
};

export const TaskRetryButton = ({
  systemId,
  taskId,
  requestId,
  integrationVersion,
}: TaskRetryButtonProps) => {
  const [error, setError] = useState(false);
  const { get: fetch } = useFetch(backendUrl(`/systems/integrations/${systemId}`), { cachePolicy: CachePolicies.NETWORK_ONLY });
  const { addToast } = useToasts();

  const { show } = useDialog(TaskRetryModal, {
    requestId,
    taskId,
  });

  const onClick = useCallback(async (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    // First things first, let's check to see if there's a newer version of the
    // integration, and given the user the option of which to use
    const response = await fetch();

    if (!response || response.length === 0) {
      addToast('No integration configuration is present for this task. Please check the integration before trying again.', {
        appearance: 'error',
        autoDismiss: true,
      });
      setError(true);
      return;
    }

    // Get latest integration version, accounting for potentially unpredictable
    // ordering
    const latest = response.reduce((prev: any, current: any) => (
      prev.version > current.version ? prev : current
    ));
    const current = response.find((integration: any) => (integration.version === integrationVersion));
    show({
      current,
      latest,
      taskId,
    });
  }, [addToast, fetch, integrationVersion, show, taskId]);

  return (
    <Button secondary short onClick={onClick} style={{ marginLeft: '12px' }} error={error}>Retry Job</Button>
  );
};
