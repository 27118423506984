import { OrganizationThemeSettings } from '@privacy-request/value-objects';
import {
  Field, NoAuthorizationTemplate, Text,
} from '@privacy-request/ui';
import React, {
  useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { defaultColorTheme } from '../../constants/org-theme-defaults';
import useDownloadResponse from '../../hooks/useDownloadResponse';
import { useFetch } from '../../hooks/useFetch';
import { backendUrl } from '../../utils/url';

export const DownloadView = () => {
  const [status, setStatus] = useState('downloading');
  const [date] = useState(new Date());
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const token = search.get('token');
  const theme: Partial<OrganizationThemeSettings> = {
    background_color: search.get('bc') || defaultColorTheme.background_color,
    card_shadow_color: search.get('csc') || defaultColorTheme.card_shadow_color,
    card_background_color: search.get('cbc') || defaultColorTheme.card_background_color,
    font_color: search.get('fc') || defaultColorTheme.font_color,
  };
  const [t] = useTranslation('requests');
  const { get, response } = useFetch(backendUrl(`requests/download/${token}`));
  useDownloadResponse(response, `${date.toISOString()}.zip`);
  useEffect(() => {
    get();
  }, [get]);

  const message = response.headers?.get('x-pr-message');

  const ht = search.get('ht');
  useEffect(() => {
    if (ht) {
      const title = document.head.querySelector('title');
      if (title) {
        title.innerText = ht;
      }
    }
  }, [ht]);

  const fu = search.get('fu');
  useEffect(() => {
    if (fu) {
      const icon = document.head.querySelector('link[rel~=icon]');
      if (icon) {
        icon.setAttribute('href', decodeURIComponent(fu));
      }
    }
  }, [fu]);

  useEffect(() => {
    if (!response.status) {
      return;
    }
    if (message) {
      setStatus(decodeURIComponent(message));
      return;
    }
    if (response.status === 200) {
      setStatus('success');
    } else if (response.status === 404) {
      setStatus('expired');
    }
  }, [response.status, message]);

  return (
    <NoAuthorizationTemplate centerStyle={{ backgroundColor: `#${theme.background_color}` }} cardStyle={{ boxShadow: `0px 0 4px 2px #${theme.card_shadow_color}`, backgroundColor: `#${theme.card_background_color}` }} noLogo>
      <Field style={{ paddingBottom: 0 }}>
        <Text style={{ color: `#${theme.font_color}`, whiteSpace: 'pre-wrap' }}>
          {t(`download.${status}`, status)}
        </Text>
      </Field>
    </NoAuthorizationTemplate>
  );
};
