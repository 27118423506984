import React, {
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useIsAdminRoute } from '../../hooks/useIsAdminRoute';
import { useToasts } from 'react-toast-notifications';
import { ProcessingActivity } from '../../types/system/ProcessingActivity';
import { System } from '../../types/system/System';
import { ColumnDefinition, FieldBuilderProps } from './columns/types/ColumnDefinition';
import { TableRow } from '@privacy-request/ui/src/atoms/SASSTable';
import { ErrorBoundary } from '../ErrorBoundary';

export type ListRowProps = {
  /**
   * The list of fields/columns to be displayed
   */
  fields: ColumnDefinition[]
  /**
   * The row/record to display data for
   */
  row: System | ProcessingActivity

  // Row properties / states
  /**
   * If true, the row cannot be selected, clicked, or otherwise interacted with
   */
  isRowDisabled?: boolean
  /**
   * If true, the row has been selected, typically for bulk action
   */
  isRowSelected?: boolean
  /**
   * If true, the user can view the row contents
   */
  canViewRow: boolean
  /**
   * If true, the user can edit the row
   */
  canEditRow: boolean

  // Row actions
  /**
   * Called when the row is selected, typically for bulk action
   */
  onRowSelect?: any
  /**
   * Called when the row is to be archived
   */
  onRowArchive: any
  /**
   * Called when the row is to be taken out of the archive
   */
  onRowUnarchive: any
  /**
   * Called when the row is to be saved
   */
  onRowSave?: any
  /**
   * Call to retrieve a link to the entity page that provides more details
   * about the record
   */
  getRowLink: any
  /**
   * Pass an id in order to indicate that the row is currently being edited
   */
  setEditing: any
};

/**
 * A generic list row used to display both System and ProcessingActivity
 * records.
 */
export const ListRow = ({
  fields,
  row,
  isRowDisabled,
  isRowSelected,
  canViewRow,
  canEditRow,
  onRowSelect,
  onRowArchive,
  onRowUnarchive,
  onRowSave,
  getRowLink,
  setEditing,
}: ListRowProps) => {
  const [t] = useTranslation('systems');
  const isAdminRoute = useIsAdminRoute();
  // const { hasPermission } = usePermissions();
  // const { me } = useMe();
  const { addToast } = useToasts();

  // const [onSave] = useMutation(UpdateSystem);

  const archived = !!row.deleted_at;

  // const canView = useMemo(() => isAdminRoute || (hasPermission('systems.list.others') || row.contacts?.some(c => c.user_id === me?.id)), [row, isAdminRoute, hasPermission, me]);
  // const canEdit = useMemo(() => hasPermission('systems.edit.others') || row.contacts?.some(c => c.user_id === me?.id), [me, hasPermission, row.contacts]);
  const onForbidden = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (e.defaultPrevented) {
      return;
    }
    addToast(t('errors.no_access'), {
      autoDismiss: true,
      appearance: 'warning',
    });
  }, [t, addToast]);

  const fieldProps: FieldBuilderProps = useMemo((): FieldBuilderProps => ({
    row,
    getRowLink: !canViewRow ? () => (undefined) : getRowLink,
    onRowClick: !canViewRow ? onForbidden : () => {},
    onRowSelect: !isRowDisabled && !archived && !!canViewRow && !!canEditRow && onRowSelect && onRowSelect.bind(null, row.id),
    isSelected: isRowSelected,
    isAdminRoute,
    t,
    archived,
    canView: canViewRow,
    canEdit: canEditRow,
    onSave: onRowSave,
    setEditing,
    onDelete: onRowArchive,
    onUndelete: onRowUnarchive,
  }), [row, canViewRow, getRowLink, onForbidden, isRowDisabled, archived, canEditRow, onRowSelect, isRowSelected, isAdminRoute, t, onRowSave, setEditing, onRowArchive, onRowUnarchive]);

  return (
    <TableRow disabled={!canViewRow || isRowDisabled}>
      {
        fields.map((field, i) => (<ErrorBoundary key={i}><field.Cell key={i} {...fieldProps} /></ErrorBoundary>))
      }
    </TableRow>
  );
};
