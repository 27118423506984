import { DataMapSettingAttributes } from '@privacy-request/value-objects';
import gql from 'graphql-tag';
import { buildGQL } from '../utils/simpleGQLBuilder';

export const DataMapSettingFragment = gql`
  fragment data_map_setting_fragment on DataMapSetting {
    id
    organization_id
    require_asset_approval
    require_processing_activity_approval
  }
`;

export interface DataMapSettingsData {
  get_data_map_settings: DataMapSettingAttributes
}

export default buildGQL<DataMapSettingsData>({
  fragment: DataMapSettingFragment,
  path: 'organizations/me/data-map-settings',
  resource: 'data_map_settings',
  singular: 'DataMapSetting',
  plural: 'DataMapSettings',
  noid: true,
});
