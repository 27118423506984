import gql from 'graphql-tag';
import { System } from '../../types/system/System';
import { FieldCopyConfig } from '../../views/SystemsView/components/SystemConnectTemplate/hooks/useSystemTemplateSynchronize';
import { SystemFragment } from './systems.fragments';

export interface UpdateSystemResult {
  update_system: System
}

export interface UpdateSystemVariables {
  system: Partial<System>
  id: number
}

export const UpdateSystem = gql`
  mutation update_system($system: System!, $id: Int!) {
    update_system(input: $system, id: $id) @rest(
      type: "System" path: "systems/{args.id}" method: "PATCH" bodySerializer: "fileEncode"
    ) {
      ...system
    }
  }
  ${SystemFragment}
`;

export const UpdateSystemAdvanced = gql`
  mutation update_system($system: System!, $id: Int!) {
    update_system(input: $system, id: $id) @rest(
      type: "System" path: "systems/advanced/{args.id}" method: "PATCH" bodySerializer: "fileEncode"
    ) {
      ...system
    }
  }
  ${SystemFragment}
`;

export interface CreateSystemResult {
  create_system: System
}

export interface CreateSystemVariables {
  system: Partial<System>
}

export const CreateSystem = gql`
  mutation create_system($system: System!) {
    create_system(input: $system) @rest(
      type: "System" path: "systems" method: "POST"
    ) {
      ...system
    }
  }
  ${SystemFragment}
`;

export const DeleteSystem = gql`
  mutation delete_system($id: Int!) {
    delete_system(id: $id) @rest(
      type: "NoResult" path: "systems/{args.id}" method: "DELETE"
    ) {
      no_results
    }
  }
`;

export interface AttachSystemToTemplateVariables {
  system_id: number
  system_template_id: number
  config: FieldCopyConfig[]
}

export interface BulkActionSystemsVariables {
  processing_activity?: any;
  ids: number[];
}

export const AttachSystemToTemplate = gql`
  mutation attach_system_to_template($config: System!, $system_id: Int!, $system_template_id: Int!) {
    attach_system_to_template(input: $config, id: $system_id, template_id: $system_template_id) @rest(
      type: "System" path: "systems/{args.id}/copy-template-values/{args.template_id}" method:"PATCH"
    ) {
      ...system
    }
  }
  ${SystemFragment}
`;

export const UpdateBulkSystems = gql`
  mutation update_bulk_systems($data: any) {
    update_bulk_systems(input: $data) @rest(
      type: "System" path: "systems/bulk-update" method: "POST"
    ) {
      no_results
    }
  }
`;
