import {
  NoAuthorizationTemplate, Label, Input, ErrorText, Button, Field, Text,
} from '@privacy-request/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useRequestPasswordReset } from './hooks/useRequestPasswordReset';

export default () => {
  const {
    onSubmit,
    onChange,
    options,
    loading,
    result,
  } = useRequestPasswordReset();

  const [t] = useTranslation('auth');
  const history = useHistory();
  const onCancel = async () => {
    history.goBack();
  };

  return (
    <NoAuthorizationTemplate noLogo>
      {result && result.error === true && (
        <Field>
          <ErrorText>{t(`reset_password.${result.message}`)}</ErrorText>
        </Field>
      )}
      {result && result.error === false && (
        <Field>
          <Text>{t(`reset_password.${result.message}`)}</Text>
        </Field>
      )}
      {(!result || result.message !== 'request_sent') && (
        <form onSubmit={onSubmit}>
          <Field>
            <Label>
              {t('form.email')}
              :
            </Label>
            <Input
              autoFocus
              name="email"
              value={options.email || ''}
              onChange={onChange}
            />
          </Field>
          <Field>
            <Button onClick={onSubmit} disabled={loading} type="submit" fluid>{loading ? t('common:form.looking_up') : t('common:form.continue')}</Button>
          </Field>
          <Field style={{ paddingBottom: 0 }}>
            <Button onClick={onCancel} secondary disabled={loading} fluid>{t('common:form.cancel')}</Button>
          </Field>
        </form>
      )}
      {(result && result.message === 'request_sent') && (
        <Field style={{ paddingBottom: 0 }}>
          <Button onClick={onCancel} fluid>{t('common:form.continue')}</Button>
        </Field>
      )}
    </NoAuthorizationTemplate>
  );
};
