import {
  AuthenticationResult,
  BrowserCacheLocation,
  Configuration,
  EventMessage,
  EventType,
  PublicClientApplication,
  RedirectRequest,
} from '@azure/msal-browser';
import {
  AZURE_CLIENT_ID,
  SUBDOMAIN,
} from '../constants';
import store from '../store';
import {
  debug, error, warn,
} from '../utils/logging';
import {
  backendUrl,
  frontendBaseUrl,
  frontendSubdomainUrl,
} from '../utils/url';
import { stripAll } from '../utils/strip';

const { hash } = window.location;

const getTenantIdFromState = () => {
  if (!hash.startsWith('#code')) {
    return;
  }
  const params = new URLSearchParams(hash.slice(1));
  return JSON.parse(atob(params.get('client_info')!).toString()).utid;
};

/**
 * Query the backend to get the client subdomain from the tenant id.
 * @param tenant_id The tenant id received from the AAD auth callback
 * @returns The subdomain for the client
 */
const exchangeTenantIdForClientDomain = (tenant_id: string) => (
  fetch(backendUrl(`auth/azure/domain/${tenant_id}`))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((res) => (
      res.domain
    ))
);

export const redirectToDomainFromStateTenantId = (_hash?: string) => (
  exchangeTenantIdForClientDomain(getTenantIdFromState())
    .then((domain) => (
      redirectToDomain(domain, _hash)
    ))
);

export const redirectToDomain = (domain: string, hash?: string) => {
  const url = stripAll(frontendSubdomainUrl(domain, ''), '/') + (hash ?? '');
  window.location.href = url;
};

const checkAccounts = (pca: PublicClientApplication) => {
  // Default to using the first account if no account is active on page load
  if (!pca.getActiveAccount() && pca.getAllAccounts().length > 0) {
    pca.setActiveAccount(pca.getAllAccounts()[0]);
  }
};

const configuration: Configuration = {
  auth: {
    clientId: AZURE_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: frontendBaseUrl('madl/auth'),
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: false,
  },
};

class CustomPublicClientApplication extends PublicClientApplication {
  async initialize(): Promise<void> {
    pca.addEventCallback((message: EventMessage) => {
      switch (message.eventType) {
        case EventType.INITIALIZE_START:
        case EventType.INITIALIZE_END:
          break;
        // case EventType.HANDLE_REDIRECT_START:
        case EventType.HANDLE_REDIRECT_END:
        case EventType.LOGIN_START:
        case EventType.LOGIN_FAILURE:
        case EventType.LOGIN_SUCCESS:
        case EventType.LOGOUT_START:
        case EventType.LOGOUT_SUCCESS:
        case EventType.LOGOUT_FAILURE:
        case EventType.LOGOUT_END:
        case EventType.ACQUIRE_TOKEN_START:
        case EventType.ACQUIRE_TOKEN_SUCCESS:
        case EventType.ACQUIRE_TOKEN_FAILURE:
          // @ts-ignore
          store.dispatch({
            type: message.eventType,
            payload: message.payload as any,
          });
          break;

        case EventType.HANDLE_REDIRECT_START:
          // Try cached credentials
          const accounts = this.getAllAccounts();
          if (accounts && accounts.length > 0) {
            // If the IdToken has expired, refresh it. Otherwise use the cached token
            getIdToken(false);
          }
          // @ts-ignore
          store.dispatch({
            type: message.eventType,
            // @ts-ignore
            payload: message.payload,
          });
          break;

        default:
          debug('MSAL Event: ', message);
          warn('Unsupported MSAL event type: ', message.eventType);
          break;
      }
    });

    return super.initialize()
      .then(() => {
        checkAccounts(this);

        // Optional - This will update account state if a user signs in from another tab or window
        // this.enableAccountStorageEvents();
      });
  }

  async handleRedirectPromise(_hash?: string | undefined): Promise<AuthenticationResult | null> {
    return super.handleRedirectPromise(_hash)
      .then((res) => {
        const tenantId = res?.tenantId ?? getTenantIdFromState();

        if (res) {
          return exchangeTenantIdForClientDomain(tenantId)
            .then((domain) => (
              redirectToDomain(domain, res.state === 'launcher' ? '#launcher' : '')
            ))
            .then(() => (res));
        }

        // If we're at the common entrypoint, we need to direct the user to
        // their organization
        if (!SUBDOMAIN && hash.startsWith('#code')) {
          return exchangeTenantIdForClientDomain(tenantId)
            .then((domain) => (
              redirectToDomain(domain, hash)
            ))
            .then(() => (res));
        }

        // Otherwise, MSAL should handle ingesting the code
        // return getIdToken().then(() => (res));
        return res;
      });
  }
}

export const pca = new CustomPublicClientApplication(configuration);

const request: RedirectRequest = {
  scopes: [
    'openid',
    'email',
  ],
  prompt: 'select_account',
};

export const loginRedirect = async (_request?: Partial<RedirectRequest>) => {
  await pca.loginRedirect({
    ...request,
    ..._request ?? {},
  })
    .catch((err) => {
      if (err.errorCode === 'interaction_in_progress') {
        // eslint-disable-next-line no-console
        console.error('Interaction in progress');
      } else {
        throw err;
      }
    });
};

export const getIdToken = async (refresh = true) => {
  const accounts = pca.getAllAccounts();
  if (accounts.length > 0) {
    const expiry = (accounts[0].idTokenClaims as any)?.exp * 1000;
    if (expiry < (Date.now())) {
      // Need to refresh
      if (refresh) {
        return pca.loginRedirect(request);
      }
      return null;
    }
    try {
      return (await pca.acquireTokenSilent({
        ...request,
        account: accounts[0],
      })).idToken;
    } catch (err) {
      // Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
      error(err);
    }
  }

  return null;
};
