// TODO Move to pr-common
const enumToStringArray = (e: any) => ([
  ...new Set(
    Object.keys(e)
      .filter((k) => (
        typeof e[k] === 'number'
            || typeof k === 'string'
      )),
  ),
]);

export enum TagItemType {
  // System
  'storage_format' = 'storage_format',
  'hosting_type' = 'hosting_type',
  'hosting_location' = 'hosting_location',
  'country' = 'country',
  'hosting_provider' = 'hosting_provider',
  'organizational_security_measure' = 'organizational_security_measure',
  'technical_security_measure' = 'technical_security_measure',

  // Processing activity
  'lawful_basis' = 'lawful_basis',
  'subject_category' = 'subject_category',
  'destination_of_personal_data' = 'destination_of_personal_data',
  'cross_border_transfer' = 'cross_border_transfer',
  'data_disposal_method' = 'data_disposal_method',
  'purpose_of_processing' = 'purpose_of_processing',
  'origin_of_personal_data' = 'origin_of_personal_data',

  // Both
  'business_function' = 'business_function',
  'personal_data_category' = 'personal_data_category',
}

export const TagItemTypes = enumToStringArray(TagItemType) as (keyof typeof TagItemType)[];

export interface TagItemConfigItem {
  hasCategories?: boolean
  editableCategories?: boolean
  defaultCategory?: string
}
export const TagItemConfig: Record<string, TagItemConfigItem> = {
  storage_format: {},
  hosting_type: {},
  hosting_location: {},
  country: {},
  hosting_provider: {},
  organizational_security_measure: {},
  technical_security_measure: {},
  lawful_basis: {},
  subject_category: {},
  cross_border_transfer: {
    hasCategories: false,
    editableCategories: false,
    defaultCategory: 'Transfer Mechanisms',
  },
  data_disposal_method: {},
  purposes_of_processing: {
    hasCategories: true,
    editableCategories: true,
  },
  purpose_of_processing: {
    hasCategories: true,
    editableCategories: true,
  },
  origin_of_personal_data: {
    hasCategories: false,
    editableCategories: false,
    defaultCategory: 'Business Operations',
  },
  business_function: {},
  personal_data_category: {
    hasCategories: true,
    editableCategories: true,
  },
  // TODO this should be based on tag-item name rather than field name.
  personal_data_categories: {
    hasCategories: true,
    editableCategories: true,
  },
};

export interface TagItem {
  /**
   * The unique id of the tag-like item
   */
  id: number
  /**
   * The type or descriminator of the tag item
   */
  type: TagItemType | keyof typeof TagItemType
  /**
   * The name or title of the tag-like item
   */
  name: string
  /**
   * The organization that owns the tag item
   */
  organization_id?: number

  // Helper fields for certain components
  count?: number
}
