import React, {
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { System } from '../../../types/system/System';
import { permissionCodes } from '@privacy-request/basic-types';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../../store/useSelector';
import { useIsAdminRoute } from '../../../hooks/useIsAdminRoute';
import { useMe } from '../../../hooks/useMe';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useVisibilityChangedCallback } from '../../../hooks/useVisibilityChangedCallback';
import { setSystemErrors, setSystem as clearSystem } from '../../../store/system/system.actions';
import { usePermissions } from '../../../hooks/usePermissions';
import {
  CreateSystem,
  CreateSystemResult,
  CreateSystemVariables,
  UpdateSystem,
  UpdateSystemResult,
  UpdateSystemVariables,
} from '../../../apollo/systems/systems.mutations';
import { CreateSystemTemplate, UpdateSystemTemplate } from '../../../apollo/systems/templates/system_templates.mutations';
import { GetSystemTemplate } from '../../../apollo/systems/templates/system_templates.queries';
import { useTranslation } from 'react-i18next';
import { userToName } from '@privacy-request/common/src/utils/userToName';
import { handleErrors } from '../../../utils/handleErrors';
import { stripNull } from '@privacy-request/utils';
import {
  GetSystem,
  GetSystemData,
  GetSystemVariables,
} from '../../../apollo/systems/systems.queries';
import { useStatefulEditing } from '../../../hooks/useStatefulEditing';

export type UseSystemProps = {
  id_override?: number
  mutation_override?: any
  is_template?: boolean
};

export type SystemManager = {
  /**
   * The current representation of the system after any updates have been made,
   * but not necessarily persisted.
   */
  current: Partial<System>
  /**
   * The original representation of the system as retrieved from the server,
   * without any additional modifications.
   */
  original?: System
  /**
   * True if we are actively trying to load the system from the server.
   */
  loading: boolean
  /**
   * True if we are actively trying to persist the system to the server.
   */
  saving: boolean
  /**
   * A set of errors that occurred when loading or saving the system, if
   * available.
   */
  errors: any
  /**
   * If true, indicates that the entity has been updated with newer data, and
   * that the values of `original` and `current` are no longer equivalent.
   */
  dirty: boolean

  onSave: (additionalEdits?: Partial<System>) => Promise<System | undefined>
  bulkUpdate: (edits: any) => void
  onChange: (e: any) => void
  onRevert: () => void
  canEdit: boolean
  canEditPerm: (pc: permissionCodes) => boolean
};

const updateReducer = (acc: any, [key, value]: any[]) => {
  if (key === 'dpia_reason') {
    acc.dpia_completed = { $set: false };
  } else if (key === 'dpia') {
    acc.dpia_completed = { $set: true };
  } else {
    acc[key] = { $set: value };
  }
  return acc;
};

/**
 * Create a new system manager instance, which by default manages the system
 * indicated by the :id path parameter.
 *
 * The system manager that is produced by this hook is typically fed into an
 * instance of `SystemManagerContextProvider` in order to pass the contents
 * down and allow the use of the `useSystem` hook. However, there are
 * circumstances where this hook may be used outside of a typical system
 * context, such as in the case of bulk actions.
 */
export const useSystem = ({
  id_override,
  mutation_override,
  is_template,
}: UseSystemProps = {}): SystemManager => {
  const { id: _id } = useParams<any>();
  const id = id_override || _id;
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const errors = useSelector(s => s.system.errors);
  const isAdminRoute = useIsAdminRoute();
  const { me } = useMe();

  const [t] = useTranslation('systems');

  const skip = !id || id === 'new';

  // const pollInterval = useSmartPoll();
  const {
    data,
    loading,
    refetch,
  } = useQuery<GetSystemData, GetSystemVariables>((isAdminRoute || is_template) ? GetSystemTemplate : GetSystem, {
    skip,
    fetchPolicy: 'cache-first',
    variables: { id },
  });
  useVisibilityChangedCallback(refetch, { onlyCallWhenVisible: true, skip });

  const {
    bulkUpdate,
    onChange,
    onRevert,
    current,
    setCurrent,
    edits,
    dirty,
  } = useStatefulEditing({
    updateReducer,
    original: data?.system,
  });

  const [createSystem, { loading: creating }] = useMutation<CreateSystemResult, CreateSystemVariables>(isAdminRoute ? CreateSystemTemplate : CreateSystem);
  const [updateSystem, { loading: updating }] = useMutation<UpdateSystemResult, UpdateSystemVariables>(mutation_override || (isAdminRoute ? UpdateSystemTemplate : UpdateSystem));

  const { hasPermission } = usePermissions();

  const canEdit = useMemo(() => {
    const contacts = data?.system.contacts || [];
    return !!isAdminRoute || hasPermission('systems.edit.others') || !!contacts.find(c => c.user_id === me?.id);
  }, [data, me, hasPermission, isAdminRoute]);

  const canEditPerm = useCallback((pc: permissionCodes) => canEdit && hasPermission(pc), [canEdit, hasPermission]);

  const onSave = useCallback(async (additionalEdits?: Partial<System>) => {
    const relatedSystems: any = current.system_related_systems?.map((srs) => ({
      system_id: srs.system_id,
      related_system_id: srs.related_system_id,
    }));
    if (dirty && relatedSystems?.length) {
      edits.system_related_systems = relatedSystems;
    }
    const changes: Partial<System> = {
      ...edits,
      ...(additionalEdits || {}),
    };

    if (current.id) {
      try {
        // below code ensure that if contact type is user then email is not get saved in the system contact
        changes.contacts?.map(contact => { if (contact.create_user) { delete contact?.email; } return contact; });

        const result = await updateSystem({ variables: { system: changes, id: current.id } });
        dispatch(clearSystem({})); // Clear the system
        setCurrent(data?.system || {});
        changes.contacts?.filter(c => (c.invite || c.create_user)).forEach((c) => {
          const name = c.user ? userToName(c.user) : c.create_user?.email;
          addToast(t('form.user_invited', { name }), { appearance: 'success', autoDismiss: true });
        });
        onRevert();
        await refetch({ id });
        return result.data?.update_system;
      } catch (e) {
        if (e instanceof Error) {
          addToast(e.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        dispatch(setSystemErrors(handleErrors(e, t)));
        return;
      }
    }

    try {
      const result = await createSystem({ variables: { system: changes } });
      dispatch(clearSystem({})); // Clear the system
      changes.contacts?.filter(c => (c.invite || c.create_user)).forEach((c) => {
        const name = c.user?.first_name ? stripNull`${c.user?.first_name} ${c.user?.last_name}` : c.user?.email;
        addToast(t('form.user_invited', { name }), { appearance: 'success', autoDismiss: true });
      });
      onRevert();
      return result.data?.create_system;
    } catch (e) {
      dispatch(setSystemErrors(handleErrors(e, t)));
    }
  }, [current.system_related_systems, current.id, dirty, edits, updateSystem, dispatch, setCurrent, data?.system, onRevert, refetch, id, addToast, t, createSystem]);

  return {
    dirty,
    errors,
    current,
    loading,
    saving: creating || updating,
    original: data?.system,
    onSave,
    bulkUpdate,
    onChange,
    onRevert,
    canEdit,
    canEditPerm,
  };
};

export const SystemManagerContext = React.createContext<SystemManager>(undefined!);

export const SystemManagerContextProvider = ({ manager, children }: any) => (
  <SystemManagerContext.Provider value={manager}>
    {children}
  </SystemManagerContext.Provider>
);

/**
 * Utilize the system manager that is stored in the nearest
 * SystemManagerContext. Note that this _requires_ a higher-level
 * SystemManagerContext, without which your component will likely fail at
 * runtime.
 * @returns SystemManager
 */
export const useContextualSystem = () => (
  useContext(SystemManagerContext) ?? {}
);
