import React, { Component } from 'react';
import { ToggleComponent } from '../../atoms';

export type ToggleEvent = {
  target: {
    name: string
    checked: boolean
  }
};

export interface ToggleProps {
  /**
   * Wether the Toggle is checked or unchecked.
   */
  checked: boolean
  /**
   * Called when the toggle changes value, with the value of target being the new value.
   */
  onChange?: (e: ToggleEvent) => void
  /**
   * Called when the toggle is activated.
   */
  onChecked?: (e: ToggleEvent) => void
  /**
   * Called when the toggle is deactivated.
   */
  onUnchecked?: (e: ToggleEvent) => void
  /**
   * Name of the component
   */
  name: string
}

export interface ToggleState {
  checked: boolean
}

export class Toggle extends Component<ToggleProps, ToggleState> {
  constructor(props: ToggleProps) {
    super(props);
    this.state = { checked: props.checked };
  }

  handleClick(e: MouseEvent) {
    this.toggle();
  }

  handleKeyUp(e: KeyboardEvent) {
    switch (e.which) {
      case 32: // space
      case 13: // enter
        this.toggle();
        break;
      default:
        break;
    }
  }

  reevaluate() {
    this.setState({ checked: this.props.checked });
  }

  toggle() {
    this.setState({ checked: !this.state.checked }, () => {
      const { name } = this.props;

      if (this.props.onChange) {
        this.props.onChange({ target: { name, checked: this.state.checked } });
      }

      if (this.props.onChecked && this.state.checked) {
        this.props.onChecked({ target: { name, checked: true } });
      }

      if (this.props.onUnchecked && !this.state.checked) {
        this.props.onUnchecked({ target: { name, checked: false } });
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.checked !== this.state.checked) {
      this.setState({ checked: nextProps.checked });
    }
  }

  render() {
    return (
      <ToggleComponent
        checked={this.state.checked}
        onKeyUp={(e: KeyboardEvent) => this.handleKeyUp(e)}
        onClick={(e: MouseEvent) => this.handleClick(e)}
      />
    );
  }
}
