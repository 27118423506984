import React, { useCallback, useRef } from 'react';
import {
  ChevRight,
  Chip,
  useOnClickOutside,
} from '@privacy-request/ui';
import { FilterComponent, FilterComponentProps } from '../../ComplexFilterDropdown/FilterComponent.d';
import { useTheme } from 'styled-components';
import keycode from 'keycode';
import { MultiselectFilter } from '../../ComplexFilterDropdown/filters/utils/createMultiselectFilter';

export const RelationshipFilterTray = (props: FilterComponentProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { onCloseTray } = props;
  useOnClickOutside(ref, onCloseTray);
  return (
    <div className="filter-config" ref={ref}>
      <ul className="filter-list compact">
        <li><MultiselectFilter name="gdpr_relationship" title="Controller" value="controller" allSelectedIfEmpty {...props} /></li>
        <li><MultiselectFilter name="gdpr_relationship" title="Joint Controller" value="joint-controller" allSelectedIfEmpty {...props} /></li>
        <li><MultiselectFilter name="gdpr_relationship" title="Processor" value="processor" allSelectedIfEmpty {...props} /></li>
        <li><MultiselectFilter name="gdpr_relationship" title="Sub-Processor" value="sub-processor" allSelectedIfEmpty {...props} /></li>
        <li><MultiselectFilter name="gdpr_relationship" title="Other" value="other" allSelectedIfEmpty {...props} /></li>
      </ul>
    </div>
  );
};

/**
 * The relationship filter allows the user to filter systems by their GDPR
 * relationship. This filter should be hidden if GDPR is disabled for the org.
 */
export const RelationshipFilter: FilterComponent = ({
  filterData,
  setOpen,
  isTrayOpen,
}: FilterComponentProps) => {
  const theme = useTheme();
  const onClick = useCallback((ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setOpen(() => (RelationshipFilterTray));
  }, [setOpen]);
  const onKeyPress = useCallback((e) => {
    switch (keycode(e)) {
      case 'space':
      case 'enter':
        onClick(e);
        break;
      default:
        break;
    }
  }, [onClick]);

  const count = filterData.gdpr_relationship?.length ?? 5;

  return (
    <div
      className="flex spaced"
      onClick={isTrayOpen ? undefined : onClick}
      onKeyPress={onKeyPress}
      role="button"
      tabIndex={0}
    >
      <Chip
        color={count > 0 ? theme.CTA_COLOR : '#ffffff'}
        textColor={count ? '#ffffff' : undefined}
      >
        {count}
      </Chip>
      <span>
        Relationship
        <ChevRight />
      </span>
    </div>
  );
};

export const relationshipFilter = RelationshipFilter;
