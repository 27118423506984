import React from 'react';
import styled from 'styled-components';

interface StatusProps {
  error?: boolean
  warn?: boolean
  neutral?: boolean
  children?: any
}

const evaluateBackgroundColor = ({
  error, warn, theme, neutral,
}: any) => {
  if (neutral) return theme.GREY;
  if (error) return theme.CTA_ERROR;
  if (warn) return theme.CTA_WARN;
  return theme.CTA_GREEN;
};

export const StatusDisplay = styled.div<StatusProps>`
  background-color: ${evaluateBackgroundColor};
  color: ${({ theme, neutral }) => (neutral ? theme.BLACK : theme.WHITE)};
  padding: 6px;
  width: 62px;
  border-radius: 128381px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
StatusDisplay.displayName = 'Status';
