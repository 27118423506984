import {
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { useMe } from '../../../hooks/useMe';
import { usePermissions } from '../../../hooks/usePermissions';
import { BulkActionModal } from './BulkActionModal';
import { AddContactsModal } from './modals/AddContactsModal';
import { AddAssetsModal } from './modals/AddAssetsModal';
import { AddPurposesModal } from './modals/AddPurposesModal';
import { AddTransfersModal } from './modals/AddTransfersModal';
import { RequestReviewsModal } from './modals/RequestReviewsModal';
import { useTranslation } from 'react-i18next';
import { SelectionItem } from '@privacy-request/ui';
import { AddActivitiesModal } from './modals/AddActivitiesModal';
import { AddSecurityMeasuresModal } from './modals/AddSecurityMeasuresModal';
import { Organization } from '../../../types';
import { AddSubsidiariesModal } from './modals/AddSubsidiariesModal';
import { SetPublishedStatusModal } from './modals/SetPublishedStatusModal';

type BulkActionEnabledContext = {
  entityType: 'systems' | 'activities'
  hasPermission: ReturnType<typeof usePermissions>['hasPermission']
  organization: Organization
};

type BulkAction = {
  text: string
  enabled: (ctx: BulkActionEnabledContext) => boolean
  component: BulkActionModal
};

type BulkActionList = { [k: string]: BulkAction };

const actions: BulkActionList = {
  addContacts: {
    text: 'common:bulk_actions.add_contacts',
    enabled: ({ entityType, hasPermission }) => (
      (entityType === 'activities' && hasPermission('processing_activities.edit.contacts'))
      || (entityType === 'systems' && hasPermission('systems.edit.contacts'))
    ),
    component: AddContactsModal,
  },
  addAssets: {
    text: 'common:bulk_actions.add_assets',
    enabled: ({ entityType, hasPermission }) => (
      entityType === 'activities' && hasPermission('processing_activities.edit.systems')
    ),
    component: AddAssetsModal,
  },
  addPurposes: {
    text: 'common:bulk_actions.add_purposes',
    enabled: ({ entityType, hasPermission }) => (
      entityType === 'activities' && hasPermission('processing_activities.edit.purposes_of_processing')
    ),
    component: AddPurposesModal,
  },
  addTransfers: {
    text: 'common:bulk_actions.add_transfers',
    enabled: ({ entityType, hasPermission }) => (
      entityType === 'activities' && hasPermission('processing_activities.edit.cross_border_transfers')
    ),
    component: AddTransfersModal,
  },
  addActivities: {
    text: 'common:bulk_actions.add_activities',
    enabled: ({ entityType, hasPermission }) => (
      entityType === 'systems' && hasPermission('systems.edit.processing_activities')
    ),
    component: AddActivitiesModal,
  },
  addSecurityMeasures: {
    text: 'common:bulk_actions.add_security_measures',
    enabled: ({ entityType, hasPermission }) => (
      entityType === 'systems' && hasPermission('systems.edit.security_measure_details')
    ),
    component: AddSecurityMeasuresModal,
  },
  requestReviews: {
    text: 'common:bulk_actions.request_reviews',
    enabled: ({ entityType, hasPermission }) => (
      (entityType === 'activities' && hasPermission('processing_activities.actions.request_review'))
      || (entityType === 'systems' && hasPermission('systems.actions.request_review'))
    ),
    component: RequestReviewsModal,
  },
  addSubsidiaries: {
    text: 'common:bulk_actions.add_subsidiaries',
    enabled: ({ entityType, organization }) => (
      organization?.features?.multi_org_enabled
      && (
        entityType === 'activities' // TODO Permissions
        || entityType === 'systems' // TODO Permissions
      )
    ),
    component: AddSubsidiariesModal,
  },
  setStatus: {
    text: 'common:bulk_actions.set_published_status',
    enabled: ({ entityType }) => (
      entityType === 'activities' // TODO Permissions
      || entityType === 'systems' // TODO Permissions
    ),
    component: SetPublishedStatusModal,
  },
};

export type BulkActionController = {
  entityType: 'systems' | 'activities'
  enabled: boolean
  enabledActions: BulkActionList
  selectionItems: SelectionItem[]
  selectAction: Dispatch<SetStateAction<string | number | undefined>>,
  selectedAction: keyof typeof actions | undefined,
};

const disabled = {
  entityType: undefined!,
  enabled: false,
  enabledActions: {},
  selectionItems: [],
  selectAction: () => {},
  selectedAction: undefined,
};

export const useBulkActionController = (entityType: 'systems' | 'activities'): BulkActionController => {
  const [t] = useTranslation();
  const { me } = useMe();
  const { hasPermission } = usePermissions();

  const [selectedAction, selectAction] = useState<keyof typeof actions | undefined>(undefined);

  const enabledActions = useMemo(() => (
    Object.keys(actions).reduce((result, key) => {
      const ctx = {
        entityType,
        hasPermission,
        organization: me?.organization!,
      };
      if (actions[key].enabled(ctx)) {
        result[key] = actions[key];
      }
      return result;
    }, {} as BulkActionList)
  ), [entityType, hasPermission, me?.organization]);

  const selectionItems = useMemo(() => (
    Object.keys(enabledActions).reduce((result, key) => {
      result.push({
        text: t(enabledActions[key].text),
        value: key,
      });
      return result;
    }, [] as any)
  ), [enabledActions, t]);

  if (!me?.organization?.features?.enable_bulk_actions) {
    return disabled;
  }

  return {
    entityType,
    enabled: Object.keys(enabledActions).length > 0,
    enabledActions,
    selectionItems,
    selectAction,
    selectedAction,
  };
};
