import React from 'react';
import styled, { withTheme } from 'styled-components';

import { Card } from '../../atoms/Card/Card';
import { Logo } from '../../atoms/icons';
import { Title } from '../../atoms/Title';

export interface NoAuthorizationTemplateProps {
  /**
   * Style overrides for the container that manages the width of the centered portion of the view.
   */
  containerStyle?: React.CSSProperties
  /**
   * Style overrides for the FloatingCard element inside the container.
   */
  cardStyle?: React.CSSProperties
  /**
   * The title underneath the Privacy Request logo of the NoAuthorizationView.
   *
   * @default "Privacy Request"
   */
  title?: string
  /**
   * Any react node type that appears above the container content (On top of the card)
   */
  topContent?: React.ReactNode
  /**
   * Boolean prop to hide the logo from the <FloatingCard />
   */
  noLogo?: boolean
  /**
   * The style for the `title` text element.
   */
  headerStyle?: React.CSSProperties
  /**
   * The style for entire wrapping div, that manages centering the container using flexbox.
   */
  centerStyle?: React.CSSProperties
}

const CenteredPage = styled.div`
  background-color: ${({ theme }) => theme.CTA_COLOR};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  transition: background-color 0.15s linear;
`;

export const CenteredHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 40px;
`;

const ContainerWidth = styled.div`
  width: 400px;
`;

export const NoAuthorizationTemplate = withTheme(({
  theme, containerStyle, topContent, children, noLogo, headerStyle = {}, cardStyle, centerStyle,
}: any) => (
  <CenteredPage className="centered-page" style={centerStyle}>
    {topContent}
    <ContainerWidth style={containerStyle}>
      {children && (
        <Card floating style={cardStyle}>
          {!noLogo && (
            <CenteredHeader style={{ paddingBottom: '48px', paddingTop: '24px' }}>
              <Logo color={theme.CTA_COLOR} />
            </CenteredHeader>
          )}

          {children}

        </Card>
      )}
    </ContainerWidth>
  </CenteredPage>
)) as React.FC<NoAuthorizationTemplateProps>;
