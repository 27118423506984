import { useMutation, useQuery } from '@apollo/react-hooks';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  CreateProcessingActivity,
  CreateProcessingActivityResult,
  SaveProcessingActivityVariables,
  UpdateProcessingActivity,
  UpdateProcessingActivityResult,
} from '../../../apollo/systems/processing_activites/processing_activities.mutations';
import {
  GetProcessingActivity,
  GetProcessingActivityData,
  GetProcessingActivityVariables,
  GetSuggestedPersonalDataCategories,
} from '../../../apollo/systems/processing_activites/processing_activities.queries';
import { useMe } from '../../../hooks/useMe';
import { usePermissions } from '../../../hooks/usePermissions';
import { useSelector } from '../../../store/useSelector';
import { ProcessingActivity } from '../../../types/system/ProcessingActivity';
import { userToName } from '@privacy-request/common/src/utils/userToName';
import { useVisibilityChangedCallback } from '../../../hooks/useVisibilityChangedCallback';
import { permissionCodes } from '@privacy-request/basic-types';
import { useStatefulEditing } from '../../../hooks/useStatefulEditing';

export type UseActivityProps = {
  id_override?: number
  mutation_override?: any
};

export type ActivityManager = {
  /**
   * The current representation of the system after any updates have been made,
   * but not necessarily persisted.
   */
  current: Partial<ProcessingActivity>
  /**
   * The original representation of the system as retrieved from the server,
   * without any additional modifications.
   */
  original?: ProcessingActivity
  /**
   * True if we are actively trying to load the system from the server.
   */
  loading: boolean
  /**
   * True if we are actively trying to persist the system to the server.
   */
  saving: boolean
  /**
   * A set of errors that occurred when loading or saving the system, if
   * available.
   */
  errors: any
  /**
   * If true, indicates that the entity has been updated with newer data, and
   * that the values of `original` and `current` are no longer equivalent.
   */
  dirty: boolean

  onSave: (additionalEdits?: Partial<ProcessingActivity>) => Promise<ProcessingActivity | undefined>
  bulkUpdate: (edits: any) => void
  onChange: (e: any) => void
  onRevert: () => void
  canEdit: boolean
  canEditPerm: (pc: permissionCodes) => boolean
};

const updateReducer = (acc: any, [key, value]: any[]) => {
  if (key === 'dpia_reason') {
    acc.dpia_completed = { $set: false };
  } else if (key === 'dpia') {
    acc.dpia_completed = { $set: true };
  } else {
    acc[key] = { $set: value };
  }
  return acc;
};

/**
 * Retrieves and manages information about a particular activity. Designed to
 * be used predominantly in conjunction with the ActivityManagerContext below
 * so that multiple hook instances do not need to be kept around and the usage
 * implementation can be simplified.
 *
 * Only use explicitly where no ActivityManagerContext is available.
 */
export const useActivity = ({
  id_override,
  mutation_override,
}: UseActivityProps = {}): ActivityManager => {
  const { id: _id } = useParams<any>();
  const id = id_override || _id;
  const { addToast } = useToasts();
  const errors = useSelector(s => s.processing_activity.errors);
  const { me } = useMe();

  const [t] = useTranslation('systems');

  const skip = !id || id === 'new';

  // const pollInterval = useSmartPoll();
  const {
    data,
    loading,
    refetch,
  } = useQuery<GetProcessingActivityData, GetProcessingActivityVariables>(GetProcessingActivity, {
    skip,
    variables: { id: Number(id) },
    fetchPolicy: 'cache-first',
  });
  useVisibilityChangedCallback(refetch, { onlyCallWhenVisible: true });

  const {
    bulkUpdate,
    onChange,
    onRevert,
    current,
    setCurrent,
    edits,
    dirty,
  } = useStatefulEditing({
    updateReducer,
    original: data?.get_processing_activity,
  });

  const [createActivity, { loading: creating }] = useMutation<CreateProcessingActivityResult, SaveProcessingActivityVariables>(CreateProcessingActivity);
  const [updateActivity, { loading: updating }] = useMutation<UpdateProcessingActivityResult, SaveProcessingActivityVariables>(mutation_override ?? UpdateProcessingActivity, { refetchQueries: [{ query: GetSuggestedPersonalDataCategories, variables: { id: Number(id) } }] });

  const { hasPermission } = usePermissions();

  const canEdit = useMemo(() => {
    const contacts = data?.get_processing_activity.contacts || [];
    return hasPermission('systems.edit.others') || !!contacts.find(c => c.user_id === me?.id);
  }, [data, me, hasPermission]);

  const canEditPerm = useCallback((pc: permissionCodes) => canEdit && hasPermission(pc), [canEdit, hasPermission]);

  useEffect(() => {
    if (!loading && data) {
      if (data.get_processing_activity?.processing_countries && typeof data.get_processing_activity?.processing_countries === 'string') {
        // @ts-ignore
        data.get_processing_activity.processing_countries = data.get_processing_activity.processing_countries.split(',');
      }
      setCurrent({ ...data.get_processing_activity, ...edits });
    } else if (skip) {
      setCurrent({ ...edits });
    }
  }, [loading, edits, data, skip, setCurrent]);

  const onSave = useCallback(async (additionalEdits?: Partial<ProcessingActivity>) => {
    const changes: Partial<ProcessingActivity> = {
      ...edits,
      ...(additionalEdits || {}),
    };
    const notifyInvites = () => {
      changes.contacts?.filter(c => c.invite || c.create_user).forEach((c) => {
        const name = c.user ? userToName(c.user) : c.create_user?.email;
        addToast(t('form.user_invited', { name }), { appearance: 'success', autoDismiss: true });
      });
    };
    // below code ensure that if contact type is user then email is not get saved in the system contact
    changes.contacts?.map(contact => { if (contact.create_user) { delete contact?.email; } return contact; });

    if (current.id) {
      try {
        const result = await updateActivity({ variables: { id: current.id, activity: changes } });
        onRevert();
        notifyInvites();
        return result.data?.update_processing_activity;
      } catch (e) {
        if (e instanceof Error) {
          addToast(e.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }
    }

    try {
      const result = await createActivity({ variables: { activity: changes } });
      notifyInvites();
      return result.data?.create_processing_activity;
    } catch (e) {
      if (e instanceof Error) {
        addToast(e.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
  }, [edits, current.id, addToast, t, updateActivity, onRevert, createActivity]);

  return {
    current,
    original: data?.get_processing_activity,
    errors,
    saving: updating || creating,
    loading: !data && loading,
    bulkUpdate,
    onChange,
    onRevert,
    onSave,
    dirty,
    canEdit,
    canEditPerm,
  };
};

export const ActivityManagerContext = React.createContext<ActivityManager>(undefined!);

export const ActivityManagerContextProvider = ({ manager, children }: any) => (
  <ActivityManagerContext.Provider value={manager}>
    {children}
  </ActivityManagerContext.Provider>
);

export const useContextualActivity = () => (
  useContext(ActivityManagerContext) ?? {}
);
