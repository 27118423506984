/**
 * Get the value of the first key from a map.
 * @param data The map to get a value from
 * @returns The value, or undefined if not found
 */
export const getOnly = (data: any) => {
  if (data && Object.keys(data).length > 0) {
    return data[Object.keys(data)[0]];
  }
  return undefined;
};
