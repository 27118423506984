import { OverlayContainer } from '@privacy-request/ui';
import React, { useMemo, useState } from 'react';

export type UseModalContext = {
  set: (node: React.ReactNode) => void
  modal: React.ReactNode
};

export const modalContext = React.createContext<UseModalContext>({
  set() {},
  modal: undefined,
});

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [modal, set] = useState<React.ReactNode>();
  const value = useMemo(() => ({
    set,
    modal,
  }), [modal]);
  return (
    <modalContext.Provider value={value}>
      {children}
      {
        modal ? (
          <OverlayContainer className="active">
            {modal}
          </OverlayContainer>
        ) : null
      }
    </modalContext.Provider>
  );
};
