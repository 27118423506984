import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Button, Field } from '@privacy-request/ui';
import { useTranslation } from 'react-i18next';
import { useRouting } from '../../../../hooks/useRouting';
import { useSystemBulkActionsEdit } from '../../../../views/SystemsView/hooks/useSystemBulkActionsEdit';
import Modal from '../../../OverlayProvider/Modal';
import { CustomFieldConfig, CustomFields } from '../../../CustomFields/CustomFields';
import { BulkActionModal, BulkActionModalProps } from '../BulkActionModal';
import { useProcessingActivityBulkActionsEdit } from '../../../../views/ProcessingActivitiesView/hooks/useProcessingActivityBulkActionsEdit';

export const SetPublishedStatusModal: BulkActionModal = ({ onCancel, controller }: BulkActionModalProps) => {
  const { onSetPublishedStatus: onSetAssetPublishedStatus } = useSystemBulkActionsEdit();
  const { onSetPublishedStatus: onSetActivityPublishedStatus } = useProcessingActivityBulkActionsEdit();
  const { goBack } = useRouting();
  const [t] = useTranslation('systems');
  const [published, setPublished] = useState<boolean>(true);

  const onChange = useCallback((ev) => {
    ev.nativeEvent.preventDefault();
    ev.nativeEvent.stopPropagation();
    const { target: { value } } = ev;
    setPublished(value);
  }, []);
  const config = useMemo(() => ([{
    type: 'checkbox-single',
    name: 'published',
    text: published ? t('common:bulk_actions.texts.set_published') : t('common:bulk_actions.texts.set_unpublished'),
  }] as CustomFieldConfig), [t, published]);
  const value = useMemo(() => ({
    published,
  }), [published]);
  const onSubmit = useCallback(async () => {
    if (controller.entityType === 'systems') {
      await onSetAssetPublishedStatus(published);
    } else {
      await onSetActivityPublishedStatus(published);
    }
    onCancel();
  }, [controller.entityType, onCancel, onSetActivityPublishedStatus, onSetAssetPublishedStatus, published]);

  return (
    <div>
      <Modal title={t('common:bulk_actions.set_published_status')} onCancel={onCancel ?? goBack} style={{ maxWidth: '640px' }}>
        <CustomFields config={config} onChange={onChange} value={value} />
        <Field flex right style={{ paddingBottom: 0 }}>
          <Button padded style={{ display: 'flex', justifyContent: 'flex-end' }} onClick={onSubmit}>{t('common:bulk_actions.set_published_status')}</Button>
        </Field>
      </Modal>
    </div>
  );
};
