import update from 'immutability-helper';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SystemContact } from '../../../types/system/SystemContact';
import { CustomField } from '../../CustomFields/CustomField';
import { CustomFieldConfig } from '../../CustomFields/CustomFields';

interface Options {
  setContact: React.Dispatch<React.SetStateAction<Partial<SystemContact>>>
  setEditMode: React.Dispatch<React.SetStateAction<'unknown' | 'user' | 'contact'>>
}
export const useExternalContactEdit = ({ setContact, setEditMode }: Options) => {
  const [t] = useTranslation('systems');
  const { index } = useParams<any>();

  const onCustomContactChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name }: any = e.target;
    let { value }: any = e.target;

    setEditMode('contact');
    if (name === 'phone') {
      value = value.match(/\d+/g)?.join('') || '';
    }
    setContact(c => update(c, { [name]: { $set: value } }));
  }, [setContact, setEditMode]);

  const customContactConfig = useMemo((): CustomFieldConfig => [{
    type: 'section',
    config: [...['first_name', 'last_name', 'email', 'phone'].map((k): CustomField => ({
      type: 'text',
      label: t(`form.${k}`),
      name: k,
    })), {
      type: 'text',
      label: t('form.contact_type'),
      name: 'type',
      width: 2,
    }],
    storageKey: 'bleh-why-imake-these-things-so-smart',
    fluid: true,
    expandable: false,
    title: t(index ? 'form.update_custom_contact' : 'form.add_custom_contact'),
  }], [t, index]);

  return {
    config: customContactConfig,
    onCustomContactChange,
  };
};
