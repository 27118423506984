import React from 'react';
import { TableCell, ResizableTableHeader } from '@privacy-request/ui/src/atoms/SASSTable';
import {
  FieldBuilderComponent,
  FieldBuilderProps,
  FieldHeaderComponent,
  FieldHeaderProps,
} from './types/ColumnDefinition';
import { Tooltip } from '@privacy-request/ui';

export const Header: FieldHeaderComponent = ({
  t,
}: FieldHeaderProps) => (
  <ResizableTableHeader key="subsidiaries">{t('table.subsidiaries')}</ResizableTableHeader>
);

export const Cell: FieldBuilderComponent = ({
  row,
}: FieldBuilderProps) => (
  <TableCell key="subsidiaries" truncate>
    <Tooltip content={row.sub_organizations?.map((o: any) => (o.name)).join(', ')}>
      {row.sub_organizations?.map((o: any) => (o.name)).join(', ')}
    </Tooltip>
  </TableCell>
);

export const relations = ['subsidiaries'];
