import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuditLog } from '../../types/audit/AuditLog';
import { PRFile } from '../../types/file/PRFile';
import { error } from '../../utils/logging';

const transformField = (name: string, values: any, t: any) => {
  const value = values[name];
  if (value === undefined || value === null) {
    return t('common:none');
  }
  if (typeof value === 'boolean' && name === 'is_active') {
    return t(`common:${value ? 'active' : 'inactive'}`);
  }
  if (typeof value === 'boolean') {
    return t(`common:${value ? 'yes' : 'no'}`);
  }
  if (name === 'functions') {
    if (Array.isArray(value)) {
      return value.map((v) => (t(`systems:functions.${v}`))).join(', ');
    }
  }
  if (name === 'lawful_basis') {
    return value.join(', ');
  }
  if (name === 'host_type') {
    return t(`systems:system_types.${value}`);
  }
  if (name === 'type') {
    return t(`systems:form.types.${value}`);
  }
  if (name === 'storage_format') {
    return t(`systems:storage_formats.${value}`);
  }
  if (name === 'hosting_country') {
    return value.split(',').join(', ');
  }
  if (name === 'processing_countries') {
    return value.split(',').join(', ');
  }
  if (name === 'gdpr_relationship') {
    return t(`systems:relationship.gdpr.${value}`);
  }
  if (name === 'ccpa_relationship') {
    return t(`systems:relationship.ccpa.${value}`);
  }
  if (name === 'hosting_location') {
    return t(`systems:hosting.${value}`);
  }
  if (['agreements', 'certificates', 'privacy_notices', 'retention_policy'].indexOf(name) !== -1) {
    const files = value as PRFile[];
    return files.map(f => f.name).join(', ');
  }
  return value;
};

const normalize = (value: any) => (
  value === null || value === undefined || value === ''
    ? undefined
    : value
);

export const getChangedFields = (log: AuditLog, fields: string[] = []) => (
  (fields || Array.from(new Set([...Object.keys(log.previous_values), ...Object.keys(log.updated_values)])))
    .filter((f) => (normalize(log?.previous_values?.[f]) !== normalize(log?.updated_values?.[f])))
);

export interface TransformerProps {
  log: AuditLog
  isISO?: boolean
}

export interface PropertyDiffProps {
  /**
   * The audit log record to display.
   */
  log: AuditLog
  /**
   * An optional message to display as the summary for the details tag.
   * If not provided, a default will be used.
   */
  message?: any
  /**
   * An optional list of fields to restrict the diff to. If not provided, all
   * changed fields will be displayed.
   */
  fields?: string[]
  /**
   * The translation utilitiy to use.
   */
  t: any
  /**
   * The prefix to use when translating field names.
   */
  fieldPrefix: string
}

/**
 * Produces a diff showing the changes in properties for a given audit log
 * record.
 */
export const PropertyDiff = ({
  log,
  message,
  fields,
  t,
  fieldPrefix,
}: PropertyDiffProps) => {
  const changes = getChangedFields(log, fields)
    .map((f) => (
      t([`${fieldPrefix}.${f}`, 'common:audit.field_changed'], { // systems:audit.processing_activity.field
        name: f,
        old: transformField(f, log.previous_values, t),
        new: transformField(f, log.updated_values, t),
      })
    ));

  if (changes.length > 0) {
    return (
      <div>
        {
          changes.map((c, i) => (
            <div key={i}>
              {c}
              <br />
            </div>
          ))
        }
      </div>
    );
  }

  return (<span>{message}</span>);
};

/**
 * Produce a transformer that handles displaying changes in related attributes
 * that are of a common or consistent nature.
 */
export const commonRelationTransformerFactory = (map: any, translationPrefix: string) => {
  const CommonRelationTransformer = ({ log, isISO }: TransformerProps) => {
    const [t] = useTranslation('common');

    const getMapLogTableNameTranslation = () => {
      if (map[log.table_name] === 'processing_activity' && isISO) {
        return 'service';
      }
      return map[log.table_name];
    };

    try {
      const [discard, name] = (log as any)[map[log.table_name]]?.name?.split('|', 2);
      if (log.action === 'CREATE') {
        return (
          t(`${translationPrefix}.added.${getMapLogTableNameTranslation()}`, { name: name || discard })
        );
      }
      return (
        t(`${translationPrefix}.removed.${getMapLogTableNameTranslation()}`, { name: name || discard })
      );
    } catch (e) {
      error(log);
      return '';
    }
  };
  CommonRelationTransformer.evaluate = (log: AuditLog) => (
    Object.keys(map).indexOf(log.table_name) > -1
  );
  return CommonRelationTransformer;
};

/**
 * Produce a transformer that handles display contact changes.
 */
export const contactAddedTransformerFactory = (table: string) => {
  const ContactAddedTransformer = ({ log }: TransformerProps) => {
    const [t] = useTranslation('common');

    if (log.action === 'CREATE') {
      return (
        t('systems:audit.contact_added', { email: log?.updated_values?.email || log?.contact?.email })
      );
    }
    if (log.action === 'UPDATE') {
      return (
        t('systems:audit.contact_updated')
      );
    }
    return (
      t('systems:audit.contact_removed', { email: log?.previous_values?.email || log?.contact?.email })
    );
  };
  ContactAddedTransformer.evaluate = (log: AuditLog) => (
    log.table_name === table
  );
  return ContactAddedTransformer;
};

/**
 * Transformer for handling tagitem creates/updates/deletes
 */
export const tagitemRelationTransformerFactory = (table: string, translationPrefix: string) => {
  const TagitemRelationTransformer = ({ log, isISO }: TransformerProps) => {
    const [t] = useTranslation('common');

    if (!log.tagitem) {
      return null;
    }
    const [discard, name] = (log.tagitem?.name.split('|', 2) || []);
    const type = log.tagitem?.type;
    if (log.action === 'CREATE') {
      return (
        t(`${translationPrefix}.added.${type}`, { name: name || discard })
      );
    }
    return (
      t(`${translationPrefix}.removed.${type}`, { name: name || discard })
    );
  };
  TagitemRelationTransformer.evaluate = (log: AuditLog) => (log.table_name === table);
  return TagitemRelationTransformer;
};
