import clsx from 'clsx';
import React from 'react';

import './dropdown.scss';

export type DropdownWrapperProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  /**
   * If true, the element will not be interactive and should be greyed out.
   */
  disabled?: boolean
  /**
   * If false, removes the styles that make this a combobox.
   */
  select?: boolean
};

/**
 * The DropdownWrapper is a convenience element for wrapping a dropdown
 * container (ex. an input element), as well as a dropdown content component
 * and having everything play nicely together.
 *
 * This is used for most dropdowns, but some may not require it, or may have
 * different needs to which this wrapper is not well-suited.
 */
export const DropdownWrapper = React.forwardRef<HTMLDivElement, DropdownWrapperProps>(({
  className,
  tabIndex,
  select,
  ...props
}, ref) => (
  <div
    className={clsx('dropdown', className, { noselect: select === false })}
    tabIndex={Number(props.disabled ? '-1' : tabIndex || '0')}
    ref={ref}
    {...props}
  />
));
