/**
 * Determines if two supplied arrays contain the exact same data without
 * respect to the order in which they are presented.
 * @param arr1 The first array to check
 * @param arr2 The second array to check
 * @returns True if equal, false otherwise
 */
export const arraysEqual = (arr1: any[], arr2: any[]) => (
  arr1.length === arr2.length
    && arr1.every((item) => (arr2.indexOf(item) > -1))
    && arr2.every((item) => (arr1.indexOf(item) > -1))
);

/**
 * Determines if the first array contains all the values of the second
 * array.
 * @param arr1 The array to check
 * @param arr2 The values to check for
 * @returns True if all values exist, false otherwise
 */
export const arrayContains = (arr1: any[], arr2: any[]) => (
  arr2.every((item) => (arr1.indexOf(item) > -1))
);

/**
 * Converts the given value to an array if not already one.
 * @param val The value to convert
 * @returns An array
 */
export const toArray = (val: any) => {
  if (!val) {
    return [];
  }
  if (Array.isArray(val)) {
    return val;
  }
  return [val];
};
