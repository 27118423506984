import React from 'react';

export const TasksIcon = (props: any) => (
  <svg {...props} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="tasks" transform="translate(2.000000, 2.000000)" fill="#FFFFFF" fillRule="nonzero">
        <g id="Group" transform="translate(0.000000, 0.625536)">
          <path d="M14.4433594,0.049527451 C6.97587238,0.049527451 0.902918207,6.12248569 0.902918207,13.5899686 C0.902918207,21.0574686 6.97587238,27.1304098 14.4433594,27.1304098 C21.9108459,27.1304098 27.9838008,21.0574686 27.9838008,13.5899686 C27.9838008,6.12248569 21.9108459,0.049527451 14.4433594,0.049527451 Z M14.4433594,1.85537451 C20.9351392,1.85537451 26.1779639,7.09819922 26.1779639,13.5899686 C26.1779639,20.0817551 20.9351392,25.3245798 14.4433594,25.3245798 C7.95157875,25.3245798 2.70875455,20.0817551 2.70875455,13.5899686 C2.70875455,7.09819922 7.95157875,1.85537451 14.4433594,1.85537451 Z" id="Shape" />
          <path d="M19.8408771,9.0687151 C19.6066776,9.07591499 19.3844517,9.17388791 19.2211625,9.34192922 L12.6375229,15.9222339 L9.66555602,12.9502627 C9.43702088,12.722264 9.10420262,12.6335199 8.79248952,12.7174645 C8.48077642,12.8014092 8.23753361,13.0452871 8.15440208,13.357218 C8.07127055,13.669149 8.16088223,14.0017347 8.38947619,14.2296745 L11.9978171,17.8380251 C12.1672301,18.0081513 12.3974319,18.1037874 12.6375229,18.1037874 C12.877614,18.1037874 13.1078158,18.0081513 13.2772288,17.8380251 L20.4972426,10.6180145 C20.7639629,10.3583842 20.8439962,9.96167136 20.698797,9.61894002 C20.5535978,9.27620868 20.2129346,9.05772515 19.8408771,9.0687151 L19.8408771,9.0687151 Z" id="Path" />
        </g>
      </g>
    </g>
  </svg>
);

TasksIcon.defaultProps = {
  width: '32px',
  height: '32px',
};
