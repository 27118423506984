import React, {
  useRef,
} from 'react';
import { useHotkey, useOnClickOutside } from '../../hooks';
import { Card } from '../Card';
import { CloseButton } from '../CloseButton';
import { Field } from '../Field';
import { SubTitle } from '../SubTitle';
import { Title } from '../Title';

export type OverlayModalProps = {
  children: any
  style?: React.CSSProperties
  title?: string
  subtitle?: string
  onCancel: () => void
};

export const OverlayModal = ({
  children,
  style,
  title,
  subtitle,
  onCancel,
}: OverlayModalProps) => {
  const ref = useRef<HTMLDivElement>(null);
  useHotkey('Escape', onCancel);
  useOnClickOutside(ref, onCancel);

  return (
    <Card modal style={style} ref={ref}>
      {onCancel && (<CloseButton onClick={onCancel} />)}
      {title && (
        <Field>
          <Title>{title}</Title>
          {subtitle && (<SubTitle mute small>{subtitle}</SubTitle>)}
        </Field>
      )}
      {children}
    </Card>
  );
};
