import styled from 'styled-components';
import {
  InnerInput,
  InputWrap,
} from '..';

export const InputGroup = styled.div`
  display: flex;

  input,
  button,
  ${InputWrap},
  .button {
    &,
    & ${InnerInput} {
      height: 44px;
      border-radius: 0;
      border-color: #e1e0e0;
      border-left-width: 0;
    }

    &:first-child,
    &:first-child ${InnerInput} {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-left-width: 1px;
    }
    &:last-child,
    &:last-child ${InnerInput}  {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;
