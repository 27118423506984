import { ProcessingActivity } from '../../../../types/system/ProcessingActivity';
import { System } from '../../../../types/system/System';
import { SystemProcessingActivity } from '../../../../types/system/SystemProcessingActivity';
import { SystemRelatedSystem } from '../../../../types/system/SystemRelatedSystems';

export const isProcessingActivity = (obj: any): obj is ProcessingActivity => (
  obj.__typename === 'ProcessingActivity' || !!obj.systems || !!obj.dpia || !!obj.lineage || !!obj.lawful_bases
);

export const isSystem = (obj: any): obj is System => (
  obj.__typename === 'System' || !!obj.agreements || !!obj.data_retention_period || !!obj.system_template_id
);

export const getTarget = (
  relation: SystemRelatedSystem | SystemProcessingActivity | ProcessingActivity | System,
  currentItem: System | ProcessingActivity,
) => {
  if (isProcessingActivity(relation)) {
    return relation;
  }
  if (isSystem(relation)) {
    return relation;
  }
  if (relation.system_id !== currentItem.id) {
    // @ts-expect-error
    return relation.system;
  }
  // @ts-expect-error
  return relation.related_system;
};
