import React from 'react';
import {
  ErrorBoundary,
  Fields,
} from '@privacy-request/ui';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CustomField } from './CustomField';
import { CustomSection, CustomSectionComponent } from './CustomSectionComponent';
import { render } from './utils/render';
import { opts } from './utils/opts';

const CustomFieldsContainer = styled.div`
  width: 100%;

  ${Fields} + ${Fields} {
    margin-top: 12px;
  }
`;

export type CustomFieldConfig = (CustomField | CustomSection)[];

export type CustomFieldsContext = {
  namedFunctions?: { [k: string]: any }
  fullConfig?: any
};

interface CustomFieldsProps {
  config: CustomFieldConfig
  onChange: any
  value: any
  errors?: any
  fluid?: boolean
  mask?:any
  ctx?: CustomFieldsContext
}

export const CustomFields = ({
  config, onChange, value, fluid, mask, errors, ctx,
}: CustomFieldsProps) => {
  const [t, i18n] = useTranslation();

  return (
    <ErrorBoundary>
      <CustomFieldsContainer>
        {(config || []).filter((c) => (!!c)).map((c, i, orig) => {
          if (c.type === 'section') {
            // return renderSection(c, value, onChange, (i + 1) === orig.length, errors);
            return (
              <ErrorBoundary key={c.storageKey || i}>
                <CustomSectionComponent
                  section={c}
                  value={value}
                  onChange={onChange}
                  last={(i + 1) === orig.length}
                  mask={mask}
                  errors={errors}
                  ctx={ctx}
                />
              </ErrorBoundary>
            );
          }
          return (
            // @ts-expect-error
            <ErrorBoundary key={c.storageKey || i}>
              {render(c, opts(value, onChange, i === config.length, false, !!fluid, mask, errors, t, i18n, ctx))}
            </ErrorBoundary>
          );
        })}
      </CustomFieldsContainer>
    </ErrorBoundary>
  );
};
