import keycode from 'keycode';
import React, { Component } from 'react';
import styled from 'styled-components';
import { Text } from '../../atoms';
import {
  CheckBoxIcon,
  CheckBoxIndeterminateIcon,
  CheckBoxTickedIcon,
} from '../../atoms/icons';

export interface CheckBoxProps {
  /**
   * Wether the checkbox is checked or not.
   */
  checked?: boolean
  /**
   * Whether the checkbox is allowed to be in an indeterminate state or not.
   */
  allowIndeterminate?: boolean
  /**
   * The label to go along with the checkbox, which can be clicked.
   */
  label?: string
  sublabel?: string
  /**
   * Change the tabindex of the checkbox.
   */
  tabIndex?: string
  /**
   * The change handler when the checkbox is clicked.
   */
  onChange?: (e: any) => void
  name?: string
  style?: React.CSSProperties
}

const ToggleContainer = styled.div<any>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  outline: none;
  transition: all 0.1s ease-in-out;

  & path {
    fill: ${({ theme, checked }) => (checked ? theme.DARKISH_GREEN : theme.GREY)};
  }

  &:focus path, :hover path {
    fill: ${({ theme }) => theme.CTA_COLOR};
  }
`;

ToggleContainer.displayName = 'ToggleContainer';

const SvgFix = styled.div`
  position: relative;
  top: 1px;
`;

const SubText = styled(Text)`
  padding-left: 24px;
  position: absolute;
  font-size: 13px;
  top: 22px;
`;
SubText.displayName = 'SubText';

export class CheckBox extends Component<CheckBoxProps, any> {
  static defaultProps = {
    tabIndex: '0',
  };

  constructor(props: CheckBoxProps) {
    super(props);
    this.state = { checked: props.checked };
    this.getElement = this.getElement.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps: CheckBoxProps) {
    if (nextProps.checked !== this.state.checked) {
      this.setState({ checked: nextProps.checked });
    }
  }

  cycle() {
    if (this.state.checked === false && this.props.allowIndeterminate) {
      return undefined;
    }
    if (this.state.checked === true) {
      return false;
    }
    return true;
  }

  handleKeyDown(e: any) {
    switch (keycode(e)) {
      case 'space':
      case 'enter':
        this.handleClick();
        break;
      default:
        break;
    }
  }

  handleClick(e?: any) {
    if (this.props.tabIndex === '-1') {
      return;
    }

    const value = this.cycle();
    e.target = { name: this.props.name, value };
    this.setState({ checked: value });
    this.props.onChange && this.props.onChange(e);
  }

  getElement() {
    if (this.state.checked === true) {
      return (<CheckBoxTickedIcon />);
    }
    if (this.state.checked === undefined && this.props.allowIndeterminate) {
      return (<CheckBoxIndeterminateIcon />);
    }
    return (<CheckBoxIcon />);
  }

  render() {
    return (
      <ToggleContainer
        checked={this.state.checked}
        tabIndex={this.props.tabIndex}
        onKeyDown={(e: any) => this.handleKeyDown(e)}
        onClick={(e: any) => this.handleClick(e)}
        style={this.props.style}
      >
        <SvgFix>
          {this.getElement()}
        </SvgFix>
        <Text style={{ paddingLeft: '8px' }}>{ this.props.label }</Text>
        {this.props.sublabel && (<SubText>{this.props.sublabel}</SubText>)}
      </ToggleContainer>
    );
  }
}
