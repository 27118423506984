export const OAUTH_SCOPES = [
  'read',
  'write',
];

export const SUPPORTED_FILEVIEWER_FORMATS = [
  'application/json',
  'text/csv',
];

export const PIPELINE_MESSAGE_SEPARATOR = '8261ca0b-ea6a-4762-b9dd-8c106b864c1e';

export const NOREPLY_EMAIL = 'noreply@privacyrequest.com';
export const PRIVACY_REQUEST_NOREPLY_EMAIL = `Privacy Request <${NOREPLY_EMAIL}>`;

export const FALLBACK_LANGUAGE = 'en_US';
