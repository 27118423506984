import { CustomField } from '../CustomField';
import { CustomFieldDefinition } from '../CustomFieldDefinition';
import { blank } from './blank';
import { boolean } from './boolean';
import { button } from './button';
import { checkbox } from './checkbox';
import { checkboxSingle } from './checkbox-single';
import { checkover } from './checkover';
import { custom } from './custom';
import { date } from './date';
import { display } from './display';
import { dropdown } from './dropdown';
import { sassdropdown } from './sassdropdown';
import { fileupload } from './fileupload';
import { graphqlSearchAndSelect } from './graphql-search-and-select';
import { group } from './group';
import { jsonTextarea } from './json-textarea';
import { pipelineSteps } from './pipeline-steps';
import { searchAndSelect } from './search-and-select';
import { text } from './text';
import { textarea } from './textarea';
import { yesNo } from './yes-no';

export const fields = {
  'blank': blank,
  'boolean': boolean,
  'button': button,
  'checkbox-single': checkboxSingle,
  'checkbox': checkbox,
  'checkover': checkover,
  'custom': custom,
  'date': date,
  'display': display,
  'dropdown': dropdown,
  'sass-dropdown': sassdropdown,
  'fileupload': fileupload,
  'graphql-search-and-select': graphqlSearchAndSelect,
  'group': group,
  'json-textarea': jsonTextarea,
  'pipeline-steps': pipelineSteps,
  'search-and-select': searchAndSelect,
  'text': text,
  'textarea': textarea,
  'yes-no': yesNo,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const typedFields: { [k in CustomField['type']]: CustomFieldDefinition<any> } = fields;
