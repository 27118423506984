import gql from 'graphql-tag';
import { Request } from '../../types/request/Request';
import { RequestFragment } from './requests.fragments';

export interface CreateRequestVariables {
  request: Partial<Request>
}

export const CreateRequest = gql`
  mutation create_request($request: Request!) {
    create_request(input: $request) @rest(
      type: "Request" path: "requests" method: "POST"
    ) {
      ...request
    }
  }

  ${RequestFragment}
`;

export interface UpdateRequestVariables {
  request: Partial<Request>
  id: number
}

export const UpdateRequest = gql`
  mutation update_request($request: Request!, $id: Int!) {
    update_request(input: $request, id: $id) @rest(
      type: "Request" path: "requests/{args.id}" method: "PATCH"
    ) {
      ...request
    }
  }

  ${RequestFragment}
`;
export const UpdateRequestRaw = gql`
  mutation update_request_raw($request: Request!, $id: Int!) {
    update_request_raw(input: $request, id: $id) @rest(
      type: "Request" path: "requests/{args.id}/raw" method: "PATCH"
    ) {
      ...request
    }
  }

  ${RequestFragment}
`;
