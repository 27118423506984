import React, { useCallback, useMemo } from 'react';
import { CheckBox, Text } from '@privacy-request/ui';
import { FilterComponent, FilterComponentProps } from '../../FilterComponent.d';
import omit from '../../../../utils/omit';
import keycode from 'keycode';

const cycle = (currentValue: boolean | undefined) => {
  if (currentValue === false) {
    return undefined;
  }
  if (currentValue === undefined) {
    return true;
  }
  return false;
};

export type TristateFilterComponentProps = FilterComponentProps & {
  name: string
  title: string
  preset?: boolean
};

export const TristateFilter: FilterComponent<TristateFilterComponentProps> = ({
  name,
  title,
  preset,
  filterData = {},
  setFilterData,
}: TristateFilterComponentProps) => {
  const value = useMemo(() => {
    if (filterData[name] === true) {
      return true;
    }
    if (filterData[name] === false) {
      return false;
    }
    return preset;
  }, [filterData, name, preset]);

  const onClick = useCallback((ev: any) => {
    ev.stopPropagation();
    ev.preventDefault();
    const next = cycle(value);
    setFilterData((filterData: any) => ({
      ...omit(filterData, name),
      ...(next === preset ? {} : { [name]: next }),
    }));
  }, [name, preset, setFilterData, value]);
  const onKeyPress = useCallback((e) => {
    switch (keycode(e)) {
      case 'space':
      case 'enter':
        onClick(e);
        break;
      default:
        break;
    }
  }, [onClick]);

  return (
    <div
      className="flex spaced"
      onClick={onClick}
      onKeyPress={onKeyPress}
      role="button"
      tabIndex={0}
    >
      <CheckBox allowIndeterminate checked={value} onChange={onClick} />
      <Text style={{ fontSize: '16px' }}>{title}</Text>
    </div>
  );
};

export type CreateTristateFilterProps = {
  name: string
  title: string
  preset?: boolean
};

/**
 * A convenience function which creates a standard filter that has a name,
 * and a true, false, or undefined state
 */
export const createTristateFilter = (initialProps: CreateTristateFilterProps): FilterComponent => (props) => (
  <TristateFilter
    {...initialProps}
    {...props}
  />
);
