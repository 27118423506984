import React from 'react';
import update from 'immutability-helper';
import { get } from 'lodash';
import {
  CheckBox,
  Field,
  Label,
} from '@privacy-request/ui';
import { CustomFieldOptions } from '../CustomFieldOptions';
import { CustomFieldDefinition } from '../CustomFieldDefinition';
import evt from '../../../utils/evt';
import { getRest } from '../utils/getRest';
import { DisablerHack } from '../../DisablerHack/DisablerHack';

export interface CustomCheckboxFieldItem extends CustomFieldOptions {
  text: string
  value: any
}
export interface CustomCheckboxField extends CustomFieldOptions {
  type: 'checkbox'
  name: string
  label?: string
  items: CustomCheckboxFieldItem[]
  disabled?: boolean
}

export const checkbox: CustomFieldDefinition<CustomCheckboxField> = {
  type: 'checkbox',
  component: (config, { onChange, values }) => {
    const onCheckboxChange = (value: string, checked: boolean) => () => {
      if (checked) {
        const i = get(values, config.name).indexOf(value);
        onChange(evt(config.name, update(get(values, config.name), { $splice: [[i, 1]] })));
      } else {
        onChange(evt(config.name, update(get(values, config.name) || [], { $push: [value] })));
      }
    };

    return (
      <>
        {config.items.map((i: any) => {
          const checked = (get(values, config.name) || []).indexOf(i.value) !== -1;
          return (
            <CheckBox
              style={{ marginBottom: '8px' }}
              label={i.text}
              checked={checked}
              onChange={onCheckboxChange(i.value, checked)}
              {...getRest(config)}
            />
          );
        })}
      </>
    );
  },
  wrapper: (config, {
    first,
    last,
    content,
  }) => (
    <Field last={last} first={first} key={config.name || config.label}>
      <DisablerHack disabled={config.disabled}>
        {config.label && (<Label>{config.label}</Label>)}
        {content}
      </DisablerHack>
    </Field>
  ),
};
