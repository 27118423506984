import React, { useCallback } from 'react';
import { FilterComponent, FilterComponentProps } from '../../FilterComponent.d';
import { CheckBox, Text } from '@privacy-request/ui';
import keycode from 'keycode';

export type MultiselectFilterComponentProps = FilterComponentProps & {
  name: string
  title: string
  value: any
  allSelectedIfEmpty?: boolean
};

export const MultiselectFilter: FilterComponent<MultiselectFilterComponentProps> = ({
  name,
  title,
  value,
  filterData = {},
  setFilterData,
  allSelectedIfEmpty,
}: MultiselectFilterComponentProps) => {
  const selected = filterData[name]?.includes(value)
    || (allSelectedIfEmpty && [].concat(filterData[name] || []).length === 0);

  const onClick = useCallback((ev: any) => {
    ev.stopPropagation();
    ev.preventDefault();
    let result = ([] as any[]).concat(filterData[name] || []);
    if (result.includes(value)) {
      result = result.filter((o) => (o !== value && !!o));
    } else {
      result.push(value);
    }
    setFilterData((filterData: any) => ({
      ...filterData,
      [name]: result.length > 0 ? result : undefined,
    }));
  }, [filterData, name, setFilterData, value]);
  const onKeyPress = useCallback((e) => {
    switch (keycode(e)) {
      case 'space':
      case 'enter':
        onClick(e);
        break;
      default:
        break;
    }
  }, [onClick]);

  return (
    <div
      className="flex spaced"
      onClick={onClick}
      onKeyPress={onKeyPress}
      role="button"
      tabIndex={0}
    >
      <CheckBox checked={selected} onChange={onClick} />
      <Text style={{ fontSize: '16px' }}>{title}</Text>
    </div>
  );
};
