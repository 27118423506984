import { SearchAndSelect, SelectionItem } from '@privacy-request/ui';
import React, { useCallback, useMemo } from 'react';
import { SEARCH_AND_SELECT_FLOATING_BOX_MAX_HEIGHT } from '../../constants';
import { useIsAdminRoute } from '../../hooks/useIsAdminRoute';
import {
  GroupedTagItemMap, regions, useTagItems,
} from '../../hooks/useTagItems';
import evt from '../../utils/evt';

const insertDividers = (_items: SelectionItem[]) => {
  _items.unshift({
    text: 'Regions',
    value: 'DIVIDER',
  });
  let lastIndex = 1;
  Object.keys(regions).forEach((region: string) => {
    lastIndex = Math.max(lastIndex, _items.findIndex(i => i.text === region));
  });
  _items.splice(lastIndex + 1, 0, {
    text: 'Countries',
    value: 'DIVIDER',
  });
};

export interface TagItemSelectProps {
  /**
   * The field on the entity that we're examining
   */
  name: string
  /**
   * An array of ids representing the contents of the field
   */
  value?: number[]
  /**
   * The tag item type to list
   */
  type: keyof GroupedTagItemMap // or TagitemType
  /**
   * Whether or not the element is disabled and unable to change
   */
  disabled?: boolean
  onChange: (ev: any) => void
  global?: boolean
}

export const TagItemSelect = ({
  value,
  name,
  type,
  disabled,
  onChange,
  global,
  ...rest
}: TagItemSelectProps) => {
  const isAdminRoute = useIsAdminRoute();
  const val = useMemo(() => (value || []), [value]);
  const {
    grouped,
    createTagitem,
  } = useTagItems({
    custom: !isAdminRoute,
    global: true,
    value: val,
  });

  const items = useMemo(() => {
    const _items = grouped[type].map((i: any) => ({ text: i.name, value: i.id }));
    if (type === 'country') {
      insertDividers(_items);
    }
    return _items;
  }, [grouped, type]);

  const _onCreate = useCallback(async (n: string) => {
    const result = await createTagitem(n, type);
    if (!result.data) {
      // TODO Should display an error
      return;
    }
    onChange(evt(name, [...val, (result.data as any)[Object.keys(result.data)[0]]]));
  }, [createTagitem, type, val, name, onChange]);

  return (
    <SearchAndSelect
      multi
      add={!isAdminRoute}
      items={items}
      disabled={disabled}
      name={name}
      value={val}
      floatingBoxStyle={{ maxHeight: SEARCH_AND_SELECT_FLOATING_BOX_MAX_HEIGHT }}
      onChange={onChange}
      onCreate={_onCreate}
      {...rest}
    />
  );
};
