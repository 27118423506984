import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 16px 0 32px 0;
`;
Wrapper.displayName = 'Wrapper';

const Line = styled.div`
  position: absolute;
  left: 16px;
  right: 16px;
  height: 1px;
  background-color: ${({ theme }) => theme.GREY};
`;
Line.displayName = 'Line';

interface SpacerProps {
  style?: React.CSSProperties
}
export const Spacer = ({ style }: SpacerProps) => (
  <Wrapper style={style}>
    <Line />
  </Wrapper>
);
