import {
  ListText,
  TitleBar,
  Grid,
  Card,
  Dropdown,
  LoadingProvider,
  LoadingIndicator,
} from '@privacy-request/ui';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { GetDashboardPASumByStatus, GetDashboardSystemSumByStatus } from '../../apollo/dashboard/dashboard.queries';
import { Shiv } from '../../components/dashboard/Shiv';
import { WidgetCard } from './components/WidgetCard';
import { CardWrapper } from './components/CardWrapper';
import { useMe } from '../../hooks/useMe';
import { usePermissions } from '../../hooks/usePermissions';
import { useSelector } from '../../store/useSelector';
import { DashboardDataMapStatusSummary } from './DashboardDataMapStatusSummary';
import { DashboardProcessingActivitiesPendingApproval } from './DashboardProcessingActivitiesPendingApproval';
import { DashboardSystemsDiscovered } from './DashboardSystemsDiscovered';
import { DashboardSystemsPendingApproval } from './DashboardSystemsPendingApproval';
import { useDashboard } from './hooks/useDashboard';
import { ChartTypes } from './types/ChartTypes';

import './dashboard.scss';

const ListTextHack = styled(ListText)`
  display: flex;
  align-items: center;
`;
ListTextHack.displayName = 'ListTextHack';

const chartOptions: any = {
  scales: {
    yAxes: [{
      ticks: {
        beginAtZero: true,
        suggeestedMin: 0,
        suggestedMax: 10,
      },
    }],
  },
  tooltips: { intersect: false },
  legend: { display: false },
};

export const DashboardView = () => {
  const [t] = useTranslation('dashboard');
  const { me } = useMe();
  const provider = useSelector(s => s.auth.provider);
  const systems_disabled = me?.organization?.features?.systems_disabled;
  const requests_disabled = me?.organization?.features?.requests_disabled;
  const discover_assets_enabled = me?.organization?.features?.discover_assets_enabled;

  const { hasPermission } = usePermissions();

  const {
    data,
    time,
    chartData,
    systemsData,
    onChangeTimeSeries,
    onChangeChart,
    chart,
    timeSeriesItems,
    initializing,
  } = useDashboard();

  const isSystemApprover = hasPermission('systems.actions.approver');
  const isProcessingActivityApprover = hasPermission('processing_activities.actions.approver');

  const isLocal = provider === 'LOCAL';

  return (
    <LoadingProvider style={{ paddingBottom: '156px' }}>
      {initializing && <LoadingIndicator />}
      <TitleBar
        title={t('title')}
        right={!requests_disabled ? [
          <Dropdown key="a" items={timeSeriesItems} value={time} onChange={onChangeTimeSeries} />,
        ] : []}
      />
      {!requests_disabled && (
        <>
          <Grid container>
            <Card className="dashboard" style={{ marginTop: '20px' }}>
              <Line height={80} data={chartData} options={chartOptions} />
            </Card>
          </Grid>
          <Grid container>
            <CardWrapper>
              <WidgetCard
                active={chart === ChartTypes.DOWNLOAD_REQUESTS}
                onClick={() => onChangeChart(ChartTypes.DOWNLOAD_REQUESTS)}
                title={`${data?.get_dashboard_stats?.download_requests || 0}`}
                description={t('download_requests')}
              />
              <Shiv />
              <WidgetCard
                active={chart === ChartTypes.DELETION_REQUESTS}
                onClick={() => onChangeChart(ChartTypes.DELETION_REQUESTS)}
                title={`${data?.get_dashboard_stats?.deletion_requests || 0}`}
                description={t('deletion_requests')}
              />
              <Shiv />
              <WidgetCard
                active={chart === ChartTypes.OPT_OUT_REQUESTS}
                onClick={() => onChangeChart(ChartTypes.OPT_OUT_REQUESTS)}
                title={`${data?.get_dashboard_stats?.opt_out_requests || 0}`}
                description={t('opt_out_requests')}
              />
              <Shiv />
              <WidgetCard
                active={chart === ChartTypes.COMPLETED_REQUESTS}
                onClick={() => onChangeChart(ChartTypes.COMPLETED_REQUESTS)}
                title={`${data?.get_dashboard_stats?.completed_requests || 0}`}
                description={t('completed_requests')}
              />
              <Shiv />
              <WidgetCard
                active={chart === ChartTypes.AVG_TIME_TO_RESOLVE}
                onClick={() => onChangeChart(ChartTypes.AVG_TIME_TO_RESOLVE)}
                title={`${`${((data?.get_dashboard_stats?.avg_time_to_resolve || 0) / 3600).toFixed(1)}h`}`}
                description={t('avg_time_to_resolve')}
              />
            </CardWrapper>
          </Grid>
        </>
      )}
      {requests_disabled && !systems_disabled && (
        <Grid container>
          <CardWrapper>
            <WidgetCard
              title={`${systemsData?.get_dashboard_system_stats?.processing_activities || 0}`}
              description={t('processing_activities')}
            />
            <Shiv />
            <WidgetCard
              title={`${systemsData?.get_dashboard_system_stats?.assets || 0}`}
              description={t('assets')}
            />
            <Shiv />
            <WidgetCard
              title={`${systemsData?.get_dashboard_system_stats?.processing_activities_in_review || 0}`}
              description={t('processing_activities_in_review')}
            />
            <Shiv />
            <WidgetCard
              title={`${systemsData?.get_dashboard_system_stats?.assets_in_review || 0}`}
              description={t('assets_in_review')}
            />
          </CardWrapper>
        </Grid>
      )}
      {requests_disabled && !systems_disabled && (
        <Grid container>
          <CardWrapper>
            <Card style={{
              marginTop: 0, marginRight: '16px', padding: '16px',
            }}
            >
              <DashboardDataMapStatusSummary
                query={GetDashboardSystemSumByStatus}
                title={t('data_sources_by_status')}
              />
            </Card>
            <Card style={{
              marginTop: 0, marginLeft: '16px', padding: '16px',
            }}
            >
              <DashboardDataMapStatusSummary
                query={GetDashboardPASumByStatus}
                title={t('processing_activities_by_status')}
                hideNew
              />
            </Card>
          </CardWrapper>
        </Grid>
      )}
      {!systems_disabled && (
        <Grid container>
          <CardWrapper className="card-wrapper-rows">
            {!isLocal && discover_assets_enabled && (
              <Card style={{ padding: '16px' }}>
                <DashboardSystemsDiscovered />
              </Card>
            )}
            {isSystemApprover && (
              <Card style={{ padding: '16px' }}>
                <DashboardSystemsPendingApproval />
              </Card>
            )}
            {isProcessingActivityApprover && (
              <Card style={{ padding: '16px' }}>
                <DashboardProcessingActivitiesPendingApproval />
              </Card>
            )}
          </CardWrapper>
        </Grid>
      )}
    </LoadingProvider>
  );
};
