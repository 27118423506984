import clsx from 'clsx';
import React, { useCallback } from 'react';
import {
  ChevDown,
  ChevUp,
  FloatingBox,
  FloatingBoxItem,
  Input,
  SubTitle,
} from '../../atoms';
import { CheckBox } from '../CheckBox';
import './index.scss';
import { DropdownProps } from './DropdownProps';
import { useFiltering } from './useFiltering';
import { useKeyboardNavigation } from './useKeyboardNavigation';

/**
 * Render the internal content that is typically displayed inside a
 * container.
 * @param item The item
 * @param i THe index of the item
 * @returns Component
 */
const renderItemContent = (item: any, i: number, props: DropdownProps) => {
  if (item.render) {
    return item.render();
  }
  if (props.multiple && item.value !== 'DIVIDER' && item.selectable !== false) {
    let isSelected = false;
    const values: any[] = props.value || [];
    if (values.includes(item.value)) {
      isSelected = true;
    }
    return (
      <CheckBox checked={isSelected} tabIndex="-1" label={item.text} />
    );
  }
  return item.text;
};

const renderItem = (item: any, i: number, props: DropdownProps) => {
  const icon = item.icon ? React.cloneElement(item.icon, {
    width: '16px',
    height: '16px',
    color: 'BLACK',
  }) : null;
  if (item.value === 'DIVIDER') {
    return (
      <SubTitle style={{ padding: '8px 0 8px 16px', fontSize: '16px' }}>
        {renderItemContent(item, i, props)}
      </SubTitle>
    );
  }
  const onClick = (e: Event) => {
    if (item.action) {
      item.action();
    }
    if (item.selectable !== false) {
      props.onChange({
        ...e,
        target: {
          name: props.name,
          value: item.value,
        },
      });
    }
  };
  return (
    <FloatingBoxItem key={`${item.value}-${i}`} icon={icon} active={i === props.currentIndex} onClick={onClick}>
      {renderItemContent(item, i, props)}
    </FloatingBoxItem>
  );
};

const getDisplayValue = (props: DropdownProps) => {
  if (props.displayValue) {
    return props.displayValue;
  }

  if (props.value) {
    const item = props.items.find((i) => (i.value === props.value));
    if (item) {
      return item.text;
    }
  }
  return props.placeholder || '';
};

export const renderFilter = (props: DropdownProps, nav: any, filter: ReturnType<typeof useFiltering>) => {
  if (props.filter) {
    return (
      <>
        <Input
          value={filter.filterText || ''}
          onChange={(e: any) => (filter.onFilter(e.target.value))}
          placeholder="Filter options..."
          autoFocus
          {...nav}
        />
        <hr style={{ border: 'none', borderBottom: '1px solid #ccc' }} />
      </>
    );
  }
};

export const Dropdown = (props: DropdownProps) => {
  const filter = useFiltering(props);
  const items = filter.filteredItems || props.items;
  const {
    currentIndex,
    setCurrentIndex,
    wrapperRef,
    onFocus,
    onClick,
    onChange,
    onKeyUp,
    onKeyDown,
    open,
  } = useKeyboardNavigation({ ...props, items });
  const displayValue = getDisplayValue({ ...props, items });

  const dropdownItemsStyle = {
    ...(props.align === 'left' || !props.align ? { right: 0 } : {}),
    top: '55px',
    minWidth: '100%',
    zIndex: 20,
    maxHeight: '320px',
    ...(props.containerStyle || {}),
  };

  const innerProps = {
    ...props,
    items,
    currentIndex,
    onChange,
  };

  const onFilter = useCallback((text: string) => {
    setCurrentIndex(-1);
    return filter.onFilter(text);
  }, [filter.onFilter, setCurrentIndex]);

  return (
    <div
      className={clsx('dropdown', { disabled: props.disabled })}
      tabIndex={Number(props.disabled ? '-1' : '0')}
      onFocus={onFocus}
      onClick={onClick}
      onKeyUp={onKeyUp}
      style={{
        position: 'relative',
        width: '100%',
        outline: 'none',
        ...(props.wrapperStyle || {}),
      }}
      ref={wrapperRef}
    >
      <div
        className={clsx({
          disabled: props.disabled,
          open,
        })}
        tabIndex={props.disabled ? -1 : 0}
        style={props.inputStyle}
      >
        <div className="dropdown-component">
          <div className="value">{displayValue}</div>
          <div className="caret">
            {open ? <ChevUp /> : <ChevDown />}
          </div>
        </div>
      </div>

      {open && items && (
        <FloatingBox style={dropdownItemsStyle}>
          {renderFilter(props, { onKeyUp, onKeyDown }, { ...filter, onFilter })}
          {items.map((item: any, i: number) => renderItem(item, i, innerProps))}
        </FloatingBox>
      )}
    </div>
  );
};
