import React from 'react';
import styled from 'styled-components';
import { SVGComponent } from './SvgProps';
import { Svg } from './utils';

const Path = styled.path<{ color: any; theme: any }>`
  fill: ${({ theme, color }) => (color ? theme[color] || color : theme.CTA_COLOR)};
`;

export const EditIcon: SVGComponent = ({ color, ...props }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
    <Path
      color={color}
      fillRule="evenodd"
      d="M3.625 16.837l9.68-9.68 3.28 3.281-9.679 9.68H3.625v-3.281zm15.483-8.921l-1.6 1.6-3.28-3.282 1.6-1.6a.856.856 0 0 1 .625-.266c.239 0 .447.089.625.267l2.03 2.05a.791.791 0 0 1 .267.606c0 .239-.089.447-.267.625z"
    />
  </Svg>
);

EditIcon.defaultProps = {
  width: 24,
  height: 24,
};
