import React from 'react';
import styled from 'styled-components';
import {
  ICCaratDefault,
  ICCaratDown,
  ICCaratUp,
} from '../icons';
import { GenericNoWrapStyle } from '../styles';
import { Text } from '../Text';

export interface FlexColumnProps {
  /**
   * Affects the "weight" distribution (flex-grow) of the column.
   *
   * ### Must be in sync with ListText grow prop.
   */
  grow?: number
  /**
   * Defining the basis property makes the column always the specified width (ex. '64px')
   *
   * @example
   * <ListHead>
   * --<ListTitle>Name</ListTitle>
   * --<ListTitle basis="64px">Actions</ListTitle>
   * </ListHead>
   * <ListRow>
   * --<ListText>John Wilson</ListText>
   * --<ListText basis="64px"><Button>Delete</Button></ListText>
   * </ListRow>
   */
  basis?: string
  /**
   * Align the column text to the right.
   */
  right?: boolean
  /**
   * Bolden the column's text.
   */
  bold?: boolean
  /**
   * If it should display the sorter carat.
   */
  hasSort?: boolean
  center?: boolean
  sort?: 'default' | 'down' | 'up'
  name?: string
  onClick?: (...args: any[]) => void
  children?: any
  style?: React.CSSProperties
}

const sorts = {
  default: <ICCaratDefault />,
  down: <ICCaratDown />,
  up: <ICCaratUp />,
};

const StyledListTitle = styled.div<FlexColumnProps>`
  flex: 1;
  flex-grow: ${({ grow, basis }) => (basis ? '0' : grow)};
  flex-basis: ${({ basis }) => (basis || '0')};
  cursor: ${({ hasSort }) => (hasSort ? 'pointer' : 'default')};
  padding-right: 20px;
`;

StyledListTitle.defaultProps = { grow: 1 };

const evaluateTextAlign = ({ right, center }: FlexColumnProps) => {
  if (right) return 'right';
  if (center) return 'center';
  return 'left';
};

const ListTitleText = styled(Text)<FlexColumnProps>`
  display: inline;
  ${GenericNoWrapStyle}
  color: #9e9faa;
  padding-right: 6px;
  text-align: ${evaluateTextAlign}
`;
ListTitleText.displayName = 'ListTitleText';

const Positioner = styled.div`
  position: relative;
  bottom: 2px;
  display: inline;
`;
Positioner.displayName = 'Positioner';

export const ListTitle: React.FC<FlexColumnProps> = ({
  children, sort, onClick, name, right, ...props
}) => (
  <StyledListTitle {...props} onClick={() => onClick && onClick({ target: { name } })} hasSort={!!sort}>
    <ListTitleText right={right}>{children}</ListTitleText>
    {sort && (
    <Positioner>
      {sorts[sort]}
    </Positioner>
    )}
  </StyledListTitle>
);
