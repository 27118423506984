import React, {
  JSXElementConstructor,
  useCallback,
  useContext,
} from 'react';
import { modalContext } from './useModalContext';

export const useDialog = <C extends JSXElementConstructor<any>, P = Partial<React.ComponentProps<C>>>(
  Component: C,
  initialProps?: P,
) => {
  const { set } = useContext(modalContext);

  const hide = useCallback(() => {
    set(undefined);
  }, [set]);

  const show = useCallback((props?: any) => {
    set((
      <Component
        {...initialProps}
        {...props ?? {}}
        onConfirm={async (...args: any) => {
          await (props.onConfirm ? props.onConfirm : (initialProps as any)?.onConfirm)(...args);
          hide();
        }}
        onCancel={props.onCancel || hide}
      />
    ));
  }, [Component, hide, initialProps, set]);

  return {
    show,
    hide,
  };
};
