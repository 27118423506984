import React from 'react';
import { BulkActionModal, BulkActionModalProps } from '../BulkActionModal';
import { AddBulkSecurityMeasures } from '../../../../views/SystemsView/components/SystemEditors/SystemEdit/AddBulkSecurityMeasures';

export const AddSecurityMeasuresModal: BulkActionModal = ({
  onChange = () => {},
  onCancel = () => {},
}: BulkActionModalProps) => (
  <AddBulkSecurityMeasures onCancel={onCancel} />
);
