import { useCallback, useMemo } from 'react';
import { useSelector } from '../store/useSelector';
import { PermissionModule } from '../types/permission/Permission';
import { permissionCodes } from '@privacy-request/basic-types';

export const usePermissions = () => {
  let permissions = useSelector(s => s.auth.permissions);
  const permissionOverrides = useSelector(s => s.auth.permissionOverrides);

  if (permissionOverrides) {
    permissions = permissionOverrides;
  }

  const permissionMap = useMemo(() => {
    const p: Partial<Record<permissionCodes, boolean>> = {};

    permissions?.forEach((perm) => {
      p[perm] = true;
    });

    return p;
  }, [permissions]);

  const hasPermission = useCallback((permissionCode: permissionCodes) => (
    permissionMap[permissionCode] || false
  ), [permissionMap]);

  const hasModule = useCallback((module: PermissionModule) => permissions?.some((p) => p.startsWith(module)), [permissions]);

  return {
    permissions,
    hasPermission,
    hasModule,
    has: hasPermission,
  };
};
