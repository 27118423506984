import React, {
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {
  ChevRight,
  Chip,
  useOnClickOutside,
} from '@privacy-request/ui';
import { FilterComponent, FilterComponentProps } from '../../ComplexFilterDropdown/FilterComponent.d';
import { useTheme } from 'styled-components';
import { MultiselectFilter } from '../../ComplexFilterDropdown/filters/utils/createMultiselectFilter';
import { CachePolicies } from 'use-http';
import { useFetch } from '../../../hooks/useFetch';
import { backendUrl } from '../../../utils/url';
import keycode from 'keycode';

export const BusinessFunctionsFilterTray = (props: FilterComponentProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { onCloseTray } = props;
  useOnClickOutside(ref, onCloseTray);

  const {
    get,
    data = [],
  } = useFetch(backendUrl('systems/used-functions'), { cachePolicy: CachePolicies.CACHE_FIRST });
  useEffect(() => {
    get();
  }, [get]);

  return (
    <div className="filter-config" ref={ref}>
      <ul className="filter-list compact">
        {
          data.map((func: any) => (
            <li><MultiselectFilter name="business_functions" title={func.name} value={func.id} allSelectedIfEmpty {...props} /></li>
          ))
        }
      </ul>
    </div>
  );
};

export const BusinessFunctionsFilter: FilterComponent = ({
  filterData,
  setOpen,
  isTrayOpen,
}: FilterComponentProps) => {
  const theme = useTheme();
  const onClick = useCallback((ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setOpen(() => (BusinessFunctionsFilterTray));
  }, [setOpen]);
  const onKeyPress = useCallback((e) => {
    switch (keycode(e)) {
      case 'space':
      case 'enter':
        onClick(e);
        break;
      default:
        break;
    }
  }, [onClick]);

  const count = [].concat(filterData.business_functions || []).length || 'All';

  return (
    <div
      className="flex spaced"
      onClick={isTrayOpen ? undefined : onClick}
      onKeyPress={onKeyPress}
      role="button"
      tabIndex={0}
    >
      <Chip
        color={theme.CTA_COLOR}
        textColor={count ? '#ffffff' : undefined}
      >
        {count}
      </Chip>
      <span>
        Business Functions
        <ChevRight />
      </span>
    </div>
  );
};

export const businessFunctionsFilter = BusinessFunctionsFilter;
