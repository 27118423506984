import find from 'lodash/find';
import indexOf from 'lodash/indexOf';
import React from 'react';
import styled from 'styled-components';

import { Text, CheckMarkIcon } from '../../atoms';

const StepsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
`;
StepsContainer.displayName = 'StepsContainer';

const StepContainer = styled.div`
  cursor: pointer;
  width: 100%;
  max-width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  &:hover div:first-of-type {
    border-color: ${({ theme }) => theme.BLACK};
    transform: scale(1.1);
  }
`;
StepContainer.displayName = 'StepContainer';

type EvaluateBackgroundColorProps = {
  theme: any
  active: boolean
  completed: boolean
};

const evaluateBackgroundColor = ({
  theme, active, completed,
}: EvaluateBackgroundColorProps) => {
  if (active || completed) {
    return theme.CTA_COLOR;
  }

  return theme.WHITE;
};

const StatusCircle = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  border-radius: 1000px;
  border-style: solid;
  border-width: 2px;
  border-color: ${({ theme }) => theme.CTA_COLOR_HOVER};
  background-color: ${evaluateBackgroundColor};
  transform: scale(1);
  transition: border-color 0.2s linear;
  transition: transform 0.2s linear;
`;
StatusCircle.displayName = 'StatusCircle';

const DottedLine = styled.div`
  position: absolute;
  top: 16px;
  left: 45px;
  right: 45px;
  height: 1px;
  background-image: linear-gradient(to right, black 53%, rgba(255,255,255,0) 40%);
  background-position: bottom;
  background-size: 12px 1px;
  background-repeat: repeat-x;
`;
DottedLine.displayName = 'DottedLine';

export type StepProps = {
  step: any
  active: boolean
  completed: boolean
  onClick: any
};

const Step = ({
  step, active, completed, onClick,
}: StepProps) => (
  <StepContainer onClick={onClick}>
    <StatusCircle active={active} completed={completed}>
      {(active || completed) && (<CheckMarkIcon />)}
    </StatusCircle>
    <Text style={{ fontSize: '14px', textAlign: 'center' }} bold={active}>{step.text}</Text>
    {step.subtext && (active || completed) && (<Text style={{ fontSize: '12px', marginTop: '2px' }}>{step.subtext}</Text>)}
  </StepContainer>
);

export type StepsProps = {
  steps: any[]
  value: any
};

export const Steps = ({ steps, value }: StepsProps) => {
  const currentStep = indexOf(steps, find(steps, { value }));
  return (
    <StepsContainer>
      <DottedLine />
      {steps.map((step, i) => (
        <Step
          key={step.value}
          step={step}
          active={i <= currentStep}
          completed={i < currentStep}
          onClick={step.onClick}
        />
      ))}
    </StepsContainer>
  );
};
