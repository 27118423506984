import clsx from 'clsx';
import React from 'react';

export type DropdownContentProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  right?: boolean
  secondary?: boolean
  plain?: boolean
};

export const DropdownContent = ({
  className,
  right,
  secondary,
  plain,
  ...props
}: DropdownContentProps) => (
  <div
    className={clsx('dropdown-content', className, {
      right,
      secondary,
      plain,
    })}
    {...props}
  />
);
