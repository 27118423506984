import gql from 'graphql-tag';
import { TaskFragment } from '../task/task.fragments';

export const RequestFragment = gql`
  fragment request_page on Request {
    id
    email
    status
    errors
    jurisdiction
    assignee @type(name: "User") {
      id
      first_name
      last_name
    }
    due_date
    request_type
    created_at
    rejected_at
    rejected_reason
  }

  fragment request on Request {
    ...request_page
    additional_info
    attachments
    completed_at
    country
    created_at
    detected_country
    first_name
    individual_type
    internal_notes
    is_controller
    last_name
    last_twelve_months
    source
    state
    tasks @type(name: "Task") {
      ...task
    }
    updated_at
    verified_at
    external_data
  }

  ${TaskFragment}
`;
