import { User } from '../../types';

export const validateUser = (user: Partial<User>) => {
  const errors: any = {};

  (['first_name', 'last_name', 'email'] as (keyof User)[]).forEach((k) => {
    if (!user[k]) {
      errors[k] = true;
    }
  });

  if (!user?.role_id) {
    errors.role_id = true;
  }

  if (Object.keys(errors).length) {
    throw errors;
  }
};
