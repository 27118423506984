/* eslint-disable react/jsx-one-expression-per-line */
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useIsDebugMode } from '../../hooks/useIsDebugMode';
import { DirtySaveRevert } from '../DirtySaveRevert';
import { GlobalDirtySaveRevertContext } from './GlobalDirtySaveRevertContext';
import { GlobalDirtySaveRevertManager } from './GlobalDirtySaveRevertManager';

const manager = new GlobalDirtySaveRevertManager();

// Inner
// ~~~~
export const GlobalDirtySaveRevertInner = (props: any) => {
  const [buster, rerender] = useReducer((x) => (x + 1), 0);
  const {
    isDirty,
    isSaving,
    subscribe,
    unsubscribe,
    onSave,
    onRevert,
    registrations,
  } = useContext(GlobalDirtySaveRevertContext);
  const { pathname } = useLocation();
  const isDebugMode = useIsDebugMode();
  const flush = useCallback(() => (buster), [buster]);

  useEffect(() => {
    subscribe(rerender);
    return () => {
      unsubscribe(rerender);
    };
  });

  const dirty = useMemo(() => {
    flush();
    return isDirty();
  }, [flush, isDirty]);

  const saving = useMemo(() => {
    flush();
    return isSaving();
  }, [flush, isSaving]);

  const allowRoutes = useMemo(() => {
    const registeredRoutes = registrations
      .flatMap((r) => (r.allowRoutes || []))
      .filter((r) => (r));
    if (registeredRoutes.length === 0) {
      return new RegExp(pathname.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&'));
    }
    return new RegExp(
      registeredRoutes
        .map((r) => (r.source))
        .join('|'),
    );
  }, [pathname, registrations]);

  return (
    <DirtySaveRevert dirty={dirty} saving={saving} onSave={onSave} onRevert={onRevert} allowRoutes={allowRoutes}>
      {isDebugMode && (
        <pre>
          Registrations: {registrations.length}<br />
          Allowed routes: {allowRoutes.source}
        </pre>
      )}
    </DirtySaveRevert>
  );
};

export type GlobalDirtySaveRevertProps = {
  children: ReactNode
};

export const GlobalDirtySaveRevert = ({
  children,
}: GlobalDirtySaveRevertProps) => (
  <GlobalDirtySaveRevertContext.Provider
    value={manager}
  >
    {children}
    <GlobalDirtySaveRevertInner />
  </GlobalDirtySaveRevertContext.Provider>
);
