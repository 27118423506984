import styled from 'styled-components';
import { GenericTextStyle } from '../styles';

const evaluateTextAlign = ({ right, center }: any) => {
  if (right) return 'right';
  if (center) return 'center';
  return 'left';
};

export interface TextProps {
  bold?: boolean
  center?: boolean
}

export const Text = styled.p<TextProps>`
  margin: 0;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  color: ${({ theme }) => theme.BLACK};
  text-align: ${evaluateTextAlign};
  ${GenericTextStyle}
`;

Text.displayName = 'Text';
