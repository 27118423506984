import i18n from '../translations/i18next';

const countryLanguages: any = {
  GB: 'en-GB',
  CA: 'en-CA',
  US: 'en-US',
};

export const setCountryLanguage = (country?: string) => {
  let code = window.navigator.language;
  if (country) {
    code = countryLanguages[country] || code;
  }
  if (i18n.language !== code) {
    i18n.changeLanguage(code);
  }
};
