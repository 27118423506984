import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { DropdownProps } from './DropdownProps';

export const useFiltering = (props: DropdownProps) => {
  const [filterText, setFilterText] = useState<string>('');
  const [filteredItems, setFilteredItems] = useState<any[]>(props.items);

  useEffect(() => {
    if (props.onFilter) {
      props.onFilter(filterText);
      return;
    }

    setFilteredItems(props.items.filter((i) => (i.text.toLowerCase().indexOf(filterText.toLowerCase()) !== -1)));
  }, [props.items]);

  const onFilter = useCallback((text: string) => {
    setFilterText(text);
    if (props.onFilter) {
      return props.onFilter(text);
    }

    setFilteredItems(props.items.filter((i) => (i.text.toLowerCase().indexOf(text.toLowerCase()) !== -1)));
  }, [props.items, props.onFilter]);

  return {
    filterText,
    filteredItems,
    onFilter,
  };
};
