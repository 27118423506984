import gql from 'graphql-tag';

export const SendFeedback = gql`
  mutation send_feedback($feedback: Object!) {
    send_feedback(input: $feedback) @rest(
      type: "Feedback" path: "feedback" method: "POST"
    ) {
      NoResult
    }
  }
`;
