import { useApolloClient } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { GetProcessingActivity } from '../apollo/systems/processing_activites/processing_activities.queries';
import { GetSystem } from '../apollo/systems/systems.queries';
import { useSelector } from '../store/useSelector';
import { useRouting } from './useRouting';

export const SYSTEM_RESUME_KEY = 'system_resume';
export const PROCESSING_ACTIVITY_RESUME_KEY = 'processing_activity_resume';
export const URL_RESUME = 'url_resume';

export const useLinkResume = () => {
  const location = useLocation();
  const authorized = useSelector(s => s.auth.authorized);
  const initializing = useSelector(s => s.auth.initializing);
  const client = useApolloClient();
  const { replace } = useRouting();

  useEffect(() => {
    if (!authorized || initializing) {
      return;
    }

    const search = new URLSearchParams(location.search);
    const resumeEntity = (key: string, entity_type?: 'system' | 'processing_activity', query?: DocumentNode) => {
      let resume: number | string | null = search.get(key);

      if (!resume) {
        resume = sessionStorage.getItem(key);
      }

      if (!resume) {
        return;
      }

      sessionStorage.removeItem(key);

      if (query) {
        resume = Number(resume);
        client.query({
          query,
          variables: { id: resume },
        }).then((result) => {
          const entity = result.data[Object.keys(result.data)[0]];
          if (entity_type === 'system') {
            if (entity.status === 'new') {
              replace(`/systems/workflow/${entity.id}/processing-activity`)();
            } else {
              replace(`/systems/${entity.id}`)();
            }
          } else {
            replace(`/systems/processing-activities/${entity.id}`)();
          }
        });
      } else {
        replace(resume)();
      }
    };

    resumeEntity(SYSTEM_RESUME_KEY, 'system', GetSystem);
    resumeEntity(PROCESSING_ACTIVITY_RESUME_KEY, 'processing_activity', GetProcessingActivity);
    resumeEntity(URL_RESUME);
  }, [location.search, authorized, initializing, replace, client]);
};
