import React, {
  useCallback, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Board from 'react-trello';
import {
  isEqual, differenceWith, filter,
} from 'lodash';
import { useContextualActivity } from '../../hooks/useActivityContext';
import { AssetCard } from '../../../../components/AssetCard/AssetCard';
import { Lineage } from '../../../../types/system/Lineage';
import { ProcessingActivityLineageAssetsModal } from './ProcessingActivityLineageAssetsModal';
import { Button } from '@privacy-request/ui';
import evt from '../../../../utils/evt';
import update from 'immutability-helper';
import { useToasts } from 'react-toast-notifications';

export const ProcessingActivityLineage = () => {
  const {
    current: processingActivity,
    canEditPerm,
    onChange,
  } = useContextualActivity();
  const [t] = useTranslation('systems');
  const { addToast } = useToasts();
  const [oldLineages, setOldLineages] = useState<Lineage[]>();
  const [saveFlag, setSaveFlag] = useState<boolean>(false);
  const canEdit = canEditPerm('processing_activities.edit.systems');
  const [selectedLane, setSelectedLane] = useState<string>('');

  const onRemoveCard = useCallback((cardId: string, laneName: string) => {
    const assetCardId = Number(cardId.split('-')[1]) || null;
    const index = processingActivity.lineage?.findIndex(s => Number(s.system_id) === assetCardId && s.lane_name === laneName);
    if (index !== -1) {
      onChange(evt('lineage', update(processingActivity?.lineage, {
        // @ts-ignore
        $splice: [[index, 1]],
      })));
    }
  }, [processingActivity, onChange]);

  const getLanesObject = useCallback((lanes: any, tags: any) => {
    const arr = lanes;
    processingActivity?.lineage?.forEach((lineage) => {
      const laneId = lineage?.lane_name ? lineage?.lane_name : 'collection';
      const asset = processingActivity.systems?.find((s) => s.id === lineage.system_id);
      // Handle if assets is not in the PA might got deleted [if forcefully delete the assset then lineage will resyn on next change]
      if (asset) {
        arr[laneId].cards.push({
          id: `${laneId}-${asset?.id}`,
          laneId,
          title: asset?.name,
          description: asset?.description,
          created_at: asset?.created_at,
          type: asset?.type && t(`form.types.${asset?.type}`),
          assetType: asset?.hosting_types ? asset?.hosting_types[0]?.name : '',
          tags: asset?.published ? [tags?.active] : [tags?.inactive],
          position: lineage?.lane_order ? lineage?.lane_order : 999,
          onRemoveCard,
        });
      }
    });
    return arr;
  }, [t, processingActivity, onRemoveCard]);

  const lanes: any = useMemo(() => {
    let lanes: any = {
      collection: {
        id: 'collection',
        title: 'Collection',
        label: '',
        cards: [] as any,
      },
      transformation: {
        id: 'transformation',
        title: 'Transformation',
        label: '',
        cards: [] as any,
      },
      processing: {
        id: 'processing',
        title: 'Processing',
        label: '',
        cards: [] as any,
      },
      retention: {
        id: 'retention',
        title: 'Retention',
        label: '',
        cards: [] as any,
      },
    };
    const tags = {
      active: {
        bgcolor: '#61BD4F',
        color: '#FFFFFF',
        title: 'Active',
      },
      inactive: {
        bgcolor: '#EB5A46',
        color: '#FFFFFF',
        title: 'Inactive',
      },
    };

    if (processingActivity?.lineage) {
      setOldLineages(processingActivity?.lineage);
    }

    lanes = getLanesObject(lanes, tags);
    const sortedLanes = Object.values(lanes || {});
    sortedLanes.forEach((lane: any) => {
      lane?.cards.sort((card1: any, card2: any) => card1.position - card2.position);
    });
    return sortedLanes;
  }, [processingActivity, getLanesObject]);

  const onDataChange = useCallback(async (newData) => {
    const lineages: any = [];
    newData.lanes.forEach((lane: any) => {
      const cards = lane.cards || [];
      cards.forEach((card: any, index: number) => {
        const exist = filter(lineages, { system_id: Number(card.id.split('-')[1]), lane_name: lane.id });
        // can't have duplicate card in the same column.
        if (!exist.length) {
          lineages.push({
            processing_activity_id: processingActivity?.id,
            system_id: Number(card.id.split('-')[1]),
            lane_name: lane.id,
            lane_order: Number(index) + 1, // order must start with 1
          });
        }
      });
    });

    const diff = differenceWith(oldLineages, lineages, isEqual);
    // If not old lineage that means need to save for the first time
    if (saveFlag && processingActivity?.id && (!oldLineages?.length || diff?.length)) {
      onChange(evt('lineage', update(processingActivity.lineage, { $set: lineages })));
      setSaveFlag(false);
    }
  }, [processingActivity, onChange, oldLineages, saveFlag]);

  const handleDragEnd = (cardId:any, sourceLaneId:any, targetLaneId:any, position:any, cardDetails:any) => {
    const assetCardId = Number(cardId.split('-')[1]) || null;
    const isExistInTargetLane = processingActivity.lineage?.findIndex(s => Number(s.system_id) === assetCardId && s.lane_name === targetLaneId);
    if (Number(isExistInTargetLane) >= 0 && sourceLaneId !== targetLaneId) {
      addToast(t('column_duplicate_card'), {
        autoDismiss: true,
        appearance: 'warning',
      });
      return null;
    }
    setSaveFlag(true);
  };

  const components = {
    Card: AssetCard,
    AddCardLink: (e:any) => (
      <Button
        onClick={() => {
          const { laneId } = e;
          setSelectedLane(laneId);
        }}
        style={{
          margin: '5px auto',
          width: '130px',
          borderColor: 'grey',
        }}
        padded
        short
        secondary
      >
        { t('assets.add_assets')}
      </Button>
    ),
  };
  // eslint-disable-next-line no-return-assign
  lanes?.map((o: any) => (o.label = `(${o.cards?.length})`));
  const data = { lanes };

  return (
    <div
      className="wrapper-lineage"
      style={{
        userSelect: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      { data
        ? (
          <Board
            laneStyle={{ minHeight: 365 }}
            tagStyle={{ fontSize: '80%' }}
            dragable
            style={{ backgroundColor: 'transparent' }}
            data={data}
            components={components}
            onDataChange={onDataChange}
            handleDragEnd={handleDragEnd}
            editable
          />
        ) : '' }
      { selectedLane && canEdit && (
        <ProcessingActivityLineageAssetsModal laneId={selectedLane} onCloseAssetModel={() => setSelectedLane('')} />
      )}
    </div>
  );
};
