import { DataMapEntity } from '@privacy-request/value-objects';
import { CustomField } from '../../components/CustomFields/CustomField';
import { CustomFieldConfig } from '../../components/CustomFields/CustomFields';
import {
  crossBorderTransfersCustomField,
  dataDisposalMethodsCustomField,
  processingCountriesCustomField,
} from '../../views/SystemsView/common/fields';

export type CreateCommonDetailFieldsOptions = {
  isLegalAssistant: boolean
  dataMapEntity: Partial<DataMapEntity>
  t: any
  onChange: any
  canEditProcessingCountries: boolean
  canEditDataRetention: boolean
  canEditTransfers: boolean
  retentionPolicyIsTextMode: boolean
  accept: any
};

export const createCommonDetailFields = ({
  isLegalAssistant,
  dataMapEntity,
  t,
  onChange,
  canEditProcessingCountries,
  canEditDataRetention,
  canEditTransfers,
  retentionPolicyIsTextMode,
  accept,
}: CreateCommonDetailFieldsOptions): CustomFieldConfig => ([
  {
    type: 'section',
    title: t('processing_countries'),
    storageKey: 'pa_processing_countries',
    helpText: t('tooltips.processing_countries', { name: dataMapEntity.name }),
    config: [
      processingCountriesCustomField(t, dataMapEntity, onChange, { disabled: !canEditProcessingCountries }),
    ],
  },
  {
    type: 'section',
    title: t('data_retention.title'),
    storageKey: 'system_data_retention_disposal',
    config: [
      ...(retentionPolicyIsTextMode ? [{
        type: 'textarea',
        name: 'retention_policy',
        disabled: !canEditDataRetention,
        label: t('data_retention.policy'),
        width: 2,
      } as CustomField] : []),
      {
        type: 'text',
        name: 'data_retention_period',
        label: t('data_retention.period'),
        disabled: !canEditDataRetention,
      } as CustomField,
      ...(!retentionPolicyIsTextMode ? [{
        type: 'fileupload',
        single: true,
        label: t('data_retention.policy'),
        name: 'retention_policy',
        disabled: !canEditDataRetention,
        accept,
      } as CustomField] : []),
      dataDisposalMethodsCustomField(t, dataMapEntity, onChange, { disabled: !canEditDataRetention }),
    ].filter(f => f),
  },
  {
    type: 'section',
    show: !isLegalAssistant,
    title: t('cross_border_transfers.title'),
    storageKey: 'pa_cbts',
    helpText: t('tooltips.cross_border_transfers', { name: dataMapEntity.name }),
    config: [
      crossBorderTransfersCustomField(t, dataMapEntity, onChange, { disabled: !canEditTransfers }),
    ],
  },
]);
