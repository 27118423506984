import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import './index.css';
import { ToastProvider } from 'react-toast-notifications';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/react-hooks';
import { I18nextProvider } from 'react-i18next';
import { defaultTheme } from '@privacy-request/ui';
import { client } from './apollo';
import App from './App';
import { loginRedirect, pca } from './config/msal';
import * as serviceWorker from './serviceWorker';
import store from './store';
import i18next from './translations/i18next';
import { HotkeyProvider } from './components/HotkeyProvider/HotkeyProvider';
import { GlobalDirtySaveRevert } from './components/GlobalDirtySaveRevert/GlobalDirtySaveRevert';
import { ModalProvider } from './hooks/useModalContext';

const theme = {
  ...defaultTheme,
  BTN_HOVER: '#164864',
};

const AadLandingPage = () => {
  useEffect(() => {
    loginRedirect({
      state: 'launcher',
    });
  }, []);
  return null;
};

const build = (pca: any) => {
  const result = (
    <Provider store={store}>
      <Router>
        <I18nextProvider i18n={i18next}>
          <ThemeProvider theme={theme}>
            <ApolloProvider client={client}>
              <ToastProvider placement="top-center">
                <ModalProvider>
                  <HotkeyProvider>
                    <GlobalDirtySaveRevert>
                      <Switch>
                        <Route path="/aad" component={AadLandingPage} />
                        <Route path="/madl/auth" render={() => <div />} />
                        <Route path="/" component={App} />
                      </Switch>
                    </GlobalDirtySaveRevert>
                  </HotkeyProvider>
                </ModalProvider>
              </ToastProvider>
            </ApolloProvider>
          </ThemeProvider>
        </I18nextProvider>
      </Router>
    </Provider>
  );

  return result;
};

pca.initialize()
  .then(() => (
    pca.handleRedirectPromise()
  ))
  .then(() => {
    if (window.location.hash.startsWith('#launcher')) {
      return loginRedirect({
        prompt: 'none',
      });
    }
    return null;
  })
  .then(() => {
    ReactDOM.render(
      build(pca),
      document.getElementById('root'),
    );
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
  });
