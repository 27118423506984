import keycode from 'keycode';
import React from 'react';
import styled from 'styled-components';

import { Text } from '../../atoms';

export interface RadioProps {
  /**
    * Wether the radio is checked or not.
    */
  checked: boolean
  /**
    * The label to go along with the radio, which can be clicked.
    */
  label?: string
  /**
    * Change the tabindex of the radio.
    */
  /**
    * The change handler when the radio is clicked.
    */
  disabled?: boolean
  onChange?: (e: any) => void
  name?: string
  style?: React.CSSProperties
  textStyle?: React.CSSProperties

}

const evaluateContentJustification = ({ left, around }: any) => {
  if (left) return 'flex-start';
  if (around) return 'space-around';
  return 'center';
};

const RadioWrapper = styled.div<any>`
  display: flex;
  flex-direction: ${({ inline }) => (inline ? 'row' : 'column')};
  justify-content: ${evaluateContentJustification};
  width: 100%;
`;
RadioWrapper.displayName = 'RadioWrapper';

const RadioItem = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: ${({ inline }) => (inline ? '16px' : 0)};
  padding-right: ${({ inline }) => (inline ? '16px' : 0)};
  padding-bottom: 16px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  outline: none;
  transition: all 0.1s ease-in-out;

  &:hover>div, &:focus>div {
    border-color: ${({ theme, disabled }) => (disabled ? theme.GREY : theme.CTA_COLOR)};
  }
`;

const evaluateBorderColor = ({
  theme, active, disabled,
}: any) => {
  if (disabled) {
    return theme.GREY;
  }

  if (active) {
    return theme.CTA_COLOR;
  }

  return theme.GREY;
};
const RadioCircle = styled.div<any>`
  width: 15px;
  height: 15px;
  box-sizing: border-box;
  border: 1px solid ${evaluateBorderColor};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease-in-out;
`;
type EvaluateBackgroundColorProps = {
  theme: any
  active: boolean
  disabled: boolean
};

const evaluateBackgroundColor = ({
  theme, active, disabled,
}: EvaluateBackgroundColorProps) => {
  if (disabled && active) {
    return theme.GREY;
  }

  if (active) {
    return theme.CTA_COLOR;
  }

  return 'rgba(0,0,0,0)';
};
const InnerGreen = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 100px;
  transition: all 0.1s ease-in-out;
  background-color: ${evaluateBackgroundColor};
`;

const Extra = styled.div`
  position: absolute;
  top: 24px;
`;
Extra.displayName = 'Extra';

export class Radio extends React.Component<RadioProps, any> {
  constructor(props: any) {
    super(props);

    ['handleKeyDown', 'handleRadio'].forEach(k => {
      (this as any)[k] = (this as any)[k].bind(this);
    });

    this.state = {
      value: props.value,
    };
  }

  static defaultProps = {
    items: [],
    tabIndex: '0',
  };

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.checked !== this.state.checked) {
      this.setState({
        checked: nextProps.checked,
      });
    }
  }

  handleKeyDown(e: any) {
    switch (keycode(e)) {
      case 'space':
      case 'enter':
        this.handleRadio();
        break;
      default:
        break;
    }
  }

  handleRadio(e?: any) {
    if (this.props.checked) {
      return;
    }

    this.setState({
      checked: !this.state.checked,
    }, () =>
      this.props.onChange && this.props.onChange({ target: { name: this.props.name, value: this.state.checked } }));
  }

  render() {
    const {
      checked, style, label, name,
    } = this.props;
    return (
      <RadioWrapper style={style}>
        <RadioItem
          name={name}
          style={style}
          onClick={checked ? null : (e: any) => this.handleRadio(e)}
          onKeyDown={checked ? null : (e: any) => this.handleRadio(e)}
        >
          <RadioCircle disabled={!checked} active={checked}>
            <InnerGreen disabled={!checked} active={checked} />
          </RadioCircle>
          <div style={{ paddingLeft: '8px', position: 'relative' }}>
            <Text>{ label }</Text>
          </div>
        </RadioItem>
      </RadioWrapper>
    );
  }
}
