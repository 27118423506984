import { DataMapEntity } from '@privacy-request/value-objects';

export const approvalRequired = (entity: Partial<DataMapEntity>) => (
  entity.approval_requested
);

export const approvalRequiredWhere = () => ({
  $or: [
    { approval_requested: true },
    { approved_at: null },
    { approved_by: null },
  ],
});
