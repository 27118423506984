import { Sort } from './useQueryPager';

export const sortOrder = (name: string, sort?: Sort): 'up' | 'down' | undefined => {
  if (!sort) return;

  const _sort = sort.find(s => s[0] === name);

  if (!_sort) return undefined;

  if (_sort[1] === 'ASC') {
    return 'up';
  }

  return 'down';
};

export const sortProps = (name: string, sort: Sort | undefined, onClick: any) => ({
  name,
  sort: sortOrder(name, sort),
  onClick: () => (onClick({ target: { name } })),
});
