import keycode from 'keycode';
import { bool } from 'prop-types';
import React from 'react';
import { Overlay, BackDrop } from '../../atoms';

export interface ModalProps {
  /**
   * Determines if the overlay should be blocking the app
   * and render the modal content
   */
  open?: boolean
  /**
   * The main content of your application. The children of the wrapping component technically.
   */
  content: React.ReactNode
  /**
   * The content to be rendered inside the Modal overlay.
   */
  children: React.ReactNode
  /**
   * By default, your overlay will center the children with flexbox.
   * Setting this to true will allow you to specify where the children belong.
   */
  rawOverlay?: boolean
  onCancel?: Function
}

export default class Modal extends React.Component<ModalProps> {
  private overlay: any;

  static propTypes = { open: bool.isRequired };

  static defaultProps = { open: false };

  constructor(props: ModalProps) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps: ModalProps) {
    if (!this.props.open && nextProps.open) {
      window.addEventListener('keydown', this.handleKeyDown);
    } else if (this.props.open && !nextProps.open) {
      window.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  handleKeyDown(e: KeyboardEvent) {
    switch (keycode(e)) {
      case 'esc':
        this.props.onCancel && this.props.onCancel();
        break;
      default:
        break;
    }
  }

  handleClick(e: React.MouseEvent) {
    if (e.target !== this.overlay) {
      return;
    }

    this.props.onCancel && this.props.onCancel();
  }

  render() {
    return (
      <div>
        <BackDrop active={this.props.open}>
          {this.props.children}
        </BackDrop>
        <Overlay rawOverlay={this.props.rawOverlay} ref={r => this.overlay = r} onClick={e => this.handleClick(e)} active={this.props.open}>
          {this.props.content}
        </Overlay>
      </div>
    );
  }
}
