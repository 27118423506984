import React from 'react';
import { evaluateExpression } from '@privacy-request/oql';
import { Field } from '@privacy-request/ui';
import { CustomField } from '../CustomField';
import { CustomFieldCommonOptions } from '../CustomFieldCommonOptions';
import { fields } from '../fields';
import { autoTranslate } from './autoTranslate';

export const renderWrapper = (_config: CustomField, opts: CustomFieldCommonOptions) => {
  const config = autoTranslate(_config, opts.t, opts.i18n);

  if (typeof config.show !== 'undefined' && !evaluateExpression(config.show, opts.values)) {
    return null;
  }

  if (!config) {
    return (<Field last={opts.last} first={opts.first} />);
  }

  const field = fields[config.type];
  if (!field || !field.wrapper) {
    return null;
  }

  return field.wrapper(config as any, opts);
};
