import React from 'react';
import { ProcessingActivityAssetsModal } from '../../../../views/ProcessingActivitiesView/components/ProcessingActivityEdit/ProcessingActivityAssetsModal';
import { BulkActionModal, BulkActionModalProps } from '../BulkActionModal';

export const AddAssetsModal: BulkActionModal = ({
  onChange = () => {},
  onCancel = () => {},
}: BulkActionModalProps) => (
  <ProcessingActivityAssetsModal bulkAction onCancel={onCancel} />
);
