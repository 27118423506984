import gql from 'graphql-tag';

export const PermissionFragment = gql`
  fragment permission on Permission {
    id
    code
    text
    subtext
  }
`;
