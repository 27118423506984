import React from 'react';
import { TableCell, ResizableTableHeader } from '@privacy-request/ui/src/atoms/SASSTable';
import {
  FieldBuilderComponent,
  FieldBuilderProps,
  FieldHeaderComponent,
  FieldHeaderProps,
} from './types/ColumnDefinition';

export const Header: FieldHeaderComponent = ({
  t,
}: FieldHeaderProps) => (
  <ResizableTableHeader key="functions">{t('table.function')}</ResizableTableHeader>
);

export const Cell: FieldBuilderComponent = ({
  row,
}: FieldBuilderProps) => (
  <TableCell key="functions">{row.business_functions?.map((o: any) => (o.name)).join(', ')}</TableCell>
);

export const relations = ['business_functions'];
