import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Label, Field, Input, Button, CheckBox, Dropdown, ErrorMessage, TabsComponent, TabsTab,
} from '@privacy-request/ui';
import { useTranslation } from 'react-i18next';
import update from 'immutability-helper';
import { useTheme } from 'styled-components';
import Modal from '../../components/OverlayProvider/Modal';
import { useMe } from '../../hooks/useMe';
import { useRouting } from '../../hooks/useRouting';
import { User } from '../../types';
import { useEditUser } from './hooks/useEditUser';
import { CustomFieldConfig, CustomFields } from '../../components/CustomFields/CustomFields';
import { Notifications } from '@privacy-request/basic-types';
import evt from '../../utils/evt';
import { useSubsidiaryDropdownCustomField } from '../../hooks/fields/useSubsidiaryDropdownCustomField';

export interface UserEditProps {
  /**
   * Pass in a refetch function to be called after this modal has changed underlying user data.
   */
  refetch?: any
  /**
   * A term that will auto-fill the user fields.
   *
   * Should work with "Andrew Wilson" or "andrew@test.com"
   * and will fill the fields accordingly.
   */
  term?: string
  /**
   * An optional function to have called with the new
   * User when this form is completed.
   */
  onComplete?: (user: User) => void
  /**
   * Override the onSave call to inject your own logic
   */
  onSaveOverride?: (user: Partial<User>) => Promise<void>
  /**
   * Override the modal cancel objectives (goBack by default)
   * with this function.
   */
  onCancel?: any
  /**
   * Automatically picks the "data-mapping-contact" role for the user
   * if it is present.
   */
  createFromDataMapping?: boolean
  /**
   * An array of used contact emails
   */
  emailsUsed?: string[]
  /**
   * Show the "god_mode" toggle for admins.
   */
  god?: boolean
  /**
   * "profileActionTitle" for showing title when user logged in
   *  and profile missing their phone, first_name, last_name
   */
  profileActionTitle?: string
  /**
   *
   */
  profile?: boolean
}
export const UserEdit = ({
  refetch, profile, term, onComplete, onSaveOverride, createFromDataMapping, onCancel, emailsUsed, god, profileActionTitle,
}: UserEditProps) => {
  const { goBack } = useRouting();
  const [t] = useTranslation('common');
  const [tab, setTab] = useState('details');

  const { me } = useMe();
  const theme = useTheme();
  const multi_org = me?.organization?.features?.multi_org_enabled;
  const defaultDueDatePreference = me?.organization?.settings?.preferences?.due_date_preference;

  const onTabClicked = useCallback((e: any) => {
    setTab(e.target.value);
  }, []);

  const {
    user,
    roles,
    onSave,
    onChange,
    onReset,
    disableOverride,
    errors,
  } = useEditUser({
    term,
    onComplete,
    onSaveOverride,
    createFromDataMapping,
    god,
  });

  const onCreate = useCallback(async (e: any) => {
    const success = await onSave(e);
    if (!success) {
      return;
    }
    if (refetch) {
      refetch();
    }
    if (onCancel) {
      onCancel();
    } else {
      goBack();
    }
  }, [goBack, onCancel, refetch, onSave]);

  const require_mfa_org_level = me?.organization?.settings?.require_mfa;
  const require_mfa = !!require_mfa_org_level || !!user.require_mfa;

  const roleItems = roles?.roles?.map((role) => ({
    value: role.id,
    text: role.name,
  })) || [];

  const err = (key: string): any => typeof errors[key] === 'string' && errors[key] && (<ErrorMessage>{t(`errors.${errors[key]}`)}</ErrorMessage>);
  const err2 = (key: string): any => typeof errors[key] === 'boolean' && errors[key] && (<ErrorMessage>{t('errors.required')}</ErrorMessage>);

  useEffect(() => {
    setTimeout(() => {
      // This fixes any popups that remain open from underneath the modal.
      // Necessary for the system onboard workflow when inviting a new
      // user to privacy request.
      document.getElementById('first_name')?.focus();
    }, 0);
  }, []);

  const emailInUse = !!emailsUsed?.find(e => e.toLowerCase() === user.email?.toLowerCase());

  const subsidiaryField = useSubsidiaryDropdownCustomField(user.subsidiaries, {
    name: 'subsidiaries',
  });

  const fieldConfig = useMemo((): CustomFieldConfig => ([subsidiaryField]), [subsidiaryField]);

  const tabs = useMemo((): TabsTab[] => ([{
    text: 'Details',
    value: 'details',
  }, {
    text: 'Notifications',
    value: 'notifications',
  }, {
    text: 'Preferences',
    value: 'preferences',
  }]), []);

  const notificationTypes = useMemo((): Notifications[] => ([
    'new_request',
    'legal_review',
    'new_asset_discovered',
    'assign_owner',
    'request_approval',
    'request_review',
    'review_complete',
  ]), []);
  const notificationConfig = useMemo((): CustomFieldConfig => (notificationTypes.map((key) => ({
    type: 'boolean',
    name: key,
    defaultValue: true,
    label: t(`settings:communication.notifications.${key}`),
    sublabel: t(`settings:communication.notifications.${key}_desc`),
  }))), [notificationTypes, t]);
  const notifications = useMemo(() => user.settings?.notifications || {}, [user.settings]);
  const onNotificationsChange = useCallback((e) => {
    const { name, value } = e.target;
    const nots = user.settings?.notifications || {};
    onChange(evt('settings', update(user.settings || {}, {
      notifications: {
        $set: update(nots, {
          [name]: { $set: value },
        }),
      },
    })));
  }, [user.settings, onChange]);

  const preferencesConfig = useMemo((): CustomFieldConfig => ([{
    type: 'dropdown',
    name: 'due_date_preference',
    items: [{ text: 'Calendar Days', value: 'calendar' }, { text: 'Business Days', value: 'business' }],
    label: 'Due Date Display Preference',
    defaultValue: defaultDueDatePreference || 'calendar',
  }]), [defaultDueDatePreference]);
  const preferences = useMemo(() => user.settings?.preferences || {}, [user.settings]);
  const onPreferencesChange = useCallback((e) => {
    const { name, value } = e.target;
    const prefs = user.settings?.preferences || {};
    onChange(evt('settings', update(user.settings || {}, {
      preferences: {
        $set: update(prefs, {
          [name]: { $set: value },
        }),
      },
    })));
  }, [user.settings, onChange]);

  const form = (
    <>
      <Field fluid flex>
        <Field>
          <Label>First Name:</Label>
          {err2('first_name')}
          <Input
            id="first_name"
            name="first_name"
            value={user.first_name || ''}
            onChange={onChange}
          />
        </Field>
        <Field>
          <Label>Last Name:</Label>
          {err2('last_name')}
          <Input
            name="last_name"
            value={user.last_name || ''}
            onChange={onChange}
          />
        </Field>
      </Field>
      <Field>
        <Label>Email:</Label>
        {err('email')}
        {err2('email')}
        {emailInUse && <ErrorMessage>{t('systems:errors.email_already_a_contact')}</ErrorMessage>}
        <Input
          name="email"
          value={user.email || ''}
          disabled={Boolean(user?.id)}
          onChange={onChange}
        />
      </Field>
      <Field>
        <Label>Phone:</Label>
        {err2('phone')}
        <Input
          name="phone"
          value={user.phone || ''}
          onChange={onChange}
          mask={t('form.phone_format')}
        />
      </Field>
      {(user?.id !== me?.id && (typeof profile === 'boolean' ? !profile : true)) && (
        <Field>
          <Label>Role:</Label>
          {err2('role_id')}
          <Dropdown
            items={roleItems}
            value={user?.role_id || 0}
            name="role_id"
            onChange={onChange}
            disabled={me?.role?.code !== 'admin'}
          />
        </Field>
      )}
      { multi_org && profileActionTitle !== 'Complete Profile' && (<CustomFields config={fieldConfig} value={user} onChange={onChange} />)}
      {(user?.id !== me?.id && (typeof profile === 'boolean' ? !profile : true)) && (
        <Field style={{ pointerEvents: 'none' }}>
          <div style={{ pointerEvents: 'none', background: '#eee' }}>
            <Label>
              <span>Require </span>
              <abbr title="Multifactor Authentication">MFA</abbr>
              ?
            </Label>
            <CheckBox
              checked={require_mfa}
              name="require_mfa"
              onChange={onChange}
            />
          </div>
        </Field>
      )}
    </>
  );

  return (
    <Modal title={!profileActionTitle ? (user?.id ? 'Modify User' : 'Create User') : profileActionTitle} style={{ maxWidth: profile ? '768px' : '480px' }} onCancel={onCancel || goBack}>
      {profile ? (
        <>
          <Field>
            <TabsComponent
              tabs={tabs}
              tab={tab}
              onTabClicked={onTabClicked}
              nav
              secondary
            />
          </Field>
          {tab === 'details' && (
            form
          )}
          {tab === 'notifications' && (
            <CustomFields
              config={notificationConfig}
              onChange={onNotificationsChange}
              value={notifications}
            />
          )}
          {tab === 'preferences' && (
            <div style={{ width: '50%' }}>
              <CustomFields
                config={preferencesConfig}
                onChange={onPreferencesChange}
                value={preferences}
              />
            </div>
          )}
        </>
      ) : (
        form
      )}
      {god && (
        <Field>
          <Label>
            Set as Superuser -
            {' '}
            <span style={{ color: theme.CTA_ERROR }}>
              <strong>Dangerous!</strong>
              {' '}
              Use with caution.
            </span>
          </Label>
          <CheckBox
            checked={!!user?.god_mode}
            name="god_mode"
            onChange={onChange}
          />
        </Field>
      )}
      <Field flex style={{ justifyContent: 'space-between', paddingBottom: 0 }}>
        {(user?.id && tab === 'details') ? (<Button onClick={onReset} secondary padded>Reset Password</Button>) : <div />}
        <Button
          disabled={emailInUse || disableOverride}
          onClick={onCreate}
          padded
        >
          {createFromDataMapping ? 'Create & Assign User' : (!profileActionTitle ? (user?.id ? 'Modify' : 'Create') : 'Complete')}
        </Button>
      </Field>
    </Modal>
  );
};
