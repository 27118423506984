import gql from 'graphql-tag';
import { ProcessingActivity } from '../../../types/system/ProcessingActivity';
import { ProcessingActivityFragments } from './processing_activities.fragments';

export interface SaveProcessingActivityVariables {
  activity: Partial<ProcessingActivity>
  id?: number
}

export interface CreateProcessingActivityResult {
  create_processing_activity: ProcessingActivity
}

export const CreateProcessingActivity = gql`
  mutation create_processing_activity($activity: ProcessingActivity!) {
    create_processing_activity(input: $activity) @rest(
      type: "ProcessingActivity" path: "processing-activities" method: "POST"
    ) {
      ...processing_activity
    }
  }

  ${ProcessingActivityFragments}
`;

export interface UpdateProcessingActivityResult {
  update_processing_activity: ProcessingActivity
}

export interface UpdateProcessingActivityVariables {
  id: number
  activity: Partial<ProcessingActivity>
}

export interface BulkActionProcessingActivityVariables {
  systems: any;
  ids?: number[];
}

export const UpdateProcessingActivity = gql`
  mutation update_processing_activity($activity: ProcessingActivity!, $id: Int!) {
    update_processing_activity(input: $activity, id: $id) @rest(
      type: "ProcessingActivity" path: "processing-activities/{args.id}" method: "PATCH" bodySerializer: "fileEncode"
    ) {
      ...processing_activity
    }
  }

  ${ProcessingActivityFragments}
`;

export const DeleteProcessingActivity = gql`
  mutation delete_processing_activity($id: Int!) {
    delete_processing_activity(id: $id) @rest(
      type: "NoResult" path: "processing-activities/{args.id}" method: "DELETE"
    ) {
      no_results
    }
  }
`;

export const UpdateBulkProcessingActivity = gql`
  mutation update_bulk_processing_activity($data: any) {
    update_bulk_processing_activity(input: $data) @rest(
      type: "ProcessingActivity" path: "processing-activities/bulk-update" method: "POST"
    ) {
      no_results
    }
  }
`;
