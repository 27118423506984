import React from 'react';
import styled from 'styled-components';
import { CaretDownIcon } from '../../atoms';

const IconWrapper = styled.div<{ expanded: boolean }>`
  width: 32px;
  height: 32px;
  transform: rotate(${({ expanded }) => (expanded ? '0' : '-180deg')});
`;
IconWrapper.displayName = 'IconWrapper';

export const Caret = (props: any) => (
  <IconWrapper {...props}>
    <CaretDownIcon width={32} height={32} color="rgba(0,0,0,0.8)" />
  </IconWrapper>
);
