import gql from 'graphql-tag';
import { SystemFragment } from '../systems.fragments';

/**
 * @deprecated
 */
export const UpdateSystemTemplate = gql`
  mutation update_system($system: System!, $id: Int!) {
    update_system(input: $system, id: $id) @rest(
      type: "System" path: "systems/templates/{args.id}" method: "PATCH" bodySerializer: "fileEncode"
    ) {
      ...system
    }
  }
  ${SystemFragment}
`;

/**
 * @deprecated
 */
export const CreateSystemTemplate = gql`
  mutation create_system($system: System!) {
    create_system(input: $system) @rest(
      type: "System" path: "systems/templates" method: "POST"
    ) {
      ...system
    }
  }
  ${SystemFragment}
`;
