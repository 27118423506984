import { useMutation } from '@apollo/react-hooks';
import update from 'immutability-helper';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Login, LoginResult, LoginVariables,
} from '../../../apollo/auth/local.mutations';
import {
  login as actionLogin,
  loginSuccess as actionLoginSuccess,
  loginError as actionLoginError,
  requireMFA,
  requireMFAConfiguration,
} from '../../../store/auth/auth.actions';
import { Credentials } from '../../../types/auth/Authentication';

export const useLogin = () => {
  const dispatch = useDispatch();

  const [credentials, setCredentials] = useState<Partial<Credentials>>({});
  const [login] = useMutation<LoginResult, LoginVariables>(Login);

  const onLogin = useCallback(async (e?: any) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    try {
      await dispatch(actionLogin());
      const { data } = await login({ variables: { credentials } });

      if (!data?.result.error) {
        await dispatch(actionLoginSuccess({ email: credentials.email }));
      } else if (data?.result.error && data?.result?.message === 'password_expired') {
        await dispatch(actionLoginError('password_expired'));
      } else {
        // All validation issues
        await dispatch(actionLoginError('credentials_invalid'));
      }
      setCredentials(o => update(o, {
        password: { $set: '' },
        code: { $set: '' },
      }));
    } catch (err: any) {
      if (err.networkError?.result?.message === 'mfa_not_configured') {
        // User hasn't configured MFA yet. Make them do so now
        // @ts-ignore
        await dispatch(requireMFAConfiguration(err.networkError?.result?.mfa_secret));
        return;
      }
      if (err.networkError?.result?.message === 'mfa_required') {
        await dispatch(requireMFA());
      } else if (err.networkError?.result?.message) {
        // Important messages from the server (eg. missing credentials)
        // Most situations should already be handled by client side validation
        // @ts-ignore
        await dispatch(actionLoginError(err.networkError.result?.message));
      } else {
        // All validation issues
        await dispatch(actionLoginError('credentials_invalid'));
      }
      setCredentials(o => update(o, { code: { $set: '' } }));
    }
  }, [credentials, login, dispatch]);

  const onChange = useCallback((e: any) => {
    const { name, value } = e.target;
    setCredentials(o => update(o, { [name]: { $set: value } }));
  }, []);

  const onCancel = useCallback((e?: any) => {
    setCredentials(o => update(o, {
      email: { $set: '' },
      password: { $set: '' },
      code: { $set: '' },
    }));
  }, [setCredentials]);

  return {
    onLogin,
    onChange,
    onCancel,
    credentials,
  };
};
