import React from 'react';
import { TableCell, TableHeader } from '@privacy-request/ui/src/atoms/SASSTable';
import {
  FieldBuilderComponent,
  FieldBuilderProps,
  FieldHeaderComponent,
  FieldHeaderProps,
} from './types/ColumnDefinition';
import { CheckBoxIcon, CheckBoxTickedIcon } from '@privacy-request/ui';

export const Header: FieldHeaderComponent = ({
  onSelectAll,
  allSelected,
}: FieldHeaderProps) => (
  <TableHeader shrink checkbox onClick={onSelectAll} style={{ width: '16px' }}>
    {
      allSelected
        ? (<CheckBoxTickedIcon />)
        : (<CheckBoxIcon />)
    }
  </TableHeader>
);

export const Cell: FieldBuilderComponent = ({
  onRowSelect,
  isSelected,
}: FieldBuilderProps) => {
  if (!onRowSelect) {
    return (<TableCell />);
  }
  return (
    <TableCell onClick={onRowSelect} shrink checkbox>
      {
        isSelected
          ? (<CheckBoxTickedIcon />)
          : (<CheckBoxIcon />)
      }
    </TableCell>
  );
};
