import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Button, Field } from '@privacy-request/ui';
import { useTranslation } from 'react-i18next';
import { useRouting } from '../../../../hooks/useRouting';
import { useSystemBulkActionsEdit } from '../../../../views/SystemsView/hooks/useSystemBulkActionsEdit';
import Modal from '../../../OverlayProvider/Modal';
import { useQueryPager } from '../../../../hooks/useQueryPager/useQueryPager';
import { SubOrganization } from '../../../../types/sub_organization/SubOrganization';
import { GetSubOrganizationsPaged } from '../../../../apollo/sub_organizations/sub_organization.queries';
import { useMe } from '../../../../hooks/useMe';
import { CustomFieldConfig, CustomFields } from '../../../CustomFields/CustomFields';
import { BulkActionModal, BulkActionModalProps } from '../BulkActionModal';
import { useProcessingActivityBulkActionsEdit } from '../../../../views/ProcessingActivitiesView/hooks/useProcessingActivityBulkActionsEdit';

export const AddSubsidiariesModal: BulkActionModal = ({ onCancel, controller }: BulkActionModalProps) => {
  const { onAddSubsidiaries: onAddAssetSubsidiaries } = useSystemBulkActionsEdit();
  const { onAddSubsidiaries: onAddActivitySubsidiaries } = useProcessingActivityBulkActionsEdit();
  const { goBack } = useRouting();
  const [t] = useTranslation('systems');
  const [selected, setSelected] = useState<any>(undefined!);

  const { me } = useMe();
  const multi_org = me?.organization?.features?.multi_org_enabled;
  const { results } = useQueryPager<SubOrganization>(GetSubOrganizationsPaged, {
    parameterPrefix: 'ent_',
    limit: 5000,
  });
  const subsidiaryItems = useMemo(() => (
    results.map((suborg) => ({ text: suborg.name, value: suborg.id }))
  ), [results]);
  const displayValue = useMemo(() => {
    if (!multi_org) return '';

    if (!selected?.length) {
      return t('common:none');
    }

    if (selected?.length === 1) {
      const suborg = selected[0];
      const item = results.find((r) => r.id === (suborg.id || suborg));
      return item?.name;
    }

    return t('n_subsidiaries', { count: selected.length });
  }, [multi_org, selected, t, results]);
  const onChange = useCallback((ev) => {
    ev.nativeEvent.preventDefault();
    ev.nativeEvent.stopPropagation();
    const { target: { value } } = ev;
    setSelected(value);
  }, []);
  const config = useMemo(() => ([{
    type: 'sass-dropdown',
    multiple: true,
    name: 'sub_organizations',
    items: subsidiaryItems,
    displayValue,
    label: 'Subsidiaries',
    persistent: true,
    filter: true,
  }] as CustomFieldConfig), [displayValue, subsidiaryItems]);
  const value = useMemo(() => ({
    sub_organizations: selected,
  }), [selected]);
  const onSubmit = useCallback(async () => {
    if (controller.entityType === 'systems') {
      await onAddAssetSubsidiaries(selected);
    } else {
      await onAddActivitySubsidiaries(selected);
    }
    onCancel();
  }, [controller.entityType, onAddActivitySubsidiaries, onAddAssetSubsidiaries, onCancel, selected]);

  return (
    <div>
      <Modal title={t('common:bulk_actions.add_subsidiaries')} onCancel={onCancel ?? goBack} style={{ maxWidth: '640px' }}>
        <CustomFields config={config} onChange={onChange} value={value} />
        <Field flex right style={{ paddingBottom: 0 }}>
          <Button padded style={{ display: 'flex', justifyContent: 'flex-end' }} onClick={onSubmit}>{t('common:bulk_actions.add_subsidiaries')}</Button>
        </Field>
      </Modal>
    </div>
  );
};
