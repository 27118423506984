import React, { useMemo } from 'react';
import { useMe } from '../../hooks/useMe';
import * as filters from './filters';
import { DataMapEntityListFilterBar, DataMapEntityListFilterParProps } from './DataMapEntityListFilterBar';

export const ActivityListFilterBar = (props: Omit<DataMapEntityListFilterParProps, 'filters'>) => {
  const { me } = useMe();
  const _filters = useMemo(() => ([
    filters.businessFunctions,
    ...(me?.organization?.features?.multi_org_enabled ? [filters.subsidiaries] : []),
    filters.status,
    filters.onlyMine,
  ]), [me?.organization?.features?.multi_org_enabled]);

  return (
    <DataMapEntityListFilterBar {...props} filters={_filters} />
  );
};
