import keycode from 'keycode';
import React from 'react';
import styled from 'styled-components';

import { Text } from '../../atoms';

export type RadioGroupItem = {
  text: string
  value: any
  extra?: any
  subtext?: string
};

export interface RadioGroupProps {
  /**
   * Disable the inputs, greying them out and disabling the click events.
   */
  disabled?: boolean
  /**
   * The inner value of the radio group.
   */
  value: any
  /**
   * The tabIndex of the RadioGroup.
   */
  tabIndex?: number
  /**
   * The name of the RadioGroup in the target event.
   */
  name?: string
  /**
   * Sets the radio group to be in-line.
   */
  inline?: boolean
  /**
   * If true, aligns the radio button with the top line of a multiline-entry
   */
  topAlign?: boolean
  /**
   * An array of items to display as radio button options.
   *
   * @example
   * [{ text: 'Option 1', value: 1 }, ...]
   */
  items: RadioGroupItem[]
  /**
   * Standard event handler method when the radio group selection changes.
   */
  onChange: (e: any) => void
  left?: boolean
  around?: boolean
  style?: React.CSSProperties
  itemStyle?: React.CSSProperties
  textStyle?: React.CSSProperties

}

const evaluateContentJustification = ({ left, around }: any) => {
  if (left) return 'flex-start';
  if (around) return 'space-around';
  return 'center';
};
// const evaluateContentJustification = ({ left }) => left ? 'flex-start' : 'center';

const RadioWrapper = styled.div<any>`
  display: flex;
  flex-direction: ${({ inline }) => (inline ? 'row' : 'column')};
  justify-content: ${evaluateContentJustification};
  width: 100%;
`;
RadioWrapper.displayName = 'RadioWrapper';

const RadioItem = styled.div<any>`
  display: flex;
  align-items: ${({ topAlign }) => (topAlign ? 'start' : 'center')};
  justify-content: flex-start;
  padding-left: ${({ inline }) => (inline ? '16px' : 0)};
  padding-right: ${({ inline }) => (inline ? '16px' : 0)};
  padding-bottom: 16px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  outline: none;
  transition: all 0.1s ease-in-out;

  &:hover>div, &:focus>div {
    border-color: ${({ theme, disabled }) => (disabled ? theme.GREY : theme.CTA_COLOR)};
  }
`;

const evaluateBorderColor = ({
  theme, active, disabled,
}: any) => {
  if (disabled) {
    return theme.GREY;
  }

  if (active) {
    return theme.CTA_COLOR;
  }

  return theme.GREY;
};
const RadioCircle = styled.div<any>`
  width: 15px;
  height: 15px;
  box-sizing: border-box;
  border: 1px solid ${evaluateBorderColor};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease-in-out;
`;

type EvaluateBackgroundColorProps = {
  theme: any
  active: boolean
  disabled: boolean
};

const evaluateBackgroundColor = ({
  theme, active, disabled,
}: EvaluateBackgroundColorProps) => {
  if (disabled && active) {
    return theme.GREY;
  }

  if (active) {
    return theme.CTA_COLOR;
  }

  return 'rgba(0,0,0,0)';
};
const InnerGreen = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 100px;
  transition: all 0.1s ease-in-out;
  background-color: ${evaluateBackgroundColor};
`;

const Extra = styled.div``;
Extra.displayName = 'Extra';

export class RadioGroup extends React.Component<RadioGroupProps, any> {
  constructor(props: any) {
    super(props);

    ['handleKeyDown', 'handleRadio'].forEach((k: any) => {
      (this as any)[k] = (this as any)[k].bind(this);
    });

    this.state = {
      value: props.value,
    };
  }

  static defaultProps = {
    items: [],
    tabIndex: '0',
  };

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value });
    }
  }

  handleKeyDown(value: any) {
    return (e: any) => {
      switch (keycode(e)) {
        case 'space':
        case 'enter':
          if (e.preventDefault && e.target.tagName.toUpperCase() !== 'INPUT') {
            e.preventDefault();
          }
          this.handleRadio(value)();
          break;
        default:
          break;
      }
    };
  }

  handleRadio(value: any) {
    return () => {
      const { name } = this.props;

      if (this.state.value === value) {
        return;
      }

      this.setState({ value }, () => {
        this.props.onChange({ target: { name, value } });
      });
    };
  }

  render() {
    const {
      disabled, left, inline, around, style, itemStyle, textStyle, topAlign,
    } = this.props;
    return (
      <RadioWrapper style={style} inline={inline} left={left} around={around}>
        {this.props.items.map(item => {
          const isActive = item.value === this.state.value;
          return (
            <RadioItem
              key={item.value}
              style={itemStyle}
              tabIndex={this.props.tabIndex}
              disabled={disabled}
              inline={inline}
              onClick={disabled ? null : this.handleRadio(item.value)}
              onKeyDown={disabled ? null : this.handleKeyDown(item.value)}
              topAlign={topAlign}
            >
              <RadioCircle disabled={disabled || false} active={isActive}>
                <InnerGreen disabled={disabled || false} active={isActive} />
              </RadioCircle>
              <div style={{ paddingLeft: '8px', position: 'relative' }}>
                <Text style={textStyle}>{ item.text }</Text>
                <Extra>
                  {item.subtext && (<Text style={{ fontSize: '13px' }}>{item.subtext}</Text>)}
                  {item.extra && (item.extra)}
                </Extra>
              </div>
            </RadioItem>
          );
        })}
      </RadioWrapper>
    );
  }
}
