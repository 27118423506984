import { Button } from '@privacy-request/ui';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, useRouteMatch } from 'react-router-dom';
import { usePermissions } from '../../../../hooks/usePermissions';
import { useRouting } from '../../../../hooks/useRouting';
import evt from '../../../../utils/evt';
import { RelatedDataMapItemList } from '../../../../components/RelatedDataMapItems/RelatedDataMapItemList/RelatedDataMapItemList';
import { System } from '../../../../types/system/System';
import { ProcessingActivity } from '../../../../types/system/ProcessingActivity';
import { useContextualActivity } from '../../hooks/useActivityContext';
import { ProcessingActivityAssetsModal } from './ProcessingActivityAssetsModal';

export const ProcessingActivityAssets = () => {
  const {
    current: processingActivity,
    onChange,
    canEditPerm,
  } = useContextualActivity();
  const [t] = useTranslation('systems');
  const { push } = useRouting();
  const match = useRouteMatch();
  const { hasPermission } = usePermissions();

  const canEdit = canEditPerm('systems.edit.related_systems');
  const canListOthers = hasPermission('systems.list.others');

  const relations = useMemo(() => processingActivity?.systems || [], [processingActivity.systems]);
  const CreateButton = useMemo(() => {
    if (canEdit) {
      return (
        <Button
          style={{ float: 'right', marginTop: '16px' }}
          padded
          onClick={push(`${match.url}/add`)}
        >
          {t('assets.relate_assets')}
        </Button>
      );
    }
  }, [canEdit, match.url, push, t]);

  const onClick = useCallback((target: System | ProcessingActivity) => (push(`/systems/${target.id}/details`)), [push]);

  const onDelete = useMemo(() => (id: number, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e?.stopPropagation();
    if (!processingActivity.systems) {
      return;
    }
    onChange(evt('systems', processingActivity.systems.filter((s) => (s.id !== id))));
  }, [onChange, processingActivity.systems]);

  return (
    <>
      <RelatedDataMapItemList
        currentItem={processingActivity as System}
        relations={relations}
        CreateButton={CreateButton}
        onClick={onClick}
        onDelete={onDelete}
        canListOthers={canListOthers}
        showLogos
      />
      <Route path={`${match.path}/add`} component={ProcessingActivityAssetsModal} />
    </>
  );
};
