import styled from 'styled-components';
import { InputWrapper } from '../../organisms/SearchAndSelect/SearchAndSelect';
import { Chip } from '../Chip';
import { TableRow } from './TableRow';

export const Table = styled.table`
  border-collapse: collapse;

  ${TableRow}:nth-child(even) {
    background: #f8f6ff;
  }

  input, ${InputWrapper} {
    background: transparent;
    border-color: #e1e0e0;
    border-width: 0;
    border-bottom-width: 1px;
    border-top-width: 1px;
    border-top-color: transparent;
    border-radius: 0;
    padding: 0;
    height: 40px !important;
    min-width: 175px;

    &:not([disabled]):focus,
    &:not([disabled]):hover {
      border-color: #454543;
      border-width: 0;
      border-bottom-width: 1px;
      border-top-width: 1px;
      border-top-color: transparent;
      border-radius: 0;
    }
  }

  ${InputWrapper} {
    padding-right: 24px;
  }

  ${InputWrapper} input {
    margin: 0;
    padding: 0;
  }
`;

Table.displayName = 'Table';
