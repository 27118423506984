import { stripNull } from '@privacy-request/utils';
import { SystemContact } from '../types/system/SystemContact';

export const formatContact = (sc: SystemContact): string => {
  const first_name = sc.user?.first_name || sc.first_name;
  const last_name = sc.user?.last_name || sc.last_name;
  if (!first_name?.trim()) {
    return sc.user?.email || sc.email || '';
  }
  return stripNull`${first_name} ${last_name}`;
};
