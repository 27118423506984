import styled from 'styled-components';
import { GenericNoWrapStyle, GenericTextStyle } from '../styles';
import { FlexColumnProps } from './ListTitle';

export const ListText = styled.div<FlexColumnProps>`
  flex: 1;
  flex-grow: ${({ grow, basis }) => (basis ? '0' : grow)};
  flex-basis: ${({ basis }) => (basis || '0')};
  text-align: ${({ right }) => (right ? 'right' : 'left')};

  margin: 0;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  color: ${({ theme }) => theme.BLACK};
  ${GenericTextStyle}
  ${GenericNoWrapStyle}
`;

ListText.defaultProps = { grow: 1 };
