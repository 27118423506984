import gql from 'graphql-tag';
import { Permission } from '../../types/permission/Permission';
import { PermissionFragment } from './permissions.fragments';

export interface GetPermissionsData {
  permissions: Permission[]
}

export const GetPermissions = gql`
  query permissions {
    permissions @rest(type: "[Permission]", path: "permissions") {
      ...permission
    }
  }
  ${PermissionFragment}
`;

export interface GetPermissionData {
  permission: Permission
}

export interface GetPermissionVariables {
  id: number
}

export const GetPermission = gql`
  query permission($id: Int!) {
    permission(id: $id) @rest(type: "[Permission]", path: "permissions/{args.id}") {
      ...permission
    }
  }
  ${PermissionFragment}
`;
