import { System } from '../../types/system/System';
import {
  SET_SYSTEM, CHANGE_SYSTEM, SET_SYSTEM_ERRORS,
} from './system.types';

export interface SetSystemAction {
  type: typeof SET_SYSTEM
  payload: Partial<System>
}

export const setSystem = (payload: Partial<System>): SetSystemAction => ({
  type: SET_SYSTEM,
  payload,
});

export interface ChangeSystemAction {
  type: typeof CHANGE_SYSTEM
  payload: Partial<System>
}

export const changeSystem = (payload: Partial<System>): ChangeSystemAction => ({
  type: CHANGE_SYSTEM,
  payload,
});

export interface SetSystemErrorsAction {
  type: typeof SET_SYSTEM_ERRORS
  payload: any
}

export const setSystemErrors = (payload: any): SetSystemErrorsAction => ({
  type: SET_SYSTEM_ERRORS,
  payload,
});

export type SystemActionTypes =
  SetSystemAction |
  ChangeSystemAction |
  SetSystemErrorsAction;
