import React from 'react';
import { BulkActionModal, BulkActionModalProps } from '../BulkActionModal';
import { SystemEditProcessingActivityModal } from '../../../../views/SystemsView/components/SystemEditors/SystemEdit/SystemEditProcessingActivityModal';

export const AddActivitiesModal: BulkActionModal = ({
  onChange = () => {},
  onCancel = () => {},
}: BulkActionModalProps) => (
  <SystemEditProcessingActivityModal bulkAction onCancel={onCancel} />
);
