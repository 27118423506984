import gql from 'graphql-tag';
import { Role } from '../../types/role/Role';
import { RoleFragment } from './roles.fragments';

export interface GetRolesData {
  roles: Role[]
}

export const GetRoles = gql`
  query roles {
    roles @rest(type: "[Role]", path: "roles") {
      ...role
    }
  }
  ${RoleFragment}
`;

export const GetOrgRoles = gql`
  query org_roles($organization_id: Int!) {
    roles(organization_id: $organization_id) @rest(type: "[Role]", path: "organizations/{args.organization_id}/roles") {
      ...role
    }
  }
  ${RoleFragment}
`;

export interface GetRoleData {
  role: Role
}

export interface GetRoleVariables {
  id: number
}

export const GetRole = gql`
  query role($id: Int!) {
    role(id: $id) @rest(type: "[Role]", path: "roles/{args.id}") {
      ...role
    }
  }
  ${RoleFragment}
`;
