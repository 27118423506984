import { ColumnDefinition } from '../../../components/DataMapEntityList/columns/types/ColumnDefinition';
import { Field } from '../../../components/SystemList/SystemListProps';
import {
  approved_at,
  functions,
  contacts,
  name,
  row_selector,
  status,
  subsidiaries,
  updated_at,
} from '../../../components/DataMapEntityList/columns';

// @ts-expect-error
export const columns: { [k in Field]: ColumnDefinition } = {
  row_selector,
  approved_at,
  functions,
  contacts,
  name,
  status,
  subsidiaries,
  updated_at,
};
