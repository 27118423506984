import React from 'react';
import {
  Field,
  Text,
  ToggleComponent,
} from '@privacy-request/ui';
import { get } from 'lodash';
import { CustomFieldOptions } from '../CustomFieldOptions';
import { CustomFieldDefinition } from '../CustomFieldDefinition';
import { ToggleRow } from '../../ToggleRow/ToggleRow';
import evt from '../../../utils/evt';

export interface CustomBooleanField extends CustomFieldOptions {
  type: 'boolean'
  name: string
  fluid?: boolean
  bold?: boolean
  label?: string
  sublabel?: string
}

export const boolean: CustomFieldDefinition<CustomBooleanField> = {
  type: 'boolean',
  component: (config, {
    onChange,
    values,
    t,
  }) => {
    const { bold = true } = config;
    return (
      <ToggleRow style={{ margin: 0 }} onClick={() => onChange(evt(config.name, !get(values, config.name, config.defaultValue)))}>
        <div>
          <Text bold={bold}>{config.label}</Text>
          {config.sublabel && <Text style={{ fontSize: '14px' }}>{t(config.sublabel)}</Text>}
        </div>
        <ToggleComponent
          checked={get(values, config.name, config.defaultValue)}
        />
      </ToggleRow>
    );
  },
  wrapper: (config, {
    last,
    first,
    fluid,
    content,
  }) => (
    <Field last={last} first={first} fluid={fluid} style={last ? { paddingBottom: 0 } : { paddingBottom: '6px' }} key={config.name || config.label}>
      {content}
    </Field>
  ),
};
