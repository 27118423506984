import update from 'immutability-helper';
import { Role } from '../../types/role/Role';
import { RoleActionTypes } from './role.actions';
import {
  CHANGE_ROLE, CREATE_ROLE, SET_ROLES, TOGGLE_ROLE_PERMISSION,
} from './role.types';

interface RoleState {
  roles?: Role[]
  isDirty: boolean
  errors?: any
}

const initialState: RoleState = { isDirty: false };

const getName = (roles: Role[], count: number = 1): string => {
  const name = `Custom Role ${count}`;
  const index = roles.findIndex(r => r.name === name);
  if (index !== -1) {
    return getName(roles, count + 1);
  }
  return name;
};

export default (state = initialState, action: RoleActionTypes): RoleState => {
  switch (action.type) {
    case SET_ROLES:
      return update(state, { roles: { $set: action.payload }, isDirty: { $set: false } });

    case TOGGLE_ROLE_PERMISSION:
      const {
        roles, role, permission,
      } = action.payload;
      let next = state;
      if (!next.roles) {
        next = update(state, { roles: { $set: roles } });
      }
      const rIndex = next.roles?.findIndex(r => r.name === role.name) as number;
      const pIndex = role.organization_role_permissions.findIndex(orp => orp.permission_id === permission.id);
      if (pIndex === -1) {
        return update(next, {
          roles: { [rIndex]: { organization_role_permissions: { $push: [{ role_id: role.id, permission_id: permission.id }] } } },
          isDirty: { $set: true },
        });
      }
      return update(next, {
        roles: { [rIndex]: { organization_role_permissions: { $splice: [[pIndex, 1]] } } },
        isDirty: { $set: true },
      });

    case CREATE_ROLE:
      let nextState = state;
      if (!nextState.roles) {
        nextState = update(state, { roles: { $set: action.payload.roles } });
      }
      const name = getName(nextState.roles || []);
      const nextRole = {
        id: action.payload.temp_id,
        organization_id: action.payload.organization_id,
        new: true,
        code: name,
        name,
        organization_role_permissions: [],
      } as unknown as Role;
      return update(nextState, {
        roles: { $push: [nextRole] },
        isDirty: { $set: true },
      });

    case CHANGE_ROLE:
      let nState = state;
      if (!state.roles) {
        nState = update(state, { roles: { $set: action.payload.roles } });
      }
      const { id, e } = action.payload;
      const { name: fieldName, value } = e.target;
      const changes: any = { [fieldName]: { $set: value } };
      if (fieldName === 'name') {
        changes.code = { $set: value };
      }
      const roleIndex = nState.roles?.findIndex(r => r.id === id) || -1;

      if (roleIndex === -1) {
        return state;
      }

      return update(nState, {
        roles: { [roleIndex]: changes },
        isDirty: { $set: true },
      });

    default:
      return state;
  }
};
