import React, { useCallback, useRef } from 'react';
import {
  ChevRight,
  Chip,
  useOnClickOutside,
} from '@privacy-request/ui';
import { FilterComponent, FilterComponentProps } from '../../ComplexFilterDropdown/FilterComponent.d';
import { BooleanFilter } from '../../ComplexFilterDropdown/filters/utils/createBooleanFilter';
import { useTheme } from 'styled-components';
import keycode from 'keycode';
import { TristateFilter } from '../../ComplexFilterDropdown/filters/utils/createTristateFilter';

export const StatusFilterTray = (props: FilterComponentProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { onCloseTray } = props;
  useOnClickOutside(ref, onCloseTray);
  return (
    <div className="filter-config" ref={ref}>
      <ul className="filter-list compact">
        <li><BooleanFilter name="approval_requested" title="Approval Requested" preset {...props} /></li>
        <li><BooleanFilter name="review_requested" title="Review Requested" preset {...props} /></li>
        <li />
        <li><TristateFilter name="published" title="Published" {...props} /></li>
        <li><BooleanFilter name="archived" title="Archived" preset={false} {...props} /></li>
      </ul>
    </div>
  );
};

/**
 * The status filter uses multiple fields that all act together to form the
 * concept of a status.
 */
export const StatusFilter: FilterComponent = ({
  filterData,
  setOpen,
  isTrayOpen,
}: FilterComponentProps) => {
  const theme = useTheme();
  const onClick = useCallback((ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setOpen(() => (StatusFilterTray));
  }, [setOpen]);
  const onKeyPress = useCallback((e) => {
    switch (keycode(e)) {
      case 'space':
      case 'enter':
        onClick(e);
        break;
      default:
        break;
    }
  }, [onClick]);

  const count = [
    filterData.approval_requested === true || !Object.prototype.hasOwnProperty.call(filterData, 'approval_requested'),
    filterData.review_requested === true || !Object.prototype.hasOwnProperty.call(filterData, 'review_requested'),
    filterData.published !== undefined || !Object.prototype.hasOwnProperty.call(filterData, 'published'),
    filterData.archived === true,
  ].filter((o) => (o)).length;

  return (
    <div
      className="flex spaced"
      onClick={isTrayOpen ? undefined : onClick}
      onKeyPress={onKeyPress}
      role="button"
      tabIndex={0}
    >
      <Chip
        color={count > 0 ? theme.CTA_COLOR : '#ffffff'}
        textColor={count ? '#ffffff' : undefined}
      >
        {count}
      </Chip>
      <span>
        Status
        <ChevRight />
      </span>
    </div>
  );
};

export const statusFilter = StatusFilter;
