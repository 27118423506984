import React from 'react';
import { useParams } from 'react-router-dom';
import { GetProcessingActivityHistoryPaged } from '../../../../../apollo/systems/processing_activites/processing_activities.queries';
import { AuditLogView } from '../../../../../components/AuditLogView';
import { BASE_HOST } from '../../../../../constants';
import { useAuditLogAggregator } from '../../../../../hooks/useAuditLogAggregator';
import { useMe } from '../../../../../hooks/useMe';
import * as transformers from './processing-activity-audit-transformers';

export const ProcessingActivityTabAuditLog = () => {
  const { id } = useParams<any>();
  const { isISO } = useMe();
  const { results, Pager } = useAuditLogAggregator({
    pageQuery: GetProcessingActivityHistoryPaged,
    variables: { id },
  });

  return (
    <AuditLogView debug={BASE_HOST === 'https://localhost:3000'} results={results} isISO={isISO} Pager={Pager} messageTransformers={Object.values(transformers)} />
  );
};
