import {
  SubTitle,
  Text,
  CheckBox,
  Button,
  ErrorMessage,
  Input,
  Field,
  Chip,
} from '@privacy-request/ui';
import isEmpty from 'lodash/isEmpty';
import React, {
  Fragment, useCallback, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { appendIf } from '../../utils/appendIf';
import evt from '../../utils/evt';
import { CheckOverCreateElementModal } from './CheckOverCreateElementModal';
import { SelectableItem } from './SelectableItem';

const Flex = styled.div`
  display: flex;
`;
Flex.displayName = 'Flex';

const LeftFlexItem = styled.div`
  position: relative;
  width: 100%;
  &::before, &::after {
    border-left: 15px solid #e1e0e0;
    display: block;
    position: absolute;
    right: -18px;
    content: '';
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
  }
  &::before {
    top: 40%;
  }
  &::after {
    bottom: 30%;
  }
`;

const RightFlexItem = styled.div`
  width: 100%;
`;
RightFlexItem.displayName = 'Flexitem';

const ScrollableBox = styled.div<any>`
  height: 300px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.GREY};
  border-radius: 4px;
  padding: 6px;
  margin: 6px;
  overflow-y: scroll;
  display: ${({ centered }) => (centered ? 'flex' : 'box')};
  justify-content: center;
  align-items: center;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0,0,0,.5);
      box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
  &[disabled] {
    background-color: #f3f3f3;
  }
`;
ScrollableBox.displayName = 'ScrollableBox';

const CheckBoxWrapper = styled.div`
  margin: 12px;
`;
CheckBoxWrapper.displayName = 'CheckBoxWrapper';

const styles: { [key: string]: React.CSSProperties } = {
  subtitle: {
    paddingLeft: '6px',
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  lightText: { color: 'rgba(0,0,0,0.5)' },
  text: { margin: '12px' },
  button: { margin: '12px 0' },
  header: {
    paddingLeft: '18px',
    marginTop: '12px',
    marginBottom: '18px',
    color: 'rgba(0,0,0,0.57)',
  },
  leftBox: { marginRight: '12px' },
  rightBox: { marginLeft: '12px' },
};

//
// Helper functions
//
const renderCheckbox = (
  value: number[],
  item: Suggestion,
  t: any,
  suggestionKey: string,
  translationArgs: any,
  onChecked: any,
) => {
  if (!item) {
    return null;
  }
  const checked = value.indexOf(item.value) !== -1;
  return (
    <CheckBoxWrapper key={item.value}>
      <CheckBox
        label={(
          <>
            {item.text.split('|').slice(-1)}
            {item.count && (
              <span>
                {' - '}
                <strong>
                  {t(suggestionKey, {
                    count: Number(item.count),
                    ...translationArgs,
                  })}
                </strong>
              </span>
            )}
          </>
        ) as any}
        name={`${item.value}`}
        onChange={onChecked}
        checked={checked}
      />
    </CheckBoxWrapper>
  );
};

const RenderSections = ({
  value,
  selections,
  t,
  suggestionKey,
  translationArgs,
  onChecked,
  onSelectAllSuggested,
  hideCategories,
}: {
  value: number[],
  selections: any,
  t: any,
  suggestionKey: string,
  translationArgs: any,
  onChecked: any,
  onSelectAllSuggested: any,
  hideCategories: boolean,
}) => {
  const sorted = useMemo(() => Object.entries<any>(selections).sort(([a], [b]) => {
    if (a === 'Suggested') return -1;
    if (b === 'Suggested') return 1;
    return a?.localeCompare(b);
  }), [selections]);
  return (
    <>
      {sorted.map(([title, items]) => (
        <Fragment key={title}>
          {title !== '__null__' && !hideCategories && (
            <SubTitle style={styles.subtitle}>
              {title}
              {title === 'Suggested' && (<Chip style={{ margin: '0 0 3px 6px' }} onClick={onSelectAllSuggested} color="#fff">Select All Suggested</Chip>)}
            </SubTitle>
          )}
          {
            items.map((item: Suggestion & { count: number }) => (
              renderCheckbox(value, item, t, suggestionKey, translationArgs, onChecked)
            ))
          }
        </Fragment>
      ))}
    </>
  );
};

const RenderSelected = ({ grouping, hideCategories }: { grouping: any, hideCategories: boolean }) => {
  const sorted = useMemo(() => Object.keys(grouping).sort((a, b) => a?.localeCompare(b)), [grouping]);
  return (
    <>
      {sorted.map((title) => (
        <Fragment key={title}>
          {title !== '__null__' && hideCategories !== true && (<SubTitle style={styles.subtitle}>{title}</SubTitle>)}
          {grouping[title].map((purpose: SelectableItem) => (
            <Text key={purpose.value} style={styles.text}>
              •&nbsp;&nbsp;
              {purpose.text.split('|').slice(-1)}
            </Text>
          ))}
        </Fragment>
      ))}
    </>
  );
};

const groupToSections = (items: any) => (
  items.reduce((res: any, item: SelectableItem) => {
    const parts = item.text.split('|');
    const [section] = parts;
    if (parts.length === 1) {
      return {
        ...res,
        __null__: (res.__null__ || []).concat([item]),
      };
    }
    return {
      ...res,
      [section]: (res[section] || []).concat([item]),
    };
  }, {} as any)
);

export interface Suggestion {
  text: string
  value: number
  count: number
  new: boolean
}

export interface CheckOverProps {
  /**
   * The name of the field on the entity, used in form submission and updating
   * entity fields
   */
  name: string
  /**
   * The current value of the field according to the entity
   */
  value: number[]
  /**
   * The items to display as selectable within the checkover component
   */
  items: SelectableItem[]
  /**
   * Zero or more suggested items to place at the top and mark as suggested
   */
  suggested?: Partial<Suggestion>[]
  /**
   * Determines whether or not the user can interact with the element
   */
  disabled?: boolean
  /**
   * Callback invoked when a selection is made
   */
  onChange: (ev: any) => void
  /**
   * Permit adding new elements on the fly
   */
  add?: boolean
  /**
   * Called when adding new elements on the fly. Resolved on success, rejected
   * on failure
   */
  onCreate?: (name: string) => Promise<any>
  headers?: boolean
  hideCategories?: boolean
  suggestionKey?: string
  translationArgs?: any
  /**
   * Components or content to place at the left side of the bar below the
   * checkover, at the same level as the "Create Custom" button.
   */
  left?: any
  /**
   * Components or content to place at the right side of the bar below the
   * checkover, at the same level as the "Create Custom" button.
   */
  right?: any
  error?: any
}

const standardCheckOverDefaultValues = {
  suggested: [] as Partial<Suggestion>[],
  headers: true,
  hideCategories: false,
  suggestionKey: 'found_in_n_assets',
};

export const StandardCheckOver = (props: CheckOverProps) => {
  const {
    name,
    value,
    items: _items,
    suggested: _suggested,
    disabled,
    onChange,
    add,
    onCreate,
    headers,
    hideCategories,
    suggestionKey,
    translationArgs,
    left,
    right,
    error,
  } = { ...standardCheckOverDefaultValues, ...props };
  const [t] = useTranslation('systems');
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const items = useMemo(() => _items.filter((i) => i.text.toLowerCase().indexOf(search.toLowerCase()) > -1), [_items, search]);
  const suggested = useMemo(() => {
    if (!search) return _suggested;
    return _suggested.filter((s) => (s.text?.toLowerCase().indexOf(search.toLowerCase()) || -1) > -1);
  }, [_suggested, search]);

  // The items selected, as indicated by the `value` array of ids
  const selected: SelectableItem[] = useMemo(() => {
    if (!items) {
      return [];
    }
    return items.filter(({ value: v }) => (value.indexOf(v) !== -1));
  }, [items, value]);

  const selections = useMemo(() => {
    if (!items) {
      return {};
    }

    const suggestions: any = {};
    if (suggested.length) {
      suggestions.Suggested = suggested.map((suggestion) => {
        const found = items.find((r: SelectableItem) => (r.text === suggestion.text));
        return {
          ...(found || {
            value: suggestion.text,
            text: `new|${suggestion.text}`,
          }),
          new: !!found,
          count: suggestion.count,
        };
      });
    }

    const group = {
      ...suggestions,
      ...groupToSections(items),
    };

    for (const [key, value] of Object.entries<any>(group)) {
      group[key] = value.sort((a: SelectableItem, b: SelectableItem) => {
        const [aa, ab] = a.text.split('|');
        const [ba, bb] = b.text.split('|');
        return ((ab || aa).localeCompare(bb || ba));
      });
    }
    return group;
  }, [items, suggested]);

  // Group selected items by their category prefix, for display purposes
  const grouping = useMemo(() => {
    const group = groupToSections(selected);
    Object.keys(group).forEach((k) => {
      group[k] = group[k].sort((a: SelectableItem, b: SelectableItem) => a.text.localeCompare(b.text));
    });
    return group;
  }, [selected]);

  const onChecked = React.useCallback(async (e) => {
    if (!items || !onCreate) {
      return;
    }

    // eslint-disable-next-line prefer-const
    let { name: _value, value: checked } = e.target;
    _value = parseInt(_value, 10);

    const item = items.find((i: SelectableItem) => i.value === _value);

    if (!item) {
      return;
    }

    if (checked) {
      onChange(evt(name, [...value, item.value]));
    } else {
      onChange(evt(name, value.filter((i) => (i !== _value))));
    }
  }, [onChange, value, name, items, onCreate]);

  const onSelectAllSuggested = useCallback(() => {
    const next = [...value, ...suggested.map((s) => {
      const i = items.find(i => i.text === s.text);
      return i?.value;
    })].filter((v, i, arr) => arr.indexOf(v) === i);
    if (next.length !== value.length) {
      onChange(evt(name, next));
    }
  }, [suggested, name, value, items, onChange]);

  return (
    <>
      {!disabled && (
        <Flex>
          <Field style={{ padding: '0 6px', width: '100%' }}>
            <Input
              search
              style={{ height: '36px' }}
              placeholder={t('common:form.search')}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Field>
          <Field />
        </Flex>
      )}
      <Flex>
        {!disabled && (
          <LeftFlexItem style={{ ...styles.leftBox, opacity: disabled ? 0 : 1 }}>
            {headers && (<SubTitle style={styles.header}>{t(`${name}.descriptor`)}</SubTitle>)}
            {error && <ErrorMessage style={{ top: '14px' }}>{error}</ErrorMessage>}
            <ScrollableBox disabled={disabled}>
              <RenderSections
                value={value}
                selections={selections}
                t={t}
                suggestionKey={suggestionKey}
                translationArgs={translationArgs}
                onChecked={onChecked}
                onSelectAllSuggested={onSelectAllSuggested}
                hideCategories={hideCategories}
              />
            </ScrollableBox>
          </LeftFlexItem>
        )}
        <RightFlexItem style={styles.rightBox}>
          {headers && (<SubTitle style={styles.header}>{t(`${name}.description`, { count: (value || []).length })}</SubTitle>)}
          <ScrollableBox disabled={disabled} centered={isEmpty(grouping)}>
            {isEmpty(grouping) && (
              <Text style={styles.lightText}>{t(`select_from_the_left_checkover${appendIf(disabled || false, '_disabled')}`)}</Text>
            )}
            <RenderSelected grouping={grouping} hideCategories={hideCategories} />
          </ScrollableBox>
        </RightFlexItem>
      </Flex>
      {!disabled && add && onCreate && (
        <Flex style={{ justifyContent: left ? 'space-between' : 'flex-end' }}>
          {left}
          <Button disabled={disabled} style={styles.button} padded onClick={() => setOpen(true)}>{t(`${name}.buttonText`)}</Button>
          {right}
        </Flex>
      )}
      {open && onCreate && (
        <CheckOverCreateElementModal
          items={_items}
          onCancel={() => setOpen(false)}
          onCreate={onCreate}
          type={name}
        />
      )}
    </>
  );
};
