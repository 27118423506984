import React, { useCallback } from 'react';
import styled from 'styled-components';

import {
  FloatingBox,
  FloatingBoxItem,
  Text,
  SearchIcon,
  SubTitle,
} from '../../atoms';

import { XIcon } from '../../atoms/icons';
import { GenericInputStyle, GenericTextStyle } from '../../atoms/styles';

import useSearchAndSelect from './hooks/useSearchAndSelect';
import { SearchAndSelectItem } from './SearchAndSelectItem';

export interface SearchAndSelectProps {
  /**
   * Allows the SearchAndSelect component to be used to add new values to the list.
   */
  add?: boolean
  /**
   * If `add` prop is TRUE, this function will be called when the user
   * attempts to create a new entry in the list.
   */
  onCreate?: (e: any) => void
  /**
   * An array of [{ text, value }, ...] items for the SearchAndSelect to filter on.
   */
  items: SearchAndSelectItem[]
  /**
   * An optional array of [{ text, value }, ...] items for the dropdown to display.
   * Defaults to the value of `items` if not set.
   */
  dropdownItems?: SearchAndSelectItem[]
  /**
   * Change this value to specify the number of digits before the SAS begins emitting onSearchChange events.
   */
  minimumCharacters?: number
  /**
   * Chance to translate/customize the no results found text. (Not visible when using add mode)
   */
  noItemsText?: string
  /**
   * Chance to translate/customize the "Create 'custom_text'" text displayed when creating a new item.
   */
  addText?: (str: string) => string
  /**
   * Emits this event when the search value changes, giving the wrapper the opportunity to update the `items[]` array.
   */
  onSearchChange?: (e: any) => void
  /**
   * Standard onChange event when the internal value of the SAS changes.
   */
  onChange: (e: any) => void
  /**
   * Placeholder value to display when no value is selected.
   */
  placeholder?: string
  /**
   * Disable the component.
   */
  disabled?: boolean
  /**
   * Wether the component acts a multi-select or single-select.
   */
  multi?: boolean
  /**
   * The element name, passed with the change handler.
   */
  name?: string
  /**
   * The represented value of the SearchAndSelect
   */
  value?: any
  /**
   * The floating box style.
   */
  floatingBoxStyle?: React.CSSProperties
  /**
   * I have no idea what this does but I see you using it in PipelineBuilder.tsx
   */
  wrapper?: any
  /**
   * Styles for wrapper
   */
  style?: React.CSSProperties
}

export const InputWrapper = styled.div<any>`
  ${GenericInputStyle}
  ${GenericTextStyle}
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'text')};
  height: unset;
  padding: unset;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  padding-left: 16px;
  padding-bottom: 6px;
  padding-right: 32px;
`;
InputWrapper.displayName = 'InputWrapper';

const ActualInput = styled.input`
  ${GenericTextStyle}
  padding: 0;
  outline: none;
  border: none;
  background-color: transparent;
  margin-top: 11px;
  margin-right: 11px;
  margin-bottom: 5.5px;
  flex-grow: 1;
  width: 100%;
`;
ActualInput.displayName = 'ActualInput';

const InputWidth = styled.span<any>`
  width: ${({ width }) => width};
`;
InputWidth.displayName = 'InputWidth';

const Chip = styled.div`
  ${GenericTextStyle}
  border-radius: 4px;
  padding: 5px;
  margin-top: 6px;
  margin-right: 5px;
  box-sizing: border-box;
  border: 1px solid rgba(0,0,0,0);
  background-color: ${({ theme }) => theme.CTA_COLOR};
  display: flex;
  align-items: center;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transform: background-color 0.1s linear;
  &:hover {
    border: 1px solid rgba(0,0,0,0.08);
  }
  & path {
    transform: fill 0.1s linear !important;
    fill: rgba(255,255,255,0.5) !important;
  }
  & svg:hover path {
    fill: white !important;
  }
`;
Chip.displayName = 'Chip';

const SearchPositioner = styled.div`
  position: absolute;
  right: 8px;
  height: 100%;
  display: flex;
  align-items: center;
`;
SearchPositioner.displayName = 'SearchPositioner';

const styles: Record<string, React.CSSProperties> = {
  floatingBox: { zIndex: 40 },
  text: { marginTop: '12px' },
  placeholder: {
    marginTop: '12px',
    position: 'absolute',
    color: 'rgba(0,0,0,0.4)',
  },
};

export const SearchAndSelect: React.FC<SearchAndSelectProps> = (props: any) => {
  const {
    selectedItems,
    onSelect,
    onDelete,
    searchingItems,
    searchOpen,
    inputValue,
    wrapperRef,
    inputRef,
    inputWidth,
    onInputChange,
    onSetInputFocus,
    focusedItemIndex,
    onKeyDown,
    onKeyUp,
    onBlur,
    onFocus,
    onClose,
  } = useSearchAndSelect(props);

  return (
    <div ref={wrapperRef} onKeyDown={onKeyDown} onKeyUp={onKeyUp} onBlur={onBlur} onFocus={onFocus}>
      <InputWrapper disabled={props.disabled} onClick={onSetInputFocus} {...(props.wrapper || {})}>
        {selectedItems.map(({ text, value }, i) => (props.multi ? (
          <Chip key={value}>
            {text}
            {' '}
            {!props.disabled && (<XIcon onClick={() => onDelete(i)} style={{ marginLeft: '2px' }} />)}
          </Chip>
        ) : (
          <Text key={value} style={styles.text}>{text}</Text>
        )))}
        {!selectedItems.length && props.placeholder && !inputValue && (
          <Text style={styles.placeholder}>{props.placeholder}</Text>
        )}
        <InputWidth width={inputWidth}>
          <ActualInput ref={inputRef} value={inputValue} disabled={props.disabled} onChange={onInputChange} />
        </InputWidth>
        <SearchPositioner>
          <SearchIcon />
        </SearchPositioner>
      </InputWrapper>
      {searchOpen && (
        <FloatingBox style={{ ...styles.floatingBox, ...props.floatingBoxStyle }}>
          {searchingItems.map(({
            text, value, disabled = false, ref, onClick,
          }, i) => {
            if (value === 'DIVIDER') {
              return (
                <SubTitle key={`${value}-${i}-${text}`} style={{ padding: '8px 0 8px 16px', fontSize: '16px' }}>
                  {text}
                </SubTitle>
              );
            }
            return (
              <FloatingBoxItem
                key={value}
                ref={ref}
                style={{ fontWeight: value === 'add' ? 'bold' : 'unset' }}
                onClick={(e: Event) => { e.stopPropagation(); e.preventDefault(); !disabled && (onClick ? onClick(e, { onClose }) : onSelect(i)); }}
                active={i === focusedItemIndex && !disabled}
                disabled={disabled}
              >
                {text}
              </FloatingBoxItem>
            );
          })}
        </FloatingBox>
      )}
    </div>
  );
};
