import update from 'immutability-helper';
import { Request } from '../../types/request/Request';
import { RequestActionTypes } from './request.actions';
import { CHANGE_REQUEST, SET_REQUEST } from './request.types';

interface RequestState {
  request: Partial<Request>
  isDirty: boolean
  errors: any
}

const initialState: RequestState = {
  request: {},
  isDirty: false,
  errors: undefined,
};

export default (state = initialState, action: RequestActionTypes): RequestState => {
  switch (action.type) {
    case SET_REQUEST:
      return update(state, {
        request: { $set: action.payload },
        isDirty: { $set: false },
      });
    case CHANGE_REQUEST:
      return update(state, {
        request: { $set: action.payload },
        isDirty: { $set: true },
      });
    default:
      return state;
  }
};
