import clsx from 'clsx';
import {
  bool,
  func,
} from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { withHandlers, withStateHandlers } from 'recompose';
import getContext from 'recompose/getContext';
import { compose } from 'redux';
import styled from 'styled-components';
import './SideNavigationItem.scss';

export interface SideNavigationItemProps {
  /**
   * The text/html the side nav item should contain.
   */
  children?: React.ReactNode
  /**
   * Handler for click of the side nav item.
   */
  onClick?: React.MouseEventHandler<HTMLElement>
  /**
   * Indicates wether the <SideNavigationItem /> is presently selected. (Slightly darkens it.)
   */
  active?: boolean
  /**
   * The icon for the side nav item.
   */
  icon?: React.ReactNode
  /**
   * Wether the cursor should be disabled when hovering over this item.
   */
  disabled?: boolean
  isSubItem?: boolean
  noClick?: boolean
}

const Icon = styled.div`
  margin-left: 16px;
`;

Icon.displayName = 'Icon';

const Content = styled.div`
`;

Content.displayName = 'Content';

const Positioner = styled.div``;
Positioner.displayName = 'Positioner';

export const SideNavigationItem = compose(
  getContext({
    collapsed: bool.isRequired,
    getElement: func.isRequired,
  }),
)(({
  children, registerRef, active, icon, disabled, isSubItem, collapsed, className, getElement, ...props
}: any) => (
  <div
    className={clsx('nav-item', className, {
      collapsed,
      disabled,
      active,
      'is-sub-item': isSubItem,
    })}
    ref={registerRef}
    {...props}
  >
    <Icon>{ icon }</Icon>
    {(!collapsed || isSubItem) && (
      <div className="nav-item-content">
        { children }
      </div>
    )}
  </div>
)) as unknown as React.FC<SideNavigationItemProps>;
