import { EventPayload, EventType } from '@azure/msal-browser';
import {
  LOGIN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGIN_CANCEL,
  LOGOUT,
  SET_PERMISSIONS,
  SET_PERMISSION_OVERRIDES,
  LOCAL_AUTH_INITIALIZED,
  REQUIRE_MFA,
  REQUIRE_MFA_CONFIGURATION,
  FINISH_MFA,
} from './auth.types';
import { permissionCodes } from '@privacy-request/basic-types';

interface LocalAuthInitializedAction {
  type: typeof LOCAL_AUTH_INITIALIZED
}

export const localAuthInitialized = (): LocalAuthInitializedAction => ({ type: LOCAL_AUTH_INITIALIZED });

interface LoginAction {
  type: typeof LOGIN
  payload?: any
}

export const login = (): LoginAction => ({ type: LOGIN });

interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS
  payload: any
}
export const loginSuccess = (payload: any): LoginSuccessAction => ({ type: LOGIN_SUCCESS, payload });

interface LoginErrorAction {
  type: typeof LOGIN_ERROR
  payload: any
}
export const loginError = (payload: any): LoginErrorAction => ({ type: LOGIN_ERROR, payload });

interface LoginCancelAction {
  type: typeof LOGIN_CANCEL
}
export const loginCancel = (): LoginCancelAction => ({ type: LOGIN_CANCEL });

interface LogoutAction {
  type: typeof LOGOUT
}
export const logout = (): LogoutAction => ({ type: LOGOUT });

interface SetPermissionsAction {
  type: typeof SET_PERMISSIONS
  payload?: permissionCodes[]
}
export const setPermissions = (payload?: permissionCodes[]): SetPermissionsAction => ({ type: SET_PERMISSIONS, payload });

interface SetPermissionOverridesAction {
  type: typeof SET_PERMISSION_OVERRIDES
  payload?: permissionCodes[]
}
export const setPermissionOverrides = (payload?: permissionCodes[]): SetPermissionOverridesAction => ({ type: SET_PERMISSION_OVERRIDES, payload });

/**
 * MSAL Redux Actions
 *
 * Could be moved to a separate file.
 */

interface MSALLoginSuccessAction {
  type: typeof EventType.LOGIN_SUCCESS
  payload: EventPayload
}

interface MSALAcquiredTokenSuccess {
  type: typeof EventType.ACQUIRE_TOKEN_SUCCESS
  payload: EventPayload
}

interface MSALLogoutSuccess {
  type: typeof EventType.LOGOUT_SUCCESS
  payload: EventPayload
}

interface MSALLoginError {
  type: typeof EventType.LOGIN_FAILURE
  payload: EventPayload
}

interface MSALLoginStart {
  type: typeof EventType.LOGIN_START
  payload: EventPayload
}

interface MSALLogoutStart {
  type: typeof EventType.LOGOUT_START
  payload: EventPayload
}

interface MSALLogoutFailure {
  type: typeof EventType.LOGOUT_FAILURE
  payload: EventPayload
}

interface MSALLogoutEnd {
  type: typeof EventType.LOGOUT_END
  payload: EventPayload
}

interface MSALAcquireTokenStart {
  type: typeof EventType.ACQUIRE_TOKEN_START
  payload: EventPayload
}

interface MSALAcquireTokenSuccess {
  type: typeof EventType.ACQUIRE_TOKEN_SUCCESS
  payload: EventPayload
}

interface MSALAcquireTokenFailure {
  type: typeof EventType.ACQUIRE_TOKEN_FAILURE
  payload: EventPayload
}

interface MSALAcquireTokenNetworkStart {
  type: typeof EventType.ACQUIRE_TOKEN_NETWORK_START
  payload: EventPayload
}

interface MSALSSOSilentStart {
  type: typeof EventType.SSO_SILENT_START
  payload: EventPayload
}

interface MSALSSOSilentSuccess {
  type: typeof EventType.SSO_SILENT_SUCCESS
  payload: EventPayload
}

interface MSALSSOSilentFailure {
  type: typeof EventType.SSO_SILENT_FAILURE
  payload: EventPayload
}

interface MSALHandleRedirectStart {
  type: typeof EventType.HANDLE_REDIRECT_START
  payload: EventPayload
}

interface MSALHandleRedirectEnd {
  type: typeof EventType.HANDLE_REDIRECT_END
  payload: EventPayload
}

// interface AADInitializingAction {
//   type: typeof AuthenticationActions.Initializing;
// }

// interface AADInitializedAction {
//   type: typeof AuthenticationActions.Initialized;
// }

/**
 * MFA Actions
 */
interface RequireMFAAction {
  type: typeof REQUIRE_MFA
}
export const requireMFA = (): RequireMFAAction => ({ type: REQUIRE_MFA });

interface RequireMFAConfigurationAction {
  type: typeof REQUIRE_MFA_CONFIGURATION
  secret: string
}
export const requireMFAConfiguration = (secret: string): RequireMFAConfigurationAction => ({ type: REQUIRE_MFA_CONFIGURATION, secret });

interface FinishMFAAction {
  type: typeof FINISH_MFA
}
export const finishMFA = (secret: string): FinishMFAAction => ({ type: FINISH_MFA });

export type AuthActionTypes =
  LoginAction
  | LoginSuccessAction
  | LoginErrorAction
  | LoginCancelAction
  | LogoutAction
  | LocalAuthInitializedAction
  | SetPermissionsAction
  | SetPermissionOverridesAction
  // | AADAcquiredIdTokenSuccess
  // | AADAcquiredAccessTokenSuccess
  // | AADInitializedAction
  // | AADInitializingAction
  // | AADLogoutSuccess
  // | AADLoginSuccess
  // | AADLoginError
  | MSALLoginSuccessAction
  | MSALAcquiredTokenSuccess
  | MSALLogoutSuccess
  | MSALLoginError
  | MSALLoginStart
  | MSALLogoutStart
  | MSALLogoutFailure
  | MSALLogoutEnd
  | MSALAcquireTokenStart
  | MSALAcquireTokenSuccess
  | MSALAcquireTokenFailure
  | MSALAcquireTokenNetworkStart
  | MSALSSOSilentStart
  | MSALSSOSilentSuccess
  | MSALSSOSilentFailure
  | MSALHandleRedirectStart
  | MSALHandleRedirectEnd
  | RequireMFAAction
  | RequireMFAConfigurationAction
  | FinishMFAAction;
