import React from 'react';
import { Field, Label } from '@privacy-request/ui';
import { get } from 'lodash';
import FileUpload from '../../FileUpload/FileUpload';
import { CustomFieldDefinition } from '../CustomFieldDefinition';
import { CustomFieldOptions } from '../CustomFieldOptions';
import { getRest } from '../utils/getRest';

export interface CustomFileUploadField extends CustomFieldOptions {
  type: 'fileupload'
  label: string
  name: string
  single?: boolean
  disabled?: boolean
  accept?: string
}

export const fileupload: CustomFieldDefinition<CustomFileUploadField> = {
  type: 'fileupload',
  component: (config, { onChange, values }) => (
    <FileUpload
      single={config.single}
      name={config.name}
      value={get(values, config.name)}
      onChange={onChange}
      disabled={config.disabled}
      canDownload={!config.disabled}
      {...getRest(config)}
    />
  ),
  wrapper: (config, {
    first,
    last,
    content,
  }) => (
    <Field last={last} first={first} key={config.name}>
      <Label>{config.label}</Label>
      {content}
    </Field>
  ),
};
