import { createBooleanFilter } from '../../ComplexFilterDropdown/filters/utils/createBooleanFilter';

export const onlyMineFilter = createBooleanFilter({
  name: 'user',
  title: 'Only Mine',
  preset: false,
  isActive(filterData) {
    return filterData.user === 'me';
  },
  setActive(filterData) {
    if (filterData.user === 'me') {
      return {};
    }
    return {
      user: 'me',
    };
  },
});
