import React from 'react';
import { FALLBACK_ASSET_IMAGE_URL } from '../../constants';
import './Logo.scss';

export type LogoProps = {
  /**
   * The url to the logo image
   */
  url: string
  /**
   * The alternative text of the image if neither the url nor the fallback
   * resolves to an image
   */
  alt: string
};

export const Logo = ({ url, alt }: LogoProps) => (
  <img
    className="logo"
    src={url}
    alt={alt}
    onError={({ currentTarget }) => {
      currentTarget.onerror = null; // prevents looping
      currentTarget.src = FALLBACK_ASSET_IMAGE_URL;
    }}
  />
);
