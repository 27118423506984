import { Button } from '@privacy-request/ui';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { CachePolicies } from 'use-http';
import { CustomFields } from '../../../../components/CustomFields/CustomFields';
import { ExpandAndCollapse } from '../../../../components/ExpandAndCollapse/ExpandAndCollapse';
import { NextPrev } from '../../../../components/NextPrev';
import { useFetch } from '../../../../hooks/useFetch';
import { useMe } from '../../../../hooks/useMe';
import { setProcessingActivityErrors } from '../../../../store/processing_activity/processing_activity.actions';
import { appendIf } from '../../../../utils/appendIf';
import evt from '../../../../utils/evt';
import { backendUrl } from '../../../../utils/url';
import { businessFunctionCustomField } from '../../../SystemsView/common/fields';
import { useContextualActivity } from '../../hooks/useActivityContext';
import { CustomField } from '../../../../components/CustomFields/CustomField';
import { useSubsidiaryDropdownCustomField } from '../../../../hooks/fields/useSubsidiaryDropdownCustomField';
import { StepComponent, StepComponentProps } from '../../../../components/DataMapWorkflowComponents/types/WorkflowStep';

export const DetailsStep: StepComponent = ({
  steps,
  forward,
  backward,
}: StepComponentProps) => {
  const [t] = useTranslation('systems');
  const {
    current: processingActivity, onChange, errors,
  } = useContextualActivity();
  const dispatch = useDispatch();
  const { isISO } = useMe();
  const { addToast, removeAllToasts } = useToasts();

  const subsidiariesField = useSubsidiaryDropdownCustomField(processingActivity.sub_organizations, { name: 'sub_organizations' });

  const fields = useMemo((): CustomField[] => [
    {
      type: 'text',
      name: 'name',
      label: t('pa.workflow.name'),
      autoFocus: true,
    },
    // TODO label: pa.workflow.functions
    businessFunctionCustomField(t, processingActivity, onChange, { errors, persistent: true }),
    subsidiariesField,

  ], [t, processingActivity, onChange, errors, subsidiariesField]);

  const { get } = useFetch(backendUrl(`processing-activities/check-name?name=${encodeURIComponent(processingActivity?.name || '')}`), { cachePolicy: CachePolicies.NETWORK_ONLY });

  const validate = useCallback(async () => {
    const err: any = {};
    if (!processingActivity.name) {
      err.name = t('common:errors.required');
    }
    if (!err.name) {
      const name = await get();
      if (name !== processingActivity.name) {
        err.name = t('common:errors.unique violation');
        const onAccept = () => {
          onChange(evt('name', name));
          removeAllToasts();
        };
        addToast((
          <div>
            {t(`errors.processing_activity_taken${appendIf(isISO, '_service')}`, { name: processingActivity.name, suggestion: name })}
            <Button style={{ marginTop: '12px' }} onClick={onAccept} short secondary fluid>{t('errors.use_suggestion', { suggestion: name })}</Button>
          </div>
        ), {
          appearance: 'warning',
          autoDismiss: true,
        });
      }
    }
    if (!processingActivity.business_functions?.length) {
      err.business_functions = t('common:errors.required');
    }

    if (Object.keys(err).length) {
      dispatch(setProcessingActivityErrors(err));
      return false;
    }
    return true;
  }, [dispatch, t, processingActivity, isISO, addToast, get, onChange, removeAllToasts]);

  return (
    <ExpandAndCollapse title={t(`pa.workflow.details_desc${appendIf(isISO, '_service')}`)} storageKey="pa_details_Desc" expandable={false}>
      <div style={{ width: '356px' }}>
        <CustomFields fluid config={fields} errors={errors} value={processingActivity} onChange={onChange} />
      </div>
      <NextPrev workflow={steps} onNext={forward} onPrev={backward} validate={validate} />
    </ExpandAndCollapse>
  );
};
