import React from 'react';
import { Button, Field } from '@privacy-request/ui';
import { useTranslation } from 'react-i18next';
import { useIsAdminRoute } from '../../../../../hooks/useIsAdminRoute';
import { organizationalSecurityMeasuresField, technicalSecurityMeasuresField } from '../../../common/fields';
import Modal from '../../../../../components/OverlayProvider/Modal';
import { useRouting } from '../../../../../hooks/useRouting';
import { useSystemBulkActionsEdit } from '../../../hooks/useSystemBulkActionsEdit';
import { useSelectionContext } from '../../../../../hooks/useSelectionContext';
import { useSystem } from '../../../hooks/useSystemContext';

export const AddBulkSecurityMeasures = ({ disabled, onCancel }: { disabled?: boolean, onCancel?: any }) => {
  const {
    current: system,
  } = useSystem();
  const { selections } = useSelectionContext();
  const { onChangeBulkSecurityMeasure, onApplyBulkSecurityMeasure } = useSystemBulkActionsEdit();
  const { goBack } = useRouting();
  const [t] = useTranslation('systems');
  const isAdminRoute = useIsAdminRoute();

  return (
    <div>
      <Modal title={t('security_measures.title')} onCancel={onCancel ?? goBack} style={{ maxWidth: '640px' }}>
        <Field>
          {technicalSecurityMeasuresField(t, system, onChangeBulkSecurityMeasure, {
            add: !isAdminRoute,
            disabled,
            value: Object.keys(selections.technical_security_measures ?? {}).map((o) => (Number(o))),
          })}
        </Field>
        <Field>
          {organizationalSecurityMeasuresField(t, system, onChangeBulkSecurityMeasure, {
            add: !isAdminRoute,
            disabled,
            value: Object.keys(selections.organizational_security_measures ?? {}).map((o) => (Number(o))),
          })}
        </Field>
        <Field flex right style={{ paddingBottom: 0 }}>
          <Button padded style={{ display: 'flex', justifyContent: 'flex-end' }} onClick={onApplyBulkSecurityMeasure}>Apply Security Measures</Button>
        </Field>
      </Modal>
    </div>
  );
};
