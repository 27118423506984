import React, { useCallback, useMemo } from 'react';
import { BulkActionController } from './useBulkActionController';

export const BulkActionModalProvider = (controller: BulkActionController) => {
  const {
    enabledActions,
    selectAction,
    selectedAction,
  } = controller;
  const Component = useMemo(() => (
    selectedAction ? enabledActions[selectedAction]?.component : undefined
  ), [enabledActions, selectedAction]);

  const onCancel = useCallback(() => {
    selectAction(undefined);
  }, [selectAction]);

  const rendered = useMemo(() => {
    if (Component) {
      return (
        <Component
          onChange={() => {}}
          onCancel={onCancel}
          controller={controller}
        />
      );
    }
    return null;
  }, [Component, controller, onCancel]);

  return rendered;
};
