import {
  Field, Button, Text,
} from '@privacy-request/ui';

import find from 'lodash/find';
import indexOf from 'lodash/indexOf';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import { useRouting } from '../../hooks/useRouting';
import { useNavigationUrls } from '../../hooks/useNavigationUrls';
import { ACTIVITIES_BASE_PATH, SERVICES_BASE_PATH } from '../../constants';

interface NextPrevProps {
  disabled?: boolean
  workflow: any
  disclaimer?: string
  validate?: any
  onNext?: any
  nextText?: string
  onPrev?: any
  prevDisabled?: boolean
}

export const NextPrev = ({
  disabled,
  workflow,
  disclaimer,
  onNext: _onNext,
  nextText = 'common:form.next',
  onPrev: _onPrev,
  validate,
  prevDisabled,
}: NextPrevProps) => {
  const {
    activityUrl,
    assetUrl,
  } = useNavigationUrls();
  const {
    step, tab, id,
  } = useParams<any>();
  const [t] = useTranslation();
  const location = useLocation();
  const { replace } = useRouting();
  const idx = indexOf(workflow, find(workflow, { value: step || tab }));
  const onPrev: (() => void) | undefined = useMemo(() => {
    if (idx === 0) {
      return undefined;
    }
    return () => {
      if (_onPrev) {
        return _onPrev();
      }
      if (location.pathname.indexOf('systems/workflow') !== -1) {
        replace(assetUrl(`/workflow/${id}/${workflow[idx - 1].value}`))();
      } else if (location.pathname.indexOf('requests/workflow') !== -1) {
        replace(`/requests/workflow/${id}/${workflow[idx - 1].value}`)();
      } else if (location.pathname.startsWith(ACTIVITIES_BASE_PATH) || location.pathname.startsWith(SERVICES_BASE_PATH)) {
        replace(activityUrl(`/workflow/${workflow[idx - 1].value}`))();
      } else {
        replace(`/requests/assign/${id}/${workflow[idx - 1].value}`)();
      }
    };
  }, [_onPrev, location.pathname, replace, assetUrl, id, workflow, idx, activityUrl]);
  const onNext = useCallback(() => {
    if (_onNext) {
      return _onNext();
    }
    const proceed = () => {
      if (location.pathname.indexOf('systems/workflow') !== -1) {
        replace(assetUrl(`/workflow/${id}/${workflow[idx + 1].value}`))();
      } else if (location.pathname.indexOf('requests/workflow') !== -1) {
        replace(`/requests/workflow/${id}/${workflow[idx + 1].value}`)();
      } else if (location.pathname.indexOf('processing-activities') !== -1) {
        replace(activityUrl(`/workflow/${workflow[idx + 1].value}`))();
      } else {
        replace(`/requests/assign/${id}/${workflow[idx + 1].value}`)();
      }
    };
    if (validate) {
      const validates = validate();
      if (validates instanceof Promise) {
        validates.then((shouldProceed) => {
          if (shouldProceed) {
            proceed();
          }
        });
      } else if (validates) {
        proceed();
      }
    } else {
      proceed();
    }
  }, [_onNext, validate, location.pathname, replace, assetUrl, id, workflow, idx, activityUrl]);
  return (
    <Field
      fluid
      style={{
        paddingBottom: 0, paddingTop: '16px', display: 'flex', justifyContent: 'space-between', marginTop: disclaimer ? '36px' : 0,
      }}
    >
      {onPrev ? <Button onClick={onPrev} disabled={prevDisabled} secondary>{t('common:form.previous')}</Button> : <div />}
      <div style={{ position: 'relative' }}>
        {disclaimer && (
          <Text style={{
            position: 'absolute', fontSize: '12px', width: '512px', right: 0, top: '-36px', textAlign: 'right', fontWeight: 'bold',
          }}
          >
            {disclaimer}
          </Text>
        )}
        <Button padded onClick={onNext} disabled={disabled}>{t(nextText)}</Button>
      </div>
    </Field>
  );
};
