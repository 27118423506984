import { SET_DRAGGING } from './drag.types';

export interface SetDraggingAction {
  type: typeof SET_DRAGGING
  payload: boolean
}

export const setDragging = (payload: boolean): SetDraggingAction => ({ type: SET_DRAGGING, payload });

export type DragActionTypes = SetDraggingAction;
