import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export const useRouting = () => {
  const history = useHistory();

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const push = useCallback((url) => () => {
    history.push(url);
  }, [history]);

  const replace = useCallback((url) => () => {
    history.replace(url);
  }, [history]);

  const go = useCallback((n) => () => {
    history.go(n);
  }, [history]);

  return {
    goBack,
    replace,
    push,
    go,
  };
};
