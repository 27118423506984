import { useQuery } from '@apollo/react-hooks';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  GetDashboardStatsData, GetDashboardStats, GetDashboardChartsData, GetDashboardCharts, GetDashboardSystemStatsData, GetDashboardSystemStats,
} from '../../../apollo/dashboard/dashboard.queries';
import { useMe } from '../../../hooks/useMe';
import { useSmartPoll } from '../../../hooks/useSmartPoll';
import { useVisibilityChangedCallback } from '../../../hooks/useVisibilityChangedCallback';
import { setChart, setTime } from '../../../store/dashboard/dashboard.actions';
import { useSelector } from '../../../store/useSelector';
import { ChartTypes } from '../types/ChartTypes';
import { TimeSeries } from '../types/TimeSeries';
import { useChartTransform } from './useChartTransform';

export const useDashboard = () => {
  const [t] = useTranslation('dashboard');
  const dispatch = useDispatch();
  const time = useSelector(s => s.dashboard.time);
  const chart = useSelector(s => s.dashboard.chart);
  const [initializing, setInitializing] = useState(true);
  const { me } = useMe();
  const systems_disabled = me?.organization?.features?.systems_disabled;
  const requests_disabled = me?.organization?.features?.requests_disabled;
  const pollInterval = useSmartPoll();

  const {
    data, loading: statsLoading, refetch: statRefetch,
  } = useQuery<GetDashboardStatsData>(GetDashboardStats, {
    pollInterval,
    fetchPolicy: 'cache-and-network',
    variables: { time },
    skip: requests_disabled,
  });
  useVisibilityChangedCallback(statRefetch, { onlyCallWhenVisible: true });

  const {
    data: charts, loading: chartsLoading, refetch: chartRefetch,
  } = useQuery<GetDashboardChartsData, any>(GetDashboardCharts, {
    pollInterval,
    fetchPolicy: 'cache-and-network',
    variables: {
      time,
      chart,
    },
    skip: requests_disabled,
  });
  useVisibilityChangedCallback(chartRefetch, { onlyCallWhenVisible: true });

  const {
    data: systemsData, loading: systemsDataLoading, refetch: sysDataRefetch,
  } = useQuery<GetDashboardSystemStatsData, any>(GetDashboardSystemStats, {
    pollInterval,
    fetchPolicy: 'cache-and-network',
    skip: !requests_disabled && !systems_disabled,
  });
  useVisibilityChangedCallback(sysDataRefetch, { onlyCallWhenVisible: true });

  useEffect(() => {
    if (!statsLoading && !chartsLoading && !systemsDataLoading) {
      setInitializing(false);
    }
  }, [statsLoading, chartsLoading, systemsDataLoading]);

  const chartData = useChartTransform(charts, time, chart);

  const timeSeriesItems = useMemo(() => Object.values(TimeSeries).map(ts => ({
    text: t(`time.${ts}`),
    value: ts,
  })), [t]);

  const onChangeChart = useCallback((chart: ChartTypes) => {
    dispatch(setChart(chart));
  }, [dispatch]);

  const onChangeTimeSeries = useCallback((e) => {
    dispatch(setTime(e.target.value));
  }, [dispatch]);

  return {
    data,
    chartData,
    time,
    loading: chartsLoading || statsLoading,
    initializing,
    onChangeTimeSeries,
    onChangeChart,
    chart,
    timeSeriesItems,
    systemsData,
  };
};
