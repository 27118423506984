import {
  Button, Buttons, CheckBoxIcon, CheckBoxTickedIcon, Dropdown, Field, Input, Label, List, ListHead, ListRow, ListText, ListTitle, Pagination, SelectionItem, SubTitle,
} from '@privacy-request/ui';
import update from 'immutability-helper';
import { startCase } from 'lodash';
import React, {
  useCallback, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { PRFile } from '../../../types/file/PRFile';
import { ListTextCheckBox } from '../../EntityContactsEdit/EntityContactsEdit';
import Modal from '../../OverlayProvider/Modal';
import {
  EditModification,
  FileModification,
  RequestTaskFileViewer,
} from '@privacy-request/basic-types';
import Scrollbar from 'react-scrollbars-custom';

interface FileViewerJSONRowProps {
  objectKey: string;
  value: any;
  modifications: FileModification[];
  requestType?: string;
  disabled?: boolean;
  depth?: number;
  onCheck: (objectKey: string) => any;
  onChange: (e: any) => void;
}

const getDisplay = (objectKey: string) => (
  objectKey.split('.').slice(1).map(startCase).join(' > ')
);

export const FileViewerJSONRow = React.memo(({
  objectKey,
  value,
  onCheck,
  onChange,
  requestType,
  depth = 0,
  disabled,
  modifications,
}: FileViewerJSONRowProps) => {
  const mod = useMemo(() => modifications.find((m) => m.type === 'edit' && m.field === objectKey) as EditModification, [modifications, objectKey]);
  const checked = useMemo(() => modifications.findIndex((m) => m.type === 'delete' && m.field === objectKey) !== -1, [modifications, objectKey]);

  if (typeof value === 'object' && !(value instanceof Array)) {
    return (
      <>
        {Object.entries(value).map(([key, value]) => (
          <FileViewerJSONRow
            key={key}
            objectKey={`${objectKey}.${key}`}
            value={value}
            requestType={requestType}
            disabled={disabled}
            depth={depth + 1}
            onCheck={onCheck}
            onChange={onChange}
            modifications={modifications}
          />
        ))}
      </>
    );
  }

  if (Array.isArray(value)) {
    return (
      <>
        {value.map((v, i) => (
          <>
            <div style={{ height: '12px' }} />
            <SubTitle style={{
              paddingLeft: `${depth * 2}px`, fontSize: '16px', margin: '4px 0 4px 30px',
            }}
            >
              {getDisplay(objectKey)}
              {' '}
              #
              {i + 1}
            </SubTitle>
            <FileViewerJSONRow
              key={i}
              objectKey={`${objectKey}.${i}`}
              value={v}
              requestType={requestType}
              depth={depth + 1}
              disabled={disabled}
              onCheck={onCheck}
              onChange={onChange}
              modifications={modifications}
            />
            <div style={{ height: '12px' }} />
          </>
        ))}
      </>
    );
  }

  let displayValue = value;
  if (typeof mod?.updated_value !== 'undefined') {
    displayValue = mod.updated_value;
  }

  return (
    <ListRow secondary style={{ height: '32px', marginTop: '2px' }}>
      <ListText><span style={{ paddingLeft: `${depth * 6}px`, opacity: checked ? 0.4 : 1 }}>{getDisplay(objectKey)}</span></ListText>
      <ListText grow={2}>
        <Input
          style={{
            width: '100%', height: '28px', fontSize: '14px', opacity: checked ? 0.6 : 1,
          }}
          value={displayValue}
          disabled={disabled || !!checked}
          onChange={onChange}
          name={objectKey}
        />
      </ListText>
      {!disabled && (
        <ListTextCheckBox basis="24px" checked={checked} style={{ paddingRight: 0 }} onClick={disabled ? () => {} : () => onCheck(objectKey)}>
          {checked ? (
            <CheckBoxIcon />
          ) : (
            <CheckBoxTickedIcon />
          )}
        </ListTextCheckBox>
      )}
    </ListRow>
  );
});

interface FileViewerProps {
  onCancel: any;
  file: PRFile | File;
  requestType?: string;
  content: RequestTaskFileViewer[];
  onSave: (fileMods: FileModification[]) => void;
}

export const FileViewer = ({
  onCancel,
  file,
  requestType,
  content,
  onSave,
}: FileViewerProps) => {
  const [t] = useTranslation('common');
  const [modifications, setModifications] = useState<FileModification[]>((file as PRFile).modifications || []);
  const [contentIndex, setContentIndex] = useState(0);
  const [recordIndex, setRecordIndex] = useState(0);

  const items = useMemo(() => {
    if (content.length) {
      return Object.entries<any>(content[contentIndex].items[recordIndex].datum).filter(([, v]) => !!v);
    }

    return [];
  }, [content, contentIndex, recordIndex]);

  const ddItems = useMemo((): SelectionItem[] => content.map((v, i) => ({
    text: `${v.type} (${v.items.length})`,
    value: i + 1,
  })), [content]);

  const originalIndex = useMemo(() => (content.length ? content[contentIndex].items[recordIndex].originalIndex : -1), [content, contentIndex, recordIndex]);

  const onCheck = useCallback((objectKey: string) => {
    setModifications((mods) => {
      const modIndex = mods.findIndex(m => m.type === 'delete' && m.field === objectKey);
      if (modIndex > -1) {
        return update(mods, { $splice: [[modIndex, 1]] });
      }
      return update(mods, {
        $push: [{
          type: 'delete',
          field: objectKey,
        }],
      });
    });
  }, []);

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setModifications((mods) => {
      const index = mods.findIndex(m => m.type === 'edit' && m.field === name);
      if (index > -1) {
        return update(mods, { [index]: { updated_value: { $set: value } } });
      }
      return update(mods, {
        $push: [{
          type: 'edit',
          updated_value: value,
          field: name,
        }],
      });
    });
  }, []);

  const _onSave = useCallback(() => {
    onSave(modifications);
  }, [onSave, modifications]);

  if (file instanceof File) {
    return <span />;
  }

  return (
    <Modal style={{ maxWidth: '740px', zIndex: '10000' }} title={t('file_viewer')} onCancel={onCancel}>
      {ddItems.length > 1 && (
        <Field>
          <Label>Object:</Label>
          <Dropdown
            items={ddItems}
            value={contentIndex + 1}
            onChange={(e) => { setContentIndex(e.target.value - 1); setRecordIndex(0); }}
          />
        </Field>
      )}
      {content[contentIndex].items.length > 1 && (
        <Field>
          <Label>Record Number:</Label>
          <Pagination
            style={{ justifyContent: 'flex-start' }}
            pageNumber={recordIndex + 1}
            onChange={(p) => setRecordIndex(p - 1)}
            totalEntries={1}
            totalPages={content[contentIndex].items.length}
          />
        </Field>
      )}
      <Field>
        <List>
          <ListHead>
            <ListTitle>{t('form.field')}</ListTitle>
            <ListTitle grow={2}>{t('form.value')}</ListTitle>
            <ListTitle basis="24px" style={{ paddingRight: 0 }} />
          </ListHead>
          <Scrollbar
            style={{ width: '100%', height: '468px' }}
            removeTracksWhenNotUsed
            minimalThumbSize={30}
            scrollDetectionThreshold={100}
          >
            {items.map(([key, value]) => (
              <FileViewerJSONRow
                key={key}
                objectKey={`${originalIndex}.${key}`}
                value={value}
                requestType={requestType}
                onCheck={onCheck}
                onChange={onChange}
                modifications={modifications}
              />
            ))}
          </Scrollbar>
        </List>
      </Field>
      <Field style={{ paddingBottom: 0 }} flex right>
        <Buttons>
          <Button secondary onClick={onCancel}>{t('form.cancel')}</Button>
          <Button onClick={_onSave}>{t('form.save')}</Button>
        </Buttons>
      </Field>
    </Modal>
  );
};
