import gql from 'graphql-tag';
import { Suggestion } from '../../../components/CheckOver/CheckOver';
import { ProcessingActivity } from '../../../types/system/ProcessingActivity';
import { AuditLogFragment } from '../../audit_log/audit_log.fragments';
import { ProcessingActivityFragments } from './processing_activities.fragments';

export interface ProcessingActivitiesPaged {
  count: number
  rows: ProcessingActivity[]
}

export interface GetProcessingActivitiesPagedData {
  processing_activities_paged: ProcessingActivitiesPaged
}

export const GetProcessingActivitiesPaged = gql`
  query processing_activities_paged($limit: Int!, $offset: Int!, $filter: String!, $fields: String!, $relations: String!, $sort: String!) {
    processing_activities_paged(limit: $limit, offset: $offset, filter: $filter, fields: $fields, relations: $relations, sort: $sort)
      @rest(type: "ProcessingActivitiesPaged", path: "processing-activities?{args}") {
        rows @type(name: "ProcessingActivityPageItem") {
          ...processing_activity_page
        }
        count
      }
  }

  ${ProcessingActivityFragments}
`;

export interface GetProcessingActivityData {
  get_processing_activity: ProcessingActivity
}

export interface GetProcessingActivityVariables {
  id: number
}

export const GetProcessingActivity = gql`
  query get_processing_activity($id: Int!) {
    get_processing_activity(id: $id) @rest(type: "ProcessingActivity" path: "processing-activities/{args.id}") {
      ...processing_activity
    }
  }

  ${ProcessingActivityFragments}
`;

export interface GetProcessingActivityHistoryPagedVariables {
  id: number
}

export const GetProcessingActivityHistoryPaged = gql`
  query get_processing_activity_history_paged($limit: Int!, $offset: Int!, $id: Int!) {
    get_processing_activity_history_paged(id: $id, limit: $limit, offset: $offset)
      @rest(type: "ProcessingActivityHistory", path: "processing-activities/{args.id}/history?{args}") {
        rows @type(name: "AuditLog") {
          ...audit_log
        }
        count
      }
  }
  ${AuditLogFragment}
`;

export interface GetSuggestedPersonalDataCategoriesData {
  get_suggested_personal_data_categories: Suggestion[]
}

export const GetSuggestedPersonalDataCategories = gql`
  query get_suggested_personal_data_categories($id: Int!) {
    get_suggested_personal_data_categories(id: $id) @rest(
      type: "SuggestedPersonalDataCategory" path: "processing-activities/{args.id}/suggested-personal-data-categories"
    ) {
      name
      count
    }
  }
`;
