import React from 'react';

export const RequestsIcon = (props: any) => (
  <svg {...props} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
      <g id="requests-icon" transform="translate(3.000000, 5.000000)" stroke="#FFFFFF" strokeWidth="1.2">
        <line x1="1.30051788" y1="0.6" x2="19.6994821" y2="0.6" id="Line" />
        <line x1="1.30051788" y1="4.6" x2="19.6994821" y2="4.6" id="Line-Copy" />
        <line x1="1.30051788" y1="8.6" x2="19.6994821" y2="8.6" id="Line-Copy-2" />
        <line x1="1.30051788" y1="12.6" x2="10.545883" y2="12.6" id="Line-Copy-3" />
        <line x1="1.30051788" y1="16.6" x2="10.545883" y2="16.6" id="Line-Copy-4" />
        <line x1="1.30051788" y1="20.6" x2="10.545883" y2="20.6" id="Line-Copy-5" />
        <g id="Group-14" transform="translate(18.000000, 14.000000)">
          <line x1="1.8" y1="4.2" x2="5.4" y2="4.2" id="Line-Copy-7" />
          <line x1="1.8" y1="4.2" x2="1.8" y2="0.6" id="Line-Copy-8" />
        </g>
        <circle id="Oval-2" cx="20" cy="18" r="6" />
      </g>
    </g>
  </svg>
);

RequestsIcon.defaultProps = {
  width: '32px',
  height: '32px',
};
