import { useMutation } from '@apollo/react-hooks';
import update from 'immutability-helper';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { GetRequest } from '../../../apollo/request/requests.queries';
import { SaveTask, SaveTaskWithVendorToken } from '../../../apollo/task/task.mutations';
import { Task } from '../../../types/request/Task';

export const useEditTask = (id: number, requestId: number, task: Partial<Task>) => {
  const [edits, setEdits] = useState<Partial<Task>>({});
  const [t] = useTranslation('requests');
  const { token } = useParams<any>();
  const { addToast } = useToasts();

  const [save, { loading: saving }] = useMutation<any, any>(token ? SaveTaskWithVendorToken : SaveTask);

  const onChange = useCallback((e: any) => {
    const { name, value } = e.target;

    setEdits(e => update(e, { [name]: { $set: value } }));
  }, []);

  const onSave = useCallback(async (additionalEdits?: Partial<Task>) => {
    const changes = {
      ...edits,
      ...additionalEdits,
    };

    try {
      await save({
        variables: {
          task: changes,
          token,
          id,
          request_id: requestId,
        },
        ...(requestId ? {
          refetchQueries: [{ query: GetRequest, variables: { id: requestId } }],
        } : {}),
      });
      addToast(t('edit.system_updated'), {
        autoDismiss: true,
        appearance: 'success',
      });
      setEdits({});
    } catch (err: any) {
      addToast(err.message, {
        autoDismiss: true,
        appearance: 'error',
      });
    }
  }, [edits, id, t, addToast, save, requestId, token]);

  const onFileChange = useCallback((e: any) => {
    const { name, value } = e.target;
    const changes: Partial<Task> = { [name]: value };
    onSave(changes);
  }, [onSave]);

  return {
    onChange,
    onFileChange,
    onSave,
    task: edits,
    saving,
  };
};
