import gql from 'graphql-tag';
import { BasicResult, AuthenticationStrategies } from '../../types/common/Common';

export interface GetAuthenticatedResult {
  result: BasicResult
}

export interface GetAuthenticatedVariables {

}

export interface GetAuthenticationStrategiesResult {
  result: AuthenticationStrategies
}

export interface GetAuthenticationStrategiesVariables {
  domain: string
}

export const GetAuthenticated = gql`
  query status {
    result @rest(type: "[BasicResult]", path: "authentication/status") {
      error
      payload
    }
  }
`;

export const GetAuthenticationStrategies = gql`
  query strategies($domain: String) {
    result(domain: $domain) @rest(type: "AuthenticationStrategies", path: "authentication/strategies/{args.domain}") {
      local
      azure
      strategies
    }
  }
`;

export const GetPasswordResetTokenStatus = gql`
  query reset_token_status($email: String!, $token: String!) {
    reset_token_status(email: $email, token: $token) @rest(type: "ResetTokenStatus", path: "authentication/token-status?{args}") {
      message
      error
    }
  }
`;
