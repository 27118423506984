/**
 * For non-interactive elements which are given an `onClick` handler, this
 * applies that handler to the onKeyUp handler as well.
 * @param fn The function to use for onClick and onKeyUp
 * @returns An object to be spread into the element
 */
export const clickable = (fn: any) => ({
  onClick: fn,
  onKeyUp: fn,
});
