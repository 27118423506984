import {
  ListText,
  List,
  ListHead,
  ListTitle,
  ListRow,
  Button,
  TrashIcon,
  HelpIcon,
  Tooltip,
} from '@privacy-request/ui';
import update from 'immutability-helper';
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  useLocation, Route, useRouteMatch, useParams,
} from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';
import { useMe } from '../../hooks/useMe';
import { useRouting } from '../../hooks/useRouting';
import { EntityContactsCreateModal } from './EntityContactsCreateModal';
import { NoEntitiesFoundDisplay } from '../NoEntitiesDisplay/NoEntitiesFoundDisplay';
import { User } from '../../types';
import { appendIf } from '../../utils/appendIf';
import evt from '../../utils/evt';
import { formatPhoneNumber } from '../../utils/formatPhone';
import { validateEmail } from '../../utils/validateEmail';

const styles: { [key: string]: React.CSSProperties } = {
  list: { marginTop: '1rem' },
  listText: {
    cursor: 'pointer',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingRight: 0,
  },
  listTextDisabled: { opacity: 0.5 },
  listTitle: { paddingRight: 0 },
};

export const ListTextCheckBox = styled(ListText)<{ checked: boolean }>`
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;

  & path {
    fill: ${({ theme, checked }) => (checked ? theme.DARKISH_GREEN : theme.CTA_COLOR)};
  }

  &:focus path, :hover path {
    fill :${({ theme }) => theme.CTA_COLOR};
  }
`;
ListTextCheckBox.displayName = 'ListTextCheckBox';

export interface EntityContactsEditProps {
  disabled?: boolean
  contacts: any[] | undefined
  original_contacts: any[] | undefined
  onChange: any
  name: string
  canEdit?: boolean
  contactKey: 'system_id' | 'processing_activity_id'
}

export const EntityContactsEdit = ({
  disabled, original_contacts, contacts, onChange, canEdit, name, contactKey: key,
}: EntityContactsEditProps) => {
  const { push } = useRouting();
  const { id } = useParams<any>();
  const location = useLocation();
  const match = useRouteMatch();
  const [t] = useTranslation('systems');
  const [createUser, setCreateUser] = useState('');
  const { addToast } = useToasts();

  const onDeleteContact = React.useCallback((e: any, index: number) => {
    e.stopPropagation();

    const newContacts = update(contacts, { $splice: [[index, 1]] });

    onChange(evt(name, newContacts));
  }, [contacts, onChange, name]);

  const onUserCreated = React.useCallback(async (user: Partial<User>) => {
    const next = update<any>(contacts || [], {
      $push: [{
        [key]: Number(id),
        user_id: user.id,
        create_user: user,
        user,
        type: 'Owner',
      }],
    });
    onChange(evt(name, next));
  }, [onChange, name, contacts, id, key]);

  const onCreateUser = React.useCallback((term: string) => {
    if (validateEmail(term)) {
      setCreateUser(term);
    } else {
      addToast(t('pa.workflow.email_format_matcher'), {
        autoDismiss: true,
        appearance: 'error',
      });
    }
  }, [setCreateUser, addToast, t]);

  const { me } = useMe();

  const noop = React.useCallback((e: any) => e.stopPropagation(), []);

  const isOneOwner = contacts?.length === 1;
  const isUserOwner = original_contacts?.filter((c: any) => (c.user_id === me?.id)).length !== 0
      || me?.role?.code === 'admin'
      || me?.god_mode === true;

  return (
    <div>
      <List style={styles.list}>
        <ListHead>
          <ListTitle>{t('contacts.name')}</ListTitle>
          <ListTitle>{t('contacts.contact_type')}</ListTitle>
          <ListTitle grow={2}>{t('contacts.email')}</ListTitle>
          <ListTitle>{t('contacts.phone')}</ListTitle>
          <ListTitle>{t('contacts.reviewer')}</ListTitle>
          <ListTitle style={styles.listTitle} basis="26px" />
        </ListHead>
        {contacts?.map(({
          id, user, type, is_reviewer, ...rest
        }, i) => {
          const first_name = user?.first_name || rest.first_name || '';
          const last_name = user?.last_name || rest.last_name || '';
          const phone = (user?.phone && formatPhoneNumber(user?.phone)) || (rest?.phone && formatPhoneNumber(rest?.phone)) || '';
          const email = user?.email || rest.email || '';

          const disableOwnerButtons = !isUserOwner || (isOneOwner) || disabled;

          let onClick;
          if (canEdit && isUserOwner && !rest.create_user) {
            onClick = push(`${location.pathname}/contacts/${i}`);
          } else if (rest.create_user) {
            onClick = () => {
              addToast(t('tooltips.save_asset_first_fault'), {
                appearance: 'warning',
                autoDismiss: true,
              });
            };
          }

          return (
            <ListRow secondary key={`${id}-${i}-${rest.email}-${rest.phone}`} onClick={onClick} plain>
              <ListText>
                {React.createElement(rest.create_user ? Tooltip : 'div', { content: t(`tooltips.save_asset_first${appendIf(key === 'processing_activity_id', '_pa')}`) }, (
                  <span>
                    {`${first_name} ${last_name}`}
                    {rest.create_user && <span style={{ marginLeft: '4px' }}><HelpIcon width={18} height={18} color="rgba(0,0,0,0.4)" /></span>}
                  </span>
                ))}
              </ListText>
              <ListText>{type}</ListText>
              <ListText grow={2}>{email}</ListText>
              <ListText>{phone}</ListText>
              <ListText>{t(`common:${(!!user && is_reviewer) ? 'yes' : 'no'}`)}</ListText>
              <ListText basis="26px" style={{ ...styles.listText, ...(disableOwnerButtons && styles.listTextDisabled) }}>
                <TrashIcon width="26" height="26" onClick={disableOwnerButtons ? noop : (e: any) => onDeleteContact(e, i)} />
              </ListText>
            </ListRow>
          );
        })}
        {!!(contacts?.length === 0) && (
          <NoEntitiesFoundDisplay
            to={`${match.url}/addContact`}
            secondary
            noEntities
            noEntitiesKey="systems:table.no_contacts"
          />
        )}
        {(!disabled && isUserOwner) && (
          <Button
            style={{
              marginTop: '1rem', marginBottom: '2rem', float: 'right',
            }}
            padded
            onClick={push(`${location.pathname}/addContact`)}
          >
            {t('contacts.add_contact')}
          </Button>
        )}
      </List>
      <Route
        path={`${match.path}/addContact`}
        render={() => (
          <EntityContactsCreateModal
            contactKey={key}
            contacts={contacts}
            onCreate={onCreateUser}
            onChange={onChange}
            name={name}
            createUser={createUser}
            setCreateUser={setCreateUser}
            onUserCreated={onUserCreated}
          />
        )}
      />
      <Route
        path={`${match.path}/contacts/:index`}
        render={() => (
          <EntityContactsCreateModal
            contactKey={key}
            contacts={contacts}
            onChange={onChange}
            name={name}
            createUser={createUser}
            setCreateUser={setCreateUser}
            onUserCreated={onUserCreated}
          />
        )}
      />
      {/* <Route
        path={`${match.path}/createUser`}
        render={() => (
          <UserEdit
            createFromDataMapping
            onSaveOverride={onUserCreated}
            term={createTerm}
            emailsUsed={contacts?.map(c => c.user?.email || c.email)}
          />
        )}
      /> */}
    </div>
  );
};
