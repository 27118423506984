import React from 'react';
import { SVGComponent } from './SvgProps';

export const PlusIcon: SVGComponent = (props) => (
  <svg {...props} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="admin-desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="Rectangle-9-Copy" fill="#D8D8D8" opacity="0" x="0" y="0" width="24" height="24" />
      <polygon id="+" fill={props.color} points="13 11 23 11 23 13 13 13 13 23 11 23 11 13 1 13 1 11 11 11 11 1 13 1" />
    </g>
  </svg>
);

PlusIcon.defaultProps = {
  width: 24,
  height: 24,
  color: '#FFFFFF',
};
