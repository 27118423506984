import { Button, Field } from '@privacy-request/ui';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/OverlayProvider/Modal';
import { useProcessingActivityBulkActionsEdit } from '../../hooks/useProcessingActivityBulkActionsEdit';
import { CustomFieldConfig, CustomFields } from '../../../../components/CustomFields/CustomFields';
import { purposesOfProcessingCustomField } from '../../../SystemsView/common/fields';
import { useSelectionContext } from '../../../../hooks/useSelectionContext';

interface ProcessingActivityPurposeProcessingModalProps {
  onCancel(): void,
  items: any,
  type: string,
}

export const ProcessingActivityPurposeProcessingModal = ({
  onCancel,
  items,
  type,
}: ProcessingActivityPurposeProcessingModalProps) => {
  const [t] = useTranslation('systems');
  const { selections, replace } = useSelectionContext();
  const { onAddPurposeOfProcessing } = useProcessingActivityBulkActionsEdit();

  const onClick = useCallback(async (e) => {
    await onAddPurposeOfProcessing(e);
    onCancel?.();
  }, [onAddPurposeOfProcessing, onCancel]);

  const onChange = useCallback((e) => {
    const { name, value } = e.target;
    replace(name, ...(Array.from(value as any) as any));
  }, [replace]);

  const fieldConfig = useMemo((): CustomFieldConfig => ([
    purposesOfProcessingCustomField(t, selections, onChange, {
      add: false,
      value: Object.keys(selections.purposes_of_processing ?? {}).map((o) => (Number(o))),
    }),
  ]), [selections, onChange, t]);

  return (
    <Modal title={t('purposes_of_processing.pa_title')} style={{ maxWidth: '740px' }} onCancel={onCancel}>
      <CustomFields config={fieldConfig} value={{}} onChange={onChange} />
      <Field right>
        <Button style={{ float: 'right', minWidth: '200px' }} onClick={onClick}>{t('purposes_of_processing.add')}</Button>
      </Field>
    </Modal>
  );
};
