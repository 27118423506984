import {
  ErrorMessage,
  Label,
  LoadingIndicator,
  LoadingProvider,
} from '@privacy-request/ui';
import React, { useMemo } from 'react';
import { GroupedTagItemMap, useTagItems } from '../../hooks/useTagItems';
import { toArray } from '../../utils/array';
import { TagItemCheckOver } from '../TagItemCheckOver/TagItemCheckOver';
import { TagItemDropdown } from '../TagItemDropdown/TagItemDropdown';
import { TagItemSelect } from '../TagItemSelect/TagItemSelect';
import { useIsAdminRoute } from '../../hooks/useIsAdminRoute';

export type TagItemCustomFieldKnownProps = {
  name: string
  label?: string
  component: typeof TagItemDropdown | typeof TagItemSelect | typeof TagItemCheckOver
  entity: any
  type: keyof GroupedTagItemMap
  onChange: (ev: any) => void
  errors?: any
  global?: boolean
};
export type TagItemCustomFieldProps = TagItemCustomFieldKnownProps & Parameters<TagItemCustomFieldKnownProps['component']>[0];

export const TagItemCustomField = ({
  name,
  label,
  component: Component,
  entity,
  onChange,
  errors,
  value: _value,
  global,
  ...rest
}: TagItemCustomFieldProps) => {
  const isAdminRoute = useIsAdminRoute();
  const value = useMemo(() => (
    _value || toArray(entity[name]).map((o) => (o.id || o))
  ), [entity, name, _value]);
  const { loading } = useTagItems({
    custom: !isAdminRoute,
    global: true,
    value,
  });

  return (
    <LoadingProvider>
      {loading && (<LoadingIndicator />)}
      <Label>{label}</Label>
      {errors?.[name] && (
        <ErrorMessage style={{ right: 0 }}>{errors?.[name]}</ErrorMessage>
      )}
      <Component
        name={name}
        onChange={onChange}
        value={value}
        global={global || isAdminRoute}
        {...rest}
      />
    </LoadingProvider>
  );
};
