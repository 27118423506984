import styled from 'styled-components';
import { DivTableRow } from './DivTableRow';

export const DivTable = styled.div`
  display: table;

  ${DivTableRow}:nth-child(even) {
    background: #f8f6ff;
  }
`;

DivTable.displayName = 'DivTable';
