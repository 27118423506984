import clsx from 'clsx';
import React from 'react';

import './table.scss';

export type TableProps = React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableElement>, HTMLTableElement> & {
  striped?: boolean
  fixed?: boolean
};

export const Table = ({
  className,
  striped,
  fixed,
  ...props
}: TableProps) => (
  <table
    className={clsx('table', className, {
      striped,
      fixed,
    })}
    {...props}
  />
);
