import { useQuery } from '@apollo/react-hooks';
import {
  ListRow, Title, ListText, LoadingProvider, LoadingIndicator, List, ListHead, ListTitle,
} from '@privacy-request/ui';
import { DocumentNode } from 'graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataMapSummary } from '../../apollo/dashboard/dashboard.queries';
import { useSmartPoll } from '../../hooks/useSmartPoll';
import { useVisibilityChangedCallback } from '../../hooks/useVisibilityChangedCallback';

interface DashboardDataMapStatusSummaryProps {
  query: DocumentNode
  title: string
  hideNew?: boolean
}

export const DashboardDataMapStatusSummary = ({
  title,
  query,
  hideNew,
}: DashboardDataMapStatusSummaryProps) => {
  const [t] = useTranslation('systems');
  const {
    data, loading, refetch,
  } = useQuery(query, {
    fetchPolicy: 'cache-and-network',
    pollInterval: useSmartPoll(),
  });
  useVisibilityChangedCallback(refetch, { onlyCallWhenVisible: true });
  const result = ((data && data[Object.keys(data)[0]]) || {}) as DataMapSummary;
  return (
    <>
      <Title style={{ marginTop: 0 }}>{title}</Title>
      <LoadingProvider>
        {(loading && !data) && <LoadingIndicator />}
        <List>
          <ListHead>
            <ListTitle>{t('table.status')}</ListTitle>
            <ListTitle right style={{ paddingRight: 0, textAlign: 'right' }}>{t('table.count')}</ListTitle>
          </ListHead>
          {[!hideNew && 'new', 'waiting-review', 'waiting-approval', 'active'].filter(f => f).map((status) => {
            if (typeof status === 'boolean') {
              return <span />;
            }
            return (
              <ListRow secondary key={status || 'frig off typescript'}>
                <ListText>{t(`dashboard:status.${status}`)}</ListText>
                <ListText style={{ paddingRight: '6px', textAlign: 'right' }}>{result[status.replace('-', '_') as keyof DataMapSummary] || 0}</ListText>
              </ListRow>
            );
          })}
        </List>
      </LoadingProvider>
    </>
  );
};
