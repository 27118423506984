import { useCallback } from 'react';
import { CachePolicies } from 'use-http';
import { useFetch } from '../../hooks/useFetch';
import { backendUrl } from '../../utils/url';
import { useToasts } from 'react-toast-notifications';

export const useTaskRetry = (requestId: number, taskId: number, done: () => any = () => {}) => {
  const { post: postJob } = useFetch(backendUrl(`requests/${requestId}/tasks/${taskId}/job`), {
    cachePolicy: CachePolicies.NETWORK_ONLY,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const { addToast } = useToasts();

  const triggerRetry = useCallback(async (version?: number) => {
    try {
      await postJob({
        version,
      });
      addToast('We will retry the task shortly.', {
        appearance: 'success',
        autoDismiss: true,
      });
      done();
    } catch (err) {
      addToast('Failed to retry the task.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast, done, postJob]);

  return {
    triggerRetry,
  };
};
