import gql from 'graphql-tag';
import { TagItem } from '../../types/tagitem/TagItem';
import { TagItemFragment } from './tagitems.fragments';

//
// Get all tagitems (probably preferred)
//
export interface GetTagItemsData {
  get_tagitems: {
    rows: TagItem[]
    count: number
  }
}

export const GetTagItems = gql`
  query get_tagitems($limit: Int!, $offset: Int!, $filter: String) {
    get_tagitems(limit: $limit, offset: $offset, filter: $filter)
      @rest(type: "TagItemPaged", path: "tagitems?{args}") {
        rows @type(name: "TagItem") {
          ...tagitem
        }
        count
      }
  }
  ${TagItemFragment}
`;
