import { func } from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import Spinner from 'react-spinkit';
import defaultProps from 'recompose/defaultProps';
import getContext from 'recompose/getContext';
import lifecycle from 'recompose/lifecycle';
import { compose } from 'redux';

interface LoadingIndicatorProps {
  name?: 'three-bounce' | 'double-bounce' | 'rotating-plane' | 'folding-cube' | 'wave' | 'wandering-cubes' | 'pulse' | 'chasing-dots' | 'circle' | 'cube-grid' | 'wordpress' |
  'ball-grid-beat' | 'ball-grid-pulse' | 'line-spin-fade-loader' | 'ball-spin-fade-loader' | 'ball-pulse-rise' | 'line-scale' | 'line-scale-pulse-out' |
  'line-scale-pulse-out-rapid' | 'line-scale-party' | 'ball-triangle-path' | 'ball-scale-ripple-multiple' | 'ball-pulse-sync' | 'ball-beat' |
  'ball-scale-multiple' | 'ball-zig-zag' | 'ball-zig-zag-deflect' | 'ball-clip-rotate' | 'ball-clip-rotate-pulse' | 'ball-clip-rotate-multiple' |
  'ball-scale-ripple' | 'triangle-skew-spin' | 'pacman'
  color?: string
  getLoadingProvider?: any
  none?: boolean
}

export const LoadingIndicator = compose(
  defaultProps({
    color: '#1F6187',
    name: 'cube-grid', // http://kyleamathews.github.io/react-spinkit/
  }),
  getContext({
    getLoadingProvider: func.isRequired,
    activate: func.isRequired,
    deactivate: func.isRequired,
  }),
  lifecycle({
    componentDidMount() {
      // @ts-ignore
      this.props.activate();
    },
    componentWillUnmount() {
      // @ts-ignore
      this.props.deactivate();
    },
  }),
)(({
  name, color, getLoadingProvider, none,
}: LoadingIndicatorProps) => {
  const element = getLoadingProvider();

  if (!element || none) {
    return null;
  }

  return ReactDOM.createPortal(
    <Spinner color={color} name={name} fadeIn="quarter" />,
    element,
  );
}) as React.FC<LoadingIndicatorProps>;
