import {
  Field,
  List,
  ListHead,
  ListRow,
  ListText,
  ListTitle,
} from '@privacy-request/ui';
import React, {
  useContext,
  useLayoutEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AggregateAuditLog, AuditLog } from '../../types/audit/AuditLog';
import { DateTime } from '../DateTime/DateTime';
import { HotkeyContext } from '../HotkeyProvider/HotkeyContext';

const defaultUsernameTransformer = (row: AggregateAuditLog, t: any) => {
  if (row.source_type === 'SYSTEM' || row.source_type === 'CRON') {
    return t('common:system_user');
  }

  return row?.user?.email;
};

const sanitize = (row: AuditLog) => {
  if (!row.previous_values) {
    row.previous_values = {};
  }
  if (!row.updated_values) {
    row.updated_values = {};
  }
  return row;
};

const transformMessage = (row: AuditLog, transformers: any[], t: any, isISO?: boolean) => {
  const Transformer = transformers.find((t) => (t.evaluate(row)));
  if (Transformer) {
    return (<Transformer log={sanitize(row)} isISO={isISO} />);
  }
  return null;
};

const ListRowHack = styled(ListRow)`
  background-color: ${({ theme }) => theme.BG_GREY};
  height: unset;
  padding: 8px 20px;
  align-items: flex-start;
`;
ListRowHack.displayName = 'ListRowHack';

const ListTextHack = styled(ListText)`
  overflow: unset;
  white-space: unset;
  padding-right: 20px;
  text-overflow: unset;
`;
ListTextHack.displayName = 'ListTextHack';

interface AuditLogViewItemProps {
  row: AggregateAuditLog
  /**
   * Function that takes an audit log row and produces the name of the user who
   * performed the action.
   */
  usernameTransformer(row: AggregateAuditLog, t: any): any
  /**
   * Function that takes an audit log row and produces a message to display in
   * the table.
   */
  messageTransformers?: any
  /**
   * If set, will spit out a JSON representation of each audit record.
   */
  debug?: boolean
  isISO?: boolean
  t: any
}

const AuditLogViewItem = ({
  row, usernameTransformer, messageTransformers, t, isISO, debug,
}: AuditLogViewItemProps) => {
  const [vis, setVis] = useState(true);
  const contentRef = useRef<HTMLDivElement>(document.createElement('div'));
  const { state } = useContext(HotkeyContext);

  useLayoutEffect(() => {
    if (!contentRef.current?.innerText.length) {
      setVis(false);
    }
  }, []);

  if (!vis) {
    return null;
  }

  return (
    <ListRowHack>
      <ListText basis="200px" bold>
        <DateTime date={row.timestamp} format="datetime" />
      </ListText>
      <ListText>
        {usernameTransformer(row, t)}
      </ListText>
      <ListTextHack ref={contentRef} grow={3}>
        {row.logs.map((log) => (
          <div>
            {transformMessage(log, messageTransformers, t, isISO)}
          </div>
        ))}

        {state.debug && (
          <details>
            <summary>Debug Data</summary>
            <pre style={{ whiteSpace: 'pre-wrap' }}>{JSON.stringify(row, null, 2)}</pre>
          </details>
        )}
      </ListTextHack>
    </ListRowHack>
  );
};

export interface AuditLogViewProps {
  results?: AggregateAuditLog[]
  Pager?: any
  isISO?: boolean
  /**
   * Function that takes an audit log row and produces the name of the user who
   * performed the action.
   */
  usernameTransformer?(row: AggregateAuditLog, t: any): any
  /**
   * Function that takes an audit log row and produces a message to display in
   * the table.
   */
  messageTransformers?: any
  /**
   * If set, will spit out a JSON representation of each audit record.
   */
  debug?: boolean
}

export const AuditLogView = ({
  results,
  Pager = () => (<span />),
  usernameTransformer = defaultUsernameTransformer,
  messageTransformers = [],
  debug = false,
  isISO,
}: AuditLogViewProps) => {
  const [t] = useTranslation('common');

  return (
    <Field style={{ marginTop: '16px' }}>
      <List>
        <ListHead>
          <ListTitle basis="200px">{t('audit.table.date')}</ListTitle>
          <ListTitle>{t('audit.table.user')}</ListTitle>
          <ListTitle grow={3}>{t('audit.table.action')}</ListTitle>
        </ListHead>
        {
          results?.map((row) => (
            <AuditLogViewItem debug={debug} key={row.transaction_id} t={t} isISO={isISO} messageTransformers={messageTransformers} usernameTransformer={usernameTransformer} row={row} />
          ))
        }
      </List>
      <Pager />
    </Field>
  );
};
