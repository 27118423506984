import React from 'react';

export const FeedbackIcon = (props: any) => (
  <svg {...props} viewBox="0 0 66 66" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="noun_Announcement_1634592-(1)" transform="translate(1.000000, 0.000000)" fill="#FFFFFF" fillRule="nonzero">
        <g id="Group">
          <path d="M14.247619,65.1428571 C14.8523148,65.1428571 15.4305875,64.895026 15.847619,64.4571429 L21.7904762,58.5904762 L25.8285714,55.9238095 L31.9238095,62.0190476 C32.3293913,62.4731278 32.9152813,62.7242235 33.5238095,62.7047619 C34.1267107,62.6961744 34.701797,62.4497088 35.1238095,62.0190476 L46.8571429,50.2857143 C47.287804,49.8637018 47.5342696,49.2886155 47.5428571,48.6857143 C47.5460816,48.0580672 47.2993512,47.4549484 46.8571429,47.0095238 L44.0380952,44.1904762 L51.6571429,39.2380952 L52.8761905,40.5333333 C53.3483995,40.9228746 53.9402526,41.1380939 54.552381,41.1428571 C55.1393832,41.1269022 55.7035479,40.9119824 56.152381,40.5333333 C56.995427,39.6039725 56.995427,38.1865037 56.152381,37.2571429 L28.5714286,9.67619048 C27.6672592,8.84353102 26.2755979,8.84353102 25.3714286,9.67619048 C24.9292463,10.106495 24.6798077,10.6972872 24.6798077,11.3142857 C24.6798077,11.9312842 24.9292463,12.5220764 25.3714286,12.952381 L26.5904762,14.1714286 L7.23809524,44.0380952 L1.37142857,49.9809524 C0.933545474,50.3979839 0.685714286,50.9762567 0.685714286,51.5809524 C0.685714286,52.1856481 0.933545474,52.7639209 1.37142857,53.1809524 L12.647619,64.4571429 C13.0646506,64.895026 13.6429233,65.1428571 14.247619,65.1428571 Z M41.9809524,48.6857143 L33.5238095,57.1428571 L29.7904762,53.4095238 L40.0761905,46.7047619 L41.9809524,48.6857143 Z M48.3809524,35.9619048 L20.7238095,53.7904762 L12.0380952,45.1047619 L29.8666667,17.447619 L48.3809524,35.9619048 Z M9.06666667,48.6857143 L17.1428571,56.7619048 L14.247619,59.6571429 L6.17142857,51.5809524 L9.06666667,48.6857143 Z" id="Shape" />
          <path d="M45.4857143,20.8000312 C46.0886155,20.7914125 46.6637018,20.5449469 47.0857143,20.1142857 L55.6952381,11.5047619 C56.2804732,10.9195268 56.5090335,10.0665282 56.2948226,9.2670822 C56.0806117,8.46763623 55.4561733,7.84319786 54.6567273,7.62898696 C53.8572814,7.41477606 53.0042827,7.64333637 52.4190476,8.22857143 L43.8095238,16.8380952 C42.9664778,17.7674561 42.9664778,19.1849249 43.8095238,20.1142857 C44.2549484,20.5564941 44.8580672,20.8032245 45.4857143,20.8000312 Z" id="Path" />
          <path d="M39.2380952,15.0095238 C39.4720635,15.1327035 39.7366525,15.1856213 40,15.1619048 C40.9885895,15.1853982 41.8801801,14.5705081 42.2095238,13.6380952 L45.9428571,3.42857143 C46.2707138,2.28304706 45.6635954,1.07782628 44.547942,0.659456241 C43.4322886,0.241086204 42.1823967,0.749928135 41.6761905,1.82857143 L37.8666667,12.1142857 C37.6484597,12.6792181 37.6716993,13.3089533 37.9309525,13.8562656 C38.1902057,14.4035779 38.6627449,14.820487 39.2380952,15.0095238 Z" id="Path" />
          <path d="M60.2666667,17.752381 L49.9809524,21.4857143 C49.0063189,21.9421065 48.4634408,22.9996431 48.6606892,24.0576116 C48.8579375,25.1155802 49.7454314,25.9064372 50.8190476,25.9809524 L51.5809524,25.8285714 L61.7904762,22.0190476 C62.3658265,21.8300108 62.8383657,21.4131017 63.0976189,20.8657894 C63.3568721,20.3184771 63.3801118,19.6887419 63.1619048,19.1238095 C62.9728679,18.5484592 62.5559588,18.07592 62.0086465,17.8166668 C61.4613343,17.5574136 60.8315991,17.5341739 60.2666667,17.752381 Z" id="Path" />
        </g>
      </g>
    </g>
  </svg>
);

FeedbackIcon.defaultProps = {
  width: '24px',
  height: '24px',
};
