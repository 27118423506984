import { TFunction } from 'i18next';
import isEmpty from 'lodash/isEmpty';

export const handleErrors = (e: any, t: TFunction) => {
  const errors: any = {};
  if (e instanceof Error) {
    if (e.message.indexOf('500') !== -1) {
      errors.general = t('common:errors.500');
    }
  } else if (typeof e === 'object') {
    return e;
  }
  return isEmpty(errors) ? undefined : errors;
};
