import React from 'react';
import './App.css';

import {
  Switch, Route, Redirect,
} from 'react-router-dom';
import { OverlayProvider } from './components';
import { SUBDOMAIN } from './constants';
import { useLocalAuthInitialize } from './hooks/useLocalAuthInitialize';
import { DownloadView } from './views/DownloadView/DownloadView';
import LoginView from './views/LoginView';
import FindMyOrganizationView from './views/LoginView/FindMyOrganizationView';
import { MainView } from './views/MainView';
import { VendorView } from './views/VendorView/VendorView';
import { VerificationView } from './views/VerificationView/VerificationView';
import { HotkeyModal } from './components/HotkeyProvider/HotkeyModal';

function App() {
  useLocalAuthInitialize();

  return (
    <OverlayProvider>
      <Switch>
        { SUBDOMAIN && (<Route path="/vendor/task/:token" component={VendorView} />) }
        { SUBDOMAIN && (<Route path="/download" component={DownloadView} />) }
        { SUBDOMAIN && (<Route path="/verify" component={VerificationView} />) }
        { SUBDOMAIN && (<Route path="/login" component={LoginView} />) }
        <Route path="/" exact={!SUBDOMAIN} component={SUBDOMAIN ? MainView : FindMyOrganizationView} />
        { !SUBDOMAIN && (<Route path="/" render={() => (<Redirect to="/" />)} />) }
      </Switch>
      <HotkeyModal />
    </OverlayProvider>
  );
}

export default App;
