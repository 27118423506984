import React, { Suspense } from 'react';
import Spinner from 'react-spinkit';
import {
  Route,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { OnboardingWorkflow } from './components/OnboardingWorkflow/OnboardingWorkflow';
import {
  ActivityManagerContextProvider,
  useActivity,
  useContextualActivity,
} from './hooks/useActivityContext';
import { ProcessingActivityEdit } from './components/ProcessingActivityEdit/ProcessingActivityEdit';
import { DebugView } from '../../components/DebugView/DebugView';

/**
 * The `ActivityPageInner` component loads the system and translations, throwing
 * a promise to invoke suspense in the case that the system hasn't loaded yet,
 * and returning the onboarding workflow if onboarding is not yet finished,
 * followed by the `SystemsEdit` view.
 */
export const ActivityPageInner = () => {
  const match = useRouteMatch();
  const { id } = useParams<any>();
  const {
    loading,
    current,
  } = useContextualActivity();

  if ((!current && loading) || (!current.id && id !== 'new')) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw new Promise(() => {});
  }

  if (!current.onboarded) {
    return (
      <>
        <OnboardingWorkflow />
        <DebugView>
          <pre>
            {JSON.stringify(current, null, 2)}
          </pre>
        </DebugView>
      </>
    );
  }

  return (
    <>
      <Route path={`${match.path}/:tab?`} component={ProcessingActivityEdit} />
      <DebugView>
        <pre>
          {JSON.stringify(current, null, 2)}
        </pre>
      </DebugView>
    </>
  );
};

export const Loader = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      flexDirection: 'column',
    }}
  >
    <Spinner color="#1F6187" name="cube-grid" fadeIn="quarter" />
    <strong style={{ marginTop: '1rem' }}>Loading your activity!</strong>
  </div>
);

/**
 * The `ActivityPage` handles Suspense for the inner page.
 */
export const ActivityPage = () => {
  const activityManager = useActivity();

  return (
    <ActivityManagerContextProvider manager={activityManager}>
      <Suspense fallback={<Loader />}>
        <ActivityPageInner />
      </Suspense>
    </ActivityManagerContextProvider>
  );
};
