import React from 'react';
import { TableCell, ResizableTableHeader } from '@privacy-request/ui/src/atoms/SASSTable';
import {
  FieldBuilderComponent,
  FieldBuilderProps,
  FieldHeaderComponent,
  FieldHeaderProps,
} from './types/ColumnDefinition';
import { sortProps } from '../../../hooks/useQueryPager/pagerUtils';
import { DateTime } from '../../DateTime/DateTime';

export const Header: FieldHeaderComponent = ({
  sort,
  onSortChange,
  t,
}: FieldHeaderProps) => (
  <ResizableTableHeader key="approved_at" {...sortProps('approved_at', sort, onSortChange)}>{t('table.approved_at')}</ResizableTableHeader>
);

export const Cell: FieldBuilderComponent = ({
  row,
}: FieldBuilderProps) => (
  <TableCell key="approved_at"><DateTime format="human-date" date={row.approved_at!} /></TableCell>
);

export const fields = ['approved_at'];
