import React, { useMemo } from 'react';
import { TableHeader, TableRow } from '@privacy-request/ui/src/atoms/SASSTable';
import { useTranslation } from 'react-i18next';
import { Field, headerBuilders } from './fieldBuilders';

export type RequestListHeaderProps = {
  /**
   * List of fields/columns to display, in order
   */
  fields: Field[]
  /**
   * The current sorts
   */
  sort?: any
  /**
   * Function to change sorts
   */
  onSortChange?: any
};

export const RequestListHeader = ({
  sort,
  onSortChange,
  fields,
}: RequestListHeaderProps) => {
  const [t] = useTranslation('requests');

  const builderProps = useMemo(() => ({
    onSortChange,
    sort,
    t,
  }), [onSortChange, sort, t]);

  return (
    <TableRow>
      {
        fields.map((field) => (headerBuilders[field](builderProps)))
      }
      <TableHeader shrink />
    </TableRow>
  );
};
