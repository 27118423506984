import { stripSlashes } from '@privacy-request/utils';
import {
  ACTIVITIES_BASE_PATH,
  SERVICES_BASE_PATH,
  SYSTEMS_BASE_PATH,
} from '../constants';
import { useAuth } from './useAuth';

export const useNavigationUrls = () => {
  const { me } = useAuth();
  const is_ISO = me?.organization?.framework_context === 'ISO';

  return {
    activityUrl: (path: string) => (
      `/${is_ISO ? SERVICES_BASE_PATH : ACTIVITIES_BASE_PATH}/${stripSlashes(path)}`
    ),
    assetUrl: (path: string) => (
      `/${SYSTEMS_BASE_PATH}/${stripSlashes(path)}`
    ),
  };
};
